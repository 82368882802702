import { useParams } from "react-router-dom";
import PaginatedList from "../pagination/PaginatedList";
import useProjectPurchases from "./hooks/useProjectPurchases";
import ResourcePurchaseList from "./ResourcePurchaseList";
import PurchaseButtons from "./PurchaseButtons";
import { purchaseFilters } from "./PurchasesList";
import DocsModal from "../utils/DocsModal";
import { useState } from "react";
import useProjectJobPurchases from "./hooks/useProjectJobPurchases";

const ProjectJobPurchases = (props : any) => {
  const { jobUuid } = useParams<{ jobUuid: string }>();

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/accounts-9332/purchasing/" />
      <PaginatedList
        listName="projectJobPurchasesList"
        indexHook={useProjectJobPurchases}
        indexHookArguments={[jobUuid]}
        originalFilters={purchaseFilters}
        list={({ data }) => {
          return (
            <div className="col-12">
              <ResourcePurchaseList
                purchases={data ?? []}
                project={props.project}
              />
            </div>
          );
        }}
      />
    </>
  );
};

export default ProjectJobPurchases;
