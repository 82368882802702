import dayjs from "dayjs";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import { useEffect } from "react";
import { IUseApiWithData } from "../api/apiTypes";
import { BranchIndex } from "../branches/branchTypes";
import { ModalHeader, Modal, ModalBody } from "reactstrap";
import BottomLogo from "../utils/BottomLogo";
import { BiChevronRight } from "react-icons/bi";
import { Link } from "react-router-dom";

const BranchUpdateReminder = () => {
  const { data }: IUseApiWithData<BranchIndex[]> = useApi(
    dayjs().day() === 1 ? "add-weekly-forecast" : "",
    [],
  );

  const { modal, setModal } = useModal();

  useEffect(() => {
    if (data.length > 0) {
      setModal(true);
    }
  }, [data]);

  return (
    <Modal className="wd-md-1000 mx-wd-800 w-95" isOpen={modal}>
      <ModalHeader>Branch Updates Required.</ModalHeader>
      <ModalBody>
        <p className="mb-3">
          The following branches require updates to their weekly forecasts.
        </p>
        <div className="space-y-3">
          {data.map((branch) => (
            <div>
              <BranchUpdateLink
                branch={branch}
                key={branch.uuid}
                branches={data}
              />
            </div>
          ))}
        </div>
      </ModalBody>
    </Modal>
  );
};

export const BranchUpdateLink = ({
  branch,
  branches,
}: {
  branch: BranchIndex;
  branches: BranchIndex[];
}) => {
  return (
    <Link
      to={{
        pathname: `/branches/${branch.uuid}/weekly-forecasts`,
        state: {
          otherBranches: branches,
          add: true,
        },
      }}
    >
      <div
        className="rounded-lg border p-3 justify-content-between position-relative d-flex align-items-center parent-hover-shift shadow-sm bg-white"
        key={branch.id}
      >
        <div>
          <p className="mb-0 text-dark no-wrap">{branch.name}</p>
          <p className="mb-0 text-secondary tx-12">{branch.number}</p>
        </div>
        <div className="btn ms-5 btn-warning btn-sm z-10 d-flex align-items-center gap-2 money no-wrap">
          Fill Forecast{" "}
          <BiChevronRight className="hover-shift-child" size={18} />
        </div>
        <BottomLogo size={20} left={250} bottom={15} />
      </div>
    </Link>
  );
};

export default BranchUpdateReminder;
