import { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { Configure, InstantSearch, useInfiniteHits } from "react-instantsearch";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import dayjs from "dayjs";
import { useEffect, useRef } from "react";
import { useAuth } from "../../context/auth-context";
import { EquipmentType, CalibrationUnit } from "../../enums/Model";
import { Equipment, EquipmentStatus } from "./equipmentTypes";
import {
  EquipmentTable,
  EquipmentHeader,
  EquipmentFooter,
  info,
} from "./EquipmentList";
import HeaderPage from "../header/HeaderPage";
import BossSearchBox from "../utils/algolia/BossSearchBox";
import PaginatedCard from "../pagination/PaginatedCard";
import EquipmentFilterModal from "./EquipmentFilterModal";
import ExportCsvButton from "../utils/typesense/ExportCsvButton";
import { isEquipmentInCalibration } from "./equipment";

interface EquipmentIndex extends Equipment {
  custom_values?: Array<{
    field_label: string;
    value: string;
    field_type: number;
  }>;
}

interface EquipmentFilters {
  withdrawn?: number[];
  next_calibration_due_date?: string;
  recurring_payment_next_date?: string;
  type_type?: string[];
  branch_id?: string[];
  type?: string[];
  custom_values?: {
    field_label?: string[];
    value?: string[];
  };
}

const EXPORT_HEADERS = [
  "name",
  "type",
  "make",
  "model",
  "serial_number",
  "call_sign",
  "branch",
  "group",
  "sub_location",
  "next_calibration_due_date",
  "withdrawn",
  "custom_values",
];

const VALUE_TRANSFORMERS = {
  custom_values: (item: EquipmentIndex) =>
    `"${
      item.custom_values
        ?.map((cv) => `${cv.field_label}: ${cv.value}`)
        .join("; ") || ""
    }"`,
  type: (item: EquipmentIndex) => item.type?.type || "",
  branch: (item: EquipmentIndex) => item.branch?.name || "",
  group: (item: EquipmentIndex) => item.group?.name || "",
  next_calibration_due_date: (item: EquipmentIndex) =>
    item.next_calibration_due_date
      ? dayjs.unix(item.next_calibration_due_date).format("YYYY-MM-DD")
      : "",
  withdrawn: (item: EquipmentIndex) => EquipmentStatus[item.withdrawn] || "",
};

const NewEquipmentList = () => {
  const { user } = useAuth();
  const [filters, setFilters] = useState<EquipmentFilters>({});
  const [showTable, setShowTable] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const searchClient = useMemo(() => {
    const adapter = new TypesenseInstantSearchAdapter({
      server: {
        apiKey: user?.search_key ?? "",
        nodes: [
          {
            host: "search.thebossapp.com.au",
            port: 443,
            protocol: "https",
          },
        ],
      },
      additionalSearchParameters: {
        query_by: [
          "name",
          "make",
          "model",
          "serial_number",
          "call_sign",
          "sub_location",
          "type.type",
          "branch.name",
          "group.name",
          "project.name",
          "custom_values.field_label",
          "custom_values.value",
          "number",
          "padded_number",
        ].join(","),
        per_page: 18,
        /**@ts-ignore */
        num_facet_candidates: -1,
      },
    });

    return adapter.searchClient;
  }, [user?.search_key]);

  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
  };

  const getFilterString = () => {
    const parts = [];

    if (filters.withdrawn?.length) {
      parts.push(`withdrawn:=[${filters.withdrawn.join(",")}]`);
    }
    if (filters.next_calibration_due_date) {
      parts.push(filters.next_calibration_due_date);
    }
    if (filters.recurring_payment_next_date) {
      parts.push(filters.recurring_payment_next_date);
    }

    return parts;
  };

  const getFilterStringForCsvExport = () => {
    const parts = [...getFilterString()];

    if (filters.branch_id?.length) {
      parts.push(`branch.name:=[${filters.branch_id.join(",")}]`);
    }
    if (filters.type_type?.length) {
      parts.push(`type.type:=[${filters.type_type.join(",")}]`);
    }
    if (filters.custom_values?.field_label?.length) {
      parts.push(
        `custom_values.field_label:=[${filters.custom_values.field_label.join(
          ",",
        )}]`,
      );
    }

    return parts.join(" && ");
  };

  const renderActionButtons = () => (
    <div className="mb-3 d-flex space-x-3">
      {user?.hasAccessTo("App\\Models\\Equipment", "create") && (
        <>
          <Link
            to="/equipment/add"
            className="btn btn-outline-primary p-1 px-2"
          >
            Add new Equipment
          </Link>
          <Link
            to="/equipment/import"
            className="btn btn-outline-primary p-1 px-2"
          >
            Import Equipment
          </Link>
        </>
      )}
      {user?.is_admin && (
        <Link
          to="/equipment/custom-fields"
          className="btn btn-outline-primary p-1 px-2"
        >
          Equipment Custom Fields
        </Link>
      )}
      <ExportCsvButton
        searchClient={searchClient}
        indexName={`${import.meta.env.VITE_SCOUT_PREFIX ?? ""}equipment`}
        filters={getFilterStringForCsvExport()}
        headers={EXPORT_HEADERS}
        valueTransformers={VALUE_TRANSFORMERS}
        filename="equipment_export"
        searchQuery={searchQuery}
      />
    </div>
  );

  return (
    <>
      <HeaderPage
        titlePage="Equipment"
        pageDescription="Track individual pieces of equipment in your organization. You can add new equipment, view existing equipment, and manage their calibrations and assignments."
        relatedLinks={[
          {
            name: "Equipment Types",
            link: "/equipment-types",
            model: EquipmentType,
          },
          {
            name: "Calibrations",
            link: "/calibration-units",
            model: CalibrationUnit,
          },
        ]}
        crumbs={[{ link: "equipment", name: "Equipment", active: true }]}
      />

      {renderActionButtons()}

      <InstantSearch
        searchClient={searchClient}
        indexName={`${import.meta.env.VITE_SCOUT_PREFIX ?? ""}equipment`}
      >
        <div className="d-flex space-x-3 align-items-center">
          <BossSearchBox onSearch={handleSearchChange} />
          <Configure filters={getFilterString().join(" && ")} />
          <EquipmentFilterModal setFilters={setFilters} filters={filters} />
          <Button
            outline
            size="sm"
            color="primary"
            className="ms-3"
            onClick={() => setShowTable(!showTable)}
          >
            {showTable ? "Show List" : "Show Table"}
          </Button>
        </div>
        <div className="row">
          <Hits showTable={showTable} />
        </div>
      </InstantSearch>
    </>
  );
};

const Hits = ({ showTable }: { showTable: boolean }) => {
  const { items, isLastPage, showMore } = useInfiniteHits<EquipmentIndex>();
  const sentinelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!sentinelRef.current) return;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !isLastPage) {
          showMore();
        }
      });
    });

    observer.observe(sentinelRef.current);

    return () => {
      observer.disconnect();
    };
  }, [isLastPage, showMore]);

  return (
    <div className="mt-3">
      {showTable ? (
        <div>
          <EquipmentTable equipment={items} />
        </div>
      ) : (
        <>
          <div className="row row-sm">
            {items?.map((equipment) => {
              const updatedEquipment = {
                ...equipment,
                is_in_calibration: isEquipmentInCalibration(equipment),
              };

              return (
                <PaginatedCard
                  key={equipment.id} // Add a key prop for React lists
                  header={<EquipmentHeader equipment={updatedEquipment} />}
                  info={info(updatedEquipment)}
                  bottomContent={
                    <EquipmentFooter equipment={updatedEquipment} />
                  }
                />
              );
            })}
          </div>
        </>
      )}
      <div ref={sentinelRef} />
    </div>
  );
};

export default NewEquipmentList;
