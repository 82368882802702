import { toast } from "react-toastify";
import useApi from "../../api/useApi";
import AddEditModal from "../../chartOfAccounts/AddEditModal";
import { ChartOfAccount } from "../../chartOfAccounts/chartOfAccountTypes";
import { FormSubmitHandler } from "redux-form";
import { useAuth } from "../../../context/auth-context";
import formError from "../../utils/formError";

const EditAccountModal = ({
  account,
  toggle,
  modal,
}: {
  account: ChartOfAccount | null;
  toggle: () => void;
  modal: boolean;
}) => {
  const { user } = useAuth();

  const { response } = useApi("chart-of-account-types", []);

  const { data: organisation } = useApi(
    `organisations/${user?.active_organisation.uuid}`,
  );

  const { data: taxTypes } = useApi("tax-types", []);

  const { takeAction } = useApi();

  const onSubmit: FormSubmitHandler<ChartOfAccount> = (values) => {
    return takeAction("update", `chart-of-accounts/${account?.uuid}`, values)
      .then(() => {
        toast.success("Account updated");

        toggle();
      })
      .catch(formError);
  };

  if (!account) {
    return null;
  }

  return (
    <AddEditModal
      toggle={toggle}
      form="EditAccountModal"
      title={`Edit Account: ${account.name}`}
      modal={modal}
      // @ts-ignore
      types={response?.data ?? []}
      taxCodes={taxTypes}
      initialValues={account}
      organisationGroupMembers={organisation?.group_members ?? []}
      onSubmit={onSubmit}
    />
  );
};

export default EditAccountModal;
