import dayjs from "dayjs";
import { toast } from "react-toastify";
import { SubmissionError } from "redux-form";
import swal from "sweetalert";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import errorSwal from "../utils/errorSwal";
import formError from "../utils/formError";
import isSubmitting from "../utils/submitting";
import DownloadInvoiceButton from "./DownloadInvoiceButton";
import SendInvoiceButton from "./SendInvoiceButton";
import SendModal from "./SendModal";
import { useAuth } from "../../context/auth-context";

const ProfileButton = ({ invoice, setInvoice }) => {
  const { takeAction, loading: submitting } = useApi();
  const { toggle, modal } = useModal();
  const { user } = useAuth();

  const { data: contacts } = useApi(
    `projects/${invoice.project.uuid}/contacts?filter[invoice_recipient]=true`,
    [],
  );

  const submit = (values) => {
    if (invoice?.integration_estimation?.length > 0) {
      return swal({
        title: "Unable to edit",
        text: "It looks like this invoice is out of sync with your accounting integration, please ask an admin to sync up before continuing.",
        icon: "warning",
      });
    }
    return takeAction("update", `invoices/${invoice.uuid}`, values)
      .then(({ data }) => {
        setInvoice(data.data);
        toast.success("Invoice Updated");
      })
      .catch(errorSwal);
  };

  const hasIntegration = invoice.integration;

  const toCC =
    contacts?.map(({ email, name }) => ({
      label: name,
      value: email,
    })) ?? [];

  toCC.push({
    label: user.secondary_email ?? user.email,
    value: user.secondary_email ?? user.email,
  });

  return (
    <ul className="nav nav-activity-profile mg-y-20">
      <DownloadInvoiceButton invoice={invoice} />
      {!invoice.requested_at && (
        <li className="nav-item">
          <button
            type="button"
            onClick={() => {
              if (invoice.integration) {
                toggle();
                return;
              }

              submit({
                requested_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                completed_at: invoice.integration
                  ? dayjs().format("YYYY-MM-DD HH:mm:ss")
                  : null,
              });
            }}
            className="pointer-hover w-100 nav-link"
            disabled={submitting}
          >
            <i className="icon ion-plus tx-primary" />{" "}
            {isSubmitting(
              submitting,
              `${hasIntegration ? "Approve and send" : "Request"} ${
                invoice.is_credit_note ? "Credit Note" : "Invoice"
              }`,
              "Requesting...",
            )}
          </button>
        </li>
      )}
      {invoice.requested_at && !invoice.completed_at && (
        <>
          <li className="nav-item">
            <button
              type="button"
              onClick={() =>
                submit({ completed_at: dayjs().format("YYYY-MM-DD HH:mm:ss") })
              }
              className="pointer-hover w-100 nav-link"
              disabled={submitting}
            >
              <i className="icon ion-plus tx-primary" />{" "}
              {isSubmitting(submitting, "Mark as Invoiced")}
            </button>
          </li>
          <li className="nav-item">
            <button
              type="button"
              onClick={() => submit({ requested_at: null })}
              className="pointer-hover w-100 nav-link"
              disabled={submitting}
              style={{ minWidth: "200px" }}
            >
              <i className="icon ion-minus tx-danger" />{" "}
              {isSubmitting(submitting, "Unmark as Ready to be invoiced")}
            </button>
          </li>
        </>
      )}
      {invoice.completed_at && (
        <>
          <li className="nav-item">
            <button
              type="button"
              onClick={() => {
                const data = { completed_at: null };
                if (invoice.integration) {
                  data.requested_at = null;
                }

                submit(data);
              }}
              className="pointer-hover w-100 nav-link"
              disabled={submitting}
            >
              <i className="icon ion-minus tx-danger" />{" "}
              {isSubmitting(
                submitting,
                `${hasIntegration ? "UnApprove" : "Unmark as"} ${
                  invoice.is_credit_note
                    ? hasIntegration
                      ? "Credit Note"
                      : "Credited"
                    : "Invoiced"
                }`,
              )}
            </button>
          </li>
        </>
      )}

      <SendInvoiceButton contacts={contacts} invoice={invoice} />
      <SendModal
        toggle={toggle}
        modal={modal}
        form="SendInvoice"
        onSubmit={(values) => {
          if (!values.to || values.to.length === 0) {
            throw new SubmissionError({
              _error: "Please specify an email to send to.",
            });
          }

          return submit({
            requested_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
            completed_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
            to: values.to?.map(({ value }) => value),
            cc: values.cc?.map(({ value }) => value) ?? [],
            send_attachments: values.send_attachments,
            merge_attachments: values.merge_attachments,
          })
            .then(() => {
              toggle();
              toast.success("Invoice sent");
            })
            .catch(formError);
        }}
        title={`Approve and send ${invoice.name}`}
        initialValues={{
          to: invoice.client.email?.map((email) => ({
            label: email,
            value: email,
          })),
          cc: toCC,
        }}
        invoice={invoice}
      />
    </ul>
  );
};

export default ProfileButton;
