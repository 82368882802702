import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getJobProductivity = (
  { pageParam = 1 },
  jobId: string,
  search?: string,
) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(`jobs/${jobId}/productivity?filter[search]=${search}`)
    .then(({ data }) => data);
};

export default function useJobProductivity(search: string, jobId: string) {
  return useInfiniteQuery(
    ["job-productivity", { jobId, search }],
    (pageParam) => getJobProductivity(pageParam, jobId, search),
    {
      getNextPageParam: ({ meta }) => {
        if (!meta) {
          return;
        }

        if (meta.current_page === meta.last_page) {
          return;
        }

        return meta.current_page + 1;
      },
    },
  );
}
