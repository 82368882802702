import { useInfiniteQuery } from "react-query";
import api from "../../../api/api";

const getBranchWeeklyForecasts = (
  { pageParam = 1 },
  branchUuid: string,
  search?: string,
) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(
      `branches/${branchUuid}/weekly-forecasts?page=${pageParam}&filter[search]=${search}`,
    )
    .then(({ data }) => data);
};

export default function useBranchWeeklyForecasts(
  branchUuid: string,
  search?: string,
) {
  return useInfiniteQuery(
    ["branch-weekly-forecasts", { branchUuid, search }],
    (pageParam) => getBranchWeeklyForecasts(pageParam, branchUuid, search),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }

        return meta.current_page + 1;
      },
      enabled: !!branchUuid,
    },
  );
}
