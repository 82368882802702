import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { connect } from "react-redux";
import useTimesheetDates from "./hooks/useTimesheetDates";
import useUnapprovedLeaveDates from "./hooks/useUnapprovedLeaveDates";

const TimesheetDayPicker = (props) => {
  const { selectedDay, handleDayClick, user } = props;
  const { data: timesheets } = useTimesheetDates(user.id);
  const { data: unApproveLeaveDates } = useUnapprovedLeaveDates(user.id);

  const missingTimesheetdays = user.missing_timesheet
    ? user.missing_timesheet.map((date) => new Date(date))
    : [];

  const timesheetDays = timesheets
    ? timesheets.map((timesheet) => new Date(timesheet.date))
    : [];
  
  const leaveDates = unApproveLeaveDates
    ? unApproveLeaveDates.map((date) => new Date(date))
    : [];

  const modifiers = {
    missingTimesheetdays: missingTimesheetdays,
    timesheetDays: timesheetDays,
    unapprovedLeave: leaveDates,
  };

  return (
    <>
      <DayPicker
        selected={selectedDay}
        onDayClick={handleDayClick}
        modifiers={modifiers} // Pass the combined modifiers
        modifiersClassNames={{
          missingTimesheetdays: "missingTimesheetdaysClass",
          timesheetDays: "timesheetDays",
          unapprovedLeave: "unapprovedLeaveClass",
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedDay: state.timesheets.selectedDay,
});

export default connect(mapStateToProps, {})(TimesheetDayPicker);
