import CustomNotificationForm from "./CustomNotificationForm";
import HeaderPage from "../header/HeaderPage";
import useApi from "../api/useApi";
import { toast } from "react-toastify";
import formError from "../utils/formError";
import { useAuth } from "../../context/auth-context";

const AddCustomNotification = () => {
  const { takeAction } = useApi();

  const { user } = useAuth();

  const onSubmit = (values: any) => {
    return takeAction("store", "custom-notifications", values)
      .then(() => {
        toast.success("Custom Notification Created Successfully");
      })
      .catch(formError);
  };

  return (
    <>
      <HeaderPage
        titlePage="Add Custom Notification"
        crumbs={[
          {
            name: "Custom Notifications",
            link: "/custom-notifications",
            active: false,
          },
          {
            name: "Add Custom Notification",
            link: "/custom-notifications/add",
            active: true,
          },
        ]}
      />
      <CustomNotificationForm
        initialValues={{
          notifiable_model: "App\\Models\\Purchase",
          notifiable_id: user?.id,
          notifiable_type: "App\\Models\\User",
        }}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default AddCustomNotification;
