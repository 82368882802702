import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import NoMatch from "../404";
import UserMicrosoftDetails from "../integrations/microsoft/UserMicrosoftDetails";
import RequestedLeave from "../leave/RequestedLeave";
import ProfileDocuments from "../standardDocuments/ProfileDocuments";
import Timesheets from "../timesheets/Timesheets";
import ExpenseClaims from "./ExpenseClaims";
import Invites from "./Invites";
import MultiFactorAuthentication from "./MultiFactorAuthentication";
import { PendingApprovals } from "./PendingApprovals";
import UserDetailTabs from "./UserDetailTabs";
import UsersStaffRoles from "./UsersStaffRoles";
import AdminDeleteTwoFactorAuthentication from "./AdminDeleteTwoFactorAuthentication";
import ProjectEmails from "../projects/ProjectEmails";
import { GroupType } from "./userTypes";
import UsersOrganisations from "./UsersOrganisations";
import UserPerformanceReviews from "./UserPerformanceReviews";
import SupportTicket from "./SupportTicket";
import OnboardingResponses from "./OnboardingResponses";

const ShowPage = (props) => {
  const { page, userId } = useParams();

  const { user, setUser } = props;

  const { user: authUser, setUser: setAuthUser } = useAuth();

  const isAdmin = authUser?.is_admin;

  const isIT = authUser?.groups.includes(GroupType.IT);

  const isCurrentUser = authUser?.id === user?.id;

  const isCurrentUserOrganisation =
    authUser?.active_organisation?.id ===
    user?.organisation_user?.organisation_id;

  if (
    !isCurrentUser &&
    !isAdmin &&
    isIT &&
    page !== "admin-mfa" &&
    page !== "microsoft"
  ) {
    return <NoMatch />;
  }

  switch (page) {
    case "profile":
      return (
        <UserDetailTabs user={user} setUser={setUser} initialValues={user} />
      );
    case "documents":
      return (
        <ProfileDocuments
          {...props}
          model="App\Models\User"
          documentableId={userId}
          documentPath={`organisations/${authUser.active_organisation.uuid}/documents/users/${userId}`}
          homeUrl={`users/${userId}/documents`}
        />
      );
    case "timesheets":
      return isCurrentUserOrganisation ? (
        <Timesheets user={user} initialValues={user} {...props} />
      ) : (
        <NoMatch />
      );
    case "requested-leave":
      return isCurrentUserOrganisation ? (
        <RequestedLeave user={user} />
      ) : (
        <NoMatch />
      );
    case "pending-approvals":
      return isCurrentUserOrganisation ? (
        <PendingApprovals {...props} />
      ) : (
        <NoMatch />
      );
    case "invites":
      return isCurrentUserOrganisation ? <Invites /> : <NoMatch />;
    case "expense-claims":
      return isCurrentUserOrganisation ? <ExpenseClaims /> : <NoMatch />;
    case "employment-details":
      return isCurrentUserOrganisation ? (
        <UsersStaffRoles user={user} />
      ) : (
        <NoMatch />
      );
    case "microsoft":
      return isCurrentUserOrganisation ? (
        <UserMicrosoftDetails {...props} />
      ) : (
        <NoMatch />
      );
    case "mfa":
      return isCurrentUserOrganisation ? (
        <MultiFactorAuthentication />
      ) : (
        <NoMatch />
      );
    case "admin-mfa":
      return isCurrentUserOrganisation ? (
        <AdminDeleteTwoFactorAuthentication {...props} />
      ) : (
        <NoMatch />
      );
    case "organisations":
      return (
        <UsersOrganisations
          organisationUsers={props.organisationUsers}
          refreshData={props.refreshData}
        />
      );
    case "emails":
      return isCurrentUserOrganisation ? (
        <ProjectEmails
          messagableId={user.id}
          messagableType="App\\Models\\User"
        />
      ) : (
        <NoMatch />
      );
    case "performance-reviews":
      return isCurrentUserOrganisation ? (
        <UserPerformanceReviews />
      ) : (
        <NoMatch />
      );
    case "support-tickets":
      return isCurrentUserOrganisation ? (
        <SupportTicket user={user} />
      ) : (
        <NoMatch />
      );
    case "onboarding-responses":
      return isCurrentUserOrganisation ? (
        <OnboardingResponses user={user} />
      ) : (
        <NoMatch />
      );
    default:
      return <NoMatch />;
  }
};

export default ShowPage;
