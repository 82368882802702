import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import errorSwal from "../utils/errorSwal";
import * as actions from "./actions";

/**
 *
 * @param {string=} initialUrl
 * @param {any=} initialData
 * @param {bool} [initialLoading=false]
 * @param {null|string|function=} redirectToIfNotFound
 */
const useApi = (
  initialUrl,
  initialData = null,
  initialLoading = false,
  redirectToIfNotFound,
  showError = true,
) => {
  const history = useHistory();

  const [loading, setLoading] = useState(initialLoading);
  const [response, setResponse] = useState(undefined);
  const [data, setData] = useState(initialData);
  const [url, setUrl] = useState(initialUrl);
  const [refresher, setRefresher] = useState(0);

  const takeAction = (type, url, data, config) => {
    if (actions[type] === undefined) {
      throw new Error(`You have passed "${type}" as your action in the 'takeAction' function, which is not supported. 
      Make sure you are only using store, update, destroy, index or show as actions.`);
    }

    setLoading(true);
    return actions[type](url, data, config)
      .then((res) => {
        setResponse(res);
        setLoading(false);
        return res;
      })
      .catch((err) => {
        setLoading(false);
        setResponse(err);
        return Promise.reject(err);
      });
  };

  useEffect(() => {
    if (url) {
      setLoading(true);

      // Create abort controller for this request
      const abortController = new AbortController();

      actions
        .index(url, { signal: abortController.signal }) // Pass signal to request
        .then((res) => {
          setResponse(res);
          setData(res.data.data);
        })
        .catch((err) => {
          // Don't process error if request was aborted
          if (err.name === "AbortError") return;

          if (
            [404, 403].includes(err?.response?.status) &&
            redirectToIfNotFound
          ) {
            if (typeof redirectToIfNotFound === "string") {
              history.push(redirectToIfNotFound);
            } else {
              redirectToIfNotFound();
            }
          }

          setResponse(err?.response);
          return showError ? errorSwal(err) : null;
        })
        .finally(() => {
          setLoading(false);
        });

      // Cleanup function to abort request when component unmounts
      // or when url/refresher changes
      return () => {
        abortController.abort();
      };
    }
  }, [url, refresher]);

  const refreshData = () => setRefresher(refresher + 1);

  return {
    loading,
    setLoading,
    takeAction,
    response,
    setUrl,
    data,
    setData,
    refreshData,
    url,
  };
};

export default useApi;
