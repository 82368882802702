import { useState, useEffect } from "react";
import { BudgetItem } from "../types";
import TextButton from "../../utils/TextButton";
import { BiMoney } from "react-icons/bi";
import { FaRegComment } from "react-icons/fa";
import { ChartOfAccount } from "../../chartOfAccounts/chartOfAccountTypes";

interface InputProps {
  budgetItem: BudgetItem;
  onBlur: (budgetItem: BudgetItem) => void;
  account: ChartOfAccount | null;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const Input = ({ budgetItem, onBlur, account, onKeyDown }: InputProps) => {
  const [value, setValue] = useState<string>(
    budgetItem.amount ? String(budgetItem.amount) : "",
  );

  const [comments, setComments] = useState<string>(budgetItem.comment || "");

  const [isEditing, setIsEditing] = useState(false);

  // When budgetItem changes, update the value
  useEffect(() => {
    setValue(budgetItem.amount ? String(budgetItem.amount) : "");
  }, [budgetItem]);

  const handleBlur = () => {
    // Only update if the value has changed
    if (
      value !== String(budgetItem.amount) ||
      comments !== budgetItem.comment
    ) {
      onBlur({
        ...budgetItem,
        amount: value,
        comment: comments,
      });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Handle Enter key to blur the input (save the value)
    if (e.key === "Enter") {
      e.preventDefault();
      e.currentTarget.blur();
      return;
    }

    // Pass other key events to the parent component
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  return (
    <div
      className="position-relative bg-white w-100 h-100"
      style={{
        minWidth: value.length * 20 > 100 ? 100 : value.length * 20,
      }}
    >
      <input
        title={`Enter ${isEditing ? "amount" : "notes"} for ${
          account?.code
        } - ${account?.name}`}
        type="text"
        id="cell-input"
        className="border-0 position-relative rounded-lg w-100 h-100"
        value={isEditing ? comments : value}
        onBlur={handleBlur}
        onChange={(e) => {
          isEditing ? setComments(e.target.value) : setValue(e.target.value);
        }}
        onKeyDown={handleKeyDown}
        style={{
          boxSizing: "border-box",
          padding: "0.5rem",
          textAlign: "right", // Align text to the right for numbers
        }}
      />
      <TextButton
        id="edit-button"
        className="position-absolute text-secondary"
        style={{ top: "7px", left: "8px", zIndex: 1 }}
        onClick={() => setIsEditing(!isEditing)}
      >
        {isEditing ? <BiMoney /> : <FaRegComment />}
      </TextButton>
    </div>
  );
};

export default Input;
