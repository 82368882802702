import { useMemo } from "react";
import useApi from "../api/useApi";
import ReactTable from "../table/ReactTable";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { MdAdd, MdOutlineContentCopy } from "react-icons/md";
import { Link } from "react-router-dom";
import HeaderPage from "../header/HeaderPage";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import deleteSwal from "../utils/deleteSwal";

const NewUserInvitedList = () => {
  const { data: users, setData } = useApi("users?filter[no_password]=true", [], true);

  const columns = useMemo(
    () => [
      {
        header: "Name",
        accessor: "full_name",
        cell: (info: any) => {
          const user = info.row.original;
          return (
            <div>
              <Link
                to={`/users/invite/add/${user.uuid}`}
                className="text-dark mb-0"
              >
                {user.full_name}
              </Link>
              <CopyToClipboard
                text={user.email}
                options={{ format: "text/plain" }}
                onCopy={() => toast.success("Email copied to clipboard")}
              >
                <p className="mb-0 pointer-hover">
                  {user.email} <MdOutlineContentCopy />
                </p>
              </CopyToClipboard>
              <small className="text-muted">
                {user.primary_position?.name}
              </small>
            </div>
          );
        },
      },
      {
        header: "Status",
        cell: (info: any) => {
          const user = info.row.original;

          const { staff_role_count, is_contractor, payroll_integration } = user;

          if (staff_role_count == 0) {
            return (
              <div
                className="d-flex h-100 align-items-center"
                style={{ padding: "8px 0" }}
              >
                <span className="bg-warning text-white rounded-pill px-2 text-sm">
                  No Staff Role
                </span>
              </div>
            );
          }

          if (staff_role_count > 0 && is_contractor) {
            return (
              <div
                style={{ padding: "8px 0" }}
                className="d-flex h-100 align-items-center"
              >
                <span className="bg-success text-white rounded-pill px-2 text-sm">
                  Completed
                </span>
              </div>
            );
          }

          if (!is_contractor && !payroll_integration) {
            return (
              <div
                style={{ padding: "8px 0" }}
                className="d-flex h-100 align-items-center"
              >
                <span className="bg-warning text-white rounded-pill px-2 text-sm">
                  Awaiting Payroll Information
                </span>
              </div>
            );
          }

          return (
            <div
              style={{ padding: "8px 0" }}
              className="d-flex h-100 align-items-center"
            >
              <span className="bg-success text-white rounded-pill px-2 text-sm">
                Completed
              </span>
            </div>
          );
        },
      },
      {
        header: "Actions",
        accessor: "actions",
        cell: (info: any) => {
          const user = info.row.original;
          const { takeAction, loading } = useApi();

          const deleteUser = (user: any) => {
            return deleteSwal("", `Delete ${user.full_name}'s invitation?`)
              .then(() => takeAction("destroy", `users/${user.uuid}`))
              .then(() => {
                toast.success("User cancelled successfully");
                setData((prevUsers: any[]) => 
                  prevUsers.filter((u) => u.uuid !== user.uuid)
                );
              })
              .catch(errorSwal);
          };

          return (
            <div
              className="d-flex h-100 align-items-center gap-2"
              style={{ padding: "8px 0" }}
            >
              <button
                className="btn btn-outline-primary btn-sm"
                disabled={loading}
                onClick={() =>
                  takeAction("store", `users/${user.uuid}/resend-password-link`)
                    .then(() => toast.success("Password link sent"))
                    .catch(errorSwal)
                }
              >
                {isSubmitting(loading, "Resend Password Link", "Resending...")}
              </button>
              <button
                className="btn btn-outline-danger btn-sm"
                disabled={loading}
                onClick={() => deleteUser(user)}
              >
                Delete
              </button>
            </div>
          );
        },
      },
    ],
    [users],
  );

  return (
    <>
      <HeaderPage titlePage="Users Not Signed In" crumbs={[]} />
      <ReactTable
        extraButtons={
          <Link className="ms-3" to="/users/invite/add">
            <MdAdd className="tx-18" /> Add New User
          </Link>
        }
        columns={columns}
        data={users}
      />
    </>
  );
};

export default NewUserInvitedList;
