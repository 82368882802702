import { Link } from "react-router-dom";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import PaginatedCard from "../pagination/PaginatedCard";
import PaginatedList from "../pagination/PaginatedList";
import usePaySchedules from "./hooks/usePaySchedules";
import { PayScheduleIndex } from "./payScheduleTypes";

const PayScheduleList = () => {
  return (
    <>
      <HeaderPageAdminFilter
        titlePage="Pay Schedules"
        pageDescription="Manage your organization's pay schedules"
        crumbs={[
          { link: "pay-schedules", name: "Pay Schedules", active: true },
        ]}
      />

      <PaginatedList
        listName="payScheduleList"
        originalFilters={[]}
        indexHook={usePaySchedules}
        addLink="/pay-schedules/add"
        itemCard={({ item }: { item: PayScheduleIndex }) => (
          <PaginatedCard
            key={item.uuid}
            info={[
              {
                name: "Name",
                value: item.name,
              },
              {
                name: "Frequency",
                value:
                  item.frequency.charAt(0).toUpperCase() +
                  item.frequency.slice(1),
              },
            ]}
            header={
              <div className="w-100">
                <div className="d-flex align-items-center w-100">
                  <div className="ms-1" style={{ width: "85%" }}>
                    <p className="mb-0 fw-bolder tx-inverse no-wrap">
                      <Link to={`/pay-schedules/${item.uuid}/edit`}>
                        {item.name}
                      </Link>
                    </p>
                    <p className="mb-0">{item.frequency}</p>
                  </div>
                </div>
              </div>
            }
          />
        )}
      />
    </>
  );
};

export default PayScheduleList;
