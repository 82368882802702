import React, { useEffect } from "react";
import { EquipmentCalibrationType } from "./equipmentCalibrationTypeTypes";
import {
  Field,
  FieldArray,
  FieldArrayFieldsProps,
  FormSubmitHandler,
  InjectedFormProps,
  WrappedFieldArrayProps,
  change,
  formValueSelector,
  reduxForm,
} from "redux-form";
import FormHeader from "../utils/FormHeader";
import SelectInput from "../form/SelectInput";
import { IUseApi, IUseApiWithData } from "../api/apiTypes";
import useApi from "../api/useApi";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import SubmitButton from "../utils/SubmitButton";
import { toast } from "react-toastify";
import renderToggleInput from "../utils/renderToggleInput";

interface EquipmentCalibrationWorksheetProps {
  calibrationType: EquipmentCalibrationType;
}

const EquipmentCalibrationWorksheet = (
  props: InjectedFormProps<{}, EquipmentCalibrationWorksheetProps> &
    EquipmentCalibrationWorksheetProps,
) => {
  const { calibrationType, handleSubmit } = props;

  const { takeAction }: IUseApi = useApi();

  const {
    data: equipmentTypes,
  }: IUseApiWithData<{ name: string; id: number }[]> = useApi(
    "custom-fields/equipment-types",
    [],
  );

  const { data: worksheets }: IUseApiWithData<string[]> = useApi(
    `workbook-sheets?id=${calibrationType.document_id}`,
    [],
  );

  // Add state to track deleted UUIDs
  const [deletedUuids, setDeletedUuids] = React.useState<string[]>([]);

  const onSubmit: FormSubmitHandler<{}, EquipmentCalibrationWorksheetProps> = (
    data,
  ) => {
    const submitData = {
      ...data,
      deleted_uuids: deletedUuids // Add deleted UUIDs to submission
    };

    return takeAction(
      "update",
      `calibration-types/${calibrationType.uuid}/worksheet`,
      submitData,
    ).then(() => {
      setDeletedUuids([]); // Clear deleted UUIDs after successful submission
      toast.success("Worksheet updated successfully");
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <FieldArray
          name="worksheet_equipment_types"
          equipmentTypes={equipmentTypes}
          component={EquipmentTypeSheets}
          worksheets={worksheets}
          setDeletedUuids={setDeletedUuids}
        />
        <div className="col-12 form-group">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const EquipmentTypeSheets = ({
  fields,
  worksheets,
  equipmentTypes,
  setDeletedUuids,
}: WrappedFieldArrayProps & {
  worksheets: string[];
  equipmentTypes: { name: string; id: number }[];
  setDeletedUuids: (fn: (prev: string[]) => string[]) => void;
}) => {
  const handleRemoveSheet = (index: number) => {
    const currentValues = fields.getAll() || [];
    const removedSheet = currentValues[index];
    
    // If the sheet has a UUID, add it to deletedUuids
    if (removedSheet?.uuid) {
      setDeletedUuids(prev => [...prev, removedSheet.uuid]);
    }
    
    const updatedValues = [
      ...currentValues.slice(0, index),
      ...currentValues.slice(index + 1)
    ];
    
    change('equipmentCalibrationWorksheet', 'worksheet_equipment_types', updatedValues);
    fields.remove(index);
  };
  
  return (
    <>
      <FormHeader>Equipment Sheets</FormHeader>
      {fields.map((name, index) => {
        return (
          <React.Fragment key={name}>
            <div className="col-12 form-group">
              <Field
                component={SelectInput}
                name={`${name}.sheet_name`}
                label="Sheet Name"
                options={worksheets.map((sheet) => ({
                  label: sheet,
                  value: sheet,
                }))}
              />
            </div>
            <FieldArray
              component={EquipmentTypeFields}
              name={`${name}.equipment_types`}
              equipmentTypes={equipmentTypes}
              handleRemoveSheet={handleRemoveSheet}
              sheetFieldIndex={index}
            />
          </React.Fragment>
        );
      })}
      <div className="col-12">
        <Button color="primary" onClick={() => fields.push({})}>
          Add Equipment Sheet
        </Button>
      </div>
    </>
  );
};

const EquipmentTypeFields = ({
  fields,
  equipmentTypes,
  handleRemoveSheet,
  sheetFieldIndex,
}: WrappedFieldArrayProps & {
  equipmentTypes: { name: string; id: number }[];
  handleRemoveSheet: (index: number) => void;
  sheetFieldIndex: number;
}) => {
  return (
    <>
      {fields.map((field, index) => {
        return (
          <WrappedEquipmentType
            key={field}
            field={field}
            index={index}
            fields={fields}
            equipmentTypes={equipmentTypes}
          />
        );
      })}
      <div className="col-12 form-group d-flex gap-2">
        <Button onClick={() => fields.push({})}>Add Equipment Type</Button>

        <Button
          outline
          color="danger"
          onClick={() => handleRemoveSheet(sheetFieldIndex)}
        >
          Remove Sheet
        </Button>
      </div>
    </>
  );
};

const EquipmentType = ({
  field,
  index,
  fields,
  equipmentTypes,
  equipmentTypeId,
}: any) => {
  const { data, setUrl } = useApi("", []);

  useEffect(() => {
    if (equipmentTypeId) {
      setUrl(
        `/custom-fields?model=${encodeURIComponent(
          "App\\Models\\EquipmentType",
        )}&modelId=${equipmentTypeId}`,
      );
    }
  }, [equipmentTypeId]);

  return (
    <>
      <div className="col-lg-3 form-group">
        <Field
          component={SelectInput}
          name={`${field}.equipment_type_id`}
          label="Equipment Type"
          options={equipmentTypes.map((t: any) => ({
            label: t.name,
            value: t.id,
          }))}
        />
      </div>
      <div className="col-lg-3 form-group">
        <Field
          component={renderToggleInput}
          name={`${field}.ignore_branch`}
          label="Ignore Branch"
          information="Toggle this if you want to ignore branch for this equipment type"
        />
      </div>
      <div className="col-lg-4 form-group">
        <Field
          component={SelectInput}
          isMulti
          options={data.map((d: any) => ({
            label: d.field_attributes.label,
            value: d.id,
          }))}
          name={`${field}.custom_field_ids`}
          label="Custom Fields"
        />
      </div>
      <div className="col-lg-2 form-group d-flex align-self-end">
        <Button
          outline
          block
          className=""
          color="danger"
          onClick={() => fields.remove(index)}
        >
          Remove
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state: any, props: any) => {
  const selector = formValueSelector("equipmentCalibrationWorksheet");

  return {
    equipmentTypeId: selector(state, `${props.field}.equipment_type_id`),
  };
};

const WrappedEquipmentType = connect(mapStateToProps)(EquipmentType);

const form = reduxForm<{}, EquipmentCalibrationWorksheetProps>({
  form: "equipmentCalibrationWorksheet",
});

export default form(EquipmentCalibrationWorksheet);
