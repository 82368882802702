import { useEffect, useState } from "react";
import { ReactComponent as Empty } from "../../svgs/empty.svg";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import SVGContainer from "../utils/SVGContainer";
import AddModal from "./AddModal";
import CommentList from "./CommentList";
import ListModal from "./ListModal";
import { Button } from "reactstrap";
import { IUseApiWithData } from "../api/apiTypes";
import { Comment } from "./commentTypes";

interface SideListProps {
  commentableType: string;
  commentableId: number;
  refresh?: boolean;
}

const SideList = ({
  commentableType,
  commentableId,
  refresh
}: SideListProps) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const {
    data: comments,
    setData: setComments,
    loading,
    response,
    refreshData,
  } : IUseApiWithData<Comment[]> = useApi(
    commentableType && commentableId
      ? `comments?filter[commentable_type]=${encodeURIComponent(
          commentableType,
        )}&filter[commentable_id]=${commentableId}`
      : undefined,
    [],
    true,
  );

  useEffect(() => {
    refreshData();
  }, [refresh]);

  const { toggle: toggleViewAll, modal: modalViewAll } = useModal();

  if (loading) return null;

  if (comments.length === 0) {
    return (
      <>
        <SVGContainer SVG={Empty}>
          <p className="mg-y-20">
            No comments added{" "}
            <button onClick={toggle} className="btn btn-link p-0" type="button">
              click here
            </button>{" "}
            to start adding.
          </p>
        </SVGContainer>
        <AddModal
          commentableType={commentableType}
          commentableId={commentableId}
          toggle={toggle}
          modal={modal}
          comments={comments}
          setComments={setComments}
        />
      </>
    );
  }

  return (
    <>
      <div className="bg-white rounded-lg border">
        <div className="d-flex align-items-center pt-3 px-3">
          <label className="slim-card-title mb-0">Comments</label>
          <Button
            onClick={toggle}
            type="button"
            color="primary"
            outline
            size="sm"
            className="ms-auto"
          >
            Add Comment
          </Button>
        </div>

        <CommentList comments={comments} />

        {response?.data?.meta?.total > 5 && (
          <button
            type="button"
            onClick={toggleViewAll}
            className="bg-gray-100 text-secondary text-center border-0 w-100 rounded-bottom py-2"
          >
            View {response?.data?.meta?.total - 5} more comment
            {response?.data?.meta?.total - 5 > 1 && "s"}
          </button>
        )}
      </div>

      <AddModal
        commentableType={commentableType}
        commentableId={commentableId}
        toggle={toggle}
        modal={modal}
        comments={comments}
        setComments={setComments}
      />

      <ListModal
        modal={modalViewAll}
        toggle={toggleViewAll}
        commentableType={commentableType}
        commentableId={commentableId}
        response={response}
      />
    </>
  );
};

export default SideList;
