import dayjs from "dayjs";
import { Field, formValueSelector } from "redux-form";

import { connect } from "react-redux";
import { useAuth } from "../../context/auth-context";
import JOB_STATUS from "../../data/job_status";
import JobStatus from "../../enums/JobStatus";
import {
  default as renderField,
  default as RenderField,
  money,
} from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";
import { AdministrationLevels } from "../user/userTypes";
import FormErrorAlert from "../form/FormErrorAlert";

const JobForm = (props) => {
  const { user } = useAuth();

  const isBasic =
    user.position_administration_level == AdministrationLevels.Basic &&
    !user.is_admin;

  const {
    match: { params },
    initialValues,
    job,
    status,
    change,
  } = props;

  const isPositionAdmin = user.is_position_admin;

  return (
    <>
      <div className="row">
        <FormErrorAlert error={props.error} />
        <div className="form-group col-lg-12">
          <Field
            name="name"
            type="text"
            component={renderField}
            required
            validate={required}
            label="Job Name"
            extraProps={{
              disabled: job.can_only_edit_status || isBasic || job.has_invoices,
            }}
          />
          {job.has_invoices && (
            <small className="d-block">
              Unable to edit name for jobs that have invoices.
            </small>
          )}
        </div>

        <div className="form-group col-lg-4">
          <Field
            name="branch_id"
            label="Branch"
            required
            validate={required}
            component={SelectInputAsync}
            url="/organisationbranches/branches"
            placeholder="Select Branch"
            disabled={job.can_only_edit_status || isBasic || job.has_invoices}
            hideDisabled={true}
          />
          {job.has_invoices && (
            <small className="d-block">
              Unable to edit branch for jobs that have invoices.
            </small>
          )}
        </div>
        <div className="form-group col-lg-4">
          <Field
            name="project_manager"
            label="Job Manager"
            required
            validate={required}
            component={SelectInputAsync}
            url="/users/projectmanagers"
            placeholder="Select Project Manager"
            disabled={job.can_only_edit_status || isBasic}
          />
        </div>

        <div className="form-group col-lg-4">
          <Field
            name="users"
            label="Staff"
            multiple="true"
            component={SelectInputAsync}
            url="/users/staff"
            placeholder="Staff"
            disabled={job.can_only_edit_status || isBasic}
          />
          <small>
            Select Staff from Your Organisation that will be working on this job
          </small>
        </div>

        <div className="form-group col-lg-12">
          <Field
            name="description"
            label="Notes"
            type="textarea"
            textarea
            rows={3}
            cols={64}
            component={renderField}
            extraProps={{ disabled: job.can_only_edit_status || isBasic }}
          />
        </div>
        <div className="col-12 mb-3">
          <hr />
        </div>

        <div className="form-group col-lg-3">
          {initialValues.actual_start_date ? (
            <>
              <label className="form-control-label tx-inverse tx-semibold">
                Start Date
              </label>
              <p className="mg-b-0 form-control border-0 bg-transparent px-1 tx-inverse">
                {dayjs(initialValues.actual_start_date).format("DD/MM/YYYY")}
              </p>
            </>
          ) : (
            <Field
              name="scheduled_start_date"
              type="date"
              required
              validate={required}
              component={renderField}
              label="Scheduled Start Date"
              extraProps={{
                disabled: job.can_only_edit_status,
                min: "1900-01-01",
                max: "2999-12-31",
              }}
            />
          )}
        </div>
        <div className="col-lg-3 form-group">
          <Field
            name="scheduled_start_time"
            type="time"
            component={renderField}
            label="Scheduled Start time"
            extraProps={{ disabled: job.can_only_edit_status }}
          />
        </div>

        {job.status != 2 && (
          <div className="form-group col-lg-3">
            {initialValues.actual_finish_date ? (
              <>
                <label className="form-control-label tx-inverse tx-semibold">
                  Finish Date
                </label>
                <p className="mg-b-0 form-control border-0 bg-transparent px-1 tx-inverse">
                  {dayjs(initialValues.actual_finish_date).format("DD/MM/YYYY")}
                </p>
              </>
            ) : (
              <Field
                name="scheduled_finish_date"
                type="date"
                component={renderField}
                required
                validate={required}
                label="Scheduled Finish Date"
                extraProps={{
                  disabled: job.can_only_edit_status,
                  min: "1900-01-01",
                  max: "2999-12-31",
                }}
              />
            )}
          </div>
        )}

        <div className="col-lg-3 form-group">
          <Field
            name="scheduled_finish_time"
            type="time"
            component={renderField}
            label="Scheduled Finish time"
            extraProps={{ disabled: job.can_only_edit_status }}
          />
        </div>

        {/*  <div className="col-lg-12">
          <hr />
        </div>

        <div className="col-lg-12 mg-t-25" /> */}

        {isPositionAdmin && !isBasic && (
          <div className="form-group col-lg-3">
            <Field
              name="estimated_value"
              {...money}
              component={renderField}
              label="Budget/Contract Value"
              extraProps={{ disabled: job.can_only_edit_status }}
            />
          </div>
        )}

        {!isBasic && (
          <>
            <div className="form-group col-lg-3">
              <Field
                name="estimated_hours"
                type="number"
                component={renderField}
                label="Estimated Hours"
                extraProps={{ disabled: job.can_only_edit_status }}
              />
            </div>

            <div className="form-group col-lg-3">
              <Field
                name="job_dependency"
                label="Job Dependencies"
                multiple="true"
                component={SelectInputAsync}
                url={`/job/dependencies?project=${params.number}&job=${params.jobUuid}`}
                disabled={job.can_only_edit_status}
              />
            </div>
            <div className="form-group col-lg-3">
              <Field
                label="Critical Path"
                form-control-label
                name="critical_path"
                component={renderToggleInput}
                disabled={job.can_only_edit_status}
              />
            </div>
          </>
        )}

        <div className="col-12 mb-3">
          <hr />
        </div>

        <div className="form-group col-lg-6">
          <Field
            name="status"
            label="Job Status"
            component={SelectInputAsync}
            options={JOB_STATUS}
            required
            validate={required}
            placeholder="Job Status"
            order={false}
          />
        </div>

        {!job.is_standard && !job.job_type.link && (
          <div className="form-group col-lg-6">
            <Field
              name="job_type_id"
              label="Job Type"
              component={SelectInputAsync}
              onChangeValue={(value, event) => {
                change('job_type_type', value?.type);
              }}
              url="/job-types/with-custom-jobs?filter[withTrashed]=true"
              formatData={(data) => {
                return data.data.map((d) => ({
                  label: d.name,
                  value: d.id,
                  disabled: d.disabled,
                  type: d.type,
                }));
              }}
              required
              validate={required}
              placeholder="Select Job Type"
              disabled={job.can_only_edit_status}
            />
          </div>
        )}

        {status == JobStatus.Cancelled && (
          <div className="form-group col-lg-12">
            <Field
              name="cancelled_reason"
              label="Reason for Cancellation"
              textarea
              component={RenderField}
              required
              validate={required}
            />
          </div>
        )}
        <div className="form-layout-footer form-group col-lg-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state, { form }) => {
  const selector = formValueSelector(form);

  return {
    status: selector(state, "status"),
  };
};

export default connect(mapStateToProps, {})(JobForm);
