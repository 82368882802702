import { Modal, ModalBody, ModalHeader, Progress } from "reactstrap";
import { Branch } from "../branchTypes";
import { FileRejection, useDropzone } from "react-dropzone";
import useS3Uploader from "../../hooks/useS3Uploader";
import errorSwal from "../../utils/errorSwal";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import useApi from "../../api/useApi";
import { IUseApi } from "../../api/apiTypes";
import { BiCloudUpload } from "react-icons/bi";
import { useState } from "react";
import { Alert } from "reactstrap";
import dayjs from "dayjs";

// Simple InformationAlert component
const InformationAlert = ({
  type,
  title,
  body,
}: {
  type: string;
  title: string;
  body: string;
}) => (
  <Alert color={type === "warning" ? "warning" : "info"}>
    <h6 className="alert-heading">{title}</h6>
    <p className="mb-0" style={{ whiteSpace: "pre-line" }}>
      {body}
    </p>
  </Alert>
);

const ImportModal = ({
  branch,
  type,
  modal,
  toggle,
  refreshData,
  period,
}: {
  branch?: Branch;
  type: "revenue" | "cost_of_sales" | "operating_expenses" | "capex";
  modal: boolean;
  toggle: () => void;
  refreshData: () => void;
  period: [string, string];
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);

  const { uuid } = useParams<{ uuid: string }>();

  const { takeAction }: IUseApi = useApi();

  const { upload, uploadPercent, setUploadPercent } = useS3Uploader();

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (fileRejections.length > 0) {
      setRejectedFiles(fileRejections);
      return;
    }

    if (acceptedFiles.length === 0) return;

    setLoading(true);

    return upload(
      acceptedFiles[0],
      `tmp/organisations/samples`,
      (file: any) => {
        return takeAction("store", `branches/${uuid}/budget-import`, {
          path: file.path,
          type,
          start_month: dayjs(period[0]).format("YYYY-MM-DD"),
        })
          .then((res: any) => {
            toast.success("Data Imported Successfully");
            setUploadPercent(0);
            setLoading(false);
            toggle();
            refreshData();
          })
          .catch((error) => {
            setLoading(false);
            errorSwal(error);
          });
      },
    );
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ],
    multiple: false,
    onDrop,
    disabled: loading,
  });

  return (
    <Modal
      className="wd-md-1000 mx-wd-1000 w-95"
      isOpen={modal}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>
        Import {type} for {branch?.name}
      </ModalHeader>
      <ModalBody>
        <div
          {...getRootProps()}
          className={`tn-300 rounded-lg ${
            isDragActive ? "bg-gray-100 border-2 text-muted" : "border"
          } d-flex align-items-center w-100 h-100 justify-content-center border-dashed`}
          style={{ minHeight: "7rem" }}
        >
          <input {...getInputProps()} />
          {loading ? (
            <div>
              <i className="fa fa-spinner fa-spin" />
            </div>
          ) : (
            <div>
              <div className="text-center mt-3">
                <BiCloudUpload size={24} />
                <p className="mb-0">Upload Budget</p>
                <p className="mb-4">Please upload excel file in the form of:</p>
              </div>
              <BudgetImportExampleTable />
            </div>
          )}
        </div>
        {uploadPercent > 0 && uploadPercent < 100 && (
          <div className="mt-3">
            <Progress
              className="progress-bar-sm"
              striped
              animated
              value={uploadPercent}
              color="primary"
            />
          </div>
        )}
        {rejectedFiles.length > 0 && (
          <div className="mt-3">
            <InformationAlert
              type="warning"
              title="Files Rejected"
              body={`The following files were rejected: \n\n${rejectedFiles
                .map((file) => file.file.name)
                .join("\n\n")}.\n\nPlease upload a CSV or excel file.`}
            />
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

const BudgetImportExampleTable = () => {
  return (
    <div className="rounded-lg border mb-3">
      <table className="budget-table rounded-lg">
        <thead>
          <tr>
            <th>Account Number</th>
            <th>M 1</th>
            <th>M 2</th>
            <th>M 3</th>
            <th>M 4</th>
            <th>M 5</th>
            <th>M 6</th>
            <th>M 7</th>
            <th>M 8</th>
            <th>M 9</th>
            <th>M 10</th>
            <th>M 11</th>
            <th>M 12</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Account Number Only</td>
            <td>1000</td>
            <td>1000</td>
            <td>1000</td>
            <td>1000</td>
            <td>1000</td>
            <td>1000</td>
            <td>1000</td>
            <td>1000</td>
            <td>1000</td>
            <td>1000</td>
            <td>1000</td>
            <td>1000</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ImportModal;
