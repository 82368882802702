import React, { useState, useRef } from "react";
import {
  Button,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from "reactstrap";
import Select from "react-select";
import { FaFillDrip, FaMoneyBill, FaRegComment, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import { BudgetItem, BudgetRow, MonthlyTotals, SelectOption } from "../types";
import { formatAmount } from "../utils/budgetFormatters";
import Input from "./Input";
import { useWindowEvent } from "../../hooks/useWindowEvent";
import useModal from "../../hooks/useModal";
import PurchaseModal from "./PurchaseModal";
import { Branch, BudgetLock } from "../branchTypes";
import dayjs from "dayjs";
import ImportModal from "./ImportModal";
import { CiImport } from "react-icons/ci";
import { AiOutlinePlus } from "react-icons/ai";
import { ActualSpending } from "./budgetTypes";
import ActualPurchasesModal from "./ActualPurchasesModal";
import { useAuth } from "../../../context/auth-context";
import { GroupType } from "../../user/userTypes";
import EditAccountModal from "./EditAccountModal";
import { ChartOfAccount } from "../../chartOfAccounts/chartOfAccountTypes";
import useApi from "../../api/useApi";
import { useParams } from "react-router-dom";
import errorSwal from "../../utils/errorSwal";
import swal from "sweetalert";
import _ from "lodash";

// Add CSS styles for the actual spending row
const actualSpendingRowStyle = {
  borderTop: "none",
  fontSize: "0.9em",
  color: "#555",
  backgroundColor: "rgba(240, 248, 255, 0.5)",
};

// Helper function to calculate variance between budget and actual
const calculateVariance = (
  budgeted: string | number | null | undefined,
  actual: number,
): number => {
  if (!budgeted) return -actual;

  const budgetedNum =
    typeof budgeted === "string" ? parseFloat(budgeted) : budgeted;
  return budgetedNum - actual;
};

// Helper function to format variance with color
const formatVariance = (
  variance: number,
  type: "revenue" | "cost_of_sales" | "operating_expenses" | "capex",
): JSX.Element => {
  // Determine if actual is over or under budget
  const isNegative = variance < 0;

  // For display purposes:
  // If variance is positive, actual is UNDER budget (spent less than budgeted)
  // If variance is negative, actual is OVER budget (spent more than budgeted)
  const displayText = isNegative ? "over" : "under";

  // Simplified color logic: green for under budget, red for over budget
  const color = isNegative ? "red" : "green";

  return (
    <span
      style={{
        color: color,
        fontSize: "0.8em",
        display: "block",
      }}
    >
      {formatAmount(Math.abs(variance), type)} {displayText}
    </span>
  );
};

interface BudgetTableProps {
  title: string;
  budgetRows: BudgetRow[];
  months: string[];
  accounts: ChartOfAccount[];
  monthlyTotals: MonthlyTotals;
  onAddRow?: () => void;
  onDeleteRow?: (row: BudgetRow) => void;
  onAccountSelect: (rowIndex: number, option: SelectOption | null) => void;
  onAmountBlur: (budgetItem: BudgetItem) => void;
  showAddButton?: boolean;
  type: "revenue" | "cost_of_sales" | "operating_expenses" | "capex";
  columnStyles: {
    account: { width: number };
    accountEditing: { minWidth: number };
    month: { width: number };
  };
  extraRows?: JSX.Element;
  branch: Branch | undefined;
  refreshBudgetItems: () => void;
  budgetLock?: BudgetLock;
  period: [string, string];
  actualSpending: ActualSpending;
  showActuals: boolean;
  isReadOnly?: boolean;
}

const BudgetTable = ({
  title,
  budgetRows,
  months,
  accounts,
  monthlyTotals,
  onAddRow,
  onDeleteRow,
  onAccountSelect,
  onAmountBlur,
  showAddButton = true,
  type,
  columnStyles,
  extraRows,
  branch,
  budgetLock,
  refreshBudgetItems,
  period,
  actualSpending,
  isReadOnly = false,
  showActuals,
}: BudgetTableProps) => {
  const [editingCell, setEditingCell] = useState<{
    rowIndex: number;
    type: "account" | "amount";
    month?: string;
  } | null>(null);

  const { uuid } = useParams<{ uuid: string }>();

  const { user } = useAuth();

  // Use a ref to track the next cell to focus
  const nextCellRef = useRef<{
    rowIndex: number;
    type: "account" | "amount";
    month?: string;
  } | null>(null);

  const [selectedMonth, setSelectedMonth] = useState<string>(
    dayjs(period[0]).format("YYYY-MM"),
  );

  const [selectedAccountId, setSelectedAccountId] = useState<string>("");

  const [selectedAccount, setSelectedAccount] = useState<ChartOfAccount | null>(
    null,
  );

  const canViewAccount =
    user?.is_admin || user?.groups.includes(GroupType.Accounting);

  const { toggle: toggleAccountsModal, modal: accountsModal } = useModal();

  const { toggle, modal } = useModal();

  const [selectedRow, setSelectedRow] = useState<BudgetRow | null>(null);

  const { takeAction } = useApi();

  useWindowEvent("mousedown", (e: MouseEvent) => {
    const target = e.target as HTMLElement;

    // If clicking on the input or its container, don't do anything
    if (target.closest("#cell-input") || target.closest("#edit-button")) {
      return;
    }

    // If clicking outside, clear the editing state
    setEditingCell(null);
  });

  const handleCellClick = (
    rowIndex: number,
    type: "account" | "amount",
    month?: string,
  ) => {
    if (budgetLock?.locked_at) {
      toast.warning("Budget is locked");
      return;
    }

    // If already editing this cell, don't do anything
    if (
      editingCell?.rowIndex === rowIndex &&
      editingCell?.type === type &&
      editingCell?.month === month
    ) {
      return;
    }

    // If we're clicking on an amount cell, make sure the account is selected first
    if (type === "amount" && !budgetRows[rowIndex].account) {
      toast.warning("Please select an account first");
      // Focus on the account cell instead
      setEditingCell({ rowIndex, type: "account" });
      return;
    }

    setEditingCell({ rowIndex, type, month });

    // Focus the input after a short delay to ensure it's rendered
    setTimeout(() => {
      const cellInput = document.getElementById("cell-input");
      if (cellInput) {
        cellInput.focus();
        if (cellInput instanceof HTMLInputElement) {
          cellInput.select();
        }
      }
    }, 50);
  };

  // Improve the handleKeyDown function to better handle tab navigation
  const handleKeyDown = (
    e: React.KeyboardEvent,
    rowIndex: number,
    cellType: "account" | "amount",
    month?: string,
  ) => {
    if (e.key !== "Tab") {
      return;
    }

    e.preventDefault();

    // If we're in an amount cell, save the current value before moving
    if (cellType === "amount" && month) {
      const cellInput = e.target as HTMLInputElement;
      const currentValue = cellInput.value;

      // Find the current budget item
      const currentRow = budgetRows[rowIndex];
      const currentBudgetItem = currentRow.amounts.find(
        (amount) =>
          dayjs(amount.month).format("YYYY-MM") ===
          dayjs(month).format("YYYY-MM"),
      );

      if (currentBudgetItem) {
        // Call onAmountBlur with the updated budget item
        onAmountBlur({
          ...currentBudgetItem,
          amount: currentValue,
          type,
        });
      }
    }

    // Determine the next cell to focus
    let nextRowIndex = rowIndex;
    let nextType = cellType;
    let nextMonth = month;

    if (!e.shiftKey) {
      // Tab forward
      if (cellType === "account") {
        // Move from account to first month
        nextType = "amount";
        nextMonth = months[0] + "-01";
      } else if (cellType === "amount") {
        const currentMonthIndex = months.findIndex(
          (m) => m === dayjs(month).format("YYYY-MM"),
        );

        if (currentMonthIndex < months.length - 1) {
          // Move to next month in same row
          nextMonth = months[currentMonthIndex + 1] + "-01";
        } else if (rowIndex < budgetRows.length - 1) {
          // Move to account in next row
          nextRowIndex = rowIndex + 1;
          nextType = "account";
          nextMonth = undefined;
        } else {
          // We're at the end, loop back to first cell
          nextRowIndex = 0;
          nextType = "account";
          nextMonth = undefined;
        }
      }
    } else {
      // Shift+Tab (backward)
      if (cellType === "account") {
        if (rowIndex > 0) {
          // Move to last month of previous row
          nextRowIndex = rowIndex - 1;
          nextType = "amount";
          nextMonth = months[months.length - 1] + "-01";
        } else {
          // We're at the beginning, loop to last cell
          nextRowIndex = budgetRows.length - 1;
          nextType = "amount";
          nextMonth = months[months.length - 1] + "-01";
        }
      } else if (cellType === "amount") {
        const currentMonthIndex = months.findIndex(
          (m) => m === dayjs(month).format("YYYY-MM"),
        );

        if (currentMonthIndex > 0) {
          // Move to previous month in same row
          nextMonth = months[currentMonthIndex - 1] + "-01";
        } else {
          // Move to account in same row
          nextType = "account";
          nextMonth = undefined;
        }
      }
    }

    // Store the next cell in the ref
    nextCellRef.current = {
      rowIndex: nextRowIndex,
      type: nextType,
      month: nextMonth,
    };

    // Clear the current editing cell
    setEditingCell(null);

    // Use a single setTimeout to set the new editing cell and focus
    setTimeout(() => {
      if (nextCellRef.current) {
        setEditingCell(nextCellRef.current);

        // Focus after the component has had time to render
        setTimeout(() => {
          const cellInput = document.getElementById("cell-input");
          if (cellInput) {
            cellInput.focus();

            // If it's an input field, select all text for easy replacement
            if (cellInput instanceof HTMLInputElement) {
              cellInput.select();
            }
          }
        }, 5);
      }
    }, 1);
  };

  const grandTotal = Object.values(monthlyTotals).reduce(
    (sum, amount) => sum + amount,
    0,
  );

  const { toggle: toggleImport, modal: importModal } = useModal();

  // Get all unique account IDs from actualSpending that aren't in budgetRows
  const getAdditionalActualAccounts = () => {
    if (!showActuals) return [];

    // Get all account IDs from budgetRows
    const budgetAccountIds = budgetRows
      .filter((row) => row.account)
      .map((row) => row.account!.id);

    // Collect all unique account IDs from actualSpending
    const actualAccountIds = new Set<string>();
    const actualAccounts = new Map<string, ChartOfAccount>();

    months.forEach((month) => {
      const monthData = actualSpending[month];
      if (monthData && monthData.accounts) {
        Object.entries(monthData.accounts).forEach(
          ([accountId, accountData]) => {
            // Only add accounts that don't already have a budget row
            // Use strict comparison to ensure we don't miss any accounts
            if (
              //@ts-ignore
              !budgetAccountIds.includes(accountId) &&
              accountData.account &&
              // Double-check by account code as well for extra safety
              !budgetRows.some(
                (row) =>
                  row.account && row.account.code === accountData.account.code,
              )
            ) {
              actualAccountIds.add(accountId);
              actualAccounts.set(accountId, accountData.account);
            }
          },
        );
      }
    });

    // Convert to array of account objects
    return Array.from(actualAccountIds).map((id) => ({
      id,
      account: actualAccounts.get(id)!,
    }));
  };

  const additionalActualAccounts = getAdditionalActualAccounts();

  return (
    <>
      <div className="mt-4">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="mb-0 text-dark">{title}</h5>
          {!isReadOnly && (
            <div className="d-flex space-x-3">
              {/* <GetEstimatedCostsOfSales accounts={accounts} months={months} /> */}
              <Button
                size="sm"
                color="link"
                className="shadow-none"
                outline
                onClick={toggleImport}
              >
                <CiImport size={16} /> Import
              </Button>
              {showAddButton && onAddRow && !budgetLock?.locked_at && (
                <Button size="sm" color="secondary" outline onClick={onAddRow}>
                  <AiOutlinePlus size={14} /> Add Account
                </Button>
              )}
            </div>
          )}
        </div>
        <div className="">
          <table className="budget-table">
            <thead className="bg-white rounded-lg">
              <tr className="">
                <th className="" style={columnStyles.account}>
                  Account
                </th>
                {months.map((month) => (
                  <th key={month} className="text-end ">
                    {dayjs(month).format("MMM YYYY")}
                  </th>
                ))}
                <th className="text-end" style={{ width: 150 }}>
                  Total
                </th>
                <th style={{ width: 50 }}></th>
              </tr>
            </thead>
            <tbody>
              {extraRows}
              {budgetRows.map((row, rowIndex) => (
                <>
                  <tr
                    key={`budget-${rowIndex}`}
                    className={`${
                      rowIndex !== budgetRows.length - 1
                        ? "budget-row "
                        : "last-row"
                    }`}
                  >
                    <td
                      style={
                        editingCell?.rowIndex === rowIndex &&
                        editingCell?.type === "account"
                          ? columnStyles.accountEditing
                          : columnStyles.account
                      }
                      onClick={() =>
                        isReadOnly ? null : handleCellClick(rowIndex, "account")
                      }
                      className=""
                    >
                      {editingCell?.rowIndex === rowIndex &&
                      editingCell?.type === "account" ? (
                        <Select
                          id="cell-input"
                          value={
                            row.account
                              ? {
                                  value: row.account.id,
                                  label: `${row.account.code} - ${row.account.name}`,
                                }
                              : null
                          }
                          onChange={(option: any) => {
                            // Convert the option to match SelectOption type
                            const convertedOption = option
                              ? { ...option, value: String(option.value) }
                              : null;
                            onAccountSelect(rowIndex, convertedOption);
                          }}
                          options={accounts
                            .filter(
                              (account) =>
                                account.id === row.account?.id ||
                                !budgetRows.some(
                                  (budgetRow) =>
                                    budgetRow.account?.id === account.id,
                                ),
                            )
                            .map((account) => ({
                              value: account.id,
                              label: `${account.code} - ${account.name}`,
                            }))}
                          styles={{
                            menu: (baseStyles: Record<string, unknown>) => ({
                              ...baseStyles,
                              zIndex: 9999,
                            }),
                            container: (
                              baseStyles: Record<string, unknown>,
                            ) => ({
                              ...baseStyles,
                              width: "100%",
                            }),
                          }}
                          onKeyDown={(e: any) => {
                            if (e.key === "Tab" || e.key === "Enter") {
                              e.preventDefault();
                              handleKeyDown(e, rowIndex, "account");
                            }
                          }}
                          menuPlacement="auto"
                          autoFocus
                        />
                      ) : (
                        <div style={{ cursor: "pointer" }}>
                          {row.account
                            ? `${row.account.code} - ${row.account.name}`
                            : "Click to select account"}
                        </div>
                      )}
                    </td>
                    {row.amounts.map((amount) => {
                      // Remove whitespace from account code if it exists
                      const cleanAccountCode = amount.account?.code
                        ? amount.account.code.replace(/\s+/g, "")
                        : "";

                      const formattedMonth = dayjs(amount.month).format(
                        "YYYY-MM",
                      );

                      return (
                        <React.Fragment key={amount.month}>
                          <td
                            id={`breakdowns-actual-spending-${cleanAccountCode}-${formattedMonth}`}
                            onClick={() => {
                              if (isReadOnly) {
                                return;
                              }
                              if (!amount.account_id) {
                                toast.warning("Please select an account first");
                                return;
                              }
                              handleCellClick(rowIndex, "amount", amount.month);
                            }}
                            className="text-end position-relative "
                          >
                            {editingCell?.rowIndex === rowIndex &&
                            editingCell?.type === "amount" &&
                            dayjs(editingCell?.month).format("YYYY-MM") ===
                              dayjs(amount.month).format("YYYY-MM") ? (
                              <Input
                                budgetItem={{
                                  ...amount,
                                  type,
                                }}
                                account={row.account}
                                onBlur={onAmountBlur}
                                onKeyDown={(e) =>
                                  handleKeyDown(
                                    e,
                                    rowIndex,
                                    "amount",
                                    amount.month,
                                  )
                                }
                              />
                            ) : (
                              <div className="d-flex justify-content-between pointer-hover">
                                <CommentPopover item={amount} />
                                {formatAmount(amount.amount || "", type)}{" "}
                              </div>
                            )}
                          </td>
                          {amount.items && amount.items.length > 0 ? (
                            <UncontrolledPopover
                              target={`breakdowns-actual-spending-${cleanAccountCode}-${formattedMonth}`}
                            >
                              <PopoverHeader>Breakdowns</PopoverHeader>
                              <PopoverBody className="space-y-3">
                                {Object.entries(
                                  _.groupBy(amount.items, "organisation.name"),
                                ).length == 1
                                  ? Object.entries(
                                      _.groupBy(amount.items, "branch.name"),
                                    ).map(([branchName, items]) => {
                                      const total = items.reduce(
                                        (sum, item) =>
                                          sum +
                                          (parseFloat(item.amount as string) ||
                                            0),
                                        0,
                                      );

                                      return (
                                        <div key={branchName}>
                                          <a
                                            className="d-block"
                                            href={`${items[0]?.branch?.link}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <p className="mb-0 text-dark">
                                              {branchName}
                                            </p>
                                            <small className="text-secondary">
                                              {formatAmount(total, type)}
                                            </small>
                                          </a>
                                        </div>
                                      );
                                    })
                                  : Object.entries(
                                      _.groupBy(
                                        amount.items,
                                        "organisation.name",
                                      ),
                                    ).map(([organisationName, items]) => {
                                      const total = items.reduce(
                                        (sum, item) =>
                                          sum +
                                          (parseFloat(item.amount as string) ||
                                            0),
                                        0,
                                      );

                                      return (
                                        <div key={organisationName}>
                                          <p className="mb-0 text-dark">
                                            {organisationName}
                                          </p>
                                          <small className="text-secondary">
                                            {" "}
                                            {formatAmount(total, type)}
                                          </small>
                                        </div>
                                      );
                                    })}
                              </PopoverBody>
                            </UncontrolledPopover>
                          ) : null}
                        </React.Fragment>
                      );
                    })}
                    <td className="text-end">
                      {formatAmount(
                        row.amounts.reduce(
                          (sum, amount) =>
                            sum +
                            (amount.amount
                              ? typeof amount.amount === "string"
                                ? parseFloat(amount.amount)
                                : amount.amount
                              : 0),
                          0,
                        ),
                        type,
                      )}
                    </td>
                    <td className="text-center">
                      <div className="d-flex space-x-1">
                        {onDeleteRow &&
                          row.amounts.some((item) => item.uuid) &&
                          !budgetLock?.locked_at && (
                            <Button
                              color="link"
                              className="p-0 text-danger"
                              onClick={() => {
                                if (isReadOnly) {
                                  toast.warning(
                                    "Budget is read only, unable to delete",
                                  );

                                  return;
                                }

                                onDeleteRow(row);
                              }}
                              title="Delete row"
                            >
                              <FaTrash />
                            </Button>
                          )}
                        <Button
                          onClick={() => {
                            setSelectedRow(row);
                            toggle();
                          }}
                          color="link"
                          className="p-0 text-success"
                        >
                          <FaMoneyBill />
                        </Button>
                        {row.account?.auto_fill && (
                          <Button
                            title="Auto Fill from Account Settings"
                            color="link"
                            className="p-0 text-primary"
                            onClick={() => {
                              if (isReadOnly) {
                                toast.warning(
                                  "Budget is read only, unable to auto fill",
                                );

                                return;
                              }

                              return swal({
                                title: "Auto Fill from Account Settings",
                                icon: "info",
                                text: getAutoFillExplanation(row.account),
                                buttons: ["Cancel", "Auto Fill"],
                              }).then((value: any) => {
                                if (value) {
                                  return takeAction(
                                    "store",
                                    `branches/${uuid}/budget-auto-fill`,
                                    {
                                      account_id: row.account?.id,
                                      months: months,
                                      type,
                                    },
                                  )
                                    .then(() => {
                                      refreshBudgetItems();
                                      toast.success("Budget auto filled");
                                    })
                                    .catch(errorSwal);
                                }
                              });
                            }}
                          >
                            <FaFillDrip />
                          </Button>
                        )}
                      </div>
                    </td>
                  </tr>

                  {/* Actual Spending Row for budget rows */}
                  {showActuals && row.account && (
                    <tr
                      key={`actual-${rowIndex}`}
                      className="actual-spending-row"
                      style={actualSpendingRowStyle}
                    >
                      <td
                        onClick={() => {
                          if (!canViewAccount) {
                            return;
                          }

                          //@ts-ignore
                          setSelectedAccount(row.account);
                          toggleAccountsModal();
                        }}
                        style={columnStyles.account}
                      >
                        <div className="ps-3 fst-italic">Actual</div>
                      </td>
                      {months.map((month, monthIndex) => {
                        const monthData =
                          actualSpending[month]?.accounts?.[
                            row.account?.id || ""
                          ];

                        const actualAmount = monthData?.total || 0;

                        const budgetAmount = row.amounts[monthIndex]?.amount;

                        const variance = calculateVariance(
                          budgetAmount,
                          actualAmount,
                        );

                        return (
                          <td
                            key={month}
                            className="text-end position-relative"
                            onClick={() => {
                              if (monthData && monthData.purchases.length > 0) {
                                setSelectedMonth(month);
                                //@ts-ignore
                                setSelectedAccountId(row.account?.id || "");
                                toggle();
                              }
                            }}
                            style={{
                              cursor:
                                monthData && monthData.purchases.length > 0
                                  ? "pointer"
                                  : "default",
                            }}
                          >
                            {monthData ? (
                              <div>
                                <div className="d-flex justify-content-between">
                                  {monthData.purchases.length > 0 && (
                                    <div
                                      className="position-absolute"
                                      style={{
                                        left: "5px",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                      }}
                                      title={`${monthData.purchases.length} purchase(s)`}
                                    ></div>
                                  )}
                                  <div className="w-100 text-end">
                                    {formatAmount(actualAmount, type)}
                                  </div>
                                </div>
                                {budgetAmount && formatVariance(variance, type)}
                              </div>
                            ) : (
                              <div className="text-muted">-</div>
                            )}
                          </td>
                        );
                      })}
                      <td className="text-end">
                        {/* Total column */}
                        <div>
                          {formatAmount(
                            months.reduce((sum, month) => {
                              const monthData =
                                actualSpending[month]?.accounts?.[
                                  row.account?.id || ""
                                ];
                              return sum + (monthData?.total || 0);
                            }, 0),
                            type,
                          )}

                          {/* Variance for total */}
                          {(() => {
                            const totalActual = months.reduce((sum, month) => {
                              const monthData =
                                actualSpending[month]?.accounts?.[
                                  row.account?.id || ""
                                ];
                              return sum + (monthData?.total || 0);
                            }, 0);

                            const totalBudget = row.amounts.reduce(
                              (sum, amount) =>
                                sum +
                                (amount.amount
                                  ? typeof amount.amount === "string"
                                    ? parseFloat(amount.amount)
                                    : amount.amount
                                  : 0),
                              0,
                            );

                            const totalVariance = calculateVariance(
                              totalBudget,
                              totalActual,
                            );

                            return totalBudget
                              ? formatVariance(totalVariance, type)
                              : null;
                          })()}
                        </div>
                      </td>
                      <td></td>
                    </tr>
                  )}
                </>
              ))}

              {/* Additional rows for accounts with actual spending but no budget */}
              {showActuals && additionalActualAccounts.length > 0 && (
                <>
                  {/* Separator row if there are both budget rows and additional actual rows */}
                  {budgetRows.length > 0 && (
                    <tr className="separator-row">
                      <td colSpan={months.length + 3} className="py-2">
                        <div className="border-top border-secondary-subtle"></div>
                      </td>
                    </tr>
                  )}

                  {/* Header for additional accounts section */}
                  <tr>
                    <td colSpan={months.length + 3} className="pb-2">
                      <h6 className="mb-0 text-muted">
                        Accounts with spending (no budget)
                      </h6>
                    </td>
                  </tr>

                  {/* Rows for each additional account */}
                  {additionalActualAccounts.map((item, index) => (
                    <tr
                      key={`additional-actual-${index}`}
                      className="actual-spending-row"
                      style={{
                        ...actualSpendingRowStyle,
                        backgroundColor: "rgba(255, 248, 240, 0.5)",
                      }}
                    >
                      <td
                        onClick={() => {
                          if (!canViewAccount) {
                            return;
                          }

                          setSelectedAccount(item.account);
                          toggleAccountsModal();
                        }}
                        style={columnStyles.account}
                      >
                        <div>
                          {`${item.account.code} - ${item.account.name}`}
                        </div>
                      </td>
                      {months.map((month) => {
                        const monthData =
                          actualSpending[month]?.accounts?.[item.id];
                        const actualAmount = monthData?.total || 0;

                        const resources = [
                          ...(monthData?.purchases || []),
                          ...(monthData?.journals || []),
                        ];

                        return (
                          <td
                            key={month}
                            className="text-end position-relative"
                            onClick={() => {
                              if (resources.length > 0) {
                                setSelectedMonth(month);
                                setSelectedAccountId(item.id);
                                toggle();
                              }
                            }}
                            style={{
                              cursor:
                                resources.length > 0 ? "pointer" : "default",
                            }}
                          >
                            {monthData ? (
                              <div>
                                <div className="d-flex justify-content-between">
                                  {resources.length > 0 && (
                                    <div
                                      className="position-absolute"
                                      style={{
                                        left: "5px",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                      }}
                                      title={`${resources.length} items(s)`}
                                    ></div>
                                  )}
                                  <div className="w-100 text-end">
                                    {formatAmount(actualAmount, type)}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="text-muted">-</div>
                            )}
                          </td>
                        );
                      })}
                      <td className="text-end">
                        {/* Total column */}
                        <div>
                          {formatAmount(
                            months.reduce((sum, month) => {
                              const monthData =
                                actualSpending[month]?.accounts?.[item.id];
                              return sum + (monthData?.total || 0);
                            }, 0),
                            type,
                          )}
                        </div>
                      </td>
                      <td></td>
                    </tr>
                  ))}
                </>
              )}

              {/* Monthly Totals Row */}
              <tr
                style={{
                  backgroundColor: "rgb(240 242 248)",
                  borderBottom: "none",
                }}
                className="fw-bolder"
              >
                <td className=" " style={columnStyles.account}>
                  Monthly Totals
                </td>
                {months.map((month) => (
                  <td
                    key={month}
                    className="text-end   "
                    style={columnStyles.month}
                  >
                    {formatAmount(monthlyTotals[month], type)}
                  </td>
                ))}
                <td className="text-end" style={columnStyles.month}>
                  {formatAmount(grandTotal, type)}
                </td>
                <td></td>
              </tr>
              {/* Grand Total Row */}
              <tr
                style={{ backgroundColor: "rgb(240 242 248)" }}
                className="fw-bolder "
              >
                <td className="" style={columnStyles.account}>
                  Total Budget
                </td>
                <td
                  colSpan={months.length + 1}
                  className="text-end  "
                  style={columnStyles.month}
                >
                  {formatAmount(grandTotal, type)}
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <PurchaseModal
        toggle={toggle}
        modal={modal}
        selectedRow={selectedRow}
        branch={branch}
        months={months}
      />
      <ImportModal
        branch={branch}
        type={type}
        modal={importModal}
        toggle={toggleImport}
        refreshData={refreshBudgetItems}
        period={period}
      />
      <ActualPurchasesModal
        selectedMonth={selectedMonth}
        selectedAccountId={selectedAccountId}
        actualSpending={actualSpending}
        toggle={toggle}
        modal={modal}
        setSelectedMonth={setSelectedMonth}
        setSelectedAccountId={setSelectedAccountId}
      />
      <EditAccountModal
        account={selectedAccount}
        toggle={toggleAccountsModal}
        modal={accountsModal}
      />
    </>
  );
};

const getAutoFillExplanation = (account: ChartOfAccount | null) => {
  if (!account) {
    return "";
  }

  if (account.auto_fill_type === "percentage_of_revenue") {
    return `This will auto fill the budget for this account based on ${account.percentage_of_revenue}% of revenue.`;
  }

  if (account.auto_fill_type === "multiple_of_full_time_staff") {
    return `This will auto fill the budget for this account to be $${account.multiple_of_full_time_staff} x the number of full time staff.`;
  }

  return "";
};

export const CommentPopover = ({ item }: { item: BudgetItem }) => {
  if (!item.comment) {
    return <div></div>;
  }

  return (
    <>
      <FaRegComment
        className="text-secondary"
        size={16}
        id={`comment-${item.uuid}`}
      />
      <UncontrolledPopover trigger="hover" target={`comment-${item.uuid}`}>
        <PopoverBody>
          <div className="white-space">{item.comment}</div>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

export default BudgetTable;
