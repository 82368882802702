import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useMemo, useEffect } from "react";
import { useQueryClient } from "react-query";
import {
  FaChartLine,
  FaDollarSign,
  FaUser,
  FaRegComment,
  FaLink,
} from "react-icons/fa";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import BottomLogo from "../utils/BottomLogo";
import dayjs from "dayjs";
import { money } from "../form/formatters";
import AddBranchForecast from "./AddBranchForecast";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import { FormSubmitHandler } from "redux-form";
import BranchForecastForm from "./BranchForecastForm";

import useApi from "../api/useApi";
import { Branch, BranchIndex, WeeklyForecast } from "./branchTypes";
import PaginatedList from "../pagination/PaginatedList";
import useBranchWeeklyForecasts from "./hooks/useBranchWeeklyForecasts";
import useModal from "../hooks/useModal";
import Tabs from "../tabs/Tabs";
import ReactTable from "../table/ReactTable";
import { GrClose } from "react-icons/gr";
import formError from "../utils/formError";
import { toast } from "react-toastify";
import { BranchUpdateLink } from "../home/BranchUpdateReminder";
import FormHeader from "../utils/FormHeader";
import { useAuth } from "../../context/auth-context";
import { IoCash, IoWarning } from "react-icons/io5";
import { PiInvoice } from "react-icons/pi";
import UnpaidInvoicesTable from "../invoices/UnpaidInvoicesTable";
import { Line } from "react-chartjs-2";

const WeeklyForecasts = ({ branch }: { branch: Branch }) => {
  const { user } = useAuth();

  const location = useLocation<{
    add?: boolean;
    otherBranches?: BranchIndex[];
  }>();

  const { forecastUuid, uuid } = useParams<{
    forecastUuid?: string;
    uuid: string;
  }>();

  const history = useHistory();

  const add = location.state?.add;
  const otherBranches = location.state?.otherBranches ?? [];

  const tabs = [
    {
      title: "Previous Forecasts",
      component: <PreviousForecasts branch={branch} />,
    },
  ];

  if (user?.id == branch.branch_manager_id || user?.is_admin) {
    tabs.push({
      title: "Add Forecast",
      component: <AddForecast branch={branch} />,
    });
  }

  if (!branch) {
    return null;
  }

  return (
    <>
      {otherBranches.length > 0 && (
        <div className="mb-3">
          <FormHeader>Other Branches to complete</FormHeader>
          <div className="d-flex w-100 flex-nowrap overflow-auto pb-1 space-x-3 mb-3 z-10">
            {otherBranches
              .filter((b) => b.uuid !== uuid)
              .map((branch) => (
                <div>
                  <BranchUpdateLink
                    branch={branch}
                    key={branch.uuid}
                    branches={otherBranches}
                  />
                </div>
              ))}
          </div>
        </div>
      )}
      <Tabs
        tabs={tabs}
        startingTab={add ? 1 : 0}
        onSelect={(tab) => {
          history.replace(`/branches/${branch.uuid}/weekly-forecasts`);
        }}
        rightNav={(tab) => {
          if (!forecastUuid || tab === 1) {
            return null;
          }

          return (
            <Link to={`/branches/${branch.uuid}/weekly-forecasts`}>
              <GrClose />
            </Link>
          );
        }}
      />
    </>
  );
};

const AddForecast = ({ branch }: { branch: Branch }) => {
  return (
    <>
      <OverviewCards branch={branch} />
      <AddBranchForecast />
    </>
  );
};

const OverviewCards = ({
  branch,
  forecast,
}: {
  branch: Branch;
  forecast?: WeeklyForecast;
}) => {
  // Get current date and month using dayjs
  const currentDate = dayjs(forecast?.week);
  const currentMonth = currentDate.month();
  const currentYear = currentDate.year();

  // Filter forecasts from the current month
  const currentMonthForecasts = branch.weekly_forecasts
    .filter((forecast) => {
      const forecastDate = dayjs(forecast.week);
      return (
        forecastDate.month() === currentMonth &&
        forecastDate.year() === currentYear
      );
    })
    .sort((a, b) => dayjs(b.week).valueOf() - dayjs(a.week).valueOf()); // Sort by date, newest first

  // Get the two most recent forecasts from the current month
  const thisWeekForecast = currentMonthForecasts[0];
  const lastWeekForecast = currentMonthForecasts[1];

  // Calculate revenue values
  const thisMonthRevenue = thisWeekForecast?.revenue || 0;
  const lastMonthRevenue = lastWeekForecast?.revenue || 0;

  // Determine if we should show the percentage change
  const showChange = currentMonthForecasts.length >= 2;

  // Calculate percentage change
  const percentageChange =
    showChange && lastMonthRevenue > 0
      ? ((thisMonthRevenue - lastMonthRevenue) / lastMonthRevenue) * 100
      : 0;

  // Format the percentage with + sign for positive values
  const formattedPercentage = showChange
    ? percentageChange >= 0
      ? `+ ${percentageChange.toFixed(1)}%`
      : `${percentageChange.toFixed(1)}%`
    : "";

  // Determine the color class based on whether the change is positive or negative
  const percentageColorClass =
    percentageChange >= 0
      ? "badge-soft-success text-success"
      : "badge-soft-danger text-danger";

  const debtGreaterThanNinetyDays = branch.unpaid_invoices.filter((invoice) => {
    const dueDate = dayjs(invoice.due_date);
    const ninetyDaysAgo = dayjs().subtract(90, "days");
    return dueDate.isBefore(ninetyDaysAgo);
  });

  const totalDebtGreaterThanNinetyDays = debtGreaterThanNinetyDays.reduce(
    (acc, invoice) => acc + parseFloat(invoice.current_total_due),
    0,
  );

  const totalUnpaidInvoices = branch.unpaid_invoices.reduce(
    (acc, invoice) => acc + parseFloat(invoice.current_total_due),
    0,
  );

  const debtRatio =
    (totalDebtGreaterThanNinetyDays / totalUnpaidInvoices) * 100;

  const mostRecentDso = branch.dso[branch.dso.length - 1];

  return (
    <div className="row">
      <div className="col-lg-4 mb-4">
        <OverviewCard
          value={
            <>
              <h5 className="text-secondary mb-2">
                ${thisMonthRevenue.toLocaleString()}
                {showChange &&
                  (percentageChange >= 0 ? (
                    <BiChevronUp size={18} className="text-soft-success ms-2" />
                  ) : (
                    <BiChevronDown
                      size={18}
                      className="text-soft-danger ms-2"
                    />
                  ))}
              </h5>

              {showChange && (
                <div className="d-inline-block">
                  <span
                    className={`rounded px-1 tx-12 ${percentageColorClass}`}
                  >
                    {formattedPercentage}
                  </span>
                  <span className="text-muted tx-12 ms-2">
                    From previous week
                  </span>
                </div>
              )}
            </>
          }
          title="Forecasted Revenue"
          icon={
            <FaDollarSign style={{ color: "rgb(85, 110, 230)" }} size={14} />
          }
        />
      </div>
      <div className="col-lg-4 mb-4">
        <OverviewCard
          value={
            <div>
              <h5 className="text-secondary mb-2">
                {branch.full_time_headcount}
              </h5>
              <span className="mb-0 tx-12 z-10 text-secondary">
                Current headcount
              </span>
            </div>
          }
          title="Full Time Headcount"
          icon={<FaUser style={{ color: "rgb(85, 110, 230)" }} size={14} />}
        />
      </div>
      <div className="col-lg-4 mb-4">
        <OverviewCard
          value={
            <>
              <h5 className="text-soft-success mb-2">
                {money.format(branch.current_forecast.toFixed(2))}
              </h5>
              <span className="mb-0 tx-12 z-10 text-secondary">
                Budgeted revenue
              </span>
            </>
          }
          title={dayjs().format("MMMM")}
          icon={
            <FaChartLine style={{ color: "rgb(85, 110, 230)" }} size={14} />
          }
        />
      </div>
      <div className="col-lg-4 mb-4">
        <OverviewCard
          value={
            <>
              <h5
                id="debt-greater-than-ninety-days-popover"
                className={`${
                  debtGreaterThanNinetyDays.length === 0
                    ? "text-soft-success"
                    : "text-soft-danger"
                } mb-2 pointer-hover`}
              >
                {money.format(totalDebtGreaterThanNinetyDays.toFixed(2))}
              </h5>
              <span className="mb-0 tx-12 z-10 text-secondary">
                {debtGreaterThanNinetyDays.length} invoices
                <span
                  className={`${
                    debtRatio > 3
                      ? "badge-soft-danger text-soft-danger"
                      : "badge-soft-success text-soft-success"
                  } tx-12 ms-1 px-1 rounded`}
                >
                  {debtRatio.toFixed(1)}% of total
                </span>
              </span>
              <UncontrolledPopover
                trigger="hover"
                placement="auto"
                target="debt-greater-than-ninety-days-popover"
                popperClassName="staff-contribution-popover"
                innerClassName="wider-popover"
              >
                <PopoverBody>
                  <UnpaidInvoicesTable invoices={debtGreaterThanNinetyDays} />
                </PopoverBody>
              </UncontrolledPopover>
            </>
          }
          title="Debt Over 90 Days"
          subTitle="Target is < 3% of total"
          icon={<IoWarning style={{ color: "rgb(85, 110, 230)" }} size={14} />}
        />
      </div>
      <div className="col-lg-4 mb-4">
        <OverviewCard
          title="Unpaid Invoices"
          icon={<PiInvoice style={{ color: "rgb(85, 110, 230)" }} size={14} />}
          value={
            <>
              <h5
                className="text-soft-danger mb-2 pointer-hover"
                id="unpaid-invoices-popover"
              >
                {money.format(totalUnpaidInvoices.toFixed(2))}
              </h5>
              <span
                className="mb-0 tx-12 z-10 text-secondary"
                id="unpaid-invoices-count"
                style={{ cursor: "pointer" }}
              >
                {branch.unpaid_invoices.length} invoices
              </span>
              <UncontrolledPopover
                trigger="hover"
                placement="auto"
                target="unpaid-invoices-popover"
                popperClassName="staff-contribution-popover"
                innerClassName="wider-popover"
              >
                <PopoverBody>
                  <UnpaidInvoicesTable invoices={branch.unpaid_invoices} />
                </PopoverBody>
              </UncontrolledPopover>
            </>
          }
        />
      </div>
      <div className="col-lg-4 mb-4">
        <OverviewCard
          title={
            <div className="d-flex align-items-center">
              DSO{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="ms-2 text-secondary"
                href="https://www.investopedia.com/terms/d/dso.asp"
                title="DSO is the average number of days it takes to collect payment from a customer."
              >
                <FaLink size={12} />
              </a>
            </div>
          }
          subTitle="Target is < 60 days"
          icon={<IoCash style={{ color: "rgb(85, 110, 230)" }} size={14} />}
          value={
            <div>
              <h5
                id="dso-popover"
                className={`${
                  mostRecentDso.dso > 60
                    ? "text-soft-danger"
                    : "text-soft-success"
                }`}
              >
                {mostRecentDso.dso.toFixed(1)}
              </h5>
              <span className="text-muted tx-12">
                DSO for {dayjs(mostRecentDso.month).format("MMM YYYY")}
              </span>
              <UncontrolledPopover
                trigger="hover"
                placement="auto"
                target="dso-popover"
                popperClassName="staff-contribution-popover"
                innerClassName="wider-popover"
              >
                <PopoverBody>
                  <DsoChart branch={branch} />
                </PopoverBody>
              </UncontrolledPopover>
            </div>
          }
        />
      </div>
    </div>
  );
};

const OverviewCard = ({
  title,
  icon,
  value,
  subTitle,
}: {
  title: React.ReactNode;
  icon: React.ReactNode;
  value: React.ReactNode;
  subTitle?: React.ReactNode;
}) => {
  return (
    <div className="bg-white border position-relative money h-100">
      <BottomLogo size={25} left={240} bottom={10} />
      <div className="card-body h-100 d-flex flex-column justify-content-between p-3">
        <div className="d-flex align-items-center mb-4">
          <div
            className="rounded-circle d-flex align-items-center justify-content-center"
            style={{
              height: "25px",
              width: "25px",
              backgroundColor: "rgba(85, 110, 230, 0.25)",
            }}
          >
            {icon}
          </div>
          <div className="ms-3">
            <p className="mb-0 text-dark">{title}</p>
            <small>{subTitle}</small>
          </div>
        </div>
        {value}
      </div>
    </div>
  );
};

const PreviousForecasts = ({ branch }: { branch: Branch }) => {
  const { uuid, forecastUuid } = useParams<{
    uuid: string;
    forecastUuid?: string;
  }>();
  const location = useLocation<{ forecast?: WeeklyForecast }>();

  return (
    <>
      {forecastUuid ? (
        <EditForecast
          initialForecast={location.state?.forecast}
          branch={branch}
        />
      ) : (
        <PaginatedList
          indexHook={(search) => {
            return useBranchWeeklyForecasts(uuid, search);
          }}
          indexHookArguments={[]}
          listName="weeklyForecasts"
          originalFilters={[]}
          list={({ data }) => {
            const weeklyForecasts = data as WeeklyForecast[];

            return (
              <div className="col-12">
                <WeeklyForecastTable
                  weeklyForecasts={weeklyForecasts}
                  branchUuid={uuid}
                />
              </div>
            );
          }}
        />
      )}
    </>
  );
};

const EditForecast = ({
  initialForecast,
  branch,
}: {
  initialForecast?: WeeklyForecast;
  branch: Branch;
}) => {
  const { takeAction, data, setUrl } = useApi();

  const { forecastUuid } = useParams<{ forecastUuid: string }>();

  useEffect(() => {
    if (initialForecast) {
      return;
    }

    setUrl(`weekly-forecasts/${forecastUuid}`);
  }, [initialForecast]);

  const forecast = initialForecast ?? data;

  const onSubmit: FormSubmitHandler<WeeklyForecast> = (values, _, props) => {
    return takeAction("update", `weekly-forecasts/${forecast.uuid}`, values)
      .then(() => {
        toast.success("Forecast updated successfully");
      })
      .catch(formError);
  };

  return (
    <>
      <OverviewCards branch={branch} forecast={forecast} />

      <BranchForecastForm
        onSubmit={onSubmit}
        initialValues={forecast}
        form="EditBranchForecast"
      />
    </>
  );
};
// Comment Popover component for weekly forecasts
const CommentPopover = ({ forecast }: { forecast: WeeklyForecast }) => {
  if (!forecast.comments) {
    return null;
  }

  return (
    <>
      <FaRegComment
        className="text-secondary ms-2"
        size={14}
        id={`forecast-comment-${forecast.uuid}`}
      />
      <UncontrolledPopover
        trigger="hover"
        target={`forecast-comment-${forecast.uuid}`}
      >
        <PopoverBody>{forecast.comments}</PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

const WeeklyForecastTable = ({
  weeklyForecasts,
  branchUuid,
}: {
  weeklyForecasts: WeeklyForecast[];
  branchUuid: string;
}) => {
  const queryClient = useQueryClient();
  const { takeAction } = useApi();
  const { modal, toggle } = useModal();

  const columns = useMemo(
    () => [
      {
        accessorKey: "week",
        header: "Week Starting",
        cell: (info: any) => {
          const forecast = info.row.original;

          return (
            <div className="d-flex align-items-center text-dark">
              <Link
                to={{
                  pathname: `/branches/${branchUuid}/weekly-forecasts/${forecast.uuid}`,
                  state: { forecast },
                }}
                className=""
              >
                {dayjs(info.getValue()).format("DD/MM/YYYY")}
              </Link>
              {forecast.comments && <CommentPopover forecast={forecast} />}
            </div>
          );
        },
      },
      {
        accessorKey: "revenue",
        header: "Revenue",
        cell: (info: any) =>
          `$${info.getValue().toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
      },
      {
        accessorKey: "wages",
        header: "Wages",
        cell: (info: any) =>
          `$${info.getValue().toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
      },
      {
        accessorKey: "recruitment_fees",
        header: "Recruitment Fees",
        cell: (info: any) =>
          `$${info.getValue().toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
      },
      {
        accessorKey: "subcontractor_costs",
        header: "Subcontractor Costs",
        cell: (info: any) =>
          `$${info.getValue().toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
      },
      {
        accessorKey: "other_costs",
        header: "Other Costs",
        cell: (info: any) =>
          `$${info.getValue().toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
      },
    ],
    [branchUuid, queryClient, takeAction, modal, toggle],
  );

  return (
    <ReactTable
      data={weeklyForecasts}
      columns={columns}
      disableSearch
      enableColumnSearch
      enableCsvExport
      CsvFileName="Weekly_Forecasts"
    />
  );
};

const DsoChart = ({ branch }: { branch: Branch }) => {
  const data = {
    labels: branch.dso.map((dso) => dayjs(dso.month).format("MMM YYYY")),
    datasets: [
      {
        data: branch.dso.map((dso) => dso.dso.toFixed(1)),
        borderColor: "rgb(85, 110, 230)",
        fill: true,
        backgroundColor: (context: any) => {
          const chart = context.chart;
          if (!chart.ctx) {
            return;
          }
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            // This case happens on initial chart load
            return null;
          }
          const gradient = ctx.createLinearGradient(
            0,
            chartArea.bottom,
            0,
            chartArea.top,
          );
          gradient.addColorStop(0, "rgba(255, 255, 255, 0)"); // white and transparent
          gradient.addColorStop(1, "rgba(85, 110, 230, 0.7)"); //  orange, with some opacity

          return gradient;
        },
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 4,
      },
    ],
  };

  return (
    <div>
      <Line
        data={data}
        options={{
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
              intersect: false,
              mode: "index",
              callbacks: {
                title: (tooltipItems) => {
                  return tooltipItems[0].label;
                },
                label: (tooltipItem) => {
                  return `DSO: ${tooltipItem.raw}`;
                },
              },
            },
          },
          elements: {
            point: {
              radius: 0,
              hoverRadius: 4,
              hitRadius: 8,
            },
            line: {
              tension: 0.5,
              borderWidth: 2,
              borderColor: "#F2994A",
              fill: false,
            },
          },
        }}
      />
    </div>
  );
};

export default WeeklyForecasts;
