import { Field, formValueSelector } from "redux-form";
import FormModal from "../utils/FormModal";
import renderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import SelectInput from "../form/SelectInput";
import { useAuth } from "../../context/auth-context";
import { IntegrationType } from "../integrations/integrationTypes";
import Tabs from "../tabs/Tabs";
import useApi from "../api/useApi";
import filterOption from "../../utils/filterOption";
import { connect } from "react-redux";
import { money, percentage } from "../form/formatters";

const AddEditModal = (props) => {
  const { taxCodes, types, organisationGroupMembers } = props;

  const hasGroupMembers = organisationGroupMembers.length > 0;

  const { user } = useAuth();

  return (
    <FormModal {...props}>
      {({ change }) => {
        if (
          user.active_organisation.integration_types.includes(
            IntegrationType.Accounting,
          ) &&
          !props.initialValues?.uuid
        ) {
          return (
            <Tabs
              tabs={[
                {
                  title: "Details",
                  component: (
                    <Fields
                      {...props}
                      change={change}
                      hasGroupMembers={hasGroupMembers}
                    />
                  ),
                },
                {
                  title: "Sync From Accounting",
                  component: (
                    <SyncFromAccounting
                      chartOfAccounts={props.chartOfAccounts}
                    />
                  ),
                },
              ]}
            />
          );
        }

        return (
          <Fields
            {...props}
            change={change}
            hasGroupMembers={hasGroupMembers}
          />
        );
      }}
    </FormModal>
  );
};

const Fields = ({
  hasGroupMembers,
  taxCodes,
  types,
  organisationGroupMembers,
  autoFillType,
  autoFill,
  change,
}) => {
  return (
    <>
      <div className="form-group col-lg-4">
        <Field
          required
          validate={required}
          component={renderField}
          name="name"
          label="Name"
        />
      </div>
      <div className="form-group col-lg-2">
        <Field
          required
          validate={required}
          component={renderField}
          name="code"
          label="Code"
        />
      </div>
      <div className="form-group col-lg-3">
        <Field
          required
          validate={required}
          component={SelectInputAsync}
          options={types}
          name="type"
          label="Type"
        />
      </div>
      <div className="form-group col-lg-3">
        <Field
          required
          validate={required}
          component={SelectInputAsync}
          name="tax_code"
          label="Tax Code"
          options={taxCodes}
        />
      </div>
      <div className="form-group col-lg-12">
        <Field
          required
          validate={required}
          component={renderField}
          textarea
          name="description"
          label="Description"
        />
      </div>
      <div className="form-group col-lg-3">
        <Field
          component={renderToggleInput}
          label="Payment"
          name="credit_card"
        />
      </div>
      <div className="form-group col-lg-3">
        <Field
          component={renderToggleInput}
          label="Purchase Order"
          name="purchase_order"
        />
      </div>
      <div className="form-group col-lg-3">
        <Field
          component={renderToggleInput}
          label="Expense Claim"
          name="expense_claim"
        />
      </div>
      <div className="form-group col-lg-3">
        <Field
          component={renderToggleInput}
          label="Sales Account"
          name="sales_account"
        />
      </div>

      <div className="form-group col-lg-3">
        <Field
          component={renderToggleInput}
          label="Subcontractor Account"
          name="is_subcontractor_account"
        />
      </div>

      <div className="form-group col-lg-12">
        <Field
          component={renderToggleInput}
          label="Budget Control"
          name="budget_control"
        />
        <small className="d-block">
          Check this if you want purchases attributed to this account be blocked
          if they are over budget.
        </small>
      </div>
      {hasGroupMembers ? (
        <>
          <div className="form-group col-lg-6">
            {/* These are the wrong way around, I know.*/}
            <Field
              component={SelectInput}
              options={organisationGroupMembers.map((p) => ({
                label: p.name,
                value: p.id,
              }))}
              name="loan_organisation_id"
              label="Inter Entity Loan From"
            />
          </div>
          <div className="form-group col-lg-6">
            <Field
              component={SelectInput}
              options={organisationGroupMembers.map((p) => ({
                label: p.name,
                value: p.id,
              }))}
              name="loan_from_organisation_id"
              label="Inter Entity Loan To"
            />
          </div>
          <div className="form-group col-lg-12">
            <Field
              component={renderToggleInput}
              name="is_external_credit_card_account"
              label="Is External Credit Card Account"
            />
          </div>
        </>
      ) : null}
      <div className="col-12 mb-3 mt-4">
        <div className="d-flex justify-content-between align-items-center">
          <label className="section-title my-0">
            Budget Auto Fill Settings
          </label>
          <div>
            <label className="me-3">Auto Fill in Budget</label>
            <Field
              component={renderToggleInput}
              className="toggle-sm"
              name="auto_fill"
              icons={false}
              onChange={(e) => {
                if (!e.target.checked) {
                  change("auto_fill_type", null);
                  change("percentage_of_revenue", null);
                  change("multiple_of_full_time_staff", null);
                }
              }}
            />
          </div>
        </div>
        <hr />
      </div>
      {autoFill ? (
        <>
          <div className="form-group col-lg-12">
            <Field
              component={SelectInput}
              changeValue={(value) => {
                change("percentage_of_revenue", null);
                change("multiple_of_full_time_staff", null);
              }}
              name="auto_fill_type"
              label="Auto Fill Type"
              required
              validate={required}
              options={[
                {
                  label: "Percentage of Revenue",
                  value: "percentage_of_revenue",
                },
                {
                  label: "Multiple of Full Time Staff",
                  value: "multiple_of_full_time_staff",
                },
              ]}
            />
          </div>
          {autoFillType === "percentage_of_revenue" && (
            <div className="form-group col-lg-12">
              <Field
                component={renderField}
                name="percentage_of_revenue"
                label="Percentage of Revenue"
                {...percentage}
                required
                validate={required}
              />
            </div>
          )}
          {autoFillType === "multiple_of_full_time_staff" && (
            <div className="form-group col-lg-12">
              <Field
                component={renderField}
                name="multiple_of_full_time_staff"
                label="Multiple of Full Time Staff"
                required
                validate={required}
                {...money}
              />
            </div>
          )}
        </>
      ) : (
        <div className="form-group col-lg-12">
          <p>
            Use the auto fill settings to automatically fill the budget for this
            account.
          </p>
        </div>
      )}
    </>
  );
};

const SyncFromAccounting = ({ chartOfAccounts }) => {
  const { data: integrationAccounts } = useApi("accounting/accounts", []);

  const currentlyIntegratedAccounts = chartOfAccounts
    .filter((account) => account.accounting_integration_model)
    .map(
      (account) => account.accounting_integration_model.external_integration_id,
    );

  const options = integrationAccounts
    .filter(
      (account) => !currentlyIntegratedAccounts.includes(account.AccountID),
    )
    .map((account) => ({
      label: (
        <>
          <p className="text-dark mb-0">{account.Code}</p>
          <small className="mb-0">{account.Name}</small>
        </>
      ),
      value: account.AccountID,
      text: `${account.Name} ${account.Code}`,
    }));

  return (
    <div className="form-group col-lg-12">
      <Field
        component={SelectInput}
        name="account_id"
        validate={required}
        required
        label="Sync From Accounting"
        filterOption={filterOption}
        options={options}
      />
      <small className="d-block">
        Check this if you want to sync this account from your accounting
        software.
      </small>
    </div>
  );
};

const mapStateToProps = (state, { form }) => {
  const selector = formValueSelector(form);

  return {
    autoFillType: selector(state, "auto_fill_type"),
    autoFill: selector(state, "auto_fill"),
  };
};

export default connect(mapStateToProps)(AddEditModal);
