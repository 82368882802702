import { Field, WrappedFieldArrayProps } from "redux-form";
import FormHeader from "../utils/FormHeader";
import RenderField from "../utils/renderField";
import SelectInput from "../form/SelectInput";
import { money } from "../form/formatters";
import { Button } from "reactstrap";
import useApi from "../api/useApi";
import { IUseApi, IUseApiWithData } from "../api/apiTypes";
import { AllowanceType } from "../allowanceTypes/allowanceTypeTypes";
import required from "../utils/required";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";

const EmploymentDetailAllowances = (
  props: WrappedFieldArrayProps & { organisationId?: number },
) => {
  const { fields, organisationId } = props;
  const { takeAction }: IUseApi = useApi();

  const { data: allowanceTypes }: IUseApiWithData<AllowanceType[]> = useApi(
    `allowance-types?filter[has_trashed_allowances]=true&organisation_id=${organisationId}`,
    [],
  );

  if (allowanceTypes.length === 0) {
    return null;
  }

  return (
    <>
      <FormHeader>Allowances</FormHeader>

      {fields.map((allowance, index) => {
        const value = fields.get(index);

        return (
          <>
            <div className="form-group col-lg-4">
              <Field
                name={`${allowance}.allowance_type_id`}
                component={SelectInput}
                label="Allowance Type"
                required
                validate={required}
                options={allowanceTypes.map((allowanceType) => ({
                  label: `${allowanceType.name}${
                    allowanceType.deleted_at ? " (Deleted)" : ""
                  }`,
                  value: allowanceType.id,
                  disabled: allowanceType.deleted_at,
                }))}
              />
            </div>
            <div className="form-group col-lg-4">
              <Field
                name={`${allowance}.notes`}
                component={RenderField}
                label="Notes"
              />
            </div>
            <div className="form-group col-lg-2">
              <Field
                name={`${allowance}.amount`}
                component={RenderField}
                label="Amount"
                required
                validate={required}
                {...money}
              />
            </div>
            <div className="form-group col-lg-2 d-flex align-items-end">
              <Button
                block
                color="danger"
                outline
                onClick={() => {
                  if (value.uuid) {
                    return deleteSwal().then(() => {
                      takeAction(
                        "destroy",
                        `staff-role-allowances/${value.uuid}`,
                      )
                        .then(() => {
                          fields.remove(index);
                        })
                        .catch(errorSwal);
                    });
                  }
                  fields.remove(index);
                }}
              >
                Delete
              </Button>
            </div>
          </>
        );
      })}
      <div className="form-group col-12 d-flex">
        <Button
          color="primary"
          className="ms-auto"
          outline
          onClick={() => fields.push({})}
        >
          Add Allowance
        </Button>
      </div>
    </>
  );
};
export default EmploymentDetailAllowances;
