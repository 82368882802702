import { Button } from "reactstrap";
import { Branch, BudgetLock } from "../branchTypes";
import { IUseApi } from "../../api/apiTypes";
import useApi from "../../api/useApi";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import isSubmitting from "../../utils/submitting";
import errorSwal from "../../utils/errorSwal";
import { useAuth } from "../../../context/auth-context";
import { GroupType } from "../../user/userTypes";

interface BranchBudgetLockButtonProps {
  budgetLock?: BudgetLock;
  period: [string, string];
  refreshData: Function;
}

const BranchBudgetLockButton = ({
  budgetLock,
  period,
  refreshData,
}: BranchBudgetLockButtonProps) => {
  const { uuid } = useParams<{ uuid: string }>();
  const { user } = useAuth();
  const { takeAction, loading } = useApi();

  const { data: branch }: IUseApi<Branch> = useApi(
    `organisation-branches/${uuid}`,
    null,
  );

  const handleLockBudget = async () => {
    try {
      await takeAction("store", `/branches/${uuid}/budget-lock`, {
        date: period[0],
      });
      refreshData();
      toast.success("Budget lock requested");
    } catch (error) {
      errorSwal(error);
    }
  };

  const hasPermission =
    user?.hasAccessTo(
      "App\\Models\\OrganisationBranch",
      "update_budgets",
      branch?.id,
    ) ||
    user?.id == branch?.branch_manager_id ||
    user?.groups.includes(GroupType.Accounting);

  if (!hasPermission) {
    return null;
  }

  if (!budgetLock) {
    return (
      <Button color="primary" disabled={loading} onClick={handleLockBudget}>
        {isSubmitting(loading, "Request Budget to be Locked", "Requesting...")}
      </Button>
    );
  }

  return null;
};

export default BranchBudgetLockButton;
