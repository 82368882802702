import { useMemo } from "react";
import HeaderPage from "../header/HeaderPage";
import PaginatedList from "../pagination/PaginatedList";
import useExcelExtractions from "./hooks/useExcelExtractions";
import ReactTable from "../table/ReactTable";
import { Link } from "react-router-dom";
import { RiAddFill } from "react-icons/ri";
import { BiTrash } from "react-icons/bi";
import TextButton from "../utils/TextButton";

interface ExcelExtraction {
  id: number;
  uuid: string;
  name: string;
  organisation_id: number;
  created_at: string;
  updated_at: string;
  columns: Array<{
    id: number;
    uuid: string;
    sheet: string;
    cell: string;
    column_name: string;
  }>;
}

const ExcelExtractionsList = () => {
  return (
    <>
      <HeaderPage
        crumbs={[
          {
            link: "excel-extractions",
            name: "Excel Extractions",
            active: true,
          },
        ]}
        titlePage="Excel Extractions"
      />

      <PaginatedList
        indexHook={useExcelExtractions}
        listName="excelExtractions"
        extraButtons={() => (
          <Link to="/excel-extractions/add">
            <RiAddFill aria-label="Add New Resource" className="tx-24 ms-1" />
          </Link>
        )}
        originalFilters={[]}
        list={({ data }) => {
          const excelExtractions = data as ExcelExtraction[];

          return (
            <div className="col-12 mt-3">
              <ExcelExtractionsTable excelExtractions={excelExtractions} />
            </div>
          );
        }}
      />
    </>
  );
};

const ExcelExtractionsTable = ({
  excelExtractions,
}: {
  excelExtractions: ExcelExtraction[];
}) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        cell: (info: any) => {
          return (
            <Link
              className="text-dark"
              to={`/excel-extractions/${info.row.original.uuid}/extract`}
            >
              {info.row.original.name}
            </Link>
          );
        },
      },
      {
        accessorKey: "columns",
        header: "Columns",
        cell: (info: any) => {
          return info.row.original.columns.length;
        },
      },
      {
        accessorKey: "Actions",
        header: "Actions",
        cell: (info: any) => {
          return (
            <TextButton className="text-danger" onClick={() => {}}>
              <BiTrash aria-label="Delete" size={18} />
            </TextButton>
          );
        },
      },
    ],
    [excelExtractions],
  );

  return <ReactTable columns={columns} data={excelExtractions} disableSearch />;
};

export default ExcelExtractionsList;
