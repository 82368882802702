import {
  Field,
  FieldArray,
  InjectedFormProps,
  reduxForm,
  WrappedFieldArrayProps,
} from "redux-form";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import renderToggleInput from "../utils/renderToggleInput";
import { Button } from "reactstrap";
import { money } from "../form/formatters";
import TextButton from "../utils/TextButton";
import { FiTrash } from "react-icons/fi";
import SubmitButton from "../utils/SubmitButton";
import useApi from "../api/useApi";
import { Branch } from "../branches/branchTypes";
import SelectInput from "../form/SelectInput";
import FormErrorAlert from "../form/FormErrorAlert";
import { Journal } from "./journalTypes";
import ApprovalButton from "../approvals/ApprovalButton";
import { ChartOfAccount } from "../chartOfAccounts/chartOfAccountTypes";
import filterOption from "../../utils/filterOption";

interface JournalLineFormValues {
  description?: string;
  account_id?: string;
  branch_id?: string;
  debit?: number;
  credit?: number;
  tax_type?: "no_tax" | "tax_exclusive" | "tax_inclusive";
}

export interface JournalFormValues {
  narration: string;
  date?: string;
  cash_basis: boolean;
  journal_lines: JournalLineFormValues[];
}

interface JournalFormProps {
  journal?: Journal;
  refreshData?: Function;
}

const JournalForm = (
  props: InjectedFormProps<Partial<JournalFormValues>, JournalFormProps> &
    JournalFormProps,
) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <FormErrorAlert error={props.error} />

        <div className="col-lg-12 form-group">
          <Field
            name="narration"
            component={RenderField}
            required
            validate={required}
            label="Narration"
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field name="date" component={RenderField} label="Date" type="date" />
        </div>

        <div className="col-lg-6 form-group">
          <Field
            component={renderToggleInput}
            name="cash_basis"
            label="Show journal on cash basis reports"
          />
        </div>
        <FieldArray
          name="journal_lines"
          component={JournalLines}
          journal={props.journal}
        />
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const JournalLines = ({
  fields,
  journal,
  refreshData,
}: WrappedFieldArrayProps<JournalLineFormValues> & JournalFormProps) => {
  const { data: accounts } = useApi("chart-of-accounts", []);
  const { data: branches } = useApi("custom-fields/branches", []);

  const calculateTotals = () => {
    let totalDebit = 0;
    let totalCredit = 0;

    fields.forEach((field, index) => {
      const fieldValue = fields.get(index);
      totalDebit += fieldValue?.debit
        ? parseFloat(fieldValue.debit.toString())
        : 0;
      totalCredit += fieldValue?.credit
        ? parseFloat(fieldValue.credit.toString())
        : 0;
    });

    return { totalDebit, totalCredit };
  };

  const { totalDebit, totalCredit } = calculateTotals();

  return (
    <div className="col-12 mt-4 mb-4">
      <div className="col-12 d-flex justify-content-between align-items-center  mt-3">
        <label className={`section-title mt-0 mb-0`}>Journal Lines</label>
        <div>
          <ApprovalButton
            wrapperClass="-"
            buttonClass="btn-sm"
            block={false}
            onSuccess={refreshData}
            approvalAction={journal?.approval_action}
          />
        </div>
      </div>
      <hr className="w-100" />

      <div className="bg-white p-2 rounded-lg border shadow-sm">
        <table className=" w-100">
          <thead className="bg-light p-2 text-dark">
            <tr className="p-2">
              <th className="px-2 py-3" style={{ width: "5%" }}>
                #
              </th>
              <th className="px-2 py-3" style={{ width: "25%" }}>
                Description
              </th>
              <th className="px-2 py-3" style={{ width: "20%" }}>
                Account
              </th>
              <th className="px-2 py-3" style={{ width: "20%" }}>
                Branch
              </th>
              <th className="px-2 py-3" style={{ width: "12%" }}>
                Debit
              </th>
              <th className="px-2 py-3" style={{ width: "12%" }}>
                Credit
              </th>
              <th className="px-2 py-3" style={{ width: "6%" }}>
                Delete
              </th>
            </tr>
          </thead>
          <tbody className="mt-3">
            {fields.map((field, index) => {
              const fieldValue = fields.get(index);

              return (
                <tr key={field}>
                  <td className="align-middle">{index + 1}</td>
                  <td>
                    <Field
                      name={`${field}.description`}
                      component={RenderField}
                      label=""
                      required
                      validate={required}
                    />
                  </td>
                  <td>
                    <Field
                      name={`${field}.account_id`}
                      component={SelectInput}
                      label=""
                      required
                      validate={required}
                      filterOption={filterOption}
                      options={accounts.map((account: ChartOfAccount) => ({
                        label: (
                          <>
                            <p className="text-dark mb-0">{account.code}</p>
                            <p className="text-muted mb-0">{account.name}</p>
                          </>
                        ),
                        value: account.id,
                        text: `${account.code} - ${account.name}`,
                      }))}
                    />
                  </td>
                  <td>
                    <Field
                      name={`${field}.branch_id`}
                      component={SelectInput}
                      label=""
                      required
                      validate={required}
                      options={branches.map((branch: Branch) => ({
                        label: branch.name,
                        value: branch.id,
                      }))}
                    />
                  </td>
                  <td>
                    <Field
                      name={`${field}.debit`}
                      component={RenderField}
                      label=""
                      extraProps={{
                        disabled: fieldValue?.credit,
                      }}
                      {...money}
                    />
                  </td>
                  <td>
                    <Field
                      name={`${field}.credit`}
                      component={RenderField}
                      extraProps={{
                        disabled: fieldValue?.debit,
                      }}
                      {...money}
                    />
                  </td>
                  <td className="text-center">
                    <TextButton
                      onClick={() => fields.remove(index)}
                      title="Remove Line"
                    >
                      <FiTrash className="text-danger" />
                    </TextButton>
                  </td>
                </tr>
              );
            })}
            <tr>
              <td colSpan={4} className="text-end fw-bold py-3 text-dark">
                Totals:
              </td>
              <td className="fw-bold py-3 text-dark ">
                ${totalDebit.toFixed(2)}
              </td>
              <td className="fw-bold py-3 text-dark">
                ${totalCredit.toFixed(2)}
              </td>
              <td></td>
            </tr>
            {totalDebit !== totalCredit && (
              <tr>
                <td colSpan={7} className="text-danger">
                  Warning: Debits and credits are not balanced. The difference
                  is ${Math.abs(totalDebit - totalCredit).toFixed(2)}.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <Button
          type="button"
          outline
          className="btn btn-sm"
          onClick={() => fields.push({})}
        >
          Add Line
        </Button>
        {fields.length > 0 && (
          <div
            className={
              totalDebit === totalCredit ? "text-success" : "text-danger"
            }
          >
            {totalDebit === totalCredit
              ? "✓ Journal is balanced"
              : "✗ Journal is not balanced"}
          </div>
        )}
      </div>
    </div>
  );
};

const form = reduxForm<Partial<JournalFormValues>, JournalFormProps>({
  form: "journal",
});

export default form(JournalForm);
