import dayjs from "dayjs";
import { BudgetItem, BudgetRow, MonthlyTotals } from "../types";
import { getMonthColumns } from "./budgetUtils";

/**
 * Create the main revenue row from budget items
 */
export const createMainRevenueRow = (
  budgetItems: BudgetItem[],
  period: [string, string],
): BudgetRow => {
  const months = getMonthColumns(period);

  return {
    //@ts-ignore
    amounts: months.map((month) => {
      const item = budgetItems.find(
        (item) =>
          dayjs(item.month).format("YYYY-MM") === month &&
          item.type === "revenue" &&
          item.revenue_type === "main",
      );

      return (
        item || {
          account_id: "",
          month: `${month}-01`,
          amount: "",
          type: "revenue" as const,
          revenue_type: "main" as const,
        }
      );
    }),
    account: null,
  };
};

/**
 * Create the adjustment revenue row from budget items
 */
export const createAdjustmentRevenueRow = (
  budgetItems: BudgetItem[],
  period: [string, string],
): BudgetRow => {
  const months = getMonthColumns(period);

  return {
    //@ts-ignore
    amounts: months.map((month) => {
      const item = budgetItems.find(
        (item) =>
          dayjs(item.month).format("YYYY-MM") === month &&
          item.type === "revenue" &&
          item.revenue_type === "adjustment",
      );

      return (
        item || {
          account_id: "",
          month: `${month}-01`,
          amount: "",
          type: "revenue" as const,
          revenue_type: "adjustment" as const,
        }
      );
    }),
    account: null,
  };
};

/**
 * Calculate subcontractor markup rows
 */
export const createSubcontractorMarkupRows = (
  costOfSalesRows: BudgetRow[],
  period: [string, string],
): BudgetRow[] => {
  //@ts-ignore
  return costOfSalesRows
    .filter((row) => row.account?.is_subcontractor_account)
    .map((row) => ({
      amounts: row.amounts.map((amount) => {
        const baseAmount = amount.amount
          ? typeof amount.amount === "string"
            ? parseFloat(amount.amount)
            : amount.amount
          : 0;

        const markupPercentage =
          amount.branch?.subcontractor_markup_percentage || 0;

        const markupAmount = (baseAmount * markupPercentage) / 100;

        const totalAmount = baseAmount + markupAmount;

        return {
          account_id: amount.account_id,
          month: amount.month,
          amount: baseAmount ? totalAmount.toString() : "",
          type: "revenue" as const,
          revenue_type: "subcontractor_markup" as const,
          is_readonly: true,
          subcontractor_account_name: row.account?.name,
          items: amount.items?.map((item) => ({
            ...item,
            amount:
              ((item.amount as number) * markupPercentage) / 100 +
              (item.amount as number),
          })),
        };
      }),
      account: null,
    }));
};

/**
 * Calculate revenue totals from all revenue sources
 */
export const calculateRevenueTotals = (
  mainRevenueRow: BudgetRow,
  adjustmentRevenueRow: BudgetRow,
  subcontractorMarkupRows: BudgetRow[],
): MonthlyTotals => {
  const totals: MonthlyTotals = {};

  // Add main revenue
  mainRevenueRow.amounts.forEach((amount) => {
    if (!amount.month || !amount.amount) return;
    const month = dayjs(amount.month).format("YYYY-MM");
    const amountValue =
      typeof amount.amount === "string"
        ? parseFloat(amount.amount)
        : amount.amount;
    totals[month] = (totals[month] || 0) + amountValue;
  });

  // Add adjustment revenue
  adjustmentRevenueRow.amounts.forEach((amount) => {
    if (!amount.month || !amount.amount) return;
    const month = dayjs(amount.month).format("YYYY-MM");
    const amountValue =
      typeof amount.amount === "string"
        ? parseFloat(amount.amount)
        : amount.amount;
    totals[month] = (totals[month] || 0) + amountValue;
  });

  // Add subcontractor markup revenue
  subcontractorMarkupRows.forEach((row) => {
    row.amounts.forEach((amount) => {
      if (!amount.month || !amount.amount) return;
      const month = dayjs(amount.month).format("YYYY-MM");
      const amountValue =
        typeof amount.amount === "string"
          ? parseFloat(amount.amount)
          : amount.amount;
      totals[month] = (totals[month] || 0) + amountValue;
    });
  });

  return totals;
};
