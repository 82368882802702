import { Modal, ModalHeader, ModalBody } from "reactstrap";

const FullWidthModal = ({
  isOpen,
  toggle,
  title,
  children,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="modal-max-width"
      backdrop="static"
      style={{ width: '98%', maxWidth: '98%' }}
    >
      <ModalHeader toggle={toggle}>
        {title}
      </ModalHeader>
      <ModalBody className="p-3">
        <div className="table-responsive">
          {children}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default FullWidthModal;
