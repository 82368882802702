import { useHistory, useLocation, useParams } from "react-router-dom";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import BranchForecastForm from "./BranchForecastForm";
import { toast } from "react-toastify";
import formError from "../utils/formError";
import { FormSubmitHandler } from "redux-form";
import { BranchIndex, WeeklyForecast } from "./branchTypes";
import dayjs from "dayjs";

const AddBranchForecast = () => {
  const { takeAction }: IUseApi = useApi();

  const { uuid } = useParams<{ uuid: string }>();

  const location = useLocation<{
    otherBranches: BranchIndex[];
  }>();

  const history = useHistory();

  const onSubmit: FormSubmitHandler<WeeklyForecast> = (values, _, props) => {
    return takeAction("store", `branches/${uuid}/forecasts`, {
      ...values,
      week: dayjs(values.week)
        .startOf("week")
        .add(1, "day")
        .format("YYYY-MM-DD"),
    })
      .then(({ data }) => {
        toast.success("Forecast added successfully");

        history.push({
          pathname: `/branches/${uuid}/weekly-forecasts/${data.data.uuid}`,
          state: {
            forecast: data.data,
            otherBranches: location.state?.otherBranches,
          },
        });
      })
      .catch(formError);
  };

  return (
    <>
      <BranchForecastForm onSubmit={onSubmit} form="AddBranchForecast" />
    </>
  );
};

export default AddBranchForecast;
