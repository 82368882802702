import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getRoles = ({ pageParam = 1 }, search?: string) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(
      `organisation-roles?page=${pageParam}&filter[search]=${
        search || ""
      }&paginate=9`,
    )
    .then(({ data }) => data);
};

export default function useRoles(search?: string) {
  return useInfiniteQuery(
    ["organisation-roles", { search }],
    (pageParam) => getRoles(pageParam, search),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }

        return meta.current_page + 1;
      },
    },
  );
}
