import React from "react";
import { Field } from "redux-form";
import FileInput from "../upload/FileInput";
import required from "../utils/required";
import RenderField from "../utils/renderField";

const InsuranceFields = ({ fields }) => {
  return fields.map((field, index) => {
    const current = fields.get(index);
    return (
      <React.Fragment key={index}>
        <div className="col-lg-6 form-group">
          <Field
            name={`${field}.document`}
            component={FileInput}
            label={current.name}
            validate={required}
            required
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            name={`${field}.expiry_date`}
            component={RenderField}
            type="date"
            timeFormat={false}
            label="Expiry Date"
            validate={required}
            required
          />
        </div>
      </React.Fragment>
    );
  });
};

export default InsuranceFields;
