import { useEffect, useState } from "react";
import { Branch } from "../branchTypes";
import { BudgetRow } from "../types";
import { ModalHeader, ModalBody, Modal, Button } from "reactstrap";
import { GrNext, GrPrevious } from "react-icons/gr";
import dayjs from "dayjs";
import PurchaseTable from "../../purchases/PurchaseTable";
import useApi from "../../api/useApi";
import CustomScaleLoader from "../../utils/scaleLoader";

const PurchaseModal = ({
  toggle,
  modal,
  selectedRow,
  branch,
  months,
}: {
  toggle: () => void;
  modal: boolean;
  selectedRow: BudgetRow | null;
  branch: Branch | undefined;
  months: string[];
}) => {
  const [selectedMonth, setSelectedMonth] = useState<string>(
    dayjs(months[0]).format("YYYY-MM-DD"),
  );

  const { data, setUrl, loading } = useApi("", [], true);

  useEffect(() => {
    setUrl(
      `purchases?filter[account_id]=${selectedRow?.account?.id}&filter[branch_id]=${branch?.id}&filter[month]=${selectedMonth}`,
    );
  }, [selectedMonth, branch?.id, selectedRow?.account?.id]);

  return (
    <Modal className="wd-md-1000 mx-wd-800 w-95" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        Purchases for {selectedRow?.account?.name}
      </ModalHeader>
      <ModalBody>
        <div className="d-flex justify-content-between mb-5">
          <Button
            color="link"
            className="p-0"
            onClick={() =>
              setSelectedMonth(
                dayjs(selectedMonth).subtract(1, "month").format("YYYY-MM-DD"),
              )
            }
          >
            <GrPrevious size={20} />
          </Button>
          <p className="mb-0 text-dark tx-18">
            {dayjs(selectedMonth).format("MMMM YYYY")}
          </p>
          <Button
            color="link"
            className="p-0"
            onClick={() =>
              setSelectedMonth(
                dayjs(selectedMonth).add(1, "month").format("YYYY-MM-DD"),
              )
            }
          >
            <GrNext size={20} />
          </Button>
        </div>

        {loading && <CustomScaleLoader>Fetching Purchases</CustomScaleLoader>}
        <PurchaseTable data={data} showFooter={true} />
      </ModalBody>
    </Modal>
  );
};

export default PurchaseModal;
