import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Button, PopoverBody, UncontrolledPopover } from "reactstrap";
import useApi from "../api/useApi";
import CustomScaleLoader from "../utils/scaleLoader";
import ReactTable from "../table/ReactTable";
import { ColumnDef } from "@tanstack/react-table";
import FormModal from "../utils/FormModal";
import { Field } from "redux-form";
import SelectInput from "../form/SelectInput";
import RenderField from "../utils/renderField";
import useModal from "../hooks/useModal";
import formError from "../utils/formError";
import { toast } from "react-toastify";

const Activities = ({
  activities,
  branch,
}: {
  activities: any[];
  branch: { id: number };
}) => {
  let partOfBranch = false;

  return (
    <>
      {activities.map((activity) => {
        if (
          !partOfBranch &&
          activity?.properties?.attributes?.branch_id == branch.id
        ) {
          partOfBranch = true;
          return (
            <p key={activity.id} className="mb-0">
              Added to branch at {dayjs(activity.created_at).format("HH:mm")} on{" "}
              {dayjs(activity.created_at).format("DD/MM/YYYY")}
            </p>
          );
        }
        if (partOfBranch && activity.properties?.old?.branch_id == branch.id) {
          partOfBranch = false;
          return (
            <p key={activity.id} className="mb-0">
              Removed from branch at{" "}
              {dayjs(activity.created_at).format("HH:mm")} on{" "}
              {dayjs(activity.created_at).format("DD/MM/YYYY")}
            </p>
          );
        }
      })}
    </>
  );
};

const BranchEquipment = ({ branch }: { branch: any }) => {
  const { uuid } = useParams<{ uuid: string }>();
  const [previous, setPrevious] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState<any[]>([]);

  const { toggle, modal } = useModal();

  const { data, setUrl, loading, refreshData } = useApi(
    `/organisation-branches/${uuid}/equipment`,
    [],
    true,
  );

  useEffect(() => {
    if (previous) {
      setUrl(`/organisation-branches/${uuid}/equipment?previous=true`);
      return;
    }
    setUrl(`/organisation-branches/${uuid}/equipment`);
  }, [previous]);

  const columns: ColumnDef<any>[] = [
    {
      accessorKey: "name",
      header: "Equipment Name",
      cell: ({ row }) => {
        return (
          <div id={`equipment-${row.original.id}`}>
            <Link to={row.original.link}>{row.original.name}</Link>
            <UncontrolledPopover
              target={`equipment-${row.original.id}`}
              placement="right"
              trigger="hover"
            >
              <PopoverBody>
                <Activities
                  activities={row.original.activities}
                  branch={branch}
                />
              </PopoverBody>
            </UncontrolledPopover>
          </div>
        );
      },
    },
  ];

  const handleTransferEquipment = () => {
    // TODO: Implement equipment transfer functionality
    console.log("Transfer equipment:", selectedEquipment);
  };

  return (
    <>
      <div className="row">
        <div className="col-12 d-flex align-items-center">
          <label className="section-title mt-3">
            {previous ? "Previous" : "Current"} Equipment
          </label>
          <Button
            color="link"
            className="ms-auto p-0"
            onClick={() => {
              setPrevious(!previous);
              setSelectedEquipment([]);
            }}
          >
            Toggle current/previous
          </Button>
          {selectedEquipment.length > 0 && (
            <Button
              color="secondary"
              outline
              size="sm"
              className="ms-2"
              onClick={toggle}
            >
              Transfer Selected Equipment
            </Button>
          )}
        </div>
        <div className="col-12">
          <hr />
        </div>
      </div>
      {loading ? (
        <CustomScaleLoader>
          Fetching {previous ? "Previous" : "Current"} Equipment...
        </CustomScaleLoader>
      ) : (
        <ReactTable
          data={data || []}
          columns={columns}
          enableMultiSelect={previous === false}
          setSelectedRows={setSelectedEquipment}
          wrapperClasses="table-responsive bg-white"
        />
      )}
      <TransferEquipmentModal
        modal={modal}
        toggle={toggle}
        branch={branch}
        selectedEquipment={selectedEquipment}
        refreshData={refreshData}
      />
    </>
  );
};

const TransferEquipmentModal = ({
  modal,
  toggle,
  branch,
  selectedEquipment,
  refreshData,
}: {
  modal: boolean;
  toggle: () => void;
  branch: any;
  selectedEquipment: any[];
  refreshData: () => void;
}) => {
  const { takeAction } = useApi();

  const onSubmit = (values: any) => {
    return takeAction("store", "/equipment-transfer", {
      ...values,
      equipment: selectedEquipment.map((item) => item.id),
    })
      .then(() => {
        toggle();
        toast.success("Equipment transferred successfully");
        refreshData();
      })
      .catch(formError);
  };

  return (
    <FormModal
      modal={modal}
      toggle={toggle}
      title={`Transfer ${selectedEquipment.length} bits of equipment`}
      form="TransferEquipmentForm"
      onSubmit={onSubmit}
    >
      <div className="col-12 form-group">
        <Field
          component={SelectInput}
          name="branch_id"
          label="Branch"
          url="/organisation-branches"
          formatData={(data: any) =>
            data
              .filter((item: any) => item.id !== branch.id)
              .map((item: any) => ({ label: item.name, value: item.id }))
          }
        />
      </div>
      <div className="col-12 form-group">
        <Field
          component={RenderField}
          textarea
          name="edit_reasons.branch_id"
          label="Reason"
        />
      </div>
    </FormModal>
  );
};

export default BranchEquipment;
