import {
  Field,
  FieldArray,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
  WrappedFieldArrayProps,
  change as reduxFormChange,
} from "redux-form";
import FormHeader from "../utils/FormHeader";
import SelectInput from "../form/SelectInput";
import RenderField from "../utils/renderField";
import { connect } from "react-redux";
import { ComponentType, useState } from "react";
import { Button } from "reactstrap";
import { money } from "../form/formatters";
import { FiTrash } from "react-icons/fi";
import TextButton from "../utils/TextButton";
import SubmitButton from "../utils/SubmitButton";
import FormErrorAlert from "../form/FormErrorAlert";
import required from "../utils/required";

interface CustomNotificationFormProps {
  notifiableModel: string;
  notifiableType: string;
  dispatch: (action: any) => void;
}

const CustomNotificationForm = (
  props: InjectedFormProps<any, CustomNotificationFormProps> &
    CustomNotificationFormProps,
) => {
  const { handleSubmit, notifiableModel, notifiableType, initialValues, dispatch } =
    props;

  const notifiableOptions = [
    {
      label: "User",
      value: "App\\Models\\User",
      url: "custom-fields/users",
    },
    {
      label: "Branch",
      value: "App\\Models\\OrganisationBranch",
      url: "custom-fields/branches",
    },
    {
      label: "Group",
      value: "App\\Models\\Group",
      url: "/groups",
    },
  ];

  const [notifiableUrl, setNotifiableUrl] = useState(
    notifiableOptions.find(
      (option) => option.value === initialValues?.notifiable_type,
    )?.url ?? "",
  );

  const notifiableTypeMapper: Record<string, ComponentType<any>> = {
    "App\\Models\\Purchase": PurchaseNotificationFields,
    "App\\Models\\StaffPayment": StaffPaymentNotificationFields,
  };

  const handleNotifiableModelChange = (selected: any) => {
    if (selected) {
      // Clear existing rules when type changes
      dispatch(reduxFormChange('customNotificationForm', 'rules.rules', []));
    }
  };

  return (
    <form className="row" onSubmit={handleSubmit}>
      <FormErrorAlert error={props.error} />
      <div className="col-lg-12 form-group">
        <Field
          component={RenderField}
          name="name"
          label="Name"
          required
          validate={required}
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={SelectInput}
          name="notifiable_type"
          label="Notifiable Type"
          changeValue={(value: {
            label: string;
            value: string;
            url: string;
          }) => {
            if (value) {
              setNotifiableUrl(value.url);
            }
          }}
          required
          validate={required}
          options={notifiableOptions}
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={SelectInput}
          name="notifiable_id"
          label="Notifiable"
          url={notifiableUrl}
          formatData={(data: any) => {
            return data.map((item: any) => ({
              label: item.name,
              value: item.id,
            }));
          }}
          required
          validate={required}
        />
      </div>

      <div className="col-lg-12 form-group">
        <Field
          component={SelectInput}
          name="notifiable_model"
          label="Notifiable Type"
          changeValue={handleNotifiableModelChange}
          options={[
            {
              label: "Purchase",
              value: "App\\Models\\Purchase",
            },
            {
              label: "Staff Expense Claim",
              value: "App\\Models\\StaffPayment",
            },
          ]}
        />
      </div>
      <div className="col-lg-12 form-group">
        <Field
          component={RenderField}
          name="notification"
          textarea
          label="Notification"
          required
          validate={required}
        />
      </div>
      {(notifiableModel || initialValues?.notifiable_model) && (
        <>
          <Field
            component={SelectInput}
            name="rules.condition"
            label="Condition"
            options={[
              { label: "Every", value: "every" },
              { label: "Some", value: "some" },
            ]}
          />
          <FieldArray
            component={
              notifiableTypeMapper[
                notifiableModel || initialValues?.notifiable_model
              ]
            }
            name="rules.rules"
          />
        </>
      )}
      <div className="col-lg-12 form-group">
        <SubmitButton {...props} />
      </div>
    </form>
  );
};

const PurchaseNotificationFields = (props: WrappedFieldArrayProps) => {
  const { fields } = props;

  return (
    <>
      <FormHeader>Notification Rules</FormHeader>
      {fields.map((field, index) => {
        const values = fields.get(index);

        return (
          <>
            <div className="d-flex justify-content-between mb-3">
              <p className="text-dark mb-0">Rule {index + 1}</p>
              <TextButton color="danger" onClick={() => fields.remove(index)}>
                <FiTrash className="text-danger" size={16} />
              </TextButton>
            </div>
            <div className="col-lg-12 form-group">
              <Field
                component={SelectInput}
                name={`${field}.type`}
                label="Option"
                options={[
                  {
                    label: "Price",
                    value: "price",
                  },
                  {
                    label: "Supplier",
                    value: "supplier_id",
                  },
                  {
                    label: "Purchase Supplier Name",
                    value: "supplier_name",
                  },
                  {
                    label: "Branch",
                    value: "branch_id",
                  },
                  {
                    label: "Account",
                    value: "account_id",
                  },
                  {
                    label: "User",
                    value: "user_id",
                  },
                ]}
              />
            </div>
            {values.type === "price" && (
              <>
                <div className="col-lg-6 form-group">
                  <Field
                    component={SelectInput}
                    name={`${field}.price.operator`}
                    label="Operator"
                    options={[
                      {
                        label: "Greater Than or Equal To",
                        value: ">=",
                      },
                      {
                        label: "Less Than or Equal To",
                        value: "<=",
                      },
                    ]}
                  />
                </div>
                <div className="col-lg-6 form-group">
                  <Field
                    component={RenderField}
                    name={`${field}.price.value`}
                    label="Price"
                    {...money}
                  />
                </div>
              </>
            )}
            {values.type === "supplier_id" && (
              <div className="col-lg-12 form-group">
                <Field
                  component={SelectInput}
                  name={`${field}.supplier_id`}
                  label="Supplier"
                  url="/custom-fields/suppliers"
                  formatData={(data: any) => {
                    return data.map((item: any) => ({
                      label: item.name,
                      value: item.id,
                    }));
                  }}
                />
              </div>
            )}
            {values.type === "supplier_name" && (
              <div className="col-lg-12 form-group">
                <Field
                  component={RenderField}
                  name={`${field}.supplier_name`}
                  label="Supplier Name"
                />
              </div>
            )}
            {values.type === "branch_id" && (
              <div className="col-lg-12 form-group">
                <Field
                  component={SelectInput}
                  name={`${field}.branch_id`}
                  label="Branch"
                  url="/custom-fields/branches"
                  formatData={(data: any) => {
                    return data.map((item: any) => ({
                      label: item.name,
                      value: item.id,
                    }));
                  }}
                />
              </div>
            )}
            {values.type === "account_id" && (
              <div className="col-lg-12 form-group">
                <Field
                  component={SelectInput}
                  name={`${field}.account_id`}
                  label="Account"
                  url="/chart-of-accounts"
                  formatData={(data: any) => {
                    return data.map((item: any) => ({
                      label: (
                        <>
                          <p className="text-dark mb-0">{item.code}</p>
                          <p className="text-muted mb-0">{item.name}</p>
                        </>
                      ),
                      value: item.id,
                    }));
                  }}
                />
              </div>
            )}
            {values.type === "user_id" && (
              <div className="col-lg-12 form-group">
                <Field
                  component={SelectInput}
                  name={`${field}.user_id`}
                  label="User has credit card payment uploaded"
                  url="/staff"
                  formatData={(data: { name: string; id: number }[]) => {
                    return data.map((staff) => ({
                      label: staff.name,
                      value: staff.id,
                    }));
                  }}
                />
              </div>
            )}
          </>
        );
      })}
      <div className="col-lg-12 form-group">
        <Button
          className="btn btn-primary"
          onClick={() => {
            fields.push({});
          }}
        >
          Add Rule
        </Button>
      </div>
    </>
  );
};

const StaffPaymentNotificationFields = (props: WrappedFieldArrayProps) => {
  const { fields } = props;

  console.log(fields);
  return (
    <>
      <FormHeader>Notification Rules</FormHeader>
      {fields.map((field, index) => {
        const values = fields.get(index);

        console.log(values.type);

        return (
          <>
            <div className="d-flex justify-content-between mb-3">
              <p className="text-dark mb-0">Rule {index + 1}</p>
              <TextButton color="danger" onClick={() => fields.remove(index)}>
                <FiTrash className="text-danger" size={16} />
              </TextButton>
            </div>
            <div className="col-lg-12 form-group">
              <Field
                component={SelectInput}
                name={`${field}.type`}
                label="Option"
                options={[
                  {
                    label: "Account",
                    value: "account_id",
                  },
                  {
                    label: "Amount",
                    value: "amount",
                  },
                  {
                    label: "Project",
                    value: "project_id",
                  }
                ]}
              />
            </div>
            {values.type === "account_id" && (
              <div className="col-lg-12 form-group">
                <Field
                  component={SelectInput}
                  name={`${field}.account_id`}
                  label="Account"
                  url="/chart-of-accounts"
                  formatData={(data: any) => {
                    return data.map((item: any) => ({
                      label: (
                        <>
                          <p className="text-dark mb-0">{item.code}</p>
                          <p className="text-muted mb-0">{item.name}</p>
                        </>
                      ),
                      value: item.id,
                    }));
                  }}
                />
              </div>
            )}
            {values.type === "amount" && (
              <>
                <div className="col-lg-6 form-group">
                  <Field
                    component={SelectInput}
                    name={`${field}.amount.operator`}
                    label="Operator"
                    options={[
                      {
                        label: "Greater Than or Equal To",
                        value: ">=",
                      },
                      {
                        label: "Less Than or Equal To",
                        value: "<=",
                      },
                    ]}
                  />
                </div>
                <div className="col-lg-6 form-group">
                  <Field
                    component={RenderField}
                    name={`${field}.amount.value`}
                    label="Amount"
                    {...money}
                  />
                </div>
              </>
            )}
            {values.type === "project_id" && (
              <div className="col-lg-12 form-group">
                <Field
                  component={SelectInput}
                  name={`${field}.project_id`}
                  label="Project"
                  url="/projects"
                  formatData={(data: any) => {
                    return data.map((item: any) => ({
                      label: item.name,
                      value: item.id,
                    }));
                  }}
                />
              </div>
            )}
          </>
        );
      })}
      <div className="col-lg-12 form-group">
        <Button
          className="btn btn-primary"
          onClick={() => {
            fields.push({});
          }}
        >
          Add Rule
        </Button>
      </div>
    </>
  );
};

const selector = formValueSelector("customNotificationForm");

const mapStateToProps = (state: any) => {
  return {
    notifiableModel: selector(state, "notifiable_model"),
    notifiableType: selector(state, "notifiable_type"),
  };
};

const form = reduxForm<any, CustomNotificationFormProps>({
  form: "customNotificationForm",
});

export default connect(mapStateToProps)(form(CustomNotificationForm));
