import _ from "lodash";
import ReactTable from "../table/ReactTable";
import { Link } from "react-router-dom";
import useObserver from "../hooks/useObserver";
import { ChangeEventHandler, useMemo } from "react";
import { TestRequestIndex } from "./testRequestTypes";
import { TestDashboardSortKeys } from "./TestDashboard";
import { ISorter } from "../hooks/useSort";
import CustomScaleLoader from "../utils/scaleLoader";
import ApprovalStatus from "../user/ApprovalStatus";

interface TestWorksheetTableProps {
  onSearch: ChangeEventHandler<HTMLInputElement>;
  isFetching: boolean;
  flattenedData?: TestRequestIndex[];
  fetchNextPage: Function;
  activeFilters: any[];
  isFetchingNextPage: boolean;
  sorters: ISorter<TestDashboardSortKeys>[];
  toggleSorter: (sorterName: TestDashboardSortKeys) => void;
  testRequestWorksheets: any;
}

interface TestRequestWorksheetTest {
  sample: string;
  id: number;
  method: {
    name: string;
    method: string;
  };
}

const TestWorksheetTable = ({
  testRequestWorksheets,
  fetchNextPage,
  isFetchingNextPage,
}: TestWorksheetTableProps) => {
  const intersection = useObserver(fetchNextPage);

  const columns = useMemo(
    () => [
      {
        accessorKey: "worksheet",
        header: "Worksheet",
        cell: (info: any) => {
          const link = info.getValue();

          if (!link) {
            return "No Worksheet Found";
          }

          return (
            <a target="_blank" rel="noopener noreferrer" href={link}>
              Worksheet
            </a>
          );
        },
      },
      {
        accessorKey: "created_at",
        header: "Date Created",
        cell: (info: any) => {
          const date = info.getValue();

          return date ? new Date(date).toLocaleDateString() : "N/A";
        },
      },
      {
        accessorKey: "tests.sample",
        header: "Samples",
        cell: (info: any) => {
          const tests = info.row.original.tests as TestRequestWorksheetTest[];

          return tests.map((t) => t.sample).join(", ");
        },
      },
      {
        accessorKey: "tests",
        header: "Tests",
        cell: (info: any) => {
          const tests = info.getValue() as TestRequestWorksheetTest[];

          const grouped = _.groupBy(tests, "method.method");

          return Object.keys(grouped).join(", ");
        },
      },
      {
        header: "Client",
        accessorKey: "test_request.client",
        cell: (info: any) => {
          const client = info.getValue();

          return (
            <Link
              onClick={() => console.log("sdlfkjd")}
              to={`/clients/${client.uuid}/details`}
            >
              {client.name}
            </Link>
          );
        },
      },
      {
        header: "Project",
        accessorKey: "test_request.project",
        cell: (info: any) => {
          const project = info.getValue();

          return (
            <Link to={`/projects/${project.uuid}/details`}>{project.name}</Link>
          );
        },
      },
      {
        header: "Test Request",
        accessorKey: "test_request",
        cell: (info: any) => {
          const testRequest = info.getValue();

          return (
            <Link to={`/testrequests/${testRequest.uuid}/details`}>
              {testRequest.job.name}
            </Link>
          );
        },
      },
      {
        accessorKey: "approval_status",
        header: "Approval Status",
        cell: (info: any) => {
          const status = info.getValue();
          return <ApprovalStatus status={status} />;
        },
      },
    ],
    [testRequestWorksheets],
  );

  return (
    <>
      <ReactTable data={testRequestWorksheets} columns={columns} />

      <div className="mt-5" />

      <div id="intersection" ref={intersection} />
      {isFetchingNextPage && (
        <CustomScaleLoader>Fetching More Data...</CustomScaleLoader>
      )}
    </>
  );
};

export default TestWorksheetTable;
