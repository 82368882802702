import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { Field, reduxForm } from "redux-form";
import { useAuth } from "../../context/auth-context";
import AddressFields from "../addresses/AddressFields";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import {
  default as renderField,
  default as RenderField,
} from "../utils/renderField";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import { AboriginalEnum, GenderEnum } from "./userTypes";
import SelectInput from "../form/SelectInput";
import dayjs from "dayjs";

const UserDetail = (props: any) => {
  const { userId } = useParams<{ userId: string }>();

  const { handleSubmit, setUser, change, user, initialValues, initialize } =
    props;

  const [defaultBranch, setDefaultBranch] = useState(
    initialValues?.organisation_user.settings?.branch_id,
  );

  useEffect(() => {
    setDefaultBranch(initialValues?.organisation_user.settings?.branch_id);
  }, [initialValues]);

  const { user: authUser } = useAuth();

  const userIsLabourHire =
    authUser?.active_organisation.id !==
    user?.organisation_user.organisation_id;

  const { takeAction } = useApi();

  const { data: integrations } = useApi(
    authUser?.is_admin ? "integrations" : "",
    [],
  );

  const onSubmit = (values: any) => {
    return takeAction("update", `users/${userId}`, values)
      .then(({ data }: { data: any }) => {
        setUser(data.data.user);
        toast.success("Profile updated successfully");
      })
      .catch(errorSwal);
  };

  const onChangeRole = (values: any) => {
    change("organisation_user.settings.approver", "");

    if (values?.branch_id) {
      setDefaultBranch(values.branch_id);
      change("organisation_user.settings.branch_id", values.branch_id);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label className="section-title">Personal Details</label>
      <hr />
      <div className="row mg-b-25">
        <div className="col-lg-4">
          <div className="form-group">
            <Field
              name="first_name"
              validate={required}
              required
              type="text"
              component={renderField}
              label="First Name"
            />
          </div>
        </div>
        <div className="col-lg-4">
          <div className="form-group">
            <Field
              name="middle_name"
              type="text"
              component={renderField}
              label="Middle Name"
            />
          </div>
        </div>
        <div className="col-lg-4">
          <div className="form-group">
            <Field
              name="last_name"
              type="text"
              validate={required}
              required
              component={renderField}
              label="Last Name"
            />
          </div>
        </div>
        {authUser?.is_admin && (
          <div className="col-lg-6">
            <div className="form-group">
              <Field
                name="display_name"
                type="text"
                component={renderField}
                label="Display Name"
                information={
                  <>
                    This is the name that will appear across the system for the
                    selected user.
                    <br />
                    <br />
                    It can be edited by admins and HR members to accommodate
                    users who prefer to use an English name or a nickname.
                    <br />
                    <br />
                    Please confirm with the user before changing.
                    <br />
                    <br />
                    Note: This will not affect the user's official records or
                    login credentials
                  </>
                }
              />
            </div>
          </div>
        )}

        <div className="col-lg-6 form-group">
          <Field
            component={SelectInput}
            options={[
              {
                label: "Male",
                value: GenderEnum.Male,
              },
              {
                label: "Female",
                value: GenderEnum.Female,
              },
              {
                label: "Non-Binary",
                value: GenderEnum.NonBinary,
              },
              {
                label: "Prefer not to say",
                value: GenderEnum.Unspecified,
              },
            ]}
            name="gender"
            label="Gender"
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={SelectInput}
            options={[
              {
                label: "Aboriginal",
                value: AboriginalEnum.Aboriginal,
              },
              {
                label: "Torres Straight Islander",
                value: AboriginalEnum.TorresStraightIslander,
              },
              {
                label: "Neither",
                value: AboriginalEnum.Neither,
              },
              {
                label: "Prefer not to say",
                value: AboriginalEnum.Unspecified,
              },
            ]}
            name="aboriginal"
            label="Aboriginal or Torres Strait Islander origin?"
          />
        </div>
        <div className={`${authUser?.is_admin ? "col-lg-6" : "col-lg-12"}`}>
          <div className="form-group">
            <Field
              name="dob"
              type="date"
              component={renderField}
              label="Date of Birth"
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <Field
              name="phone"
              type="text"
              component={renderField}
              label="Personal Phone Number"
              required
              validate={required}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <Field
              name="work_phone"
              type="text"
              component={renderField}
              label="Work Phone Number"
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <Field
              name="email"
              type="email"
              component={renderField}
              label="Primary/Personal Email"
              validate={required}
              required
            />
            <small>
              This is generally your personal email and the email that you use
              to log into boss. If you change this email, you will need to use
              the new email to log in.
            </small>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <Field
              name="secondary_email"
              type="email"
              component={renderField}
              label="Secondary/Work Email"
            />
            <small>
              This will be your work email, and the address that notifications
              will go to if set.
            </small>
          </div>
        </div>
        {userIsLabourHire && (
          <div className="form-group col-lg-12">
            <Field
              name="employment_start_date"
              type="date"
              component={renderField}
              label="Employment Start Date"
            />
          </div>
        )}

        {authUser?.is_admin && (
          <>
            <div className="col-lg-12 form-group">
              <hr />
              <Field
                name="default_branch"
                type="text"
                component={SelectInput}
                formatData={(data: any[]) =>
                  data.map((d: any) => ({
                    label: d.name,
                    value: d.id,
                  }))
                }
                url={`organisation-branches${
                  authUser.active_organisation.id !==
                  user?.organisation_user.organisation.id
                    ? `?organisation_id=${user?.organisation_user.organisation.id}`
                    : ""
                }`}
                label={`Default Branch for ${user?.organisation_user.organisation.name}`}
              />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                component={RenderField}
                type="date"
                name="organisation_user.employment_start_date"
                label={`Employment Start Date for ${user?.organisation_user.organisation.name}`}
              />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                name="organisation_user.administration_level"
                label="Access Level"
                url="/enums?enum=AdministrationLevels"
                component={SelectInput}
              />
            </div>
          </>
        )}

        <div className="col-lg-12 form-group">
          <Field
            name="organisation_user.settings.role_id"
            type="text"
            url={`/users/${userId}/staff-roles?filter[wasActiveOn]=${dayjs().format(
              "YYYY-MM-DD",
            )}`}
            component={SelectInput}
            label={`Default Timesheet Role`}
            changeValue={onChangeRole}
            formatData={(data: any[]) =>
              data.map((d: any) => ({
                ...d,
                label: (
                  <>
                    <p className="mb-0 text-dark">{d.name}</p>
                    <small>{d.branch}</small>
                  </>
                ),
                value: d.id,
              }))
            }
          />
        </div>

        <div className="col-lg-12 form-group">
          <Field
            name="organisation_user.settings.approver"
            type="text"
            url={`/timesheet-approval-users/users?branch_id=${defaultBranch}`}
            component={SelectInput}
            label={`Default Timesheet Approver`}
          />
        </div>

        <div className="col-lg-6 form-group">
          <Field
            component={RenderField}
            name="organisation_user.settings.start_time"
            label="Default Start Time"
            type="time"
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={RenderField}
            name="organisation_user.settings.finish_time"
            label="Default Finish Time"
            type="time"
          />
        </div>
        <div className="col-md-6 form-group">
          <Field
            component={RenderField}
            extraProps={{ step: "0.01" }}
            type="number"
            label="Default Unpaid Break (hours)"
            name="organisation_user.settings.unpaid_break"
          />
        </div>
        <div className="col-md-6 form-group">
          <Field
            component={RenderField}
            extraProps={{ step: "0.01" }}
            type="number"
            label="Default Paid Break (hours)"
            name="organisation_user.settings.paid_break"
          />
        </div>
        <AddressFields {...props} postal />
        <div className="form-group col-lg-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const Userdetail = reduxForm({ form: "UserDetail", enableReinitialize: true });

export default Userdetail(UserDetail);
