import JournalForm from "./JournalForm";
import HeaderPage from "../header/HeaderPage";
import useApi from "../api/useApi";
import { toast } from "react-toastify";
import formError from "../utils/formError";
import { useHistory, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { Journal } from "./journalTypes";

const AddJournal = () => {
  const { takeAction } = useApi();
  const history = useHistory();
  const location = useLocation<{ journal?: Journal }>();

  const journal = location.state?.journal;

  const onSubmit = (values: any) => {
    return takeAction("store", "journals", values)
      .then(() => {
        toast.success("Journal Created Successfully");
        history.push("/journals");
      })
      .catch(formError);
  };

  return (
    <>
      <HeaderPage
        titlePage="Add Journal"
        crumbs={[
          {
            name: "Journals",
            link: "/journals",
            active: false,
          },
          {
            name: "Add Journal",
            link: "/journals/add",
            active: true,
          },
        ]}
      />
      <JournalForm
        initialValues={
          journal ?? {
            date: dayjs().format("YYYY-MM-DD"),
            cash_basis: true,
            journal_lines: [{}, {}, {}, {}, {}, {}],
          }
        }
        onSubmit={onSubmit}
      />
    </>
  );
};

export default AddJournal;
