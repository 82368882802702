import React, { useState } from "react";
import moment from "moment";
import { DayPicker, Matcher } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { Button } from "reactstrap";

export type PeriodType = "week" | "2weeks" | "month";

interface PeriodPickerProps {
  onPeriodSelected: (periodDays: Date[]) => void;
}

interface HoverRange {
  from: Date;
  to: Date;
}

const getPeriodDays = (periodStart: Date, periodType: PeriodType): Date[] => {
  const days: Date[] = [periodStart];
  const daysToAdd =
    periodType === "week"
      ? 6
      : periodType === "2weeks"
      ? 13
      : moment(periodStart).endOf("month").diff(periodStart, "days");

  for (let i = 1; i <= daysToAdd; i++) {
    days.push(moment(periodStart).add(i, "days").toDate());
  }
  return days;
};

const getPeriodRange = (date: Date, periodType: PeriodType) => {
  if (periodType === "month") {
    return {
      from: moment(date).startOf("month").toDate(),
      to: moment(date).endOf("month").toDate(),
    };
  }

  const startOfWeek = moment(date).startOf("week");
  return {
    from: startOfWeek.toDate(),
    to:
      periodType === "week"
        ? startOfWeek.add(6, "days").toDate()
        : startOfWeek.add(13, "days").toDate(),
  };
};

const PeriodPicker: React.FC<PeriodPickerProps> = ({ onPeriodSelected }) => {
  const [periodType, setPeriodType] = useState<PeriodType>("week");
  const currentPeriod = getPeriodRange(new Date(), periodType);
  const defaultSelectedDays = getPeriodDays(currentPeriod.from, periodType);

  const [hoverRange, setHoverRange] = useState<HoverRange | undefined>(
    undefined,
  );
  const [selectedDays, setSelectedDays] = useState<Date[]>(defaultSelectedDays);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [selectedRange, setSelectedRange] = useState({
    from: defaultSelectedDays[0],
    to: defaultSelectedDays[defaultSelectedDays.length - 1],
  });

  const handleDayChange = (date: Date) => {
    const periodRange = getPeriodRange(date, periodType);
    const periodDays = getPeriodDays(periodRange.from, periodType);
    onPeriodSelected(periodDays);
    setSelectedDays(periodDays);
    setSelectedRange(periodRange);
    setIsDropdownOpen(false);
  };

  const handlePeriodTypeChange = (newPeriodType: PeriodType) => {
    setPeriodType(newPeriodType);
    
    const today = new Date();
    let periodRange;
    
    if (newPeriodType === "week") {
      // Get current week
      periodRange = getPeriodRange(today, "week");
    } else if (newPeriodType === "2weeks") {
      // Get current week + next week
      periodRange = getPeriodRange(today, "2weeks");
    } else {
      // Get current month
      periodRange = {
        from: moment(today).startOf("month").toDate(),
        to: moment(today).endOf("month").toDate(),
      };
    }

    const periodDays = getPeriodDays(periodRange.from, newPeriodType);
    onPeriodSelected(periodDays);
    setSelectedDays(periodDays);
    setSelectedRange(periodRange);
  };

  const handleDayEnter = (date: Date) => {
    setHoverRange(getPeriodRange(date, periodType));
  };

  const handleDayLeave = () => {
    setHoverRange(undefined);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const modifiers: Record<string, Matcher | Matcher[]> = {
    hoverRange: hoverRange ? { from: hoverRange.from, to: hoverRange.to } : [],
    selectedRange:
      selectedDays.length > 0
        ? [{ from: selectedDays[0], to: selectedDays[selectedDays.length - 1] }]
        : [],
    hoverRangeStart: hoverRange?.from ? [hoverRange.from] : [],
    hoverRangeEnd: hoverRange?.to ? [hoverRange.to] : [],
    selectedRangeStart: selectedDays.length > 0 ? [selectedDays[0]] : [],
    selectedRangeEnd:
      selectedDays.length > 0 ? [selectedDays[selectedDays.length - 1]] : [],
  };

  const formatSelectedPeriod = () => {
    if (selectedDays.length === 0) return "Select a period";
    return `From ${moment(selectedDays[0]).format("LL")} to ${moment(
      selectedDays[selectedDays.length - 1],
    ).format("LL")}`;
  };

  return (
    <div className="d-flex align-items-center">
      <div
        className="PeriodPickerDropdown"
        style={{ position: "relative", display: "inline-block" }}
      >
        <button
          className="dropdown-toggle"
          onClick={handleDropdownToggle}
          style={{
            padding: "8px 12px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            backgroundColor: "white",
            cursor: "pointer",
          }}
        >
          {formatSelectedPeriod()}
        </button>

        {isDropdownOpen && (
          <div className="card" style={{ position: "absolute", zIndex: 99 }}>
            <DayPicker
              mode="range"
              selected={selectedRange}
              showWeekNumber
              showOutsideDays
              modifiers={modifiers}
              onDayClick={handleDayChange}
              onDayMouseEnter={handleDayEnter}
              onDayMouseLeave={handleDayLeave}
            />
          </div>
        )}
      </div>
      <div className="d-flex ms-3 space-x-3">
        <Button
          size="sm"
          outline={periodType !== "week"}
          onClick={() => handlePeriodTypeChange("week")}
        >
          Week
        </Button>
        <Button
          size="sm"
          outline={periodType !== "2weeks"}
          onClick={() => handlePeriodTypeChange("2weeks")}
        >
          2 Weeks
        </Button>
        <Button
          size="sm"
          outline={periodType !== "month"}
          onClick={() => handlePeriodTypeChange("month")}
        >
          Month
        </Button>
      </div>
    </div>
  );
};

export default PeriodPicker;
