export enum AccountType {
  CurrentAsset = 1,
  FixedAsset = 2,
  Inventory = 3,
  NonCurrentAsset = 4,
  Prepayment = 5,
  Equity = 6,
  Depreciation = 7,
  DirectCosts = 8,
  Expense = 9,
  Overhead = 10,
  CurrentLiability = 11,
  Liability = 12,
  NonCurrentLiability = 13,
  OtherIncome = 14,
  Revenue = 15,
  Sales = 16,
}

export interface ChartOfAccount {
  uuid: string;
  code: string;
  name: string;
  type: AccountType;
  type_name:
    | "Current Asset"
    | "Fixed Asset"
    | "Inventory"
    | "Non-Current Asset"
    | "Prepayment"
    | "Equity"
    | "Depreciation"
    | "Direct Costs"
    | "Expense"
    | "Overhead"
    | "Current Liability"
    | "Liability"
    | "Non-Current Liability"
    | "Other Income"
    | "Revenue"
    | "Sales";
  tax_code: string;
  tax_code_description: string;
  description: string;
  credit_card: boolean;
  id: number;
  purchase_order: boolean;
  integration: string;
  expense_claim: boolean;
  budget_control: boolean;
  is_subcontractor_account: boolean;
  auto_fill: boolean;
  auto_fill_type?: "percentage_of_revenue" | "multiple_of_full_time_staff";
  percentage_of_revenue?: number;
  multiple_of_full_time_staff?: number;
  organisation_id: number;
}
