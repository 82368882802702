import { AuthUser, OnboardingResponse, User } from "./userTypes";
import useApi from "../api/useApi";
import { IUseApi, IUseApiWithData } from "../api/apiTypes";
import { useState } from "react";
import { WizardStep } from "../onboarding/OnboardingSteps";
import CustomForm from "../customFields/CustomForm";
import { InjectedFormProps, reduxForm } from "redux-form";
import SubmitButton from "../utils/SubmitButton";
import { toast } from "react-toastify";
import formError from "../utils/formError";
import { Button } from "reactstrap";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import { BiCheck } from "react-icons/bi";
import CustomScaleLoader from "../utils/scaleLoader";
import { useAuth } from "../../context/auth-context";

interface OnboardingResponsesProps {
  user: User["user"];
}

interface OnboardingResponseFormProps {
  onboardingResponse?: OnboardingResponse;
  onboardingResponses: OnboardingResponse[];
  setOnboardingResponses: (responses: OnboardingResponse[]) => void;
}
const OnboardingResponses = (
  props: InjectedFormProps<{}, OnboardingResponsesProps> &
    OnboardingResponsesProps,
) => {
  const { user, handleSubmit } = props;

  const [selectedStep, setSelectedStep] = useState(1);

  const {
    data: onboardingResponses,
    setData: setOnboardingResponses,
    loading,
  }: IUseApiWithData<OnboardingResponse[]> = useApi(
    `users/${user.uuid}/onboarding-responses`,
    [],
    true,
  );

  if (loading) {
    return (
      <CustomScaleLoader>Fetching onboarding responses...</CustomScaleLoader>
    );
  }

  return (
    <>
      <div className="d-flex custom-scrollbar flex-nowrap overflow-auto pb-3 mb-5">
        <div className="d-inline-flex space-x-5">
          {onboardingResponses.map((response, i) => (
            <WizardStep
              key={response.onboarding_step.name}
              isActive={selectedStep === i + 1}
              number={i + 1}
              text={
                <span className="d-flex align-items-center">
                  {response.onboarding_step.name}{" "}
                  {response.completed_at ? (
                    <BiCheck className="ms-2" size={24} />
                  ) : (
                    ""
                  )}
                </span>
              }
              onClick={() => setSelectedStep(i + 1)}
              step={response.onboarding_step}
            />
          ))}
        </div>
      </div>
      {onboardingResponses.length > 0 && (
        <Form
          setOnboardingResponses={setOnboardingResponses}
          onboardingResponses={onboardingResponses}
          onboardingResponse={onboardingResponses[selectedStep - 1]}
          initialValues={{
            custom_fields: onboardingResponses[selectedStep - 1].custom_fields,
          }}
        />
      )}
    </>
  );
};

const ResponseForm = (
  props: InjectedFormProps<{}, OnboardingResponseFormProps> &
    OnboardingResponseFormProps,
) => {
  const { handleSubmit, onboardingResponse, setOnboardingResponses } = props;

  const { user, setUser } = useAuth();

  const { takeAction, loading }: IUseApi = useApi();

  const onSubmit = (values: any) => {
    return takeAction(
      "update",
      `onboarding-responses/${props.onboardingResponse?.uuid}`,
      values,
    )
      .then(({ data }) => {
        toast.success("Onboarding response updated");
        setOnboardingResponses(
          props.onboardingResponses.map((response) =>
            response.id === props.onboardingResponse?.id ? data.data : response,
          ),
        );
      })
      .catch(formError);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <CustomForm
          customForm={props.onboardingResponse?.custom_form}
          modelType="App\Models\OnboardingResponse"
          modelId={props.onboardingResponse?.id}
          shouldError={true}
          {...props}
        />

        <div className="col-12 d-flex space-x-3">
          <SubmitButton {...props} />
          <Button
            outline
            color="warning"
            disabled={loading}
            onClick={() => {
              takeAction(
                onboardingResponse?.completed_at ? "destroy" : "store",
                `complete-onboarding-responses/${onboardingResponse?.uuid}`,
              )
                .then(({ data }) => {
                  toast.success("Onboarding response updated");

                  const newOnboardingResponses = props.onboardingResponses.map(
                    (response) =>
                      response.id === props.onboardingResponse?.id
                        ? data.data
                        : response,
                  );

                  const newUser = {
                    ...user,
                    onboarding_responses_required: !newOnboardingResponses.find(
                      (response) => response.completed_at,
                    ),
                  } as AuthUser;

                  setUser(newUser);

                  setOnboardingResponses(newOnboardingResponses);
                })
                .catch(errorSwal);
            }}
          >
            {isSubmitting(
              loading,
              onboardingResponse?.completed_at
                ? "Mark as incomplete"
                : "Mark as complete",
              "Updating...",
            )}
          </Button>
        </div>
      </div>
    </form>
  );
};

const Form = reduxForm<{}, OnboardingResponseFormProps>({
  form: "onboardingResponses",
  enableReinitialize: true,
})(ResponseForm);

export default OnboardingResponses;
