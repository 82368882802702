import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import errorSwal from "../utils/errorSwal";
import PayScheduleForm from "./Form";
import Spinner from "../utils/Spinner";

const EditPaySchedule = () => {
  const { uuid } = useParams();
  const history = useHistory();
  const { takeAction } = useApi();
  const {
    data: paySchedule,
    loading,
    setData,
  } = useApi(`pay-schedules/${uuid}`, {}, true);

  const onSubmit = (values) => {
    return takeAction("update", `pay-schedules/${uuid}`, values)
      .then(({ data }) => {
        toast.success(`${values.name} updated successfully`);
        setData(data.data);
      })
      .catch(errorSwal);
  };

  if (loading) {
    return <Spinner loading={loading} />;
  }

  return (
    <>
      <HeaderPageAdminFilter
        titlePage={`Edit ${paySchedule?.name}`}
        crumbs={[
          { link: "pay-schedules", name: "Pay Schedules" },
          { link: "", name: "Edit Pay Schedule", active: true },
        ]}
      />
      <PayScheduleForm
        initialValues={paySchedule}
        onSubmit={onSubmit}
        form="EditPaySchedule"
      />
    </>
  );
};

export default EditPaySchedule;
