import dayjs from "dayjs";
import React from "react";
import { Field } from "redux-form";
import FileInput from "../upload/FileInput";
import required from "../utils/required";
import RenderField from "../utils/renderField";

const RequiredInsuranceFields = ({ fields }) => {
  return fields.map((field, index) => {
    const current = fields.get(index);

    const isExpiring =
      current.expiry_date &&
      dayjs().add(1, "month").isAfter(current.expiry_date);

    return (
      <React.Fragment key={index}>
        <div className="col-lg-6 form-group">
          <Field
            name={`${field}.document`}
            component={FileInput}
            label={current.type.name}
            validate={required}
            required
            showFile={false}
          />
          {current?.approval_action?.action_type === "Redo" && (
            <>
              <small>
                {current.approval_action.user.name} asked to re upload the{" "}
                {current.type.name} for the following reason:
              </small>
              <br />
              <small className="fw-bold tx-inverse">
                {current.approval_action.previous_action.remarks}
              </small>
            </>
          )}
        </div>
        <div className="col-lg-6 form-group">
          <Field
            name={`${field}.expiry_date`}
            component={RenderField}
            type="date"
            timeFormat={false}
            label="Expiry Date"
            validate={required}
            required
          />
          {isExpiring && (
            <small>
              {current.type.name} is expiring in{" "}
              {dayjs(current.expiry_date).fromNow()}, please upload new
              insurance.
            </small>
          )}
        </div>
      </React.Fragment>
    );
  });
};

export default RequiredInsuranceFields;
