import { useMemo } from "react";
import HeaderPage from "../header/HeaderPage";
import PaginatedList from "../pagination/PaginatedList";
import useCustomNotifications from "./hooks/useCustomNotifications";
import { CustomNotification } from "./customNotificationTypes";
import ReactTable from "../table/ReactTable";
import { Button } from "reactstrap";
import { RiAddFill } from "react-icons/ri";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import deleteSwal from "../utils/deleteSwal";
import { Link } from "react-router-dom";

const CustomNotificationsList = () => {
  const { takeAction }: IUseApi = useApi();
  const queryClient = useQueryClient();

  return (
    <>
      <HeaderPage crumbs={[]} titlePage="Custom Notifications" />
      <PaginatedList
        indexHook={useCustomNotifications}
        listName="customNotifications"
        extraButtons={() => (
          <Link to="/custom-notifications/add">
            <RiAddFill aria-label="Add New Resource" className="tx-24 ms-1" />
          </Link>
        )}
        originalFilters={[]}
        list={({ data }) => {
          const customNotifications = data as CustomNotification[];

          return (
            <div className="col-12">
              <CustomNotificationsTable
                customNotifications={customNotifications}
              />
            </div>
          );
        }}
      />
    </>
  );
};

const CustomNotificationsTable = ({
  customNotifications,
}: {
  customNotifications: CustomNotification[];
}) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        cell: (info: any) => {
          return (
            <Link to={`/custom-notifications/${info.row.original.uuid}`}>
              {info.row.original.name}
            </Link>
          );
        },
      },
      {
        accessorKey: "Actions",
        header: "Actions",
        cell: (info: any) => {
          const queryClient = useQueryClient();
          const { takeAction, loading }: IUseApi = useApi();

          return (
            <>
              <div className="d-flex align-items-center space-x-2">
                <Button
                  onClick={() => {
                    deleteSwal(info.row.original.name)
                      .then(() =>
                        takeAction(
                          "destroy",
                          `custom-notifications/${info.row.original.uuid}`,
                        ),
                      )
                      .then(() => {
                        toast.success("Custom Notification Deleted");
                        queryClient.invalidateQueries("custom-notifications");
                      })
                      .catch(errorSwal);
                  }}
                  size="sm"
                  color="danger"
                  outline
                  disabled={loading}
                >
                  Delete
                </Button>
              </div>
            </>
          );
        },
      },
    ],
    [customNotifications],
  );

  return (
    <ReactTable columns={columns} data={customNotifications} disableSearch />
  );
};

export default CustomNotificationsList;
