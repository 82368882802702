import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Field, formValueSelector } from "redux-form";
import HOW_CLIENTS_FOUND from "../../data/how_clients_found";
import INVOICE_TERMS from "../../data/invoice_terms";
import useApi from "../api/useApi";
import AddMultiple from "../form/AddMultiple";
import { money } from "../form/formatters";
import SelectInput from "../form/SelectInput";
import FormHeader from "../utils/FormHeader";
import RenderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import StyledRadio from "../form/StyledRadio";
import { BiCheck } from "react-icons/bi";
import { ReactComponent as Job } from "../../svgs/engineer.svg";
import { ReactComponent as People } from "../../svgs/people.svg";

const ClientInformation = ({
  fieldPrefix = null,
  abnHolder,
  requestAccount,
  add,
  disableName = false,
  change,
}) => {
  const { data: similarClients, setUrl, setData, url } = useApi(null, []);

  return (
    <>
      <div className="col-lg-12 form-group">
        <Field
          component={RenderField}
          name={fieldPrefix ? `${fieldPrefix}.name` : "name"}
          label="Name"
          disabled={disableName}
          readOnly={disableName}
          required
          validate={required}
          onBlur={(e, val) => {
            if (!add || !val) {
              return;
            }

            const newUrl = `check-contacts?name=${encodeURIComponent(
              val,
            )}&type=OrganisationClient`;

            if (url !== newUrl) {
              setData([]);
            }

            setUrl(newUrl);
          }}
        />
        {similarClients.length > 0 ? (
          <>
            <small className="text-danger d-block">
              The following client/s with a similar name were found. Please
              ensure that you are not double entering clients.
            </small>
            {similarClients.map((client) => {
              return (
                <small className="d-block" key={client.id}>
                  <Link to={client.link}>{client.name}</Link>
                </small>
              );
            })}
          </>
        ) : null}
      </div>
      <div className="col-lg-12">
        <label className="form-control-label tx-inverse tx-semibold">
          ABN Holder
        </label>
      </div>
      <Field
        component={StyledRadio}
        options={[
          {
            value: true,
            label: "Has ABN",
            icon: Job,
            description:
              "Select this option for clients that operate as businesses and have an Australian Business Number (ABN).",
          },
          {
            value: false,
            label: "Does Not Have ABN",
            icon: People,
            description:
              "Select this option for individual clients or entities that do not have an Australian Business Number (ABN).",
          },
        ]}
        button={(props) => (
          <div className="col-lg-6 form-group">
            <button
              type="button"
              className={`btn position-relative w-100 p-3 ${
                props.active
                  ? "border-warning shadow-inner bg-gray-100"
                  : "border shadow-sm bg-white"
              } rounded-lg d-flex align-items-center justify-content-center`}
              onClick={() => {
                change(fieldPrefix ? `${fieldPrefix}.request_account` : "request_account", props.value);

                props.onClick();
              }}
            >
              <div
                style={{ right: 5, top: 5 }}
                className={`position-absolute tn-300 ${
                  props.active ? "opacity-1" : "opacity-0"
                }`}
              >
                <BiCheck className="tx-warning tx-26" />
              </div>
              <div className="text-center">
                <props.icon
                  className={props.active ? "active" : ""}
                  height="150px"
                  width="150px"
                />
                <p className="mb-2 mt-3 text-dark fw-bolder">{props.label}</p>
                <p className="mb-0 tx-12">{props.description}</p>
              </div>
            </button>
          </div>
        )}
        name={fieldPrefix ? `${fieldPrefix}.abn_holder` : "abn_holder"}
        formatData={formatData}
      />

      <div className="col-lg-12 form-group">
        <Field
          component={AddMultiple}
          name={fieldPrefix ? `${fieldPrefix}.contact_email` : "contact_email"}
          label="Contact Email/s"
          required
          validate={required}
        />
        <small>
          When sending notifications to this client, the first email address in
          this list will be the address the email is sent to.
        </small>
      </div>

      <div className="col-lg-4 form-group">
        <Field
          name={
            fieldPrefix ? `${fieldPrefix}.how_client_found` : "how_client_found"
          }
          options={HOW_CLIENTS_FOUND}
          required
          label="How client found us?"
          component={SelectInputAsync}
          validate={required}
        />
      </div>
      <div className="col-lg-4 form-group">
        <Field
          name={
            fieldPrefix ? `${fieldPrefix}.client_winners` : "client_winners"
          }
          multiple
          label="Client Winners"
          url="/users/staff"
          component={SelectInputAsync}
          {...(fieldPrefix && { required: true, validate: required })}
        />
        <small>Which member/s of staff won this client?</small>
      </div>
      <div className="col-lg-4 form-group">
        <Field
          component={SelectInput}
          url="client-types"
          name={fieldPrefix ? `${fieldPrefix}.client_types` : "client_types"}
          label="Business Activities"
          isMulti
          formatData={(data) => {
            return data.map((d) => ({
              label: d.name,
              value: d.id,
            }));
          }}
          {...(fieldPrefix && { required: true, validate: required })}
        />
      </div>

      {abnHolder && (
        <div className="col-lg-12 form-group">
          <Field
            component={renderToggleInput}
            name={fieldPrefix ? `${fieldPrefix}.request_account` : "request_account"}
            label="Request Account"
          />

          <small className="d-block">
            Requesting an account will ask the client to include 3 trade
            references when filling their details.
          </small>
        </div>
      )}

      {requestAccount && (
        <>
          <FormHeader>Invoice Terms</FormHeader>
          <div className="col-lg-4 form-group">
            <Field
              component={SelectInputAsync}
              label="Invoice Terms"
              required
              validate={required}
              name="account_request.invoice_terms"
              options={INVOICE_TERMS.filter((term) => term.value !== "0")}
            />
          </div>
          <div className="col-lg-4 form-group">
            <Field
              component={RenderField}
              required
              validate={required}
              name="account_request.invoice_terms_amount"
              label="Days"
              type="number"
            />
          </div>

          <div className="col-lg-4 form-group">
            <Field
              component={RenderField}
              required
              validate={required}
              name="account_request.account_limit"
              label="Account Limit"
              {...money}
            />
          </div>
        </>
      )}
    </>
  );
};

const formatData = (data) => data.data.map((d) => d);

const mapStateToProps = (state, { form, fieldPrefix }) => {
  const selector = formValueSelector(form);

  return {
    abnHolder: selector(state, fieldPrefix ? `${fieldPrefix}.abn_holder` : "abn_holder"),
    requestAccount: selector(state, fieldPrefix ? `${fieldPrefix}.request_account` : "request_account"),
  };
};

export default connect(mapStateToProps, {})(ClientInformation);
