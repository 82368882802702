import { Field, InjectedFormProps, reduxForm } from "redux-form";
import RenderField from "../utils/renderField";
import { money } from "../form/formatters";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import { WeeklyForecast } from "./branchTypes";
import FormErrorAlert from "../form/FormErrorAlert";
import ApprovalButton from "../approvals/ApprovalButton";
import dayjs from "dayjs";
import { ApprovalOptions } from "../approvals/approvalTypes";
import ApprovalComments from "../approvals/ApprovalComments";
import { useAuth } from "../../context/auth-context";
import ApprovalStatus from "../user/ApprovalStatus";

const BranchForecastForm = (props: InjectedFormProps<WeeklyForecast>) => {
  const { handleSubmit, error, initialValues } = props;

  const { user } = useAuth();

  return (
    <>
      <div className="col-12 d-flex align-items-center justify-content-between">
        <label className="section-title my-0">
          Forecast for week of{" "}
          {dayjs().startOf("week").add(1, "day").format("DD/MM/YYYY")} -{" "}
          {dayjs().startOf("week").add(5, "days").format("DD/MM/YYYY")}
        </label>

        {initialValues?.approval_action?.user_id !== user?.id && (
          <div>
            <ApprovalStatus
              status={initialValues?.approval_status}
              className="w-100 py-2"
            />
          </div>
        )}

        {initialValues?.approval_action && (
          <div>
            <ApprovalButton
              buttonClass="btn btn-outline-secondary btn-sm"
              wrapperClass=""
              approvalAction={initialValues.approval_action}
              approvalOptions={[
                {
                  value: ApprovalOptions.Approve,
                  label: "Approve",
                },
                {
                  value: ApprovalOptions.Redo,
                  label: "Redo",
                },
              ]}
            />
          </div>
        )}
      </div>
      <div className="col-12">
        <hr className="" />
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <FormErrorAlert error={error} />
          <div className="col-lg-6 form-group">
            <Field
              component={RenderField}
              name="revenue"
              label="Revenue"
              {...money}
              required
              validate={required}
            />
          </div>
          <div className="col-lg-6 form-group">
            <Field
              component={RenderField}
              name="wages"
              label="Change in Wages"
              {...money}
              required
              validate={required}
            />
          </div>
          <div className="col-lg-4 form-group">
            <Field
              component={RenderField}
              name="recruitment_fees"
              label="Recruitment Fees"
              {...money}
              required
              validate={required}
            />
          </div>
          <div className="col-lg-4 form-group">
            <Field
              component={RenderField}
              name="subcontractor_costs"
              label="Subcontractor Costs"
              {...money}
              required
              validate={required}
            />
          </div>
          <div className="col-lg-4 form-group">
            <Field
              component={RenderField}
              name="other_costs"
              label="Other Costs"
              {...money}
              required
              validate={required}
            />
          </div>
          <div className="col-lg-12 form-group">
            <Field
              component={RenderField}
              textarea
              name="comments"
              label="Comments"
            />
          </div>
          <div className="col-lg-12 form-group">
            <SubmitButton {...props} />
          </div>
        </div>
      </form>
      {initialValues?.id && (
        <ApprovalComments
          modelType="App\Models\WeeklyForecast"
          modelId={initialValues.id}
        />
      )}
    </>
  );
};

const form = reduxForm<WeeklyForecast>({});

export default form(BranchForecastForm);
