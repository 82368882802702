import React from "react";
import { useParams } from "react-router-dom";
import { ExcelExtraction } from "./excelExtractionTypes";
import EditExcelExtraction from "./EditExcelExtraction";
import ExcelExtractor from "../excel/ExcelExtractor";

interface ShowExcelExtractionProfilePageProps {
  excelExtraction: ExcelExtraction;
  setExcelExtraction: (excelExtraction: ExcelExtraction) => void;
}

const ShowExcelExtractionProfilePage: React.FC<
  ShowExcelExtractionProfilePageProps
> = ({ excelExtraction, setExcelExtraction }) => {
  const { page } = useParams<{ page: string }>();

  switch (page) {
    case "details":
      return (
        <EditExcelExtraction
          excelExtraction={excelExtraction}
          setExcelExtraction={setExcelExtraction}
        />
      );
    case "extract":
      return <ExcelExtractor excelExtraction={excelExtraction} />;
    default:
      return null;
  }
};

export default ShowExcelExtractionProfilePage;
