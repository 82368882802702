import { useParams } from "react-router";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import Profile from "../profile";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import Spinner from "../utils/Spinner";
import { BranchIndex } from "./branchTypes";
import ShowBranchPage from "./ShowBranchPage";
import DocsModal from "../utils/DocsModal";
import { useAuth } from "../../context/auth-context";
import { ProfileTab } from "../tabs/tabTypes";
import { GroupType } from "../user/userTypes";
import ProjectOverviewDateRange from "../projects/ProjectOverviewDateRange";
import { useEffect } from "react";

export type BranchProfilePages =
  | "details"
  | "staff"
  | "equipment"
  | "projects"
  | "cpirs"
  | "jobs"
  | "deliverables"
  | "documents"
  | "leave"
  | "tenders"
  | "inter-entity-invoices"
  | "budgets"
  | "dashboard"
  | "weekly-forecasts"
  | "purchases";

const BranchProfile = () => {
  const { uuid, page } = useParams<{
    uuid: string;
    page: BranchProfilePages;
  }>();

  const { data, setData, loading, setUrl }: IUseApi<any> = useApi(
    `organisation-branches/${uuid}`,
    {},
    true,
  );

  useEffect(() => {
    setUrl(`organisation-branches/${uuid}`);
  }, [uuid]);

  const { user } = useAuth();

  const branch = data as BranchIndex;

  const tabs: ProfileTab<BranchProfilePages>[] = [
    {
      label: "Details",
      link: `/branches/${uuid}/details`,
      page: "details",
    },
    {
      label: "Documents",
      link: `/branches/${uuid}/documents`,
      page: "documents",
    },
    {
      label: "Staff",
      link: `/branches/${uuid}/staff`,
      page: "staff",
    },
    {
      label: "Leave",
      link: `/branches/${uuid}/leave`,
      page: "leave",
    },
    {
      label: "Equipment",
      link: `/branches/${uuid}/equipment`,
      page: "equipment",
    },
    {
      label: "Projects",
      link: `/branches/${uuid}/projects`,
      page: "projects",
    },
    {
      label: "CPIRs",
      link: `/branches/${uuid}/cpirs`,
      page: "cpirs",
    },
    {
      label: "Jobs",
      link: `/branches/${uuid}/jobs`,
      page: "jobs",
    },
    {
      label: "Deliverables",
      link: `/branches/${uuid}/deliverables`,
      page: "deliverables",
    },
    {
      label: "Weekly Forecasts",
      link: `/branches/${uuid}/weekly-forecasts`,
      page: "weekly-forecasts",
    },
    {
      label: "Purchases",
      link: `/branches/${uuid}/purchases`,
      page: "purchases",
    },
  ];

  if (
    user?.hasAccessTo(
      "App\\Models\\OrganisationBranch",
      "view_budgets",
      branch.id,
    ) ||
    user?.is_admin ||
    user?.id == branch.branch_manager_id ||
    user?.groups.includes(GroupType.Accounting)
  ) {
    tabs.push({
      label: "Budget",
      link: `/branch-budgets/${uuid}`,
      page: "budgets",
    });
  }

  if (
    user?.id == branch.branch_manager_id ||
    user?.groups.includes(GroupType.Accounting) ||
    user?.is_admin
  ) {
    tabs.push({
      label: "Dashboard",
      link: `/branches/${uuid}/dashboard`,
      page: "dashboard",
    });
  }

  if (user?.is_position_admin) {
    tabs.push({
      label: "Tenders",
      link: `/branches/${uuid}/tenders`,
      page: "tenders",
    });
  }

  if (
    user?.active_organisation.group_members &&
    user?.active_organisation.group_members?.length > 0 &&
    user.is_position_admin
  ) {
    tabs.push({
      label: "Inter Entity Invoices",
      link: `/branches/${uuid}/inter-entity-invoices`,
      page: "inter-entity-invoices",
    });
  }

  if (loading) {
    return <Spinner loading />;
  }

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/organisation-setup-9316/branches-divisions-and-tracking-sites/" />
      <HeaderPageAdminFilter
        crumbs={[
          { link: "branches", name: "Branches" },
          { link: "", name: "Branch Profile", active: true },
        ]}
        titlePage={branch.display_name}
      />
      <Profile<BranchProfilePages>
        content={<ShowBranchPage branch={branch} setBranch={setData} />}
        header={
          <DashboardHeader>
            <DashboardH3>{branch.display_name}</DashboardH3>
          </DashboardHeader>
        }
        tabs={tabs}
        sideBar={
          page === "dashboard" && (
            <ProjectOverviewDateRange className="d-flex mt-3" show />
          )
        }
      />
    </>
  );
};

export default BranchProfile;
