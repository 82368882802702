import { useMemo, useState } from "react";
import { Button } from "reactstrap";
import { Field, FormSubmitHandler, startSubmit, stopSubmit } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import formError from "../utils/formError";
import FormModal from "../utils/FormModal";
import RenderField from "../utils/renderField";
import InitialAvatar from "../utils/InitialAvatar";
import { useAuth } from "../../context/auth-context";
import Markdown from "react-markdown";
import errorSwal from "../utils/errorSwal";
import LinkRenderer from "./LinkRenderer";

interface ChatModalProps {
  initialChat: any;
  modelId: number;
  fieldUuid: string;
  modelType: string;
  name: string;
  change: Function;
  threadId?: string;
}

const ChatModal = (props: ChatModalProps) => {
  const { modelType, modelId, fieldUuid, change, threadId } = props;

  const { modal, toggle } = useModal();

  const { user } = useAuth();

  const {
    takeAction,
  }: IUseApi<
    {},
    {
      data: any;
    }
  > = useApi();

  const [chat, setChat] = useState<
    {
      content: any;
      role: "user" | "assistant";
    }[]
  >([
    {
      content: props.initialChat,
      role: "assistant",
    },
  ]);

  const onSubmit: FormSubmitHandler<
    { prompt: string },
    ChatModalProps
  > = async (values, dispatch, props) => {
    dispatch(startSubmit("CustomFieldChat"));

    setChat([
      ...chat,
      {
        content: {
          content: {
            text: {
              value: values.prompt,
            },
          },
        },
        role: "user",
      },
    ]);

    props.reset?.();

    return takeAction("store", `custom-fields/${fieldUuid}/prompts`, {
      modelType: modelType,
      modelId: modelId,
      threadId: threadId,
      prompts: [
        ...chat.map((message) => ({
          content: message.content.content.text.value,
          role: message.role,
        })),
        {
          content: values.prompt,
          role: "user",
        },
      ],
    })
      .then(({ data }) => {
        if (data.data.cached_thread_id) {
          const checkThread = () => {
            return takeAction(
              "show",
              `open-ai/run/${data.data.run_id}/threads/${data.data.cached_thread_id}`,
            )
              .then(({ data }) => {
                if (data.data) {
                  setChat([
                    ...chat,
                    {
                      content: {
                        content: {
                          text: {
                            value: values.prompt,
                          },
                        },
                      },
                      role: "user",
                    },
                    {
                      content: data.data,
                      role: "assistant",
                    },
                  ]);

                  return;
                }

                return setTimeout(checkThread, 3000);
              })
              .catch(errorSwal);
          };

          return checkThread();
        }

        setChat([
          ...chat,
          {
            content: values.prompt,
            role: "user",
          },
          {
            content: data.data,
            role: "assistant",
          },
        ]);

        dispatch(stopSubmit("CustomFieldChat"));
      })
      .catch((err) => {
        setChat(chat.slice(0, chat.length));
        dispatch(stopSubmit("CustomFieldChat"));

        props.change?.("prompt", values.prompt);
        return formError(err);
      });
  };

  return (
    <>
      <Button size="sm" outline onClick={toggle}>
        Chat
      </Button>
      <FormModal
        title="Chat"
        form="CustomFieldChat"
        modal={modal}
        toggle={toggle}
        onSubmit={onSubmit}
        save="Chat"
        saving="Generating Response..."
        extraFooterButtons={
          <Button
            outline
            onClick={() => {
              setChat([
                {
                  content: props.initialChat,
                  role: "assistant",
                },
              ]);
            }}
          >
            Reset Chat
          </Button>
        }
      >
        <div className="form-group col-12">
          <div className="px-0 border rounded-lg">
            {chat.map((message, index) => (
              <div
                className={`d-flex p-2 py-3 space-x-5 ${
                  message.role === "assistant" ? "bg-gray-100" : ""
                }`}
              >
                <div>
                  {message.role === "assistant" ? (
                    <img
                      src="/img/boss-192px.png"
                      alt="The BOSS App Logo"
                      style={{ height: "30px" }}
                    />
                  ) : (
                    <InitialAvatar
                      className=""
                      userName={user?.name ?? "-"}
                      size={30}
                      fontSize={14}
                    />
                  )}
                </div>
                <div>
                  <div>
                    <MarkDownRenderer message={message} />
                  </div>
                  {message.role === "assistant" ? (
                    <Button
                      outline
                      size="sm"
                      className="mt-3"
                      onClick={() => {
                        change(props.name, message.content);
                      }}
                    >
                      Set as field value
                    </Button>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="col-12 form-group">
          <Field name="prompt" textarea component={RenderField} type="text" />
        </div>
      </FormModal>
    </>
  );
};

const MarkDownRenderer = ({ message }: { message: any }) => {
  const { takeAction, loading } = useApi();

  const markdownComponents = useMemo(() => {
    return {
      a: ({ href, children }: any) => (
        <LinkRenderer
          href={href}
          children={children}
          takeAction={takeAction}
          loading={loading}
          value={message.content}
        />
      ),
    };
  }, [message.content]);

  return (
    <Markdown
      components={markdownComponents}
      urlTransform={(url: string) => {
        return url;
      }}
    >
      {message?.content?.content?.text?.value}
    </Markdown>
  );
};

export default ChatModal;
