import { AxiosError } from "axios";
import { IUseApi } from "../api/apiTypes";
import formError from "../utils/formError";
import ExcelExtractionForm from "./ExcelExtractionForm";
import useApi from "../api/useApi";
import { toast } from "react-toastify";
import { ExcelExtraction } from "./excelExtractionTypes";

interface EditExcelExtractionProps {
  excelExtraction: ExcelExtraction;
  setExcelExtraction: (excelExtraction: ExcelExtraction) => void;
}

const EditExcelExtraction = ({
  excelExtraction,
  setExcelExtraction,
}: EditExcelExtractionProps) => {
  const { takeAction }: IUseApi = useApi();

  const onSubmit = async (values: any) => {
    try {
      const response = await takeAction(
        "update",
        `/excel-extractions/${excelExtraction.uuid}`,
        values,
      );

      setExcelExtraction(response.data.data);
      toast.success("Excel extraction configuration updated successfully");
    } catch (err) {
      return formError(err as AxiosError<any>);
    }
  };

  return (
    <ExcelExtractionForm
      onSubmit={onSubmit}
      initialValues={{
        columns: excelExtraction.columns,
        name: excelExtraction.name,
      }}
    />
  );
};

export default EditExcelExtraction;
