import { BiTestTube } from "react-icons/bi";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { SiPytest } from "react-icons/si";
import BottomLogo from "../utils/BottomLogo";
import LoadingOverlay from "../utils/LoadingOverlay";
import { useTestDashboardOverview } from "./hooks/useTestDashboardOverview";

const TestDashboardOverview = ({
  queryString,
  setTestStatusFilter,
  testStatusFilter,
}: {
  queryString: string;
  setTestStatusFilter: (filter?: "to_be_reported" | "incomplete") => void;
  testStatusFilter?: "to_be_reported" | "incomplete";
}) => {
  const { data, isLoading } = useTestDashboardOverview(queryString);

  return (
    <div className="row mb-4 text-white ">
      <div className="col-lg-4 position-relative">
        <div
          style={{
            backgroundColor: "#a196ff",
          }}
          className="p-5 rounded-lg shadow position-relative brick"
        >
          <OverviewStat
            loading={isLoading}
            count={data?.tests_without_worksheets_count}
            label="Tests without Worksheets"
            icon={<HiOutlineDocumentReport className="text-white tx-28" />}
          />

          <BottomLogo size={35} bottom={20} left={220} />
        </div>
      </div>
      <div className="col-lg-4">
        <button
          type="button"
          onClick={() =>
            setTestStatusFilter(
              testStatusFilter === "incomplete" ? undefined : "incomplete",
            )
          }
          style={{
            backgroundColor:
              testStatusFilter === "incomplete"
                ? "rgb(255 0 0)"
                : "rgb(168 25 25)",
            textAlign: "justify",
          }}
          className="p-5 rounded-lg shadow position-relative brick w-100 border-0"
        >
          <OverviewStat
            loading={isLoading}
            label="Incomplete"
            count={data?.to_be_completed_count}
            icon={<BiTestTube className="text-white tx-28" />}
          />
          <BottomLogo size={35} bottom={20} left={220} />
        </button>
      </div>
      <div className="col-lg-4">
        <button
          type="button"
          style={{
            backgroundColor:
              testStatusFilter === "to_be_reported" ? "#28dfe4" : "cadetblue",
            textAlign: "justify",
          }}
          onClick={() =>
            setTestStatusFilter(
              testStatusFilter === "to_be_reported"
                ? undefined
                : "to_be_reported",
            )
          }
          className="p-5 rounded-lg shadow position-relative brick border-0 w-100"
        >
          <OverviewStat
            loading={isLoading}
            label="To Be Reported"
            count={data?.to_be_reported_count}
            icon={<SiPytest className="text-white tx-28" />}
          />

          <BottomLogo size={35} bottom={20} left={220} />
        </button>
      </div>
    </div>
  );
};

const OverviewStat = ({
  label,
  count,
  icon,
  loading,
}: {
  label: string;
  count?: number;
  icon: React.ReactNode;
  loading: boolean;
}) => {
  if (loading) {
    return <LoadingOverlay loading={loading} />;
  }
  return (
    <div className="d-flex align-items-center text-white">
      <div>{icon}</div>
      <div className="ms-4">
        <p className="tx-24 mb-0">{count}</p>
        <p className="mb-0 tx-12">{label}</p>
      </div>
    </div>
  );
};

export default TestDashboardOverview;
