import {
  Field,
  FieldArray,
  FormSubmitHandler,
  InjectedFormProps,
  getFormValues,
  reduxForm,
} from "redux-form";
import SubmitButton from "../utils/SubmitButton";
import RenderField from "../utils/renderField";
import FormHeader from "../utils/FormHeader";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import { connect } from "react-redux";
import useApi from "../api/useApi";
import { useEffect, useState } from "react";
import { EmploymentHeroUser } from "./userTypes";
import { IUseApi } from "../api/apiTypes";
import FormErrorAlert from "../form/FormErrorAlert";
import { toast } from "react-toastify";
import formError from "../utils/formError";
import SuperFundModal from "./SuperFundModal";
import EmployeeSuperFundForm from "./EmployeeSuperFundForm";
import SelectInput from "../form/SelectInput";

interface UserPayrollProps {
  formValues?: EmploymentHeroUser & {
    super_funds: any[];
  };
  userId: string;
  onSuccess?: () => void;
}

const UserPayroll = (
  props: UserPayrollProps &
    InjectedFormProps<EmploymentHeroUser, UserPayrollProps>,
) => {
  const [modal, setModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [selectedIndex, setSelectedIndex] = useState<any>(null);

  const toggle = () => setModal(!modal);

  const { handleSubmit, change, formValues, userId } = props;

  const { takeAction }: IUseApi<{}, {}> = useApi();

  const { data, refreshData: refreshEmployee } = useApi(
    `payroll-integration/users/${userId}`,
    null,
    true,
  );

  const {
    data: superFunds,
    refreshData: refreshSuperFund,
    setData: setSuperFunds,
  } = useApi(`payroll-integration/employee-super-funds/${userId}`, null, true);

  const handleOpenModal = (index: any, row: any) => {
    setSelectedIndex(index);
    setSelectedRow(row);
    toggle();
  };

  const modalOnSubmit = (selectedIndex: number, value: any) => {
    const selectedFund = formValues?.super_funds[selectedIndex];

    const updatedFund = {
      ...selectedFund,
      productCode: value.product_code,
      fundName: value.fund_name,
      employerNominatedFund: false,
    };

    if (selectedFund?.id) {
      return takeAction(
        "update",
        `payroll-integration/employee-super-funds/${userId}`,
        updatedFund,
      )
        .then(() => {
          refreshSuperFund();
          refreshEmployee();
          toggle();
        })
        .catch(formError);
    }

    const updatedSuperFunds = (formValues?.super_funds || []).map(
      (superFund: any, index: number) =>
        index === selectedIndex ? updatedFund : superFund,
    );

    change("super_funds", updatedSuperFunds);
    toggle();
  };

  const onSubmit: FormSubmitHandler<EmploymentHeroUser, UserPayrollProps> = (
    values,
  ) => {
    return takeAction("update", `payroll-integration/users/${userId}`, {
      ...values,
      bankAccount1_AllocatedPercentage: 100,
    })
      .then(() => {
        refreshSuperFund();
        refreshEmployee();

        props.onSuccess?.();
        toast.success("User updated");
      })
      .catch(formError);
  };

  useEffect(() => {
    if (data) {
      props.initialize({
        ...data,
        super_funds: superFunds || [], // Initialize super_funds with superFunds
      });
    }
  }, [data, superFunds]);

  // if (!data || !superFunds) return null;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <FormErrorAlert error={props.error} />

          <div className="col-lg-12 form-group">
            <Field
              component={RenderField}
              name="taxFileNumber"
              label="Tax File Number"
              validate={required}
              required
            />
          </div>
          <div className="col-lg-12 form-group">
            <FieldArray
              handleOpenModal={handleOpenModal}
              component={EmployeeSuperFundForm}
              name="super_funds"
              {...props}
            />
          </div>
          <FormHeader>Tax Details</FormHeader>
          <div className="col-lg-4 form-group">
            <Field
              name="leaveTemplate"
              url="/payroll-integration/leave-allowance-templates"
              required
              validate={required}
              component={SelectInput}
              label="Leave Allowance Template"
              formatData={(data: any[]) =>
                data.map((d) => {
                  return {
                    label: d.name,
                    value: d.name,
                  };
                })
              }
            />
          </div>
          <div className="col-lg-4 form-group">
            <Field
              name="payschedule"
              url="/payroll-integration/pay-schedules"
              required
              validate={required}
              component={SelectInput}
              label="Pay Schedule"
              formatData={(data: any[]) =>
                data.map((d) => {
                  return {
                    label: d.name,
                    value: d.name,
                  };
                })
              }
            />
          </div>
          <div className="col-lg-4 form-group">
            <Field
              name="primaryLocation"
              url="/payroll-integration/locations"
              required
              validate={required}
              component={SelectInput}
              label="Location"
              formatData={(data: any[]) =>
                data.map((d) => {
                  return {
                    label: d.name,
                    value: d.name,
                  };
                })
              }
            />
          </div>

          <div className="col-lg-4 form-group">
            <Field
              component={renderToggleInput}
              name="stslDebt"
              label="STSL Debt"
            />
          </div>
          <div className="col-lg-4 form-group">
            <Field
              component={renderToggleInput}
              name="claimTaxFreeThreshold"
              label="Claim Tax Free Threshold"
            />
          </div>

          <FormHeader>Bank Account</FormHeader>
          <div className="col-lg-4 form-group">
            <Field
              component={RenderField}
              name="bankAccount1_AccountName"
              label="Bank Account Name"
              required
              validate={required}
            />
          </div>
          <div className="col-lg-4 form-group">
            <Field
              component={RenderField}
              name="bankAccount1_AccountNumber"
              label="Bank Account Number"
              required
              validate={required}
            />
          </div>
          <div className="col-lg-4 form-group">
            <Field
              component={RenderField}
              name="bankAccount1_BSB"
              label="Bank Account BSB"
              required
              validate={required}
            />
          </div>

          <div className="col-12">
            <SubmitButton {...props} />
          </div>
        </div>
      </form>
      {selectedRow && (
        <SuperFundModal
          toggle={toggle}
          modal={modal}
          onSubmit={modalOnSubmit}
          row={selectedRow}
          selectedIndex={selectedIndex}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any, { form }: { form: string }) => {
  return {
    formValues: getFormValues(form)(state) as EmploymentHeroUser,
    // superFunds: selector(state, 'super_funds')
  };
};

const form = reduxForm<EmploymentHeroUser, UserPayrollProps>({
  form: "UserPayrollForm",
})(UserPayroll);

// @ts-ignore
export default connect(mapStateToProps)(form);
