import { useMemo } from "react";
import HeaderPage from "../header/HeaderPage";
import PaginatedList from "../pagination/PaginatedList";
import useJournals from "./hooks/useJournals";
import { Journal } from "./journalTypes";
import ReactTable from "../table/ReactTable";
import { RiAddFill } from "react-icons/ri";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import deleteSwal from "../utils/deleteSwal";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import TextButton from "../utils/TextButton";
import { FaTrash } from "react-icons/fa";

const JournalsList = () => {
  return (
    <>
      <HeaderPage crumbs={[]} titlePage="Journals" />
      <PaginatedList
        indexHook={useJournals}
        listName="journals"
        extraButtons={() => (
          <Link to="/journals/add">
            <RiAddFill aria-label="Add New Journal" className="tx-24 ms-1" />
          </Link>
        )}
        originalFilters={[]}
        list={({ data }) => {
          const journals = data as Journal[];

          return (
            <div className="col-12">
              <JournalsTable journals={journals} />
            </div>
          );
        }}
      />
    </>
  );
};

const JournalsTable = ({ journals }: { journals: Journal[] }) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "narration",
        header: "Narration",
        cell: (info: any) => {
          return (
            <Link to={`/journals/${info.row.original.uuid}`}>
              {info.row.original.narration}
            </Link>
          );
        },
      },
      {
        accessorKey: "date",
        header: "Date",
        cell: (info: any) => dayjs(info.row.original.date).format("DD/MM/YYYY"),
      },
      {
        accessorKey: "Actions",
        header: "Actions",
        cell: (info: any) => {
          const queryClient = useQueryClient();
          const { takeAction, loading }: IUseApi = useApi();

          return (
            <>
              <div className="d-flex align-items-center space-x-2">
                <TextButton
                  onClick={() => {
                    deleteSwal(info.row.original.name)
                      .then(() =>
                        takeAction(
                          "destroy",
                          `journals/${info.row.original.uuid}`,
                        ),
                      )
                      .then(() => {
                        toast.success("Journal Deleted");
                        queryClient.invalidateQueries("journals");
                      })
                      .catch(errorSwal);
                  }}
                  disabled={loading}
                >
                  <FaTrash size={16} className="text-danger" />
                </TextButton>
              </div>
            </>
          );
        },
      },
    ],
    [journals],
  );

  return <ReactTable columns={columns} data={journals} disableSearch />;
};

export default JournalsList;
