import _ from "lodash";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import Spinner from "../utils/Spinner";
import { OrgChartComponent, OrganisationChartUser } from "./OrgChartComponent";
import Empty from "../utils/Empty";

const OrganisationPositionChart = () => {
  const { data: users, loading } = useApi(
    "users?filter[has_primary_position]=true&filter[employed]=true",
    [],
    true,
  );

  const correctedData = users.map((d: OrganisationChartUser) => {
    return {
      ...d,
      parentId: d.is_organisation_head ? "" : d.parent_id ?? null,
    };
  });

  const sortedData = _.sortBy(correctedData, ["parent_id", "chart_order"]);

  const groupedData = _.groupBy(sortedData, "parent_id");

  const indexedGroupedData = _.mapValues(groupedData, function (group) {
    return group.map((position, index) => ({
      ...position,
      index,
      has_left: _.first(group) !== position,
      has_right: _.last(group) !== position,
    }));
  });

  const formattedData = Object.values(indexedGroupedData).flat();

  if (loading) {
    return <Spinner loading />;
  }

  const missingOrganisationHead = !users.some(
    (user: any) => user.is_organisation_head,
  );

  const usersWithMissingParent = users.filter(
    (user: any) => !user.parent_id && !user.is_organisation_head,
  );

  const invalidManagers = users
    .filter((user: any) => {
      if (!user.parent_id || user.is_organisation_head) return false;

      return !users.some((u: any) => u.id === user.parent_id);
    })
    .filter((value: any, index: any, self: any) =>
      index === self.findIndex((t: any) => t.parent_id === value.parent_id)
    );

  return (
    <>
      <HeaderPage
        titlePage="Organisation Chart"
        crumbs={[
          {
            link: "",
            name: "Position Chart",
            active: true,
          },
        ]}
      />
      <> 
        {missingOrganisationHead || usersWithMissingParent?.length != 0 || invalidManagers?.length != 0 ? (
          <div className="mt-5">
            <Empty top={false}>
              <div className="text-center mt-4">
                <p className="fw-bolder text-dark mb-2">
                  Organisation Chart Unavailable
                </p>
                {missingOrganisationHead ? (
                  <p className="mb-1">
                    The organisation chart cannot be shown due to the absence of
                    an organisation head.
                    <br />
                    Please contact HR to add an organisation head.
                  </p>
                ) : null}
                {usersWithMissingParent?.length != 0 ? (
                  <>
                    <p className="mb-1">
                      The organisation chart cannot be shown due to employees
                      without managers.
                    </p>

                    <ul
                      className="mb-1"
                      style={{ listStyleType: "none", paddingLeft: 0 }}
                    >
                      {usersWithMissingParent.map(
                        (user: any, index: number) => (
                          <li key={index}>
                            <a href={user.primary_staff_role.link}>
                              {user.primary_staff_role.name}
                            </a>
                          </li>
                        ),
                      )}
                    </ul>
                  </>
                ) : null}
                {invalidManagers?.length != 0 ? (
                  <>
                    <p className="mb-1">
                      The organisation chart cannot be shown due to employees
                      with invalid managers.
                    </p>

                    <ul
                      className="mb-1"
                      style={{ listStyleType: "none", paddingLeft: 0 }}
                    >
                      <p className="fw-bolder text-dark mb-2">
                        Invalid Manager List
                      </p>
                      {invalidManagers.map((user: any, index: number) => (
                        <li key={index}>
                          <a href={user.manager_details?.link}>
                            {user.manager_details?.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : null}
              </div>
            </Empty>
          </div>
        ) : formattedData ? (
          <div
            style={{
              width: "99vw",
              left: "0",
              position: "absolute",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            <OrgChartComponent data={formattedData} />
          </div>
        ) : (
          <div className="signin-wrapper">No Data available</div>
        )}
      </>
    </>
  );
};

export default OrganisationPositionChart;
