import TextButton from "../utils/TextButton";
import { PiGearSix } from "react-icons/pi";
import FormModal from "../utils/FormModal";
import { Field } from "redux-form";
import RenderField from "../utils/renderField";
import useApi from "../api/useApi";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import formError from "../utils/formError";
import { Branch } from "./branchTypes";
import useModal from "../hooks/useModal";
import { IUseApi } from "../api/apiTypes";

const BudgetSettingModal = ({
  branch,
  setBranch,
}: {
  branch?: Branch;
  setBranch: (branch: Branch) => void;
}) => {
  const { toggle, modal } = useModal();
  const { uuid } = useParams<{ uuid: string }>();

  const { takeAction }: IUseApi<{}, { data: Branch }> = useApi();

  const onSubmit = (values: any) => {
    return takeAction("update", `/organisation-branches/${uuid}`, values)
      .then(({ data }) => {
        toast.success("Budget settings updated");
        setBranch(data.data);

        toggle();
      })
      .catch(formError);
  };

  return (
    <>
      <TextButton
        title="Budget Settings"
        className="text-secondary"
        onClick={toggle}
      >
        <PiGearSix size={20} />
      </TextButton>
      <FormModal
        modal={modal}
        toggle={toggle}
        form="budgetSettingForm"
        title="Budget Settings"
        onSubmit={onSubmit}
        initialValues={branch}
      >
        <div className="form-group col-12">
          <Field
            component={RenderField}
            name="recurring_revenue_percentage"
            type="number"
            label="Recurring Revenue Percentage"
          />
        </div>
        <div className="col-12 form-group">
          <Field
            component={RenderField}
            name="key_client_percentage"
            type="number"
            label="Key Client Percentage"
          />
        </div>
        <div className="col-12 form-group">
          <Field
            component={RenderField}
            name="key_project_percentage"
            type="number"
            label="Key Project Percentage"
          />
        </div>
        <div className="col-12 form-group">
          <Field
            component={RenderField}
            name="one_off_client_percentage"
            type="number"
            label="One Off Client Percentage"
          />
        </div>
        <div className="col-12 form-group">
          <Field
            component={RenderField}
            name="subcontractor_markup_percentage"
            type="number"
            label="Subcontractor Markup Percentage"
          />
        </div>
      </FormModal>
    </>
  );
};

// const selector = formValueSelector("budgetSettingForm");

// const mapStateToProps = (state: any) => {
//   return {
//     recurring_revenue_percentage: selector(
//       state,
//       "recurring_revenue_percentage",
//     ),
//     key_client_percentage: selector(state, "key_client_percentage"),
//     key_project_percentage: selector(state, "key_project_percentage"),
//     one_off_client_percentage: selector(state, "one_off_client_percentage"),
//   };
// };

export default BudgetSettingModal;
