import dayjs from "dayjs";

/**
 * Determines if equipment is in calibration based on the following rules:
 * 1. If equipment has no calibration requirement (has_calibration = false) -> In calibration
 * 2. If next_calibration_due_date is null/undefined -> In calibration
 * 3. Otherwise, check if next calibration date is in the future
 */
export const isEquipmentInCalibration = (equipment: {
  next_calibration_due_date?: number | null;
  has_calibration?: boolean;
}): boolean => {
  // If equipment doesn't require calibration
  if (!equipment?.has_calibration) {
    return true;
  }

  // If no next calibration date is set
  if (!equipment?.next_calibration_due_date) {
    return true;
  }

  // Check if next calibration date is in the future
  return equipment.next_calibration_due_date > dayjs().unix();
};