import React, { useEffect, useMemo, useState } from "react";
import { BudgetItem, BudgetRow, MonthlyTotals } from "../types";
import { formatAmount } from "../utils/budgetFormatters";
import Input from "./Input";
import { useWindowEvent } from "../../hooks/useWindowEvent";
import useApi from "../../api/useApi";
import { Link, useLocation, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { IUseApiWithData } from "../../api/apiTypes";
import { PopoverBody, Spinner, UncontrolledPopover } from "reactstrap";
import { PopoverHeader } from "reactstrap";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { money, percentage } from "../../form/formatters";
import ReactTable from "../../table/ReactTable";
import { CommentPopover } from "./BudgetTable";
import { BudgetLock } from "../branchTypes";
import { toast } from "react-toastify";
import useModal from "../../hooks/useModal";
import ActualRevenueInvoiceModal from "./ActualRevenueInvoiceModal";
import _ from "lodash";

interface StaffUtilisation {
  user: string;
  title: string;
  utilisation: number;
  chargeout_rate: number;
  chargeout: number;
  link: string;
}

interface EstimatedRevenue {
  amount: number;
  users: StaffUtilisation[];
  month: string;
  branches?: { name: string; amount: number; link: string }[];
  organisations?: { id: number; name: string; amount: number; link: string }[];
}
interface RevenueBudgetTableProps {
  months: string[];
  mainRow: BudgetRow;
  adjustmentRow: BudgetRow;
  monthlyTotals: MonthlyTotals;
  onAmountBlur: (budgetItem: BudgetItem) => void;
  columnStyles: {
    account: { width: number };
    month: { width: number };
  };
  refreshBudgetItems: () => void;
  subcontractorMarkupRows: BudgetRow[];
  budgetLock?: BudgetLock;
  period: [string, string];
  showActuals: boolean;
  isReadOnly?: boolean;
}

const RevenueBudgetTable = ({
  months,
  mainRow,
  adjustmentRow,
  monthlyTotals,
  onAmountBlur,
  refreshBudgetItems,
  columnStyles,
  subcontractorMarkupRows,
  budgetLock,
  period,
  showActuals,
  isReadOnly = false,
}: RevenueBudgetTableProps) => {
  const [editingCell, setEditingCell] = useState<{
    type: "main" | "adjustment";
    month: string;
  } | null>(null);

  const { uuid } = useParams<{ uuid?: string }>();

  const [selectedMonth, setSelectedMonth] = useState<string>(
    dayjs(period[0]).format("YYYY-MM"),
  );

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const branchesParam = params.get("branches");
  const organisationIds = params.get("organisations");

  const url = useMemo(() => {
    if (uuid) {
      return `branches/${uuid}/actual-revenue?start_date=${period[0]}&end_date=${period[1]}`;
    }

    return `branches/actual-revenue?start_date=${period[0]}&end_date=${
      period[1]
    }&branches=${branchesParam}${
      organisationIds ? `&organisations=${organisationIds}` : ""
    }`;
  }, [uuid, branchesParam, period, organisationIds]);

  const estimatedRevenueUrl = useMemo(() => {
    if (uuid) {
      return `branches/${uuid}/estimated-revenue?months[]=${months.join(
        "&months[]=",
      )}`;
    }

    return `branches/estimated-revenue?months[]=${months.join(
      "&months[]=",
    )}&branches=${branchesParam}${
      organisationIds ? `&organisations=${organisationIds}` : ""
    }`;
  }, [months, uuid, branchesParam, organisationIds]);

  const {
    data: actualRevenue,
    setUrl: setActualRevenueUrl,
    loading: actualRevenueLoading,
  }: IUseApiWithData<
    Record<
      string,
      {
        total: number;
        invoices: {
          name: string;
          link: string;
        }[];
        journals: {
          name: string;
          link: string;
          total: number;
        }[];
      }
    >
  > = useApi(url, {});

  useEffect(() => {
    setActualRevenueUrl(url);
  }, [url]);

  const { toggle, modal } = useModal();

  useWindowEvent("mousedown", (e: MouseEvent) => {
    // Don't handle clicks on the cell input or its children
    const target = e.target as HTMLElement;
    if (
      target.id === "cell-input" ||
      target.closest("#cell-input") ||
      target.closest("#edit-button")
    ) {
      return;
    }

    const cellInput = document.getElementById("cell-input");
    if (cellInput) {
      cellInput.blur();
      setEditingCell(null);
      return;
    }

    setEditingCell(null);
  });

  const handleCellClick = (type: "main" | "adjustment", month: string) => {
    if (budgetLock?.locked_at) {
      toast.warning("Budget is locked");
      return;
    }

    setEditingCell({ type, month });

    // Focus the input after a short delay to ensure it's rendered
    setTimeout(() => {
      const cellInput = document.getElementById("cell-input");
      if (cellInput) {
        cellInput.focus();
        if (cellInput instanceof HTMLInputElement) {
          cellInput.select();
        }
      }
    }, 50);
  };

  const handleKeyDown = (
    e: React.KeyboardEvent,
    type: "main" | "adjustment",
    month: string,
  ) => {
    if (e.key === "Tab") {
      e.preventDefault();

      // First, trigger the blur event to save the current value
      const cellInput = document.getElementById(
        "cell-input",
      ) as HTMLInputElement;
      if (cellInput) {
        // Get the current value from the input
        const currentValue = cellInput.value;

        // Find the current budget item
        const currentRow = type === "main" ? mainRow : adjustmentRow;
        const currentBudgetItem = currentRow.amounts.find(
          (amount) => dayjs(amount.month).format("YYYY-MM") === month,
        );

        if (currentBudgetItem) {
          // Call onAmountBlur with the updated budget item
          onAmountBlur({
            ...currentBudgetItem,
            amount: currentValue,
            type: "revenue",
            revenue_type: type,
          });
        }
      }

      // Find the index of the current month
      const currentMonthIndex = months.findIndex((m) => m === month);

      // Determine the next cell to focus
      let nextType = type;
      let nextMonthIndex = currentMonthIndex;

      if (!e.shiftKey) {
        // Tab forward
        if (currentMonthIndex < months.length - 1) {
          // Move to the next month in the same row
          nextMonthIndex = currentMonthIndex + 1;
        } else if (type === "main") {
          // Move to the first month in the adjustment row
          nextType = "adjustment";
          nextMonthIndex = 0;
        } else {
          // We're at the end, do nothing or loop back to the first cell
          nextType = "main";
          nextMonthIndex = 0;
        }
      } else {
        // Shift+Tab (backward)
        if (currentMonthIndex > 0) {
          // Move to the previous month in the same row
          nextMonthIndex = currentMonthIndex - 1;
        } else if (type === "adjustment") {
          // Move to the last month in the main row
          nextType = "main";
          nextMonthIndex = months.length - 1;
        } else {
          // We're at the beginning, do nothing or loop to the last cell
          nextType = "adjustment";
          nextMonthIndex = months.length - 1;
        }
      }

      // Important: Clear the current editing cell first to ensure React re-renders
      setEditingCell(null);

      // Then set the new editing cell after a very short delay
      setTimeout(() => {
        setEditingCell({
          type: nextType,
          month: months[nextMonthIndex],
        });

        // Force focus on the new cell after another short delay to ensure the DOM has updated
        setTimeout(() => {
          const cellInput = document.getElementById("cell-input");
          if (cellInput) {
            cellInput.focus();
            if (cellInput instanceof HTMLInputElement) {
              cellInput.select();
            }
          }
        }, 50);
      }, 10);
    } else if (e.key === "Enter") {
      // Handle Enter key to blur the input (save the value)
      e.preventDefault();
      const input = e.currentTarget as HTMLInputElement;
      input.blur();
      setEditingCell(null);
    }
  };

  const {
    data: estimatedRevenue,
    setUrl: setEstimatedRevenueUrl,
    loading: estimatedRevenueLoading,
  }: IUseApiWithData<EstimatedRevenue[]> = useApi("", []);

  useEffect(() => {
    setEstimatedRevenueUrl(estimatedRevenueUrl);
  }, [estimatedRevenueUrl]);

  const calculateMonthlyTotals = (): MonthlyTotals => {
    const totals: MonthlyTotals = {};

    months.forEach((month) => {
      const mainAmount = mainRow.amounts.find(
        (amount) => dayjs(amount.month).format("YYYY-MM") === month,
      )?.amount;

      const adjustmentAmount = adjustmentRow.amounts.find(
        (amount) => dayjs(amount.month).format("YYYY-MM") === month,
      )?.amount;

      // Calculate subcontractor markup totals
      const markupAmount = subcontractorMarkupRows.reduce(
        (sum: number, row) => {
          const amount = row.amounts.find(
            (amountItem) => dayjs(amountItem.month).format("YYYY-MM") === month,
          )?.amount;
          return (
            sum +
            (amount
              ? typeof amount === "string"
                ? parseFloat(amount)
                : amount
              : 0)
          );
        },
        0,
      );

      const mainValue = mainAmount
        ? typeof mainAmount === "string"
          ? parseFloat(mainAmount)
          : mainAmount
        : 0;
      const adjustmentValue = adjustmentAmount
        ? typeof adjustmentAmount === "string"
          ? parseFloat(adjustmentAmount)
          : adjustmentAmount
        : 0;

      totals[month] = mainValue + adjustmentValue + markupAmount;
    });

    return totals;
  };

  const monthlyTotalsCalculated = calculateMonthlyTotals();

  const grandTotal = Object.values(monthlyTotalsCalculated).reduce(
    (sum, amount) => sum + amount,
    0,
  );

  const revenueRows = useMemo(() => {
    return [mainRow, adjustmentRow, ...subcontractorMarkupRows]
      .flatMap((row) => row.amounts)
      .map((row) => ({
        ...row,
        month: dayjs(row.month).format("YYYY-MM"),
      }));
  }, [mainRow, adjustmentRow, subcontractorMarkupRows]);

  const groupedRevenueRows = useMemo(() => {
    return _.groupBy(revenueRows, "month");
  }, [revenueRows]);

  return (
    <>
      <div className="mt-4">
        <div className="d-flex mb-3 align-items-center">
          <h5 className="me-3 mb-0 text-dark">Revenue</h5>
        </div>
        <div className="">
          <table className="budget-table">
            <thead className="bg-white rounded-lg">
              <tr>
                <th style={columnStyles.account}>Type</th>
                {months.map((month) => (
                  <th key={month} className="text-end">
                    {dayjs(month).format("MMM YYYY")}
                  </th>
                ))}
                <th className="text-end" style={{ width: 150 }}>
                  Total
                </th>
                <th style={{ width: 50 }}></th>
              </tr>
            </thead>
            <tbody>
              <tr
                className={
                  estimatedRevenueLoading
                    ? "bg-gray-300"
                    : "money table-success"
                }
              >
                <td className="fw-bolder text-dark">Estimated Revenue</td>
                {estimatedRevenueLoading ? (
                  <td colSpan={months.length + 2} className="text-center">
                    <Spinner size="sm" />
                  </td>
                ) : (
                  <>
                    {estimatedRevenue.length > 0 ? (
                      estimatedRevenue.map((revenue) => (
                        <td className="text-end fst-italic position-relative">
                          <span
                            className="cursor-pointer"
                            id={`revenue-popover-${revenue.month}`}
                          >
                            {formatAmount(revenue.amount, "revenue")}
                          </span>
                          <UncontrolledPopover
                            delay={{
                              show: 500,
                              hide: 0,
                            }}
                            target={`revenue-popover-${revenue.month}`}
                            trigger="hover"
                            innerClassName="wider-popover"
                            popperClassName="staff-contribution-popover"
                          >
                            <PopoverHeader>Staff Contribution</PopoverHeader>
                            <PopoverBody>
                              {revenue.organisations &&
                              revenue.organisations.length > 1 ? (
                                <BranchRevenueTable
                                  branches={revenue.organisations}
                                />
                              ) : revenue.branches ? (
                                <BranchRevenueTable
                                  branches={revenue.branches}
                                />
                              ) : (
                                <StaffUtilisationTable users={revenue.users} />
                              )}
                            </PopoverBody>
                          </UncontrolledPopover>
                        </td>
                      ))
                    ) : (
                      <td colSpan={months.length} className="text-center"></td>
                    )}
                    <td className="text-end fst-italic">
                      {formatAmount(
                        estimatedRevenue?.reduce(
                          (sum: number, revenue: any) =>
                            sum + (revenue.amount || 0),
                          0,
                        ),
                        "revenue",
                      )}
                    </td>
                    <td></td>
                  </>
                )}
              </tr>
              {/* Subcontractor Markup Rows */}
              {subcontractorMarkupRows.map((row, index) => (
                <tr key={index} className="table-success">
                  <td style={columnStyles.account}>
                    Subcontractor Markup -{" "}
                    {row.amounts[0]?.subcontractor_account_name}
                  </td>
                  {row.amounts.map((amount) => (
                    <td key={amount.month} className="text-end">
                      {formatAmount(amount.amount || "", "revenue")}
                    </td>
                  ))}
                  <td className="text-end">
                    {formatAmount(
                      row.amounts.reduce(
                        (sum, amount) =>
                          sum +
                          (amount.amount
                            ? typeof amount.amount === "string"
                              ? parseFloat(amount.amount)
                              : amount.amount
                            : 0),
                        0,
                      ),
                      "revenue",
                    )}
                  </td>
                  <td></td>
                </tr>
              ))}
              {/* Main Revenue Row */}
              <tr>
                <td style={columnStyles.account}>Main Revenue</td>
                {mainRow.amounts.map((amount) => {
                  const formattedMonth = dayjs(amount.month).format("YYYY-MM");

                  return (
                    <React.Fragment key={amount.month}>
                      <td
                        id={`budget-item-main-${formattedMonth}`}
                        onClick={() => {
                          if (isReadOnly) {
                            return;
                          }
                          handleCellClick("main", formattedMonth);
                        }}
                        className="text-end position-relative"
                      >
                        {editingCell?.type === "main" &&
                        dayjs(editingCell.month).format("YYYY-MM") ===
                          dayjs(amount.month).format("YYYY-MM") ? (
                          <Input
                            budgetItem={{
                              ...amount,
                              type: "revenue",
                              revenue_type: "main",
                            }}
                            account={null}
                            onBlur={onAmountBlur}
                            onKeyDown={(e) =>
                              handleKeyDown(e, "main", formattedMonth)
                            }
                          />
                        ) : (
                          <div className="d-flex justify-content-between pointer-hover">
                            <CommentPopover item={amount} />
                            {formatAmount(
                              typeof amount.amount === "string" ||
                                amount.amount === undefined
                                ? amount.amount || ""
                                : amount.amount.toString(),
                              "revenue",
                            )}
                          </div>
                        )}
                      </td>
                      {amount.items ? (
                        <UncontrolledPopover
                          target={`budget-item-main-${formattedMonth}`}
                        >
                          <PopoverHeader>Breakdowns</PopoverHeader>
                          <PopoverBody className="space-y-3">
                            {Object.entries(
                              _.groupBy(amount.items, "organisation.name"),
                            ).length > 1
                              ? Object.entries(
                                  _.groupBy(amount.items, "organisation.name"),
                                ).map(([organisationName, items]) => {
                                  const total = items.reduce(
                                    (sum, item) =>
                                      sum +
                                      (parseFloat(item.amount as string) || 0),
                                    0,
                                  );

                                  return (
                                    <div key={organisationName}>
                                      <p className="mb-0 text-dark">
                                        {organisationName}
                                      </p>
                                      <small className="text-secondary">
                                        {" "}
                                        {formatAmount(total, "revenue")}
                                      </small>
                                    </div>
                                  );
                                })
                              : Object.entries(
                                  _.groupBy(amount.items, "branch.name"),
                                ).map(([branchName, items]) => {
                                  const total = items.reduce(
                                    (sum, item) =>
                                      sum +
                                      (parseFloat(item.amount as string) || 0),
                                    0,
                                  );

                                  return (
                                    <div key={branchName}>
                                      <a
                                        className="d-block"
                                        href={`${items[0]?.branch?.link}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <p className="mb-0 text-dark">
                                          {branchName}
                                        </p>
                                        <small className="text-secondary">
                                          {formatAmount(total, "revenue")}
                                        </small>
                                      </a>
                                    </div>
                                  );
                                })}
                          </PopoverBody>
                        </UncontrolledPopover>
                      ) : null}
                    </React.Fragment>
                  );
                })}
                <td className="text-end">
                  {formatAmount(
                    mainRow.amounts.reduce(
                      (sum, amount) =>
                        sum +
                        (amount.amount
                          ? typeof amount.amount === "string"
                            ? parseFloat(amount.amount)
                            : amount.amount
                          : 0),
                      0,
                    ),
                    "revenue",
                  )}
                </td>
                <td></td>
              </tr>
              {/* Adjustment Row */}
              <tr className="last-row">
                <td style={columnStyles.account}>Adjustments</td>
                {adjustmentRow.amounts.map((amount) => {
                  const formattedMonth = dayjs(amount.month).format("YYYY-MM");

                  return (
                    <React.Fragment key={amount.month}>
                      <td
                        id={`budget-item-adjustment-${formattedMonth}`}
                        onClick={() => {
                          if (isReadOnly) {
                            return;
                          }
                          handleCellClick("adjustment", formattedMonth);
                        }}
                        className="text-end position-relative"
                      >
                        {editingCell?.type === "adjustment" &&
                        dayjs(editingCell.month).format("YYYY-MM") ===
                          dayjs(amount.month).format("YYYY-MM") ? (
                          <Input
                            budgetItem={{
                              ...amount,
                              type: "revenue",
                              revenue_type: "adjustment",
                            }}
                            account={null}
                            onBlur={onAmountBlur}
                            onKeyDown={(e) =>
                              handleKeyDown(
                                e,
                                "adjustment",
                                dayjs(amount.month).format("YYYY-MM"),
                              )
                            }
                          />
                        ) : (
                          <div className="d-flex justify-content-between pointer-hover">
                            <CommentPopover item={amount} />
                            {formatAmount(
                              typeof amount.amount === "string" ||
                                amount.amount === undefined
                                ? amount.amount || ""
                                : amount.amount.toString(),
                              "revenue",
                            )}
                          </div>
                        )}
                      </td>
                      {amount.items ? (
                        <UncontrolledPopover
                          target={`budget-item-adjustment-${formattedMonth}`}
                        >
                          <PopoverHeader>Breakdowns</PopoverHeader>
                          <PopoverBody className="space-y-3">
                            {Object.entries(
                              _.groupBy(amount.items, "branch.name"),
                            ).map(([branchName, items]) => {
                              const total = items.reduce(
                                (sum, item) =>
                                  sum +
                                  (parseFloat(item.amount as string) || 0),
                                0,
                              );

                              return (
                                <div key={branchName}>
                                  <a
                                    className="d-block"
                                    href={`${items[0]?.branch?.link}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <p className="mb-0 text-dark">
                                      {branchName}
                                    </p>
                                    <small className="text-secondary">
                                      {formatAmount(total, "revenue")}
                                    </small>
                                  </a>
                                </div>
                              );
                            })}
                          </PopoverBody>
                        </UncontrolledPopover>
                      ) : null}
                    </React.Fragment>
                  );
                })}
                <td className="text-end">
                  {formatAmount(
                    adjustmentRow.amounts.reduce(
                      (sum, amount) =>
                        sum +
                        (amount.amount
                          ? typeof amount.amount === "string"
                            ? parseFloat(amount.amount)
                            : amount.amount
                          : 0),
                      0,
                    ),
                    "revenue",
                  )}
                </td>
                <td></td>
              </tr>
              {showActuals && (
                <tr
                  className={`fw-bolder ${
                    actualRevenueLoading ? "bg-gray-300" : "money table-success"
                  }`}
                  style={{
                    borderBottom: "none",
                  }}
                >
                  <td style={columnStyles.account}>Actual Revenue</td>
                  {actualRevenueLoading ? (
                    <td colSpan={months.length + 2} className="text-center">
                      <Spinner size="sm" />
                    </td>
                  ) : (
                    <>
                      {months.map((month) => (
                        <td
                          onClick={() => {
                            setSelectedMonth(month);
                            toggle();
                          }}
                          key={month}
                          className="text-end"
                        >
                          {formatAmount(
                            actualRevenue?.[month]?.total || "",
                            "revenue",
                          )}
                        </td>
                      ))}
                      <td className="text-end">
                        {formatAmount(
                          Object.values(actualRevenue || {}).reduce(
                            (sum, value) => sum + (value?.total || 0),
                            0,
                          ),
                          "revenue",
                        )}
                      </td>
                      <td></td>
                    </>
                  )}
                </tr>
              )}
              {/* Monthly Totals Row */}
              <tr
                className="fw-bolder"
                style={{
                  backgroundColor: "rgb(240 242 248)",
                  borderBottom: "none",
                }}
              >
                <td style={columnStyles.account}>Monthly Totals</td>
                {months.map((month) => {
                  const revenue = groupedRevenueRows[month];

                  const items = revenue
                    .filter((i) => i.items)
                    .map((i) => i.items)
                    .flat();

                  const branches = _.groupBy(items, "branch.id");

                  const branchValues = Object.values(branches).map((branch) => {
                    const total = branch.reduce(
                      (sum, item) =>
                        sum + (parseFloat(item?.amount as string) || 0),
                      0,
                    );

                    return {
                      name: branch[0]?.branch?.name || "",
                      link: branch[0]?.branch?.link || "",
                      amount: total,
                    };
                  });

                  return (
                    <>
                      <td
                        key={month}
                        id={`monthly-total-${month}`}
                        className="text-end"
                        style={columnStyles.month}
                      >
                        {formatAmount(
                          monthlyTotalsCalculated[month],
                          "revenue",
                        )}
                      </td>
                      <UncontrolledPopover
                        innerClassName="wider-popover"
                        popperClassName="staff-contribution-popover"
                        target={`monthly-total-${month}`}
                        trigger="hover"
                        delay={{
                          show: 500,
                          hide: 200,
                        }}
                      >
                        <PopoverHeader>Breakdowns</PopoverHeader>
                        <PopoverBody>
                          <BranchRevenueTable branches={branchValues} />
                        </PopoverBody>
                      </UncontrolledPopover>
                    </>
                  );
                })}
                <td className="text-end" style={columnStyles.month}>
                  {formatAmount(grandTotal, "revenue")}
                </td>
                <td></td>
              </tr>
              {/* Grand Total Row */}
              <tr
                className="fw-bolder "
                style={{
                  backgroundColor: "rgb(240 242 248)",
                }}
              >
                <td className="fw-bolder" style={columnStyles.account}>
                  Total Revenue
                </td>
                <td
                  colSpan={months.length + 1}
                  className="text-end"
                  style={columnStyles.month}
                >
                  {formatAmount(grandTotal, "revenue")}
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <ActualRevenueInvoiceModal
        selectedMonth={selectedMonth}
        // @ts-ignore
        actualRevenue={actualRevenue}
        toggle={toggle}
        modal={modal}
        setSelectedMonth={setSelectedMonth}
      />
    </>
  );
};

const BranchRevenueTable = ({
  branches,
}: {
  branches: { name: string; amount: number; link: string }[];
}) => {
  const helper = createColumnHelper<{
    name: string;
    amount: number;
    link: string;
  }>();

  const columns = useMemo<
    ColumnDef<{ name: string; amount: number; link: string }, any>[]
  >(
    () => [
      helper.accessor("name", {
        header: "Name",
        cell: (info) => {
          return <Link to={info.row.original.link}>{info.getValue()}</Link>;
        },
      }),
      helper.accessor("amount", {
        header: "Amount",
        cell: (info) => {
          const amount = info.getValue();
          if (!amount) {
            return;
          }
          return <span>{money.format(info.getValue().toFixed(2))}</span>;
        },
      }),
    ],
    [branches],
  );
  return <ReactTable columns={columns} data={branches} disableSearch />;
};

const StaffUtilisationTable = ({ users }: { users: StaffUtilisation[] }) => {
  const helper = createColumnHelper<StaffUtilisation>();

  // Calculate total chargeout
  const totalChargeout = users.reduce((sum, user) => sum + user.chargeout, 0);

  const columns = useMemo<ColumnDef<StaffUtilisation, any>[]>(
    () => [
      helper.accessor("user", {
        header: "User",
      }),
      helper.accessor("title", {
        header: "Title",
        cell: (info) => {
          return <Link to={info.row.original.link}>{info.getValue()}</Link>;
        },
      }),

      helper.accessor("utilisation", {
        header: "Utilisation",
        cell: (info) => {
          return <span>{percentage.format(info.getValue())}</span>;
        },
      }),
      helper.accessor("chargeout_rate", {
        header: "Chargeout Rate",
        cell: (info) => {
          return <span>{money.format(info.getValue().toFixed(2))}</span>;
        },
      }),
      helper.accessor("chargeout", {
        header: "Chargeout",
        cell: (info) => {
          return <span>{money.format(info.getValue().toFixed(2))}</span>;
        },
        footer: () => <span>{money.format(totalChargeout.toFixed(2))}</span>,
      }),
    ],
    [helper, totalChargeout],
  );

  return <ReactTable columns={columns} data={users} disableSearch />;
};

export default RevenueBudgetTable;
