import { useEffect } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import { deleteCPIR } from "../../actions/CPIRActions";
import CPIR_SUBJECT_TYPES from "../../data/cpir_subject_types";
import useApi from "../api/useApi";
import SelectInput from "../form/SelectInput";
import FileInput from "../upload/FileInput";

import renderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import RiskMatrix from "./RiskMatrix";
import dayjs from "dayjs";
import RenderField from "../utils/renderField";

const CpirParentForm = (props: any) => {
  const {
    likelihood,
    severity,
    initialValues,
    confetti,
    change,
    children,
    typeField,
  } = props;

  const {
    data: subjectTypes,
    setUrl: setSubjectTypeUrl,
    setData: setSubjectTypes,
  } = useApi(
    initialValues?.uuid
      ? `cpirs/subject-types?type=${encodeURIComponent(
          initialValues.subject_type,
        )}`
      : undefined,
  );

  const { data: cpirType, setUrl: setCpirTypeUrl, setData } = useApi();

  useEffect(() => {
    if (initialValues?.uuid) {
      setData(initialValues.cpir_type);
    }
  }, [initialValues]);

  const variableColumnWidth = initialValues ? "6" : "4";

  return (
    <div className="row align-items-end">
      <div
        className={`col-lg-${variableColumnWidth} form-group align-items-center`}
      >
        {typeField({ ...props, setCpirTypeUrl })}
      </div>

      {!initialValues && <div className="col-lg-4" />}

      <div
        className={`col-lg-${variableColumnWidth} form-group align-items-center`}
      >
        <Field
          required
          validate={required}
          order={false}
          name="cpir_category_id"
          label="Category"
          component={SelectInput}
          url="/cpir-categories"
          formatData={(data: { name: string; id: number; uuid: string }[]) => {
            return data.map((d) => {
              return {
                label: d.name,
                value: d.id,
              };
            });
          }}
        />
      </div>
      <div className="col-lg-12 form-group">
        <Field
          required
          validate={required}
          name="title"
          label="Brief Description of what Occurred"
          textarea
          component={renderField}
        />
      </div>
      {(cpirType?.risk_matrix_required || props.initialValues?.type) && (
        <Field
          component={RiskMatrix}
          name="matrix"
          change={change}
          likelihood={likelihood}
          severity={severity}
        />
      )}
      <div className="col-lg-6 form-group">
        <Field
          required
          validate={required}
          name="subject_type"
          label="Is this  addressed to a Branch, Group or Person?"
          component={SelectInput}
          options={CPIR_SUBJECT_TYPES}
          changeValue={(data: { value: string }) => {
            if (data?.value) {
              setSubjectTypeUrl(
                `cpirs/subject-types?type=${encodeURIComponent(data.value)}`,
              );
            }
            setSubjectTypes([]);
          }}
        />
        {/* {subjectTypeName && !this.state.loading && !typeIds.length && (
          <p className="tx-warning mt-2">
            Your organisation does not have any {subjectTypeName.label}s
            <br />
            You can ask an administrator to create them.
          </p>
        )} */}
      </div>
      <div className="col-lg-6 form-group">
        <Field
          required
          validate={required}
          name="subject_id"
          label="Which should this be assigned to?"
          component={SelectInput}
          options={subjectTypes ?? []}
        />
      </div>
      {children({ ...props, cpirType })}
      {/* <CustomForm customForm={cpirType?.custom_fields ?? []} {...props} /> */}
      {initialValues && (
        <div className="col-lg-12 form-group">
          <Field
            label="Closed Out (Yes/No)"
            name="closed_out"
            component={renderToggleInput}
          />
        </div>
      )}
      <div className="col-lg-6 form-group">
        <Field
          label="Confidential (Yes/No)"
          name="is_confidential"
          component={renderToggleInput}
        />
      </div>

      <div className="col-6 form-group">
        <Field
            component={RenderField}
            name="scheduled_finish_date"
            label="Scheduled Finish Date"
            type="date"
          />
      </div>

      {!initialValues && (
        <div className="col-12 form-group">
          <Field
            component={FileInput}
            name="document"
            label="Upload Document"
            customFileClassName="z-0"
          />
        </div>
      )}

      <div className="col-12">
        <p style={{ lineHeight: "42px", float: "left" }}>
          {props.submitting
            ? "Saving..."
            : props.initialValues
            ? `Last Updated ${dayjs(props.initialValues?.updated_at).format(
                "DD/MM/YYYY hh:mm A",
              )}`
            : ""}
        </p>
        <SubmitButton {...props} />
        {confetti}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any, { form }: { form: string }) => {
  const selector = formValueSelector(form);

  return {
    subject_type: selector(state, "subject_type"),
    further_action_required: selector(state, "further_action_required"),
    corrective_action_required: selector(state, "corrective_action_required"),
    preventative_action_required: selector(
      state,
      "preventative_action_required",
    ),
    verify_actions: selector(state, "verify_actions"),
    closed_out: selector(state, "closed_out"),
    likelihood: selector(state, "likelihood"),
    severity: selector(state, "severity"),
  };
};

export default connect(mapStateToProps, { deleteCPIR })(CpirParentForm);
