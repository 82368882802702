import { Field, InjectedFormProps, reduxForm } from "redux-form";
import RenderField from "../utils/renderField";
import { PerformanceReviewTemplate } from "./perfomanceReviewTypes";
import SelectInput from "../form/SelectInput";
import SubmitButton from "../utils/SubmitButton";
import FormErrorAlert from "../form/FormErrorAlert";

const PerformanceReviewTemplateForm = (
  props: InjectedFormProps<PerformanceReviewTemplate>,
) => {
  const { handleSubmit, error } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <FormErrorAlert error={error} />
        <div className="col-lg-12 form-group">
          <Field component={RenderField} name="name" label="Name" />
        </div>
        <div className="form-group col-lg-6">
          <Field
            component={SelectInput}
            options={[
              {
                label: "None",
                value: 0,
              },
              {
                label: "Monthly",
                value: 12,
              },
              {
                label: "Bi-Yearly",
                value: 6,
              },
              {
                label: "Triannually",
                value: 3,
              },
              {
                label: "Quarterly",
                value: 4,
              },
            ]}
            name="number_of_check_ins"
            label="Check-in Frequency"
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={RenderField}
            name="start_date"
            label="Start Date"
            type="date"
          />
        </div>
        <div className="col-lg-12 form-group">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<PerformanceReviewTemplate>({});

export default form(PerformanceReviewTemplateForm);
