import { useEffect, useState } from "react";
import { useAuth } from "../../context/auth-context";
import HeaderPage from "../header/HeaderPage";
import { TimesheetProvider } from "../../context/timesheet-context";
import { modifyDate } from "../../actions/timesheetActions";
import { connect } from "react-redux";
import TimesheetDayPicker from "./TimesheetDayPicker";
import dayjs from "dayjs";
import { ReactComponent as Time } from "../../svgs/time.svg";
import { Button } from "reactstrap";
import useApi from "../api/useApi";
import { AuthUser } from "../user/userTypes";
import errorSwal from "../utils/errorSwal";
import { useUserTimesheet } from "../../context/timesheet-context";
import { BreakType } from "./timesheetTypes";
import { NewTimesheetPage } from "./Timesheets";


const ForceTimesheets = ({
  modifyDate,
  selectedDay,
}: {
  modifyDate: Function;
  selectedDay: string;
}) => {
  const { user, setUser } = useAuth();

  const [showProductivity, setShowProductivity] = useState(false);

  const { takeAction } = useApi();

  const handleDayClick = (day: any, { selected }: any) => {
    return selected ? undefined : modifyDate(user, day);
  };

  return (
    <>
      <HeaderPage titlePage="Add Timesheet" crumbs={[]} />
      <TimesheetProvider>
        <div className="row z-10">
          <div className="col-lg-8">
            <div
              className="bg-teal money p-3 rounded-lg shadow-sm mb-5 d-flex position-relative"
              style={{ color: "#e8fff4" }}
            >
              <div>
                <p className="mb-3 tx-18">Timesheet Required</p>
                <p className="mb-2 tx-12">
                  {user?.active_organisation.display_name} requires you to
                  submit timesheets after{" "}
                  {user?.active_organisation.timesheet_required_time} days.
                </p>
                <p className="mb-2 tx-12">
                  Missing Timesheet Dates:{" "}
                  {user?.missing_timesheet
                    .map((date: any) => dayjs(date).format("DD/MM/YYYY")) // Format as readable date
                    .join(', ')}
                </p>
                {user?.timesheet_required === "warning" && (
                  <>
                    <p className="tx-12 mb-1">
                      You may continue by clicking below one time.
                    </p>
                    <p className="tx-12 mb-0">
                      The next time you log in will be required to submit a
                      timesheet.
                    </p>
                    <Button
                      onClick={() => {
                        takeAction("store", `timesheets/force`)
                          .then(({ data }: { data: { data: AuthUser } }) => {
                            setUser(data.data);
                          })
                          .catch(errorSwal);
                      }}
                      color="warning"
                      className="mt-3"
                      size="sm"
                    >
                      continue
                    </Button>
                  </>
                )}
              </div>
              <div
                className="ms-auto pe-3 d-none d-sm-block position-absolute r-0"
                style={{
                  bottom: "-23px",
                }}
              >
                <Time width="120px" height="120px" className="drop-shadow" />
              </div>
            </div>
            {!showProductivity && (
              <p className="text-dark">
                {dayjs(selectedDay).format("dddd, MMMM D, YYYY")}
              </p>
            )}
            <TimesheetPage
              showProductivity={showProductivity}
              setShowProductivity={setShowProductivity}
              userId={user?.id}
              userUuid={user?.uuid}
              selectedDay={selectedDay}
              addTimesheet
            />
          </div>
          <div className="col-lg-4 space-y-5 d-none d-lg-block">
            <div className="bg-white d-flex align-items-center justify-content-center rounded-lg border shadow-sm">
              <TimesheetDayPicker user={user} handleDayClick={handleDayClick} />
            </div>
          </div>
        </div>
      </TimesheetProvider>
    </>
  );
};

export const TimesheetPage = (props: any) => {
  const { showProductivity, setShowProductivity, addTimesheet, userId, userUuid, selectedDay } = props;
  const formattedSelected = dayjs(selectedDay).format("YYYY-MM-DD");
  const { productivity, setProductivity } = useUserTimesheet();
  const { takeAction } = useApi();

  useEffect(() => {
    addStaffSchedules(userUuid, selectedDay);
  }, [formattedSelected]);

  function addStaffSchedules(userUuid: any, selectedDate: any) {
    takeAction("show", `staff-schedules/${userUuid}`, {
      params: {
        date: dayjs(selectedDate).format("YYYY-MM-DD")
      }
    }).then(({ data }: { data: any }) => {
      prepScheduledProductivity(data.data);
    });
  }
  
  function prepScheduledProductivity(schedules: any) {
    const formattedSchedules = schedules.map((event: any) => ({
      id: event.uuid,
      uuid: event.uuid,
      title: event.title,
      start: dayjs(event.start_time).toDate(),
      start_time_date: dayjs(event.start_time).format("YYYY-MM-DD"),
      start_time_time: dayjs(event.start_time).format("HH:mm:ss"),
      end: dayjs(event.finish_time).toDate(),
      finish_time_date: dayjs(event.finish_time).format("YYYY-MM-DD"),
      finish_time_time: dayjs(event.finish_time).format("HH:mm:ss"),
      comments: event.comments,
      job_id: event.job_id,
      project: event.job.project_id,
      is_job: event.is_job,
      pulled_from_schedule: true,
      break_type: null,
      allowances: event.allowances,
    }));

    setProductivity(formattedSchedules);
  }
  
  if (!addTimesheet) {
    return null;
  }

  return (
    <NewTimesheetPage
        showProductivity={showProductivity}
        setShowProductivity={setShowProductivity}
        userId={userUuid}
        initialValues={{
          id: userId,
        }}
        selectedDay={selectedDay}
        addTimesheet
      />
  );
};

const mapStateToProps = (state: any) => ({
  selectedDay: state.timesheets.selectedDay,
});

export default connect(mapStateToProps, {
  modifyDate,
})(ForceTimesheets);
