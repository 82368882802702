import { Field, reduxForm, FieldArray } from "redux-form";

import renderField from "../utils/renderField";
import SelectInputAsync from "../utils/SelectInputAsync";
import required from "../utils/required";
import NumberField from "../utils/NumberField";
import SubmitButton from "../utils/SubmitButton";
import RequiredCompetencies from "./RequiredCompetencies";
import { money, percentage } from "../form/formatters";
import renderToggleInput from "../utils/renderToggleInput";
import SelectInput from "../form/SelectInput";
import FormErrorAlert from "../form/FormErrorAlert";

const FormPosition = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <div className="row">
        <FormErrorAlert {...props} />
        <div className="col-lg-4 form-group">
          <Field
            name="name"
            type="text"
            component={renderField}
            label="Position"
            required
            validate={required}
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            name="division_id"
            url="/organisationdivisions/divisions"
            label="Division"
            component={SelectInputAsync}
            required
            validate={required}
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            name="administration_level"
            label="Access Level"
            url="/enums?enum=AdministrationLevels"
            component={SelectInputAsync}
            required
            validate={required}
            formatData={formatData}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={renderField}
            information="The percentage of time you would like this position to be doing billable work."
            name="utilisation_target"
            label="Target Utilisation"
            {...percentage}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={renderField}
            name="chargeout_rate"
            label="Chargeout Rate (per hour)"
            {...money}
          />
        </div>

        <div className="col-lg-4 form-group">
          <Field
            component={renderToggleInput}
            name="checks_productivity"
            label="Productivity Required?"
            information="Have this checked if you want people adding timesheets to this role to have at least as much productivity as hours on their timesheet."
          />
        </div>
        <NumberField
          label="Number"
          toggleClassName="col-lg-4"
          fieldClassName="col-lg-4"
          {...props}
        />

        <div className="col-lg-4 form-group">
          <Field
            component={SelectInput}
            name="permission_groups"
            url="permission-groups"
            label="Permission Groups"
            isClearable={false}
            isMulti
            formatData={(data) => {
              return data.map((d) => ({
                label: d.name,
                value: d.id,
              }));
            }}
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            component={renderToggleInput}
            name="check_mcm_required"
            label="MCM Required?"
            information="Have this checked if you want this position to require MCM."
          />
        </div>
        <div className="col-lg-6 form-group"></div>

        {props.showCompetencies && (
          <FieldArray
            component={RequiredCompetencies}
            name="required_competencies"
            {...props}
          />
        )}

        <div className={`form-group col-lg-12`}>
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const formatData = (data) => {
  return data.data
    .filter((d) => d.label !== "Initial")
    .map((d) => {
      return {
        ...d,
      };
    });
};

const form = reduxForm({ form: "Position" });

export default form(FormPosition);
