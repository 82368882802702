import HeaderPage from "../header/HeaderPage";
import Spinner from "../utils/Spinner";
import Edit from "./Edit";
import Tab from "../utils/tabs/Tab";
import TabList from "../utils/tabs/TabList";
import ProfileHeader from "./ProfileHeader";
import File from "./FilePage";
import RequiredCompetencies from "../requiredCompetencies";
import useApi from "../api/useApi";
import { useParams } from "react-router-dom";

const Profile = () => {
  const { uuid, page } = useParams();

  const {
    data: role,
    setData: setRole,
    loading,
  } = useApi(`/organisation-roles/${uuid}`, null, true);

  if (loading) {
    return <Spinner loading />;
  }

  return (
    <>
      <HeaderPage
        titlePage={role.name}
        crumbs={[
          { link: "organisations/settings/links", name: "Organisation" },
          { link: "organisation-roles", name: "Roles" },
          {
            link: `organisation-roles/${role.uuid}`,
            name: role.name,
            active: true,
          },
        ]}
      />
      <div className="row row-sm">
        <div className="col-lg-8">
          <ProfileHeader role={role} />
          <ShowPage role={role} setRole={setRole} />
        </div>
        <div className="col-lg-4 mg-t-20 mg-lg-t-0">
          <TabList>
            <Tab
              link={`/organisation-roles/${uuid}/details`}
              icon="fa fa-gift"
              active={page === "details"}
            >
              Details
            </Tab>
            <Tab
              link={`/organisation-roles/${uuid}/role-description`}
              icon="fa fa-gift"
              active={page === "role-description"}
            >
              Role Description
            </Tab>
            <Tab
              link={`/organisation-roles/${uuid}/required-competencies`}
              icon="fa fa-gift"
              active={page === "required-competencies"}
            >
              Required Competencies
            </Tab>
          </TabList>
        </div>
      </div>
    </>
  );
};

const ShowPage = ({ role, setRole }) => {
  const { page } = useParams();

  switch (page) {
    case "details":
      return <Edit setRole={setRole} initialValues={role} />;
    case "role-description":
      return <File role={role} setRole={setRole} />;
    case "required-competencies":
      return (
        <RequiredCompetencies
          model="App\Models\OrganisationRole"
          id={role.id}
        />
      );
    default:
      return null;
  }
};

export default Profile;
