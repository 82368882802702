import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import formError from "../utils/formError";
import CustomNotificationForm from "./CustomNotificationForm";
import Spinner from "../utils/Spinner";

const CustomNotificationProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const {
    takeAction,
    loading,
    data: customNotification,
  } = useApi(`custom-notifications/${uuid}`, null, true);

  //   useEffect(() => {
  //     takeAction("show", `custom-notifications/${uuid}`)
  //       .then(({ data }) => {
  //         setCustomNotification(data.data);
  //       })
  //       .catch((error) => {
  //         toast.error("Failed to load custom notification");
  //         console.error(error);
  //       });
  //   }, [uuid, takeAction]);

  const onSubmit = (values: any) => {
    return takeAction("update", `custom-notifications/${uuid}`, values)
      .then(() => {
        toast.success(`${values.name} updated successfully`);
      })
      .catch(formError);
  };

  if (loading && !customNotification) {
    return <Spinner loading={loading} />;
  }

  return (
    <>
      <HeaderPage
        titlePage={`Edit Custom Notification: ${customNotification.name}`}
        crumbs={[
          {
            name: "Custom Notifications",
            link: "/custom-notifications",
            active: false,
          },
          {
            name: customNotification.name,
            link: `/custom-notifications/${uuid}`,
            active: true,
          },
        ]}
      />
      <CustomNotificationForm
        initialValues={customNotification}
        onSubmit={onSubmit}
        form="EditCustomNotification"
      />
    </>
  );
};

export default CustomNotificationProfile;
