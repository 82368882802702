import { useMemo } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";
import { GrNext, GrPrevious } from "react-icons/gr";
import dayjs from "dayjs";
import ReactTable from "../../table/ReactTable";
import { ActualSpending } from "./budgetTypes";

interface ActualPurchasesModalProps {
  selectedMonth: string;
  selectedAccountId: string;
  setSelectedMonth: (month: string) => void;
  setSelectedAccountId: (accountId: string) => void;
  toggle: () => void;
  modal: boolean;
  actualSpending: ActualSpending;
}

const ActualPurchasesModal = ({
  selectedMonth,
  selectedAccountId,
  actualSpending,
  toggle,
  modal,
  setSelectedMonth,
  setSelectedAccountId,
}: ActualPurchasesModalProps) => {
  const columns = useMemo<
    ColumnDef<
      {
        name: string;
        link: string;
        amount: number;
        type: "purchase" | "journal";
      },
      any
    >[]
  >(
    () => [
      {
        header: "Type",
        accessorKey: "type",
        cell: (info: any) => {
          return (
            <span className="badge bg-info rounded-pill shadow-sm">
              {info.row.original.type === "purchase" ? "Purchase" : "Journal"}
            </span>
          );
        },
      },
      {
        header: "Name",
        accessorKey: "name",
        cell: (info: any) => {
          return <Link to={info.row.original.link}>{info.getValue()}</Link>;
        },
      },
      {
        header: "Supplier",
        accessorKey: "supplier.name",
        cell: (info: any) => {
          const purchase = info.row.original;

          if (purchase.type === "journal") {
            return "-";
          }

          if (!purchase.supplier?.name) {
            return purchase.supplier_name;
          }

          return (
            <Link to={purchase.supplier.link}>{purchase.supplier.name}</Link>
          );
        },
      },
      {
        header: "Project",
        accessorKey: "project.name",
        cell: (info: any) => {
          if (info.row.original.type === "journal") {
            return "-";
          }

          return (
            <Link to={info.row.original.project.link}>{info.getValue()}</Link>
          );
        },
      },
      {
        header: "Amount",
        accessorKey: "amount",
        cell: (info: any) => {
          return (
            <div className="text-end">
              ${(info.getValue() ?? 0).toLocaleString()}
            </div>
          );
        },
        footer: () => {
          return (
            <div className="text-end">
              $
              {(
                actualSpending[selectedMonth]?.accounts?.[selectedAccountId]
                  ?.total ?? 0
              ).toLocaleString()}
            </div>
          );
        },
      },
    ],
    [selectedMonth, selectedAccountId, actualSpending],
  );

  const monthData =
    actualSpending[selectedMonth]?.accounts?.[selectedAccountId];
  const accountName = monthData?.account?.name ?? "";

  const resources = [
    ...(monthData?.purchases ?? []),
    ...(monthData?.journals ?? []),
  ];

  return (
    <Modal className="wd-md-1000 mx-wd-800 w-95" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        Actual Purchases for {accountName} -{" "}
        {dayjs(selectedMonth).format("MMMM YYYY")}
      </ModalHeader>
      <ModalBody>
        <div className="d-flex justify-content-between mb-5">
          <Button
            color="link"
            className="p-0"
            onClick={() =>
              setSelectedMonth(
                dayjs(selectedMonth).subtract(1, "month").format("YYYY-MM"),
              )
            }
          >
            <GrPrevious size={20} />
          </Button>
          <p className="mb-0 text-dark tx-18">
            {dayjs(selectedMonth).format("MMMM YYYY")}
          </p>
          <Button
            color="link"
            className="p-0"
            onClick={() =>
              setSelectedMonth(
                dayjs(selectedMonth).add(1, "month").format("YYYY-MM"),
              )
            }
          >
            <GrNext size={20} />
          </Button>
        </div>
        <ReactTable columns={columns} data={resources} />
      </ModalBody>
    </Modal>
  );
};

export default ActualPurchasesModal;
