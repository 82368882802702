import React, { useState } from "react";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import useS3Uploader from "../hooks/useS3Uploader";
import FileInputDropzone from "../upload/FileInputDropzone";
import errorSwal from "../utils/errorSwal";
import FormModal from "../utils/FormModal";
import required from "../utils/required";

interface StandardDocumentModalProps {
  modal: boolean;
  toggle: () => void;
  whenSubmitted?: (res: any) => void;
  documentable_type: string;
  documentable_id: string | number;
  bucket: string;
  extraComponent?: React.ReactNode;
  acceptedFileTypes?: string;
}

const StandardDocumentModal = ({
  modal,
  toggle: toggleModal,
  whenSubmitted,
  documentable_type,
  documentable_id,
  bucket,
  extraComponent = null,
  acceptedFileTypes
}: StandardDocumentModalProps) => {
  const { upload, uploadPercent, setUploadPercent } = useS3Uploader();
  const { takeAction } = useApi();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const toggle = () => {
    setSelectedFile(null);
    toggleModal();
  };

  const onSubmit = () => {
    if (!selectedFile) {
      toast.error("Please select a file to upload");
      return;
    }

    const data = {
      documentable_type,
      documentable_id,
    };

    return upload(selectedFile, bucket, (documents: File[]) => {
      return takeAction("store", "documents/standard/single", {
        ...data,
        documents: [documents],
      })
        .then((res: any) => {
          toast.success("Document uploaded successfully");
          toggle();
          return res;
        })
        .then((res: any) => {
          setUploadPercent(0);
          if (whenSubmitted) whenSubmitted(res);
        })
        .catch(errorSwal);
    });
  };

  return (
    <FormModal
      toggle={toggle}
      modal={modal}
      title="Upload Document"
      onSubmit={onSubmit}
      form="StandardDocumentModal"
      extraComponent={extraComponent}
    >
      <FileInputDropzone
        acceptedFileTypes={acceptedFileTypes}
        onFileSelect={setSelectedFile}
        uploadPercent={uploadPercent}
        selectedFile={selectedFile}
      />
    </FormModal>
  );
};

export default StandardDocumentModal;
