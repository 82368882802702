export const formatAmount = (
  amount: number | string | undefined,
  type?: "revenue" | "cost_of_sales" | "operating_expenses" | "capex",
): string => {
  if (amount === undefined || amount === 0 || amount === "") return "";

  const numAmount = typeof amount === "string" ? parseFloat(amount) : amount;
  if (isNaN(numAmount)) return "";

  const absAmount = Math.abs(numAmount);

  // Cost of sales, operating expenses, and capex should always be shown in parentheses
  if (
    type === "cost_of_sales" ||
    type === "operating_expenses" ||
    type === "capex"
  ) {
    return `(${absAmount.toLocaleString()})`;
  }

  // For revenue, show positive numbers normally and negative numbers in parentheses
  if (type === "revenue") {
    return numAmount >= 0
      ? absAmount.toLocaleString()
      : `(${absAmount.toLocaleString()})`;
  }

  // Default case (backwards compatibility)
  return absAmount.toLocaleString();
};
