import { useMemo } from "react";
import { Button, Modal, ModalHeader, ModalBody, Badge } from "reactstrap";
import { Link } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";
import { GrNext, GrPrevious } from "react-icons/gr";
import dayjs from "dayjs";
import ReactTable from "../../table/ReactTable";
import SpreadPrice from "../../utils/SpreadPrice";
import Tabs from "../../tabs/Tabs";
import _ from "lodash";
import BottomLogo from "../../utils/BottomLogo";
import { money } from "../../form/formatters";

interface ActualRevenueInvoiceModalProps {
  selectedMonth: string;
  setSelectedMonth: (month: string) => void;
  toggle: () => void;
  modal: boolean;
  actualRevenue: Record<
    string,
    {
      total: number;
      invoices: {
        name: string;
        link: string;
        type: "invoice" | "journal";
        line_items: {
          total: number;
          branch: {
            name: string;
            link: string;
            id: string;
            number: string;
          };
        }[];
      }[];
      journals: {
        name: string;
        link: string;
        total: number;
        type: "invoice" | "journal";
        line_items: {
          total: number;
          branch: {
            name: string;
            link: string;
            id: string;
            number: string;
          };
        }[];
      }[];
    }
  >;
}

const ActualRevenueInvoiceModal = ({
  selectedMonth,
  actualRevenue,
  toggle,
  modal,
  setSelectedMonth,
}: ActualRevenueInvoiceModalProps) => {
  const columns = useMemo<
    ColumnDef<
      {
        name: string;
        link: string;
      },
      any
    >[]
  >(
    () => [
      {
        header: "Type",
        accessorKey: "type",
        cell: (info: any) => {
          return (
            <span className="badge bg-info rounded-pill shadow-sm">
              {info.row.original.type === "invoice" ? "Invoice" : "Journal"}
            </span>
          );
        },
      },
      {
        header: "Name",
        accessorKey: "name",
        cell: (info: any) => {
          return <Link to={info.row.original.link}>{info.getValue()}</Link>;
        },
      },
      {
        header: "Project",
        accessorKey: "project.name",
        cell: (info: any) => {
          if (info.row.original.type !== "invoice") {
            return null;
          }

          return (
            <Link to={info.row.original.project.link}>{info.getValue()}</Link>
          );
        },
      },
      {
        header: "Client",
        accessorKey: "client.name",
        cell: (info: any) => {
          if (info.row.original.type !== "invoice") {
            return null;
          }

          return (
            <Link to={info.row.original.client.link}>{info.getValue()}</Link>
          );
        },
      },
      {
        header: "Total",
        accessorKey: "total",
        cell: (info: any) => {
          return <SpreadPrice price={info.getValue() ?? 0} />;
        },
        footer: () => {
          return <SpreadPrice price={actualRevenue[selectedMonth]?.total} />;
        },
      },
    ],
    [selectedMonth, actualRevenue],
  );

  const revenue = [
    ...(actualRevenue[selectedMonth]?.invoices ?? []),
    ...(actualRevenue[selectedMonth]?.journals ?? []),
  ];

  const branches = _.groupBy(
    revenue.flatMap((item) => item.line_items),
    "branch.id",
  );

  return (
    <Modal className="wd-md-1000 mx-wd-800 w-95" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        Actual Revenue for {dayjs(selectedMonth).format("MMMM YYYY")}
      </ModalHeader>
      <ModalBody>
        <div className="d-flex justify-content-between mb-5">
          <Button
            color="link"
            className="p-0"
            onClick={() =>
              setSelectedMonth(
                dayjs(selectedMonth).subtract(1, "month").format("YYYY-MM"),
              )
            }
          >
            <GrPrevious size={20} />
          </Button>
          <p className="mb-0 text-dark tx-18">
            {dayjs(selectedMonth).format("MMMM YYYY")}
          </p>
          <Button
            color="link"
            className="p-0"
            onClick={() =>
              setSelectedMonth(
                dayjs(selectedMonth).add(1, "month").format("YYYY-MM"),
              )
            }
          >
            <GrNext size={20} />
          </Button>
        </div>

        {Object.values(branches).length > 1 ? (
          <Tabs
            tabs={[
              {
                title: "Branch Breakdown",
                //@ts-ignore
                component: <BranchBreakdown branches={branches} />,
              },
              {
                title: "Items",
                component: (
                  <ReactTable
                    columns={columns}
                    data={[
                      ...(actualRevenue[selectedMonth]?.invoices ?? []),
                      ...(actualRevenue[selectedMonth]?.journals ?? []),
                    ]}
                  />
                ),
              },
            ]}
          />
        ) : (
          <ReactTable
            columns={columns}
            data={[
              ...(actualRevenue[selectedMonth]?.invoices ?? []),
              ...(actualRevenue[selectedMonth]?.journals ?? []),
            ]}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

const BranchBreakdown = ({
  branches,
}: {
  branches: Record<
    number,
    {
      branch: {
        name: string;
        link: string;
        id: number;
        number: string;
      };
      total: number;
    }[]
  >;
}) => {
  return (
    <div className="space-y-3">
      {Object.values(branches).map((branches) => {
        const branch = branches[0].branch;

        const total = branches.reduce((acc, branch) => acc + branch.total, 0);

        return (
          <div key={branch.id}>
            <Link to={branch.link}>
              <div className="rounded-lg border p-3 justify-content-between position-relative d-flex align-items-center parent-hover-shift shadow-sm bg-white">
                <div className="d-flex w-100 align-items-center">
                  <div className="">
                    <p className="mb-0 text-dark no-wrap">{branch.name}</p>
                    <p className="mb-0 text-secondary tx-12">{branch.number}</p>
                  </div>
                  <Badge
                    className="ms-auto z-10 rounded-pill"
                    color={total > 0 ? "success" : "danger"}
                  >
                    {money.format(total.toFixed(2))}
                  </Badge>
                </div>
                <BottomLogo size={20} left={250} bottom={15} />
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default ActualRevenueInvoiceModal;
