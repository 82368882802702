import React, { useState } from "react";
import { RiAddFill } from "react-icons/ri";
import { UseInfiniteQueryResult } from "react-query";
import { Link } from "react-router-dom";
import useFilter, { IFilter } from "../hooks/useFilter";
import useObserver from "../hooks/useObserver";
import usePagination from "../hooks/usePagination";
import Empty from "../utils/Empty";
import FilterDropdown from "../utils/FilterDropdown";
import flattenInfinitePages from "../utils/flattenInfinitePages";
import CustomScaleLoader from "../utils/scaleLoader";
import { SortingState } from "@tanstack/react-table";
import { ISorter } from "../hooks/useSort";
import { LaravelPaginatedResponse } from "../utils/utilTypes";

export interface FilterProps<StringLiteral, ValueType> {
  filters: IFilter<StringLiteral, ValueType>[];
  toggleFilter: (filterName: StringLiteral, optionValue: any) => void;
}

interface PaginatedListProps<StringLiteral, ValueType, ItemType> {
  indexHook: (
    search?: string,
    ...args: any[]
  ) => UseInfiniteQueryResult<LaravelPaginatedResponse<any>>;
  originalFilters: IFilter<StringLiteral, ValueType>[];
  itemCard?: React.FC<{ item: ItemType }>;
  list?: React.FC<{
    data?: ItemType[];
    pages: any[];
    pageParams: any[];
    setSorting?: (sorting: SortingState) => void;
    sorting?: SortingState;
  }>;
  addLink?: string;
  indexHookArguments?: any[];
  extraButtons?: React.FC<{
    searchQuery?: string;
    sorting?: SortingState;
    setSorting?: Function;
  }> | null;
  listName?: string;
  showFilters?: boolean;
  sortDropdown?: ISorter<StringLiteral>[];
  defaultFilters?: string;
}

const PaginatedList = <StringLiteral, ValueType, ItemType>({
  indexHook,
  indexHookArguments = [],
  originalFilters = [],
  itemCard,
  list,
  addLink,
  extraButtons = null,
  listName,
  showFilters = true,
  defaultFilters = "",
}: PaginatedListProps<StringLiteral, ValueType, ItemType>) => {
  const { searchQuery, onSearch } = usePagination();

  const {
    stringified,
    filters,
    toggleFilter,
    filterCount,
    activeFilters,
    setFilters,
  } = useFilter<StringLiteral, ValueType>(originalFilters, listName);

  const [sorting, setSorting] = useState<SortingState>();

  const sortingString =
    sorting && sorting.length > 0
      ? `&sort=${sorting[0].desc ? "-" : ""}${sorting[0].id}`
      : "";

  const { data, isFetchingNextPage, hasNextPage, fetchNextPage, isFetching } =
    indexHook(
      searchQuery + stringified + sortingString + defaultFilters,
      ...indexHookArguments,
    );

  const intersection = useObserver(
    () => !isFetchingNextPage && hasNextPage && fetchNextPage(),
  );

  const flattenedData = flattenInfinitePages<ItemType>(data);

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div className="search-box shadow-sm flex-grow-1 d-flex">
          <input
            autoComplete="off"
            placeholder="Search..."
            className="form-control w-100"
            type="search"
            style={{ zIndex: 1 }}
            onChange={onSearch}
          />
          <button
            className="btn btn-primary ms-auto"
            type="button"
            style={{ height: "40px", width: "40px" }}
            title="Search"
          >
            <i
              className={`fa fa-${isFetching ? "spinner fa-spin" : "search"}`}
            />
          </button>
        </div>
        {originalFilters.length !== 0 && showFilters && (
          <FilterDropdown
            filters={filters}
            toggleFilter={toggleFilter}
            filterCount={filterCount}
          />
        )}
        {addLink && (
          <Link to={addLink}>
            <RiAddFill aria-label="Add New Resource" className="tx-24 ms-1" />
          </Link>
        )}
        {extraButtons?.({
          searchQuery: searchQuery + stringified,
          sorting,
          setSorting,
        })}
      </div>
      {data?.pages && (
        <p className="mb-0">{data?.pages?.[0]?.meta?.total} {data?.pages?.[0]?.meta?.total === 1 ? 'record' : 'records'} found</p>
      )}

      {flattenedData?.length === 0 && (
        <div className="mt-5">
          <Empty width="25%" height="50%">
            {activeFilters.length > 0 ? (
              <div className="d-block text-center">
                <p className="tx-inverse tx-16 fw-bolder mb-2">
                  No Results found for the following filters:
                </p>
                <div className="mb-3">
                  {activeFilters.map((filter, index) => {
                    return (
                      <p key={index} className="mb-0">
                        <span className="tx-inverse">
                          {filter.header_label}
                        </span>{" "}
                        - {filter.label}
                      </p>
                    );
                  })}
                </div>
              </div>
            ) : (
              <p className="tx-inverse fw-bolder">No Results found</p>
            )}
          </Empty>
        </div>
      )}

      {flattenedData?.length !== 0 ? (
        <div className="row row-sm">
          {itemCard
            ? flattenedData?.map((item) => {
                return itemCard({ item });
              })
            : list
            ? list({
                data: flattenedData,
                pages: data?.pages ?? [],
                pageParams: data?.pageParams ?? [],
                setSorting,
                sorting,
              })
            : null}
        </div>
      ) : null}

      {
        //TODO fix the margin top issue
      }
      <div id="intersection" ref={intersection} />
      {isFetchingNextPage && (
        <CustomScaleLoader>Fetching More Data...</CustomScaleLoader>
      )}
    </>
  );
};

export default PaginatedList;
