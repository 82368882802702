// Sort settings values to place booleans first

const sortValues = (organisation) => {
  const boolValues = organisation.settings.filter(
    (setting) => setting.form_details?.type === "boolean",
  );
  const otherValues = organisation.settings.filter(
    (setting) => setting.form_details?.type !== "boolean",
  );
  organisation.settings = [...boolValues, ...otherValues];
  return organisation;
};

export default sortValues;
