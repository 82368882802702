import useApi from "../api/useApi";
import { useParams } from "react-router-dom";
import SideList from "../comments/SideList";
import Spinner from "../utils/Spinner";
import useModal from "../hooks/useModal";
import CancelFormModal from "./CancelFormModal";
import { IUseApi } from "../api/apiTypes";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import JobStatus from "../../enums/JobStatus";
import Form from "./Form";
import dayjs from "dayjs";
import AddModal from "../comments/AddModal";
import { useState } from "react";
import { Comment } from "../comments/commentTypes";

const Overview = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const { toggle: toggleCancel, modal: cancelModal } = useModal();
  const { toggle: toggleReopen, modal: reopenModal } = useModal();
  const { takeAction, loading: cancelling }: IUseApi = useApi();
  const [refresh, setRefresh] = useState(false);

  const {
    loading,
    data: job,
    setData: setJob,
    setUrl,
    refreshData,
  } = useApi(`support-tickets/${uuid}`, "", true);

  const onSubmit = (values : any) => {
    return takeAction(
      "update",
      `support-tickets/${uuid}`,
      values,
    )
      .then(({ data }: { data: { data: any } }) => {
        toast.success(`Support Tickets Updated`);
      })
      .catch(errorSwal);
  };

  const cancel = (values: any) => {
    return takeAction("update", `support-tickets/${uuid}/cancel`, {
      ...values
    })
      .then(({ data }) => {
        setJob(data.data);
        toast.success("Support Ticket cancelled successfully");
        toggleCancel();
      })
      .catch(errorSwal);
  };

  const reopen = (comment: Comment) => {
    return takeAction("store", `support-tickets/${uuid}/reopen`)
      .then(({ data }) => {
        setJob(data.data);
        toast.success("Support Ticket reopened successfully");
        setRefresh(!refresh);
        toggleReopen();
      })
      .catch(errorSwal);
  };

  if (loading) return <Spinner loading />;

  return (
    <div className="row mg-t-20">
      <div className="col-8">
        <div className="row">
          <div className="col-12"><label className="section-title mt-3 ">Support Ticket Details</label><hr className="w-100" /></div>
          <div className="col-lg-12 form-group">
            <div className="d-flex space-x-1 align-items-start">
              <label className="form-control-label tx-inverse tx-semibold">Name</label>
            </div>
            <div className="readOnly">{job.name}</div>
          </div>

          <div className="col-lg-12 form-group">
            <div className="d-flex space-x-1 align-items-start">
              <label className="form-control-label tx-inverse tx-semibold">Job Manager</label>
            </div>
            <div className="readOnly">{job.manager?.name}</div>
          </div>

          <div className="col-lg-12 form-group">
            <div className="d-flex space-x-1 align-items-start">
              <label className="form-control-label tx-inverse tx-semibold">Expected Due Date</label>
            </div>
            <div className="readOnly">{dayjs(job.scheduled_finish_date).format("DD/MM/YYYY")}</div>
          </div>
          
          <div className="col-lg-12 form-group">
            <div className="d-flex space-x-1 align-items-start">
              <label className="form-control-label tx-inverse tx-semibold">Status</label>
            </div>
            <div className="readOnly">{job.status_badge?.label}</div>
          </div>
        </div>
        <Form
          initialValues={job}
          job={job}
          onSubmit={onSubmit}
          form="EditCustomJobJobCustomFields"
        />
      </div>
      <div className="col-4">
          <div>
            <SideList
              commentableType="App\Models\ProjectJob"
              commentableId={job.id}
              refresh={refresh}
            />
            {job.status !== JobStatus.Completed && job.status !== JobStatus.Cancelled && 
              <button type="button" onClick={toggleCancel} className="mt-3 btn btn-outline-danger d-block w-100">
                Cancel Support Request
              </button>
            }
            {(job.status === JobStatus.Completed || job.status === JobStatus.Cancelled) && 
              <button type="button" onClick={toggleReopen} className="mt-3 btn btn-outline-primary d-block w-100">
                Reopen Support Request
              </button>
            }
          </div>
      </div>
      
      <AddModal
          commentableType="App\Models\ProjectJob"
          commentableId={job.id}
          toggle={toggleReopen}
          modal={reopenModal}
          title="Reopen Support Request"
          save="Reopen support ticket"
          saving="Reopening support ticket..."
          onSubmit={reopen}
        />

      <CancelFormModal
        modal={cancelModal}
        toggle={toggleCancel}
        onSubmit={cancel}
        title="Cancel Support Ticket"
        submitLabel="Cancel"
        reasonLabel="Reason for cancellation"
        form="CancelSupportTicket"
      />
    </div>
  );
};

export default Overview;
