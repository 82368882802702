import { Link, useLocation, useParams } from "react-router-dom";
import useApi from "../api/useApi";
import { useState, useEffect, useMemo } from "react";
import dayjs from "dayjs";
import HeaderPage from "../header/HeaderPage";
import { IUseApi, IUseApiWithData } from "../api/apiTypes";
import LoadingOverlay from "../utils/LoadingOverlay";
import { GrNext, GrPrevious } from "react-icons/gr";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";
import deleteSwal from "../utils/deleteSwal";
import ExportBudgetCSV from "./ExportBudgetCSV";
import { BudgetFilter as BudgetFilterType } from "./BudgetFilter";
import { BudgetItem, BudgetRow, SelectOption } from "./types";
import { Branch } from "./branchTypes";
import BudgetSettingModal from "./BudgetSettingModal";
import BudgetTable from "./budget/BudgetTable";
import RevenueBudgetTable from "./budget/RevenueBudgetTable";
import TextButton from "../utils/TextButton";
import { formatAmount } from "./utils/budgetFormatters";
import { useAuth } from "../../context/auth-context";
import { GroupType } from "../user/userTypes";
import { Badge, PopoverBody, Spinner } from "reactstrap";
import { UncontrolledPopover } from "reactstrap";
import { PopoverHeader } from "reactstrap";
import { createColumnHelper } from "@tanstack/react-table";
import { ColumnDef } from "@tanstack/react-table";
import ReactTable from "../table/ReactTable";
import BudgetSummary from "./budget/BudgetSummary";
import BranchBudgetLockButton from "./budget/BranchBudgetLockButton";
import ApprovalButton from "../approvals/ApprovalButton";
import { ApprovalOptions } from "../approvals/approvalTypes";
import { FiUnlock } from "react-icons/fi";
import useModal from "../hooks/useModal";
import { ActualSpending } from "./budget/budgetTypes";
import { ChartOfAccount } from "../chartOfAccounts/chartOfAccountTypes";
import { AccountType } from "../chartOfAccounts/chartOfAccountTypes";
import {
  getMonthColumns,
  getBudgetRows,
  getFinancialYearName,
  filterActualSpendingByAccountType,
  getFilteredBudgetRows,
  calculateMonthlyTotals,
} from "./budget/budgetUtils";
import {
  createMainRevenueRow,
  createAdjustmentRevenueRow,
  createSubcontractorMarkupRows,
  calculateRevenueTotals,
} from "./budget/revenueUtils";

const columnStyles = {
  account: { width: 200 },
  accountEditing: { minWidth: 300 },
  month: { width: 150 },
} as const;

const BranchBudgets = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const [filter, setFilter] = useState<BudgetFilterType>("all");
  const { user } = useAuth();

  useEffect(() => {
    Array.from(document.getElementsByClassName("container")).forEach(
      (container) => {
        container.classList.remove("container");
        container.classList.add("container-large");
      },
    );

    return () => {
      Array.from(document.getElementsByClassName("container-large")).forEach(
        (container) => {
          container.classList.remove("container-large");
          container.classList.add("container");
        },
      );
    };
  }, [user?.navigation]);

  const {
    data: branch,
    setData: setBranch,
    refreshData: refreshBranchData,
    setUrl: setBranchUrl,
  }: IUseApi<Branch> = useApi(`organisation-branches/${uuid}`, null);

  useEffect(() => {
    if (!branch?.recurring_revenue_percentage) {
      setFilter("all");
    }
  }, [branch?.recurring_revenue_percentage]);

  const { toggle: toggleActuals, modal: showActuals } = useModal(true);

  const [financialYear, setFinancialYear] = useState<[string, string]>([
    dayjs().month() >= 6
      ? dayjs().startOf("year").month(6).format("YYYY-MM-DD")
      : dayjs()
          .subtract(1, "year")
          .startOf("year")
          .month(6)
          .format("YYYY-MM-DD"),
    dayjs().month() >= 6
      ? dayjs()
          .add(1, "year")
          .startOf("year")
          .month(5)
          .endOf("month")
          .format("YYYY-MM-DD")
      : dayjs().startOf("year").month(5).endOf("month").format("YYYY-MM-DD"),
  ]);

  const [period, setPeriod] = useState<[string, string]>([
    financialYear[0],
    financialYear[1],
  ]);

  useEffect(() => {
    setPeriod([financialYear[0], financialYear[1]]);
  }, [financialYear]);

  const [budgetRows, setBudgetRows] = useState<BudgetRow[]>([]);

  const { takeAction }: IUseApi = useApi();

  const budgetLock = branch?.budget_locks.find(
    (lock) =>
      dayjs(lock.date).format("YYYY-MM-DD") ===
      dayjs(financialYear[0]).format("YYYY-MM-DD"),
  );

  const { data: allBranches }: IUseApiWithData<Branch[]> = useApi(
    user?.is_admin ? "organisation-branches" : undefined,
    [],
  );

  const {
    data: budgetItems,
    setUrl: setBudgetUrl,
    loading: loadingBudgetItems,
    refreshData: refreshBudgetItems,
    setData: setBudgetItems,
  }: IUseApiWithData<BudgetItem[]> = useApi(
    `branches/${uuid}/budget-items?filter[financial_year][]=${period[0]}&filter[financial_year][]=${period[1]}`,
    [],
  );

  const {
    data: actualSpending,
    setUrl: setActualSpendingUrl,
  }: IUseApiWithData<ActualSpending> = useApi(
    `branches/${uuid}/actual-spending?start_date=${period[0]}&end_date=${period[1]}`,
    {},
  );

  const { data: accounts }: IUseApiWithData<ChartOfAccount[]> = useApi(
    "chart-of-accounts",
    [],
  );

  useEffect(() => {
    setBudgetUrl(
      `branches/${uuid}/budget-items?filter[financial_year][]=${period[0]}&filter[financial_year][]=${period[1]}`,
    );

    setBranchUrl(`organisation-branches/${uuid}`);
    setActualSpendingUrl(
      `branches/${uuid}/actual-spending?start_date=${period[0]}&end_date=${period[1]}`,
    );
  }, [period, uuid]);

  useEffect(() => {
    if (!budgetItems) return;
    setBudgetRows(getBudgetRows(budgetItems, period));
  }, [budgetItems, accounts]);

  const months = getMonthColumns(period);

  const handleAddRow = (
    type: "cost_of_sales" | "operating_expenses" | "capex",
  ) => {
    if (budgetLock?.locked_at) {
      toast.warning("Budget is locked");
      return;
    }

    const newRow: BudgetRow = {
      //@ts-ignore
      amounts: months.map((month) => ({
        account_id: "",
        month: `${month}-01`,
        amount: "",
        type,
      })),
      account: null,
    };

    setBudgetRows([...budgetRows, newRow]);
  };

  const handleDeleteRow = async (row: BudgetRow) => {
    if (!row.account) return;

    deleteSwal(`${row.account.code} - ${row.account.name}`)
      .then(() =>
        takeAction("store", `branches/${uuid}/budget-items/account`, {
          account_id: row.account?.id,
          period,
        }),
      )
      .then(() => {
        toast.success("Row deleted successfully");
        refreshBudgetItems();
      })
      .catch((err) => {
        errorSwal(err);
      });
  };

  const handleAmountBlur = async (budgetItem: BudgetItem) => {
    if (budgetLock?.locked_at) {
      toast.warning("Budget is locked");
      return;
    }

    if (!budgetItem.amount && !budgetItem.uuid) {
      return;
    }

    // Remove commas and convert to number
    const cleanedAmount = budgetItem.amount.toString().replace(/,/g, "");

    if (cleanedAmount !== "" && isNaN(parseFloat(cleanedAmount))) {
      toast.error("Invalid amount");

      return;
    }

    budgetItem.amount = cleanedAmount !== "" ? parseFloat(cleanedAmount) : 0;

    const actionToTake = budgetItem.uuid ? "update" : "store";

    const url = budgetItem.uuid
      ? `budget-items/${budgetItem.uuid}`
      : `branches/${uuid}/budget-items`;

    const currentBudgetItems = budgetItems;

    const newBudgetItems = budgetItem.uuid
      ? currentBudgetItems.map((item) =>
          item.uuid === budgetItem.uuid ? budgetItem : item,
        )
      : [...currentBudgetItems, budgetItem];

    setBudgetItems(newBudgetItems);

    try {
      const { data } = await takeAction(actionToTake, url, budgetItem);
      const updatedBudgetItems = budgetItem.uuid
        ? currentBudgetItems.map((item) => {
            return item.uuid === budgetItem.uuid ? data.data : item;
          })
        : [...currentBudgetItems, data.data];

      setBudgetItems(updatedBudgetItems);
    } catch (err) {
      setBudgetItems(currentBudgetItems);
      errorSwal(err);
    }
  };

  const handleAccountSelect = async (
    rowIndex: number,
    option: SelectOption | null,
    type: "cost_of_sales" | "operating_expenses" | "capex",
  ) => {
    if (budgetLock?.locked_at) {
      toast.warning("Budget is locked");
      return;
    }

    const newRows = [...budgetRows];

    // Get the filtered rows for this type
    const typeRows = getFilteredBudgetRows(budgetRows, type);
    const currentRow = typeRows[rowIndex];

    const selectedAccount = option
      ? accounts.find((a) => a.id === parseInt(option.value, 10)) || null
      : null;

    const oldAccount = currentRow.account;

    // Find the actual index in the full budgetRows array
    const actualRowIndex = budgetRows.findIndex(
      (row) =>
        row.amounts[0]?.type === type && row.account?.id === oldAccount?.id,
    );

    if (actualRowIndex !== -1) {
      newRows[actualRowIndex] = {
        ...currentRow,
        account: selectedAccount,
        amounts: currentRow.amounts.map((amount) => ({
          ...amount,
          account_id: selectedAccount?.id ? String(selectedAccount.id) : "",
          account: selectedAccount || undefined,
        })),
      };
    }

    setBudgetRows(newRows);

    // Only update if:
    // 1. We have a selected account
    // 2. We have an old account
    // 3. The row exists in budgetItems (has at least one amount with a UUID)
    const hasExistingItems = currentRow.amounts.some((amount) => amount.uuid);

    if (selectedAccount && oldAccount && hasExistingItems) {
      try {
        await takeAction("update", `branches/${uuid}/budget-items/account`, {
          old_account_id: oldAccount.id,
          new_account_id: selectedAccount.id,
          period: period,
        });

        refreshBudgetItems();
        toast.success("Account updated successfully");
      } catch (err) {
        errorSwal(err);
      }
    }
  };

  // Get rows for each type
  const costOfSalesRows = getFilteredBudgetRows(budgetRows, "cost_of_sales");
  const operatingExpensesRows = getFilteredBudgetRows(
    budgetRows,
    "operating_expenses",
  );
  const capexRows = getFilteredBudgetRows(budgetRows, "capex");

  // Get main and adjustment revenue rows
  const mainRevenueRow = createMainRevenueRow(budgetItems, period);
  const adjustmentRevenueRow = createAdjustmentRevenueRow(budgetItems, period);

  // Calculate subcontractor markup rows
  const subcontractorMarkupRows = createSubcontractorMarkupRows(
    costOfSalesRows,
    period,
  );

  // Calculate totals for each type
  const revenueTotals = calculateRevenueTotals(
    mainRevenueRow,
    adjustmentRevenueRow,
    subcontractorMarkupRows,
  );
  const costOfSalesTotals = calculateMonthlyTotals(costOfSalesRows);
  const operatingExpensesTotals = calculateMonthlyTotals(operatingExpensesRows);
  const capexTotals = calculateMonthlyTotals(capexRows);

  // Filter actual spending for each budget table
  const costOfSalesActualSpending = filterActualSpendingByAccountType(
    actualSpending,
    AccountType.DirectCosts,
  );

  const operatingExpensesActualSpending = filterActualSpendingByAccountType(
    actualSpending,
    AccountType.Expense,
  );

  const capexActualSpending = filterActualSpendingByAccountType(
    actualSpending,
    AccountType.FixedAsset,
  );

  const financialYearName = getFinancialYearName(financialYear);

  const hasUpdatePermission =
    user?.hasAccessTo(
      "App\\Models\\OrganisationBranch",
      "update_budgets",
      branch?.id,
    ) ||
    user?.id == branch?.branch_manager_id ||
    user?.groups.includes(GroupType.Accounting);

  const hasViewPermission =
    user?.hasAccessTo(
      "App\\Models\\OrganisationBranch",
      "view_budgets",
      branch?.id,
    ) ||
    user?.id == branch?.branch_manager_id ||
    user?.groups.includes(GroupType.Accounting);

  if (!hasViewPermission) {
    return <div>You are not authorized to view this page</div>;
  }

  return (
    <>
      <HeaderPage
        titlePage={`${branch?.name}'s Budget`}
        crumbs={[
          {
            name: "Branches",
            link: "/branches",
          },
          {
            name: branch?.name ?? "",
            link: `/branches/${uuid}/details`,
          },
          {
            name: "Budget",
            active: true,
          },
        ]}
      />

      <BranchTabs />
      <div className="position-relative">
        <LoadingOverlay loading={loadingBudgetItems} />
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center space-x-3">
            <h5 className="text-dark mb-0">{financialYearName}</h5>
          </div>
          <div className="ms-auto d-flex space-x-2">
            <SelectMonths
              period={period}
              setPeriod={setPeriod}
              financialYear={financialYear}
            />
            {budgetLock?.locked_at && (
              <>
                <Badge
                  color="warning"
                  className="rounded-pill shadow-sm me-4 d-flex align-items-center money"
                >
                  Budget locked{" "}
                </Badge>
                {hasUpdatePermission && (
                  <TextButton
                    title="Unlock"
                    className="text-secondary"
                    onClick={() =>
                      deleteSwal("Unlock Budget")
                        .then(() =>
                          takeAction(
                            "destroy",
                            `budget-locks/${budgetLock.uuid}`,
                          ),
                        )
                        .then(({ data }) => {
                          toast.success("Budget unlocked");
                          setBranch(data.data);
                        })
                        .catch(errorSwal)
                    }
                  >
                    <FiUnlock size={16} />
                  </TextButton>
                )}
              </>
            )}
            <TextButton
              title={showActuals ? "Hide Actuals" : "Show Actuals"}
              onClick={toggleActuals}
              className="text-secondary"
            >
              {showActuals ? "Hide Actuals" : "Show Actuals"}
            </TextButton>
            {hasUpdatePermission && (
              <>
                <ApprovalButton
                  buttonClass="btn btn-outline-secondary btn-sm"
                  approvalAction={budgetLock?.approval_action}
                  buttonColor="secondary"
                  approvalOptions={[
                    {
                      value: ApprovalOptions.Approve,
                      label: "Approve",
                    },
                    {
                      value: ApprovalOptions.Redo,
                      label: "Redo",
                    },
                  ]}
                />

                <BudgetSettingModal branch={branch} setBranch={setBranch} />
              </>
            )}
            <ExportBudgetCSV
              months={months}
              financialYearName={financialYearName}
              disabled={loadingBudgetItems}
              mainRevenueRow={mainRevenueRow}
              adjustmentRevenueRow={adjustmentRevenueRow}
              subcontractorMarkupRows={subcontractorMarkupRows}
              costOfSalesRows={costOfSalesRows}
              operatingExpensesRows={operatingExpensesRows}
              capexRows={capexRows}
              revenueTotals={revenueTotals}
              costOfSalesTotals={costOfSalesTotals}
              operatingExpensesTotals={operatingExpensesTotals}
              capexTotals={capexTotals}
              branch={branch}
            />
          </div>
          <div className="d-flex space-x-3 ms-4">
            <TextButton
              className="text-dark"
              title="Go to Previous Financial Year"
              onClick={() =>
                setFinancialYear([
                  dayjs(financialYear[0])
                    .subtract(1, "year")
                    .format("YYYY-MM-DD"),
                  dayjs(financialYear[1])
                    .subtract(1, "year")
                    .format("YYYY-MM-DD"),
                ])
              }
            >
              <GrPrevious />
            </TextButton>
            <TextButton
              className="text-dark"
              title="Go to Next Financial Year"
              onClick={() =>
                setFinancialYear([
                  dayjs(financialYear[0]).add(1, "year").format("YYYY-MM-DD"),
                  dayjs(financialYear[1]).add(1, "year").format("YYYY-MM-DD"),
                ])
              }
            >
              <GrNext />
            </TextButton>
          </div>
        </div>

        {/* Revenue Table */}
        <RevenueBudgetTable
          months={months}
          mainRow={mainRevenueRow}
          refreshBudgetItems={refreshBudgetItems}
          adjustmentRow={adjustmentRevenueRow}
          monthlyTotals={revenueTotals}
          onAmountBlur={handleAmountBlur}
          columnStyles={columnStyles}
          subcontractorMarkupRows={subcontractorMarkupRows}
          budgetLock={budgetLock}
          period={period}
          showActuals={showActuals}
          isReadOnly={!hasUpdatePermission}
        />

        {/* Cost of Sales Table */}
        <BudgetTable
          title="Cost of Sales"
          budgetRows={costOfSalesRows}
          months={months}
          accounts={accounts}
          monthlyTotals={costOfSalesTotals}
          onAddRow={() => handleAddRow("cost_of_sales")}
          onDeleteRow={handleDeleteRow}
          onAccountSelect={(rowIndex, option) =>
            handleAccountSelect(rowIndex, option, "cost_of_sales")
          }
          onAmountBlur={handleAmountBlur}
          type="cost_of_sales"
          columnStyles={columnStyles}
          extraRows={<GetEstimatedCostsOfSales months={months} />}
          branch={branch}
          budgetLock={budgetLock}
          refreshBudgetItems={refreshBudgetItems}
          period={period}
          actualSpending={costOfSalesActualSpending}
          showActuals={showActuals}
          isReadOnly={!hasUpdatePermission}
        />

        {/* Operating Expenses Table */}
        <BudgetTable
          title="Operating Expenses"
          budgetRows={operatingExpensesRows}
          months={months}
          accounts={accounts}
          monthlyTotals={operatingExpensesTotals}
          onAddRow={() => handleAddRow("operating_expenses")}
          onDeleteRow={handleDeleteRow}
          onAccountSelect={(rowIndex, option) =>
            handleAccountSelect(rowIndex, option, "operating_expenses")
          }
          onAmountBlur={handleAmountBlur}
          type="operating_expenses"
          columnStyles={columnStyles}
          branch={branch}
          budgetLock={budgetLock}
          refreshBudgetItems={refreshBudgetItems}
          period={period}
          actualSpending={operatingExpensesActualSpending}
          showActuals={showActuals}
          isReadOnly={!hasUpdatePermission}
        />

        {/* CAPEX Table */}
        <BudgetTable
          title="Capital Expenditure (CAPEX)"
          budgetRows={capexRows}
          months={months}
          accounts={accounts}
          monthlyTotals={capexTotals}
          onAddRow={() => handleAddRow("capex")}
          onDeleteRow={handleDeleteRow}
          onAccountSelect={(rowIndex, option) =>
            handleAccountSelect(rowIndex, option, "capex")
          }
          onAmountBlur={handleAmountBlur}
          type="capex"
          columnStyles={columnStyles}
          branch={branch}
          budgetLock={budgetLock}
          refreshBudgetItems={refreshBudgetItems}
          period={period}
          actualSpending={capexActualSpending}
          showActuals={showActuals}
          isReadOnly={!hasUpdatePermission}
        />

        {/* Budget Summary */}
        <BudgetSummary
          months={months}
          revenueTotals={revenueTotals}
          costOfSalesTotals={costOfSalesTotals}
          operatingExpensesTotals={operatingExpensesTotals}
          capexTotals={capexTotals}
          branch={branch || null}
          columnStyles={columnStyles}
        />
        <BranchBudgetLockButton
          budgetLock={budgetLock}
          period={financialYear}
          refreshData={refreshBranchData}
        />
      </div>
    </>
  );
};

interface StaffCost {
  user: string;
  title: string;
  amount: number;
  hourly_rate: number;
  hours_per_week: number;
  link: string;
  employment_basis: string;
  totalDays: number;
  branches?: { name: string; amount: number; link: string }[];
}

interface EstimatedCostsOfSales {
  amount: number;
  users: StaffCost[];
  month: string;
  branches?: { name: string; amount: number; link: string }[];
  organisations?: { name: string; amount: number; link: string }[];
}

export const GetEstimatedCostsOfSales = ({ months }: { months: string[] }) => {
  const { uuid } = useParams<{ uuid?: string }>();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const branchesParam = params.get("branches");

  const organisationIds = params.get("organisations");

  const estimatedCostsOfSalesUrl = useMemo(() => {
    if (uuid) {
      return `branches/${uuid}/estimated-costs-of-sales?months[]=${months.join(
        "&months[]=",
      )}`;
    }

    return `branches/estimated-costs-of-sales?months[]=${months.join(
      "&months[]=",
    )}&branches=${branchesParam}${
      organisationIds ? `&organisations=${organisationIds}` : ""
    }`;
  }, [months, uuid, branchesParam, organisationIds]);

  const {
    data: estimatedCostsOfSales,
    setUrl: setEstimatedCostsOfSalesUrl,
    loading: estimatedCostsOfSalesLoading,
  }: IUseApiWithData<EstimatedCostsOfSales[]> = useApi(
    estimatedCostsOfSalesUrl,
    [],
  );

  useEffect(() => {
    setEstimatedCostsOfSalesUrl(estimatedCostsOfSalesUrl);
  }, [months]);

  return (
    <tr
      className={
        estimatedCostsOfSalesLoading ? "bg-gray-300" : "money table-info"
      }
    >
      <td className="fw-bolder text-dark">Estimated Costs of Sales</td>
      {estimatedCostsOfSalesLoading ? (
        <td colSpan={months.length} className="text-center">
          <Spinner size="sm" />
        </td>
      ) : estimatedCostsOfSales?.length > 0 ? (
        estimatedCostsOfSales?.map((cost) => (
          <td className="text-end fst-italic position-relative">
            <span className="cursor-pointer" id={`costs-popover-${cost.month}`}>
              (
              {cost.amount.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}
              )
            </span>
            <UncontrolledPopover
              delay={{
                show: 500,
                hide: 0,
              }}
              placement="top"
              target={`costs-popover-${cost.month}`}
              trigger="hover"
              innerClassName="wider-popover"
              popperClassName="staff-contribution-popover"
            >
              <PopoverHeader>Staff Costs</PopoverHeader>
              <PopoverBody>
                {cost.organisations && cost.organisations.length > 1 ? (
                  <BranchCostsTable branches={cost.organisations} />
                ) : cost.branches && cost.branches.length > 1 ? (
                  <BranchCostsTable branches={cost.branches} />
                ) : (
                  <StaffCostsTable users={cost.users} />
                )}
              </PopoverBody>
            </UncontrolledPopover>
          </td>
        ))
      ) : (
        <td colSpan={months.length} className="text-center"></td>
      )}
      <td className="text-end fst-italic">
        ({" "}
        {estimatedCostsOfSales
          ?.reduce((sum: number, cost: any) => sum + cost.amount, 0)
          .toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })}
        )
      </td>
      <td></td>
    </tr>
  );
};

const BranchCostsTable = ({
  branches,
}: {
  branches: { name: string; amount: number; link: string }[];
}) => {
  const helper = createColumnHelper<{
    name: string;
    amount: number;
    link: string;
  }>();

  const columns = useMemo<
    ColumnDef<{ name: string; amount: number; link: string }, any>[]
  >(
    () => [
      helper.accessor("name", {
        header: "Name",
        cell: (info) => {
          return <Link to={info.row.original.link}>{info.getValue()}</Link>;
        },
      }),
      helper.accessor("amount", {
        header: "Amount",
        cell: (info) => {
          return <span>${formatAmount(info.getValue(), "revenue")}</span>;
        },
      }),
    ],
    [branches],
  );

  return <ReactTable columns={columns} data={branches} disableSearch />;
};

const StaffCostsTable = ({ users }: { users: StaffCost[] }) => {
  const helper = createColumnHelper<StaffCost>();

  const totalAmount = users.reduce((sum, user) => sum + user.amount, 0);

  const columns = useMemo<ColumnDef<StaffCost, any>[]>(
    () => [
      helper.accessor("user", {
        header: "User",
      }),
      helper.accessor("title", {
        header: "Title",
        cell: (info) => {
          return <Link to={info.row.original.link}>{info.getValue()}</Link>;
        },
      }),

      helper.accessor("hourly_rate", {
        header: "Hourly Rate",
        cell: (info) => {
          return <span>${formatAmount(info.getValue(), "revenue")}</span>;
        },
      }),
      helper.accessor("employment_basis", {
        header: "Employment Basis",
      }),
      helper.accessor("totalDays", {
        header: "Total Days",
      }),
      helper.accessor("hours_per_week", {
        header: "Hours per Week",
      }),
      helper.accessor("amount", {
        header: "Amount",
        cell: (info) => {
          return <span>${formatAmount(info.getValue(), "revenue")}</span>;
        },
        footer: (info) => {
          return <span>${formatAmount(totalAmount, "revenue")}</span>;
        },
      }),
    ],
    [helper],
  );

  return <ReactTable columns={columns} data={users} disableSearch />;
};

const BranchTabs = () => {
  const { user } = useAuth();

  const { uuid } = useParams<{ uuid: string }>();

  const { data: allBranches }: IUseApiWithData<Branch[]> = useApi(
    user?.is_admin ? "organisation-branches" : undefined,
    [],
  );

  return (
    <div className={`nav-statistics-wrapper `}>
      <nav className="nav flex-nowrap" style={{ overflowX: "auto" }}>
        {allBranches.map((branch, index) => {
          return (
            <Link
              style={{ whiteSpace: "nowrap" }}
              to={`/branch-budgets/${branch.uuid}`}
              key={index}
              type="button"
              className={`btn btn-link nav-link mb-3 ${
                uuid == branch.uuid ? "active" : ""
              }`}
            >
              {branch.name}
            </Link>
          );
        })}
      </nav>
    </div>
  );
};

export const SelectMonths = ({
  period,
  setPeriod,
  financialYear,
}: {
  period: [string, string];
  setPeriod: (period: [string, string]) => void;
  financialYear: [string, string];
}) => {
  const financialYearMonths = useMemo(() => {
    const months = [];
    let current = dayjs(financialYear[0]);
    const end = dayjs(financialYear[1]);

    while (current.isBefore(end) || current.isSame(end, "month")) {
      months.push(current.format("YYYY-MM"));
      current = current.add(1, "month");
    }

    return months;
  }, [financialYear]);

  return (
    <>
      <div className="d-flex space-x-2 align-items-center text-secondary rounded-pill shadow-sm px-2 py-1 bg-warning money me-2">
        <select
          title="Months"
          className="border-0 bg-transparent text-white no-focus"
          value={dayjs(period[0]).format("YYYY-MM")}
          onChange={(e) =>
            setPeriod([
              dayjs(e.target.value).startOf("month").format("YYYY-MM-DD"),
              period[1],
            ])
          }
        >
          {financialYearMonths.map((month) => {
            return (
              <option
                disabled={dayjs(month).isSameOrAfter(dayjs(period[1]))}
                value={month}
              >
                {dayjs(month).format("MMM YYYY")}
              </option>
            );
          })}
        </select>
        <span className="text-white">to</span>
        <select
          title="Months"
          className="border-0 bg-transparent text-white no-focus"
          value={dayjs(period[1]).format("YYYY-MM")}
          onChange={(e) =>
            setPeriod([
              period[0],
              dayjs(e.target.value).startOf("month").format("YYYY-MM-DD"),
            ])
          }
        >
          {financialYearMonths.map((month) => (
            <option
              disabled={dayjs(month).isSameOrBefore(dayjs(period[0]))}
              value={month}
            >
              {dayjs(month).format("MMM YYYY")}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default BranchBudgets;
