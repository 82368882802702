import dayjs from "dayjs";
import { BudgetItem, BudgetRow, MonthlyTotals } from "../types";
import { AccountType } from "../../chartOfAccounts/chartOfAccountTypes";
import { ActualSpending } from "./budgetTypes";

/**
 * Get an array of month strings (YYYY-MM) for the given period
 */
export const getMonthColumns = (period: [string, string]): string[] => {
  const months = [];
  let current = dayjs(period[0]);
  const end = dayjs(period[1]);

  while (current.isBefore(end) || current.isSame(end, "month")) {
    months.push(current.format("YYYY-MM"));
    current = current.add(1, "month");
  }

  return months;
};

/**
 * Create budget rows from budget items grouped by account
 */
export const getBudgetRows = (
  budgetItems: BudgetItem[],
  period: [string, string],
): BudgetRow[] => {
  const months = getMonthColumns(period);
  const groupedItems = Object.entries(
    budgetItems.reduce((acc, item) => {
      if (!item.account?.code) return acc;
      if (!acc[item.account.code]) acc[item.account.code] = [];
      acc[item.account.code].push(item);
      return acc;
    }, {} as Record<string, BudgetItem[]>),
  );

  return groupedItems.map(([accountCode, items]) => {
    const account = items[0]?.account || null;

    const amounts = months.map((month) => {
      const newItems = items.filter(
        (item) => dayjs(item.month).format("YYYY-MM") === month,
      );

      return newItems.length > 0
        ? {
            account_id: account?.id || "",
            month: `${month}-01`,
            amount: newItems.reduce(
              (acc, item) => acc + (item.amount as number),
              0,
            ),
            account: account,
            type: newItems[0]?.type,
            items: newItems.flatMap((item) => item.items).filter(Boolean),
            uuid: newItems[0]?.uuid,
          }
        : {
            account_id: account?.id || "",
            month: `${month}-01`,
            amount: 0,
            account: account,
          };
    });

    return { amounts, account } as BudgetRow;
  });
};

/**
 * Calculate monthly totals for a set of budget rows
 */
export const calculateMonthlyTotals = (rows: BudgetRow[]): MonthlyTotals => {
  const totals: MonthlyTotals = {};

  rows.forEach((row) => {
    if (!row.account) return;

    row.amounts.forEach((budgetItem) => {
      if (!budgetItem.month || !budgetItem.amount) return;

      const month = dayjs(budgetItem.month).format("YYYY-MM");
      const amount =
        typeof budgetItem.amount === "string"
          ? parseFloat(budgetItem.amount) || 0
          : budgetItem.amount;

      const adjustedAmount = -Math.abs(amount);

      totals[month] = (totals[month] || 0) + adjustedAmount;
    });
  });

  return totals;
};

/**
 * Get the financial year name from a period
 */
export const getFinancialYearName = (period: [string, string]): string => {
  return `FY${dayjs(period[0]).format("YY")} - FY${dayjs(period[1]).format(
    "YY",
  )}`;
};

/**
 * Filter budget rows by type
 */
export const getFilteredBudgetRows = (
  budgetRows: BudgetRow[],
  type?: "revenue" | "cost_of_sales" | "operating_expenses" | "capex",
): BudgetRow[] => {
  return budgetRows.filter((row) => {
    const itemType = row.amounts.find((row) => row.type)?.type;
    return itemType === type;
  });
};

/**
 * Filter actual spending based on account type
 */
export const filterActualSpendingByAccountType = (
  spending: ActualSpending,
  accountType: AccountType | AccountType[],
): ActualSpending => {
  if (!spending) return {};

  const filteredSpending: ActualSpending = {};

  // Convert single account type to array for consistent handling
  const accountTypes = Array.isArray(accountType) ? accountType : [accountType];

  // Iterate through each month in the spending data
  Object.keys(spending).forEach((month) => {
    if (!spending[month]) return;

    filteredSpending[month] = { accounts: {} };

    // Filter accounts by the specified account type(s)
    Object.keys(spending[month].accounts).forEach((accountId) => {
      const accountData = spending[month].accounts[accountId];

      if (accountData && accountTypes.includes(accountData.account.type)) {
        filteredSpending[month].accounts[accountId] = accountData;
      }
    });
  });

  return filteredSpending;
};
