import React, { useEffect, useState } from "react";
import PaginatedList from "../pagination/PaginatedList";
import PurchaseTable from "../purchases/PurchaseTable";
import { Purchase } from "../purchases/purchaseTypes";
import { Branch } from "./branchTypes";
import useBranchPurchases from "./hooks/useBranchPurchases";
import { Table } from "@tanstack/react-table";
import dayjs from "dayjs";
import { Button } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import { GroupType } from "../user/userTypes";
import FormModal from "../utils/FormModal";
import useModal from "../hooks/useModal";
import {
  Field,
  FieldArray,
  InjectedFormProps,
  WrappedFieldArrayProps,
} from "redux-form";
import SelectInput from "../form/SelectInput";
import { FiPlus, FiTrash } from "react-icons/fi";
import TextButton from "../utils/TextButton";
import RenderField from "../utils/renderField";
import _ from "lodash";
import useApi from "../api/useApi";
import { IUseApi, IUseApiWithData } from "../api/apiTypes";
import required from "../utils/required";
import { money } from "../form/formatters";
import { ChartOfAccount } from "../chartOfAccounts/chartOfAccountTypes";
import filterOption from "../../utils/filterOption";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import formError from "../utils/formError";

const BranchPurchases = ({ branch }: { branch: Branch }) => {
  const [tableInstance, setTableInstance] = useState<Table<Purchase>>();

  const { toggle, modal } = useModal();

  const selectedRows =
    tableInstance?.getSelectedRowModel().rows.map((row) => row.original) ?? [];

  const { user } = useAuth();

  const isAccounting =
    user?.groups.includes(GroupType.Accounting) || user?.is_admin;

  return (
    <>
      <PaginatedList
        indexHook={useBranchPurchases}
        indexHookArguments={[branch.id]}
        originalFilters={[
          {
            name: "month",
            label: "Month",
            options: [
              {
                label: "This Month",
                value: dayjs().format("YYYY-MM"),
              },
              {
                label: "Last Month",
                value: dayjs().subtract(1, "month").format("YYYY-MM"),
              },
            ],
          },
          {
            name: "has_journals",
            label: "Has Journals",
            options: [
              {
                label: "Yes",
                value: "true",
              },
              {
                label: "No",
                value: "false",
              },
            ],
          },
        ]}
        extraButtons={() => {
          if (selectedRows.length === 0) {
            return null;
          }
          return (
            <Button size="sm" outline onClick={toggle}>
              Split {selectedRows.length} Purchases
            </Button>
          );
        }}
        list={({ data }: { data?: Purchase[] }) => (
          <div className="col-12">
            <PurchaseTable
              data={data}
              // @ts-ignore
              setTableInstance={isAccounting ? setTableInstance : undefined}
              enableMultiSelect={isAccounting}
            />
          </div>
        )}
      />
      <SplitPurchasesModal
        selectedRows={selectedRows}
        toggle={toggle}
        modal={modal}
        branch={branch}
      />
    </>
  );
};

const SplitPurchasesModal = ({
  selectedRows,
  toggle,
  modal,
  branch,
}: {
  selectedRows: Purchase[];
  toggle: () => void;
  modal: boolean;
  branch: Branch;
}) => {
  const { user } = useAuth();
  const { takeAction }: IUseApi = useApi();

  const { uuid } = useParams<{ uuid: string }>();

  const totalAmount = selectedRows.reduce(
    (total, row) => total + (row.realised_total_cost || 0),
    0,
  );

  const onSubmit = (values: any) => {
    return takeAction("store", `/branches/${uuid}/split-purchase`, {
      purchases: selectedRows.map((row) => row.id),
      ...values,
    })
      .then(() => {
        toast.success("Purchases split successfully");
        toggle();
      })
      .catch(formError);
  };

  return (
    <FormModal
      title={`Split ${selectedRows.length} Purchases (${money.format(
        totalAmount,
      )})`}
      submitText="Split"
      form="split-purchases"
      save="Split"
      saving="Splitting..."
      toggle={toggle}
      modal={modal}
      onSubmit={onSubmit}
      initialValues={{
        splits: [
          {
            organisation_id: user?.active_organisation?.id,
          },
        ],
      }}
    >
      {({ change }: InjectedFormProps) => {
        return (
          <>
            <FieldArray
              name="splits"
              component={PurchaseSplitForm}
              change={change}
              totalCost={totalAmount}
              branch={branch}
            />
          </>
        );
      }}
    </FormModal>
  );
};

const PurchaseSplitForm = ({
  fields,
  change,
  totalCost,
  branch,
}: WrappedFieldArrayProps & {
  change: (field: string, value: any) => void;
  totalCost: number;
  branch: Branch;
}) => {
  const { user } = useAuth();

  const {
    data: branches,
    setUrl,
  }: IUseApiWithData<
    {
      id: number;
      name: string;
      organisation_id: number;
    }[]
  > = useApi("", []);

  const {
    data: accounts,
    setUrl: setAccountsUrl,
  }: IUseApiWithData<ChartOfAccount[]> = useApi(``, []);

  const fieldValues = fields.getAll();

  const organisationIds = _.uniq(
    fieldValues?.map((field) => field.organisation_id) ?? [],
  );

  const totalAmount = fieldValues?.reduce(
    (total, field) => total + (parseFloat(field.amount) || 0),
    0,
  );

  useEffect(() => {
    setUrl(
      `/custom-fields/branches?organisations[]=${organisationIds.join(
        "&organisations[]=",
      )}`,
    );

    const organisationsExceptActive = organisationIds.filter(
      (id) => id != user?.active_organisation?.id,
    );

    if (organisationsExceptActive.length > 0) {
      setAccountsUrl(
        `/chart-of-accounts?organisations[]=${organisationsExceptActive.join(
          "&organisations[]=",
        )}`,
      );
    }
  }, [organisationIds]);

  return (
    <>
      {totalAmount > 100 && (
        <div className="col-12">
          <div className="alert alert-danger rounded-lg shadow-sm" role="alert">
            <strong>Warning:</strong> <br />
            The total split percentage exceeds 100%. <br />
            Please adjust your splits to ensure they add up to 100% or less.
          </div>
        </div>
      )}
      {fields.map((field, index) => {
        const value = fields.get(index);

        const availableBranches = branches?.filter((b) => {
          return (
            b.organisation_id == value.organisation_id && b.id != branch.id
          );
        });

        const isSeperateOrganisation =
          value.organisation_id != user?.active_organisation?.id;

        return (
          <React.Fragment key={field}>
            <div
              className={`${
                isSeperateOrganisation ? "col-lg-3" : "col-lg-4"
              } form-group`}
            >
              <Field
                name={`${field}.organisation_id`}
                label="Organisation"
                component={SelectInput}
                onChangeValue={() => {
                  change(`${field}.branch_id`, null);
                  change(`${field}.account_id`, null);
                }}
                validate={required}
                required
                options={[
                  {
                    label: user?.active_organisation?.name,
                    value: user?.active_organisation?.id,
                  },
                  ...(user?.active_organisation?.group_members ?? []).map(
                    (member) => ({
                      label: member.name,
                      value: member.id,
                    }),
                  ),
                ]}
              />
            </div>
            <div
              className={`${
                isSeperateOrganisation ? "col-lg-3" : "col-lg-4"
              } form-group`}
            >
              <Field
                name={`${field}.branch_id`}
                label="Branch"
                validate={required}
                required
                component={SelectInput}
                options={availableBranches?.map((branch) => ({
                  label: branch.name,
                  value: branch.id,
                }))}
              />
            </div>
            {isSeperateOrganisation ? (
              <div className="col-lg-3 form-group">
                <Field
                  name={`${field}.account_id`}
                  label="Account"
                  validate={required}
                  required
                  filterOption={filterOption}
                  component={SelectInput}
                  options={accounts
                    .filter(
                      (account) =>
                        account.organisation_id == value.organisation_id,
                    )
                    .map((account) => ({
                      label: (
                        <>
                          <p className="text-dark mb-0">{account.code}</p>
                          <p className="text-muted mb-0">{account.name}</p>
                        </>
                      ),
                      value: account.id,
                      text: `${account.code} - ${account.name}`,
                    }))}
                />
              </div>
            ) : null}
            <div
              className={`${
                isSeperateOrganisation ? "col-lg-2" : "col-lg-3"
              } form-group`}
            >
              <Field
                name={`${field}.amount`}
                label="Split (%)"
                validate={required}
                required
                component={RenderField}
              />
              {value.amount && (
                <small>
                  {money.format(
                    ((parseFloat(value.amount) / 100) * totalCost).toFixed(2),
                  )}
                </small>
              )}
            </div>
            <div className="col-lg-1 form-group">
              <label className="d-block" style={{ height: "30px" }} />

              <div className="d-flex align-items-center justify-content-center">
                <TextButton onClick={() => fields.remove(index)}>
                  <FiTrash size={20} className="text-danger" />
                </TextButton>
              </div>
            </div>
          </React.Fragment>
        );
      })}

      <div className="col-12 d-flex ">
        <Button
          outline
          className="d-flex align-items-center gap-1"
          size="sm"
          onClick={() =>
            fields.push({
              organisation_id: user?.active_organisation?.id,
            })
          }
        >
          <FiPlus size={16} /> Add Split
        </Button>
      </div>
    </>
  );
};

export default BranchPurchases;
