import { useState } from "react";
import { toast } from "react-toastify";
import { Field, reset } from "redux-form";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import useUploadMultipleToS3 from "../hooks/useUploadMultipleToS3";
import errorSwal from "../utils/errorSwal";
import FormModal from "../utils/FormModal";
import Fields from "./Fields";

interface AddModalProps {
  commentableType: string;
  commentableId: number;
  toggle: () => void;
  modal: boolean;
  setComments?: (comments: any[]) => void;
  comments?: any[];
  title?: string;
  onSubmit?: (values: any) => Promise<any>;
  save?: string;
  saving?: string;
}

interface CommentFormValues {
  comment: string;
  documents?: File[];
  [key: string]: any;
}

interface FormProps {
  files: File[];
  setFiles: (files: File[]) => void;
  [key: string]: any;
}

const AddModal = ({
  commentableType,
  commentableId,
  toggle,
  modal,
  setComments,
  comments,
  title = "Add new Comment",
  onSubmit,
  save = "Add Comment",
  saving = "Adding Comment..."
}: AddModalProps) => {
  const [files, setFiles] = useState<File[]>([]);
  const { user } = useAuth();
  const { takeAction } = useApi();
  const form = "AddCommentModal";

  if (!user?.active_organisation?.uuid) {
    return null;
  }

  const { upload } = useUploadMultipleToS3(
    `organisations/${user.active_organisation.uuid}/comments`
  );

  const handleSubmit = (values: CommentFormValues, dispatch: any) => {
    return upload(values.documents ?? [])
      .then((documents) => {
        const data = {
          commentable_type: commentableType,
          commentable_id: commentableId,
          ...values,
          documents,
        };

        return takeAction("store", "comments", data);
      })
      .then(({ data }) => {
        if (setComments && comments) {
          setComments([data.data, ...comments]);
        }
        
        if (onSubmit) {
          onSubmit(data.data);
        }
        toast.success("Comment added.");
        dispatch(reset(form));
        setFiles([]);
        toggle();
      })
      .catch(errorSwal);
  };

  return (
    <FormModal
      form={form}
      title={title}
      onSubmit={handleSubmit}
      toggle={toggle}
      modal={modal}
      save={save}
      saving={saving}
    >
      {(formProps: FormProps) => (
        <Fields {...formProps} files={files} setFiles={setFiles} />
      )}
    </FormModal>
  );
};

export default AddModal; 