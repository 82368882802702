import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { modifyDate } from "../../actions/timesheetActions";
import { useUserTimesheet } from "../../context/timesheet-context";
import FormErrorAlert from "../form/FormErrorAlert";
import SelectInput from "../form/SelectInput";
import renderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import { BreakType } from "./timesheetTypes";
import validate from "./timesheetValidation";
import useApi from "../api/useApi";

const TimesheetForm = (props) => {
  const {
    userId,
    handleSubmit,
    timesheet,
    requiresApproval,
    change,
    user,
    selectedDay,
    unpaidBreak,
    paidBreak,
    initialValues,
    roleId,
    error,
    modifyDate,
    leaveReason,
  } = props;

  const [branchId, setBranchId] = useState();
  const { productivity, setProductivity } = useUserTimesheet();

  const { data: settings } = useApi("organisation-settings", {
    settings: [],
  });

  const defaultStartTime = useMemo(
    () =>
      settings?.settings.find(
        (setting) => setting?.meta_key === "default_start_time",
      )?.meta_value,
    [settings],
  );

  const standardWorkHours = useMemo(
    () =>
      settings?.settings.find(
        (setting) => setting?.meta_key === "standard_work_hours",
      )?.meta_value,
    [settings],
  );

  const finishTime = dayjs(defaultStartTime, "HH:mm")
    .add(standardWorkHours, "hours")
    .format("HH:mm");

  const formatData = useCallback((data) => {
    return data.map((d) => {
      return {
        label: d.dropdown_name,
        value: d.id,
        ...d,
      };
    });
  }, []);

  const approvalRequired =
    requiresApproval || timesheet?.approval_action?.action_type === "Redo";

  const staffRoleUrl = useMemo(() => {
    return `/users/${userId}/staff-roles?filter[wasActiveOn]=${
      props.addTimesheet
        ? dayjs(selectedDay).format("YYYY-MM-DD")
        : props?.timesheet?.date
    }`;
  }, [userId, props.addTimesheet, selectedDay, props?.timesheet?.date]);

  const changeRole = (value, onLoadRoleIntialValue = null) => {
    if (onLoadRoleIntialValue != null) {
      change("approver", onLoadRoleIntialValue.approver);
    }

    if (approvalRequired && value) {
      setBranchId(value.branch_id);
    }

    if (!value) {
      setBranchId(null);
    }

    if (initialValues?.uuid) {
      return;
    }

    if (user?.organisation_user?.settings) {
      return;
    }

    if (value?.employment_basis === "Full Time") {
      change("start_time", defaultStartTime);
      change("finish_time", finishTime);
      if (!leaveReason) {
        const newFinishTime = dayjs(defaultStartTime, "HH:mm")
          .add(standardWorkHours + 0.5, "hours")
          .format("HH:mm");

        change("finish_time", newFinishTime);

        change("paid_break", "0.25");

        change("unpaid_break", "0.5");
      }
    }
  };

  useEffect(() => {
    if (leaveReason && !initialValues?.uuid) {
      change("start_time", defaultStartTime);

      change("finish_time", finishTime);

      change("unpaid_break", null);
      change("paid_break", null);
      change("night_shift", null);

      setProductivity([]);
    }
  }, [leaveReason]);

  // AutoFilter -> After creation of break productivity(i.e. once productivity tab is opened while timesheet contains breaks), Break will be removed automatically if timesheet break is removed or set to zero
  // AutoUpdate -> when timesheet break time is increased or decreased after creating break productivity
  //               then it updates corresponding break finish time based on new break hours
  const AutoFilterAndAutoUpdateBreaks = () => {
    setProductivity(
      productivity
        .filter(
          (event) =>
            event.pulled_from_schedule ||
            (!event.pulled_from_schedule &&
              ![BreakType.Paid, BreakType.Unpaid].includes(
                event?.break_type,
              )) ||
            (paidBreak > 0 && event?.break_type === BreakType.Paid) ||
            (unpaidBreak > 0 && event?.break_type === BreakType.Unpaid),
        )
        .map((event) =>
          event?.pulled_from_schedule || event?.job_id
            ? event
            : updateBreakProductivity(event),
        ),
    );
  };

  const updateBreakProductivity = (event) => {
    const timesheetDay = props.addTimesheet
      ? selectedDay
      : props?.timesheet?.date;
    const productivityStart = dayjs(
      `${dayjs(timesheetDay).format("YYYY-MM-DD")}T${event?.start_time_time}`,
    ).toDate();

    const breakInHour =
      event.break_type === BreakType.Paid ? paidBreak : unpaidBreak;

    const breakEnd = dayjs(productivityStart)
      .add(breakInHour * 60, "m")
      .toDate();

    return {
      ...event,
      start: productivityStart,
      start_time_date: dayjs(productivityStart).format("YYYY-MM-DD"),
      end: breakEnd,
      finish_time_date: dayjs(breakEnd).format("YYYY-MM-DD"),
      finish_time_time: dayjs(breakEnd).format("HH:mm:ss"),
    };
  };

  const getOptionValues = (options, onChange) => {
    if (options.length === 1 && !initialValues?.uuid) {
      onChange(options[0].value);
      setBranchId(options[0].branch_id);
      changeRole(options[0], initialValues);
      return;
    }

    setBranchId(
      options.find((option) => {
        return option.value === (roleId || initialValues?.role_id);
      })?.branch_id,
    );
  };

  const totalHours = () => {
    const startTime = dayjs(props.startTime, "HH:mm");
    const finishTime = dayjs(props.finishTime, "HH:mm");
    let totalHours = props.nightShift
      ? finishTime.add(1, "day").diff(startTime, "hours", true)
      : finishTime.diff(startTime, "hours", true);
    totalHours -= props.unpaidBreak;

    return totalHours ? totalHours.toFixed(2) : 0;
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <FormErrorAlert error={error} />

        {!initialValues?.uuid && (
          <div className="col-lg-12 form-group d-lg-none">
            <Field
              component={renderField}
              onChangeValue={(value) => {
                modifyDate?.({}, new Date(value));
              }}
              label="Date"
              name="date"
              required
              type="date"
              validate={required}
              format={(value) => {
                if (!value) {
                  return;
                }

                return dayjs(value).format("YYYY-MM-DD");
              }}
            />
          </div>
        )}
        <div className="col-md-6 form-group">
          <Field
            url={staffRoleUrl}
            component={SelectInput}
            label="Role"
            name="role_id"
            getOptionValues={getOptionValues}
            formatData={formatData}
            changeValue={changeRole}
            required
            validate={required}
            empty="No roles found, please ask admin to add a role for you to continue."
          />
        </div>
        {(timesheet?.type_description === "Leave" || !initialValues?.uuid) && (
          <div className="col-md-6 form-group">
            <Field
              component={SelectInput}
              name="leave_reason_id"
              label="Leave Reason"
              formatData={formatLeaveReasons}
              url="/leave-reasons?include=integrationModels&filter[private]=false"
            />
            {timesheet?.type_description !== "Leave" && (
              <small>Leave this blank if you are not requesting leave.</small>
            )}
          </div>
        )}
        {approvalRequired && (
          <div className="col-md-6 form-group">
            <Field
              component={SelectInput}
              url={approverUrl(branchId)}
              label="Approver"
              name="approver"
              required
              validate={required}
              empty={
                branchId
                  ? "Unable to find timesheet approver for selected role, please ask an administrator to add before continuing."
                  : ""
              }
            />
          </div>
        )}
        <div className="col-md-6 form-group">
          <Field
            component={renderField}
            label="Start Time"
            name="start_time"
            type="time"
            required
            validate={required}
          />
        </div>
        <div className="col-md-6 form-group">
          <Field
            component={renderField}
            label="Finish Time"
            name="finish_time"
            type="time"
            required
            validate={required}
          />
        </div>
        {timesheet?.type_description !== "Leave" && !leaveReason && (
          <>
            <div className="col-md-6 form-group">
              <Field
                component={renderField}
                extraProps={{ step: "0.001" }}
                type="number"
                label="Unpaid Break (hours)"
                name="unpaid_break"
                onBlur={AutoFilterAndAutoUpdateBreaks}
              />
            </div>
            <div className="col-md-6 form-group">
              <Field
                component={renderField}
                extraProps={{ step: "0.01" }}
                type="number"
                label="Paid Break (hours)"
                name="paid_break"
                onBlur={AutoFilterAndAutoUpdateBreaks}
              />
            </div>
            <div className="col-md-12 form-group">
              <Field
                label="Night Shift"
                component={renderToggleInput}
                name="night_shift"
              />
            </div>
          </>
        )}
        <div className="col-md-12 form-group">
          <Field
            component={renderField}
            textarea
            label="Remarks"
            name="remarks"
          />
        </div>
        <div className="form-group col-12 d-flex">
          <div className="ms-auto text-end">
            <p className="mb-0">
              <span className="text-dark fw-bolder">{totalHours()}</span> total
              hours
            </p>
            <small>Please ensure this is correct.</small>
          </div>
        </div>
        <div className="col-md-12 form-group">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const approverUrl = (branchId) => {
  if (!branchId) {
    return null;
  }

  return `timesheet-approval-users/users?branch_id=${branchId}`;
};

const mapStateToProps = (state, props) => {
  const selector = formValueSelector(props.form);

  return {
    startTime: selector(state, "start_time"),
    finishTime: selector(state, "finish_time"),
    nightShift: selector(state, "night_shift"),
    unpaidBreak: selector(state, "unpaid_break"),
    paidBreak: selector(state, "paid_break"),
    roleId: selector(state, "role_id"),
    date: selector(state, "date"),
    leaveReason: selector(state, "leave_reason_id"),
  };
};

const formatLeaveReasons = (data) => {
  return data.map((reason) => {
    return {
      label: reason.reason,
      value: reason.id,
    };
  });
};

const form = reduxForm({
  validate,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
});

export default connect(mapStateToProps, {
  modifyDate,
})(form(TimesheetForm));
