import { Button } from "reactstrap";
import { useState } from "react";
import { EquipmentStatus } from "../../equipment/equipmentTypes";
import dayjs from "dayjs";
import { SearchClient } from "algoliasearch";
import errorSwal from "../errorSwal";

interface ValueTransformer {
  [key: string]: (item: any) => string;
}

interface ExportCsvProps {
  searchClient: SearchClient;
  indexName: string;
  filters: string;
  headers: string[];
  valueTransformers?: ValueTransformer;
  filename?: string;
  searchQuery?: string;
}

const ExportCsvButton = ({
  searchClient, 
  indexName, 
  filters,
  headers,
  valueTransformers = {},
  filename = 'export',
  searchQuery
}: ExportCsvProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const downloadCSV = async () => {
    try {
      setIsLoading(true);
      let allHits: any[] = [];
      let page = 0;
      let hasMore = true;

      while (hasMore) {
        const searchResults = await searchClient.search([
          {
            indexName,
            params: {
              hitsPerPage: 250,
              filters,
              page: page,
              query: searchQuery,
            },
          },
        ]);

        if ('hits' in searchResults.results[0]) {
          const hits = searchResults.results[0].hits;
          if (searchResults.results[0].page !== searchResults.results[0].nbPages) {
            allHits = [...allHits, ...hits];
            page++;
          } else {
            hasMore = false;
          }
        }

        await new Promise(resolve => setTimeout(resolve, 200));
      }
      
      const csvRows = [headers.join(',')];

      allHits.forEach((item) => {
        const row = headers.map(header => {
          if (valueTransformers[header]) {
            return valueTransformers[header](item);
          }
          return `"${((item as any)[header] || '').toString().replace(/"/g, '""').replace(/[\n\r]+/g, ' ')}"`;
        });
        csvRows.push(row.join(','));
      });
    
      const csvData = new Blob([csvRows.join('\n')], { type: 'text/csv' });
      const csvURL = window.URL.createObjectURL(csvData);
      const link = document.createElement('a');
      link.href = csvURL;
      link.download = `${filename}_${dayjs().format('YYYY-MM-DD_HH-mm')}.csv`;
      link.click();
      window.URL.revokeObjectURL(csvURL);
      setIsLoading(false);
    } catch (error) {
      errorSwal(error);
      setIsLoading(false);
    }
  }

  return (
    <Button 
      outline 
      size="sm" 
      color="primary" 
      onClick={downloadCSV}
      disabled={isLoading}
    >
      {isLoading ? 'Loading...' : 'Download CSV'}
    </Button>
  );
};

export default ExportCsvButton;