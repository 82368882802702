import { Link } from "react-router-dom";
import HeaderPage from "../header/HeaderPage";
import DocsModal from "../utils/DocsModal";
import PaginatedList from "../pagination/PaginatedList";
import PaginatedCard from "../pagination/PaginatedCard";
import useRoles from "./hooks/useRoles";
import Avatar from "../utils/Avatar";

const OrganisationRoles = () => {
  const filters = [
    {
      label: "Has Position Description",
      name: "has_description",
      options: [
        {
          label: "Yes",
          value: true,
        },
        {
          label: "No",
          value: false,
        },
      ],
    },
  ];

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/organisation-setup-9316/roles/" />
      <HeaderPage
        titlePage="Roles"
        pageDescription={
          <>
            Roles in theBOSSapp are designed to attach required competencies and
            ensure their timely completion and must be associated with an
            existing division and skill category.
          </>
        }
        relatedLinks={[
          { name: "Employment Details", link: "/employment-details" },
        ]}
        crumbs={[
          { link: "/organisations/settings/links", name: "Organisations" },
          { link: "organisation-roles", name: "Roles", active: true },
        ]}
      />
      <PaginatedList
        indexHook={useRoles}
        originalFilters={filters}
        addLink="organisation-roles/add"
        itemCard={({ item }) => (
          <PaginatedCard
            key={item.uuid}
            info={getRoleInfo(item)}
            header={<RoleHeader role={item} />}
          />
        )}
      />
    </>
  );
};

const getRoleInfo = (role) => {
  const info = [
    {
      name: "Division",
      value: role.division?.name || "-",
      link: role.division?.uuid
        ? `/divisions/${role.division.uuid}`
        : undefined,
    },
  ];

  if (role.file?.link) {
    info.push({
      name: "Role Description",
      value: (
        <a
          href={role.file.link}
          target="_blank"
          rel="noreferrer"
          className="text-secondary"
        >
          Download
        </a>
      ),
    });
  }

  return info;
};

const RoleHeader = ({ role }) => {
  return (
    <div className="w-100">
      <div className="d-flex align-items-center w-100">
        <div style={{ maxWidth: "15%" }}>
          <Avatar name={role.name} colour="info" />
        </div>
        <div className="ms-1" style={{ width: "85%" }}>
          <p className="mb-0 fw-bolder tx-inverse">
            <Link to={`/organisation-roles/${role.uuid}/details`}>
              {role.name}
            </Link>
          </p>
          {role.display_number && (
            <p className="mb-0 tx-12">{role.display_number}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrganisationRoles;
