import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { clearTimesheetForm } from "../../actions/timesheetActions";
import { useAuth } from "../../context/auth-context";
import {
  TimesheetProvider,
  useUserTimesheet,
} from "../../context/timesheet-context";
import useApi from "../api/useApi";
import ApprovalButton from "../approvals/ApprovalButton";
import ApprovalComments from "../approvals/ApprovalComments";
import { formatNumber } from "../dashboard/OverviewCard";
import HeaderPage from "../header/HeaderPage";
import ApprovalStatus from "../user/ApprovalStatus";
import { AdministrationLevels } from "../user/userTypes";
import InformationAlert from "../utils/InformationAlert";
import Spinner from "../utils/Spinner";
import Tab from "../utils/tabs/Tab";
import TabList from "../utils/tabs/TabList";
import AddToPayRun from "./AddToPayRun";
import AllowancesCard from "./AllowancesCard";
import CurrentTimesheetProductivityOverview from "./CurrentTimesheetProductivityOverview";
import DeleteTimesheet from "./DeleteTimesheet";
import HourCard from "./HourCard";
import ProfileCards from "./ProfileCards";
import ShowTimesheetPage from "./ShowTimesheetPage";
import classNames from "classnames";

export const EDIT_TIMESHEET_FORM = "EditTimesheet";

const Timesheet = (props) => {
  const { user } = useAuth();
  const {
    timesheet,
    fetchTimesheet,
    updateTimesheet,
    productivity,
    setProductivity,
  } = useUserTimesheet();

  const isBasic =
    user?.position_administration_level === AdministrationLevels.Basic &&
    !user.is_admin;

  const [initialProductivity, setInitialProductivity] = useState(productivity);

  const [productivityUpdated, setProductivityUpdated] = useState(false);

  useEffect(() => {
    if (JSON.stringify(productivity) !== JSON.stringify(initialProductivity)) {
      setProductivityUpdated(true);
    }
  }, [JSON.stringify(productivity)]);

  const { uuid, page } = useParams();

  const { data: timesheetApprovers, setUrl } = useApi("", []);

  useEffect(() => {
    return () => {
      props.clearTimesheetForm();
      setProductivity([]);
    };
  }, []);

  useEffect(() => {
    if (timesheet) {
      setUrl(`timesheet-approval-users/users?branch_id=${timesheet.branch_id}`);
    }
  }, []);

  if (!timesheet) {
    return <Spinner loading />;
  }

  const displayName = `${timesheet.type_description} ${timesheet.display_date} - ${timesheet.user.name}`;

  // console.log(timesheet.role_id);
  return (
    <>
      <HeaderPage
        titlePage={displayName}
        crumbs={[
          {
            name: "Profile",
            link: `/users/${user.uuid}/timesheets`,
          },
          { name: displayName, link: "/", active: true },
        ]}
      />
      {isBasic ? null : (
        <>
          <ProfileCards timesheet={timesheet} />
          <div className="row my-3">
            <CurrentTimesheetProductivityOverview
              width="12"
              events={productivity}
              userUuid={timesheet.user_uuid}
              form={EDIT_TIMESHEET_FORM}
            />
          </div>
        </>
      )}

      <div className="row row-sm">
        {timesheet.pay_correction && (
          <div className="col-12 my-3">
            <InformationAlert type="warning" title="Pay Correction" />
          </div>
        )}
        <div className="col-lg-8">
          <div className="d-block d-lg-none my-3">
            <ApprovalStatus
              status={timesheet.approval_status}
              className="w-100 py-2"
            />
          </div>

          <RateCard timesheet={timesheet} className="d-block d-lg-none mb-3" />
          <ShowTimesheetPage
            timesheet={timesheet}
            fetchTimesheet={fetchTimesheet}
            updateTimesheet={updateTimesheet}
            productivity={productivity}
            setProductivity={setProductivity}
            productivityUpdated={productivityUpdated}
            {...props}
          />
        </div>
        <div className="col-lg-4 mg-t-20 mg-lg-t-0">
          <TabList>
            <Tab
              link={`/timesheets/${uuid}/details`}
              active={page === "details"}
              icon="fa fa-pencil text-capitalize"
            >
              Details
            </Tab>
            {!timesheet.leave_reason_id && (
              <Tab
                link={`/timesheets/${uuid}/productivity`}
                active={page === "productivity"}
                icon="fa fa-briefcase text-capitalize"
              >
                Productivity
              </Tab>
            )}
            <Tab
              link={`/timesheets/${uuid}/check-ins`}
              active={page === "check-ins"}
              icon="fa fa-map-marker text-capitalize"
            >
              Check-ins
            </Tab>
            <Tab
              link={`/timesheets/${uuid}/documents`}
              active={page === "documents"}
              icon="fa fa-folder-open-o text-capitalize"
            >
              Documents
            </Tab>
          </TabList>
          <RateCard timesheet={timesheet} className="d-none d-lg-block mt-3" />
          <AllowancesCard
            timesheet={timesheet}
            setTimesheet={updateTimesheet}
          />
          <ApprovalButton
            {...props}
            approvalAction={timesheet.approval_action}
            canTransfer
            transferUsers={timesheetApprovers}
          />
          <DeleteTimesheet name={displayName} timesheet={timesheet} />
          <AddToPayRun timesheet={timesheet} onSuccess={fetchTimesheet} />
          {timesheet.requested_leave ? (
            <Link
              to={timesheet.requested_leave.link}
              className="my-3 btn w-100 btn-outline-primary"
            >
              Leave Request
            </Link>
          ) : null}
          <div className="d-none d-lg-block mt-3">
            <ApprovalStatus
              status={timesheet.approval_status}
              className="w-100 py-2"
            />
          </div>
          <ApprovalComments
            className="mg-t-20"
            approvalRemarks={timesheet.approval_action_remarks}
          />
          <HourCard {...props} timesheet={timesheet} className="mg-t-20" />
        </div>
      </div>
    </>
  );
};

const RateCard = ({ timesheet, className }) => {
  if (!timesheet.hourly_rates_for_day) {
    return (
      <div className={classNames(className, "card")}>
        <div className="card-body">
          <p className="mb-0">
            Added on{" "}
            <span className="fw-bolder text-dark">
              {dayjs(timesheet.created_at).format("DD/MM/YYYY")}
            </span>{" "}
            at{" "}
            <span className="fw-bolder text-dark">
              {dayjs(timesheet.created_at).format("HH:mm")}
            </span>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={`card ${className || ""}`}>
      <div className="card-body">
        <small className="mb-0">
          Hourly Rate{timesheet.hourly_rates_for_day.length > 1 ? "s" : ""}:
        </small>
        <div className="space-y-1">
          {timesheet.hourly_rates_for_day.map((rate, index) => {
            return (
              <p className="mb-0" key={index}>
                From{" "}
                <span className="tx-inverse tx-bold">{rate.from ?? 0}</span> to{" "}
                <span className="tx-inverse tx-bold">
                  {rate.to ?? timesheet.total_hours}
                </span>{" "}
                hours:{" "}
                <span className="tx-16 tx-bold tx-inverse">
                  ${formatNumber(rate.pay_rate)}
                </span>
              </p>
            );
          })}
        </div>
        <small className="mb-0 mt-2">
          Added on {dayjs(timesheet.created_at).format("DD/MM/YYYY")} at{" "}
          {dayjs(timesheet.created_at).format("HH:mm")}
        </small>
      </div>{" "}
    </div>
  );
};

const Profile = connect(null, { clearTimesheetForm })(Timesheet);

const TimesheetProfile = () => {
  return (
    <TimesheetProvider>
      <Profile />
    </TimesheetProvider>
  );
};

export default TimesheetProfile;
