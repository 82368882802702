import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import ReactTable from "../table/ReactTable";
import SpreadPrice from "../utils/SpreadPrice";
import { Invoice } from "./invoiceTypes";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

interface UnpaidInvoicesTableProps {
  invoices: Invoice[];
}

const UnpaidInvoicesTable: React.FC<UnpaidInvoicesTableProps> = ({
  invoices,
}) => {
  const [sorting, setSorting] = useState([{ id: "total_due", desc: true }]);

  // Add CSS to make the popover wider
  useEffect(() => {
    // Add custom CSS for the popover
    const style = document.createElement("style");
    style.innerHTML = `
      .unpaid-invoices-popover {
        max-width: 800px !important;
        width: auto !important;
      }
      .unpaid-invoices-popover .popover-body {
        padding: 15px;
      }
      .unpaid-invoices-table .table-responsive {
        max-height: 400px;
        overflow-y: auto;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  if (!invoices || invoices.length === 0)
    return <p>No unpaid invoices found.</p>;

  const columns = [
    {
      accessorKey: "name",
      header: "Invoice Number",
      cell: (info: any) => {
        const invoice = info.row.original;
        return (
          <>
            <Link to={invoice.link}>{info.getValue()}</Link>
            {invoice.badge && (
              <>
                <br />
                <Badge color={invoice.badge.color}>{invoice.badge.text}</Badge>
              </>
            )}
          </>
        );
      },
    },
    {
      accessorKey: "current_total_due",
      header: "Amount Due",
      cell: (info: any) => (
        <SpreadPrice price={info.row.original.current_total_due} />
      ),
    },
    {
      accessorKey: "due_date",
      header: "Due Date",
      cell: (info: any) => {
        const dueDate = info.row.original.due_date;
        return dueDate ? dayjs(dueDate).format("DD/MM/YYYY") : "-";
      },
    },
    {
      accessorKey: "project.name",
      header: "Project",
      cell: (info: any) => {
        const invoice = info.row.original;

        if (!invoice.project || !invoice.project.link) {
          return info.getValue() || "-";
        }

        return (
          <Link to={`/${invoice.project.link}`}>{invoice.project.name}</Link>
        );
      },
    },
    {
      accessorKey: "client.name",
      header: "Client",
      cell: (info: any) => {
        const invoice = info.row.original;
        if (!invoice.client || !invoice.client.link) {
          return info.getValue() || "-";
        }
        return <Link to={invoice.client.link}>{invoice.client.name}</Link>;
      },
    },
    {
      accessorKey: "is_overdue",
      header: "Status",
      cell: (info: any) => {
        const isOverdue = info.row.original.is_overdue === "1";
        return isOverdue ? (
          <Badge color="danger">Overdue</Badge>
        ) : (
          <Badge color="warning">Pending</Badge>
        );
      },
    },
  ];

  return (
    <div className="unpaid-invoices-table">
      <h6 className="mb-3">Unpaid Invoices</h6>
      <ReactTable
        disableSearch
        columns={columns}
        data={invoices}
        sorting={sorting}
        setSorting={setSorting}
      />
    </div>
  );
};

export default UnpaidInvoicesTable;
