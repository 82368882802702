import { useQuery } from "react-query";
import api from "../../api/api";

const getUnapprovedLeaveDates = (id) => {
  return api.get(`users/${id}/pending-requested-leave`).then(({ data }) => {
    // Call the function to get all leave dates
    return data.data;
  });
};

export default function useUnapprovedLeaveDates(id) {
  return useQuery(["unapprove-leave-dates", id], () => getUnapprovedLeaveDates(id));
}