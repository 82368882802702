import { BootstrapColor } from "./../ProductivityProgress";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { ProductivityStatus } from "../timesheetTypes";
import { RoleType } from "./../../home/OverallProductivityOverview";
import { ProductivityEvent } from "./../timesheetTypes";

dayjs.extend(duration);

export const getTime = (event: ProductivityEvent): number => {
  const start = dayjs(event.start);
  const end = dayjs(event.end);
  const duration = dayjs.duration(end.diff(start));
  const durationAsMinutes = duration.asMinutes();
  return durationAsMinutes / 60;
};

export const amountOfHours = (events: ProductivityEvent[]): number => {
  return events.reduce((accumulator, event) => {
    return getTime(event) + accumulator;
  }, 0);
};

export const getHours = (
  startTime?: string,
  finishTime?: string,
  nightShift?: boolean,
  unpaidBreak?: number,
): number => {
  if (!startTime || !finishTime) {
    return 0;
  }

  const start = dayjs(`1970-01-01 ${startTime}`);
  const finish = dayjs(`1970-01-01 ${finishTime}`);
  let hours = finish.diff(start, "minutes") / 60;
  if (nightShift) hours = finish.add(1, "day").diff(start, "minutes") / 60;

  if (unpaidBreak) hours = hours - unpaidBreak;

  return hours;
};

export const productivityStatus = (
  percent: number,
  role: RoleType,
): ProductivityStatus => {
  if (!role?.id) {
    return ProductivityStatus.OK;
  }

  console.log(role.position.minimum_productivity);

  if (
    role.position.minimum_productivity &&
    percent < role.position.minimum_productivity
  ) {
    return ProductivityStatus.BELOW;
  }

  if (
    role.position.maximum_productivity &&
    percent > role.position.maximum_productivity
  ) {
    return ProductivityStatus.ABOVE;
  }

  return ProductivityStatus.OK;
};

export const calculateProgressColor = (
  status: ProductivityStatus,
): BootstrapColor => {
  if ([ProductivityStatus.ABOVE, ProductivityStatus.BELOW].includes(status)) {
    return "danger";
  }

  return "primary";
};
