import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { useAuth } from "../../context/auth-context";
import HeaderPage from "../header/HeaderPage";
import { GrNext } from "react-icons/gr";
import TextButton from "../utils/TextButton";
import { GrPrevious } from "react-icons/gr";
import useApi from "../api/useApi";
import { ActualSpending } from "./budget/budgetTypes";
import { IUseApi, IUseApiWithData } from "../api/apiTypes";
import useModal from "../hooks/useModal";
import Select, { MultiValue } from "react-select";
import { Button } from "reactstrap";
import { BudgetItem } from "./types";
import RevenueBudgetTable from "./budget/RevenueBudgetTable";
import {
  getMonthColumns,
  getFinancialYearName,
  getFilteredBudgetRows,
  filterActualSpendingByAccountType,
  getBudgetRows,
  calculateMonthlyTotals,
} from "./budget/budgetUtils";
import {
  createMainRevenueRow,
  createAdjustmentRevenueRow,
  createSubcontractorMarkupRows,
  calculateRevenueTotals,
} from "./budget/revenueUtils";
import { AccountType } from "../chartOfAccounts/chartOfAccountTypes";
import BudgetTable from "./budget/BudgetTable";
import BudgetSummary from "./budget/BudgetSummary";
import { GetEstimatedCostsOfSales, SelectMonths } from "./BranchBudgets";
import LoadingOverlay from "../utils/LoadingOverlay";

interface Dashboard {
  label: string;
  value: string;
  model: string;
  uuid: string;
  tracking_site: string;
  division: string;
}

interface BranchData {
  uuid: string;
  name: string;
  budgetItems: any[];
  budgetLock: any;
  actualSpending: any;
  actualRevenue: any;
}

const columnStyles = {
  account: { width: 200 },
  accountEditing: { minWidth: 300 },
  month: { width: 150 },
} as const;

const ConsolidatedBudgets = () => {
  const location = useLocation();
  const { user } = useAuth();

  const [selectedFilters, setSelectedFilters] = useState<
    { label: string; value: string }[]
  >([]);

  const history = useHistory();

  const { toggle: toggleActuals, modal: showActuals } = useModal(true);

  const params = new URLSearchParams(location.search);
  const branchesParam = params.get("branches");
  const branches = branchesParam ? branchesParam.split(",") : [];

  const { data: organisation } = useApi(
    `organisations/${user?.active_organisation?.uuid}`,
    null,
  );

  const [financialYear, setFinancialYear] = useState<[string, string]>([
    dayjs().month() >= 6
      ? dayjs().startOf("year").month(6).format("YYYY-MM-DD")
      : dayjs()
          .subtract(1, "year")
          .startOf("year")
          .month(6)
          .format("YYYY-MM-DD"),
    dayjs().month() >= 6
      ? dayjs()
          .add(1, "year")
          .startOf("year")
          .month(5)
          .endOf("month")
          .format("YYYY-MM-DD")
      : dayjs().startOf("year").month(5).endOf("month").format("YYYY-MM-DD"),
  ]);

  // Set financial year (July-June) instead of calendar year
  const [period, setPeriod] = useState<[string, string]>([
    financialYear[0],
    financialYear[1],
  ]);

  useEffect(() => {
    setPeriod([financialYear[0], financialYear[1]]);
  }, [financialYear]);

  const [filters, setFilters] = useState<
    "branches" | "tracking_sites" | "divisions" | "organisations"
  >("branches");

  const {
    data: budgetItems,
    setUrl: setBudgetItemsUrl,
    loading: budgetItemsLoading,
  }: IUseApi<BudgetItem[]> = useApi(
    `branches/budget-items?filter[financial_year][]=${period[0]}&filter[financial_year][]=${period[1]}&branches=${branchesParam}`,
    [],
  );

  const {
    data: actualSpending,
    setUrl: setActualSpendingUrl,
  }: IUseApi<ActualSpending> = useApi(
    `branches/actual-spending?start_date=${period[0]}&end_date=${period[1]}&branches=${branchesParam}`,
    {},
  );

  useEffect(() => {
    if (selectedFilters.length === 0) {
      history.replace("/branch-budgets");
    }

    let branchQuery = "";
    let organisationQuery = "";

    if (filters === "branches") {
      branchQuery = selectedFilters.map((f) => f.value).join(",");

      history.replace(`/branch-budgets?branches=${branchQuery}`);
    }

    if (filters === "tracking_sites") {
      const trackingSites = selectedFilters.map((f) => f.value);

      const selectedBranches = availableBranches?.filter((b) =>
        trackingSites.includes(b.tracking_site),
      );

      branchQuery = selectedBranches?.map((b) => b.uuid).join(",");

      history.replace(`/branch-budgets?branches=${branchQuery}`);
    }

    if (filters === "divisions") {
      const divisions = selectedFilters.map((f) => f.value);

      const selectedBranches = availableBranches?.filter((b) =>
        divisions.includes(b.division),
      );

      branchQuery = selectedBranches?.map((b) => b.uuid).join(",");

      history.replace(`/branch-budgets?branches=${branchQuery}`);
    }

    if (filters === "organisations") {
      const organisations = selectedFilters.map((f) => f.value);

      const selectedOrganisations = organisation?.children?.filter((c: any) =>
        organisations.includes(c.id),
      );

      organisationQuery = selectedOrganisations
        ?.map((o: any) => o.id)
        .join(",");

      history.replace(`/branch-budgets?organisations=${organisationQuery}`);
    }

    setActualSpendingUrl(
      `branches/actual-spending?start_date=${period[0]}&end_date=${
        period[1]
      }&branches=${branchQuery}${
        organisationQuery ? `&organisations=${organisationQuery}` : ""
      }`,
    );

    setBudgetItemsUrl(
      `branches/budget-items?filter[financial_year][]=${
        period[0]
      }&filter[financial_year][]=${period[1]}&branches=${branchQuery}${
        organisationQuery ? `&organisations=${organisationQuery}` : ""
      }`,
    );
  }, [selectedFilters, period]);

  useEffect(() => {
    Array.from(document.getElementsByClassName("container")).forEach(
      (container) => {
        container.classList.remove("container");
        container.classList.add("container-large");
      },
    );

    return () => {
      Array.from(document.getElementsByClassName("container-large")).forEach(
        (container) => {
          container.classList.remove("container-large");
          container.classList.add("container");
        },
      );
    };
  }, [user?.navigation]);

  // Fetch available branches
  const { data: dashboards }: IUseApiWithData<Dashboard[]> = useApi(
    "user-dashboards",
    [],
  );

  // Calculate months based on period
  const months = getMonthColumns(period);
  const financialYearName = getFinancialYearName(financialYear);

  // Process budget data when it's loaded
  useEffect(() => {
    if (!budgetItems) return;
    // Process budget data here
  }, [budgetItems]);

  // Create revenue rows
  const mainRevenueRow = budgetItems
    ? createMainRevenueRow(budgetItems, period)
    : { amounts: [], account: null };

  const adjustmentRevenueRow = budgetItems
    ? createAdjustmentRevenueRow(budgetItems, period)
    : { amounts: [], account: null };

  // We need to create budget rows from the consolidated data
  const budgetRows = budgetItems ? getBudgetRows(budgetItems, period) : [];

  // Get rows for each type
  const costOfSalesRows = getFilteredBudgetRows(budgetRows, "cost_of_sales");

  const operatingExpensesRows = getFilteredBudgetRows(
    budgetRows,
    "operating_expenses",
  );
  const capexRows = getFilteredBudgetRows(budgetRows, "capex");

  // Calculate subcontractor markup rows (if we have branch data)
  const subcontractorMarkupRows = createSubcontractorMarkupRows(
    costOfSalesRows,
    period,
  );

  // Calculate totals for each type
  const revenueTotals = calculateRevenueTotals(
    mainRevenueRow,
    adjustmentRevenueRow,
    subcontractorMarkupRows,
  );
  const costOfSalesTotals = calculateMonthlyTotals(costOfSalesRows);
  const operatingExpensesTotals = calculateMonthlyTotals(operatingExpensesRows);
  const capexTotals = calculateMonthlyTotals(capexRows);

  // Filter actual spending for each budget table
  const costOfSalesActualSpending = filterActualSpendingByAccountType(
    actualSpending || {},
    AccountType.DirectCosts,
  );

  const operatingExpensesActualSpending = filterActualSpendingByAccountType(
    actualSpending || {},
    AccountType.Expense,
  );

  const capexActualSpending = filterActualSpendingByAccountType(
    actualSpending || {},
    AccountType.FixedAsset,
  );

  const availableBranches = dashboards?.filter(
    (dashboard) => dashboard.model === "App\\Models\\OrganisationBranch",
  );

  const trackingSites = new Set(
    availableBranches?.map((branch) => branch.tracking_site),
  );

  const divisions = new Set(
    availableBranches?.map((branch) => branch.division),
  );

  return (
    <>
      <HeaderPage
        titlePage="Consolidated Branch Budgets"
        crumbs={[
          { name: "Dashboard", link: "/" },
          { name: "Consolidated Branch Budgets" },
        ]}
      />

      <div className="position-relative">
        <LoadingOverlay loading={budgetItemsLoading} />

        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center space-x-3">
            <h5 className="text-dark mb-0">{financialYearName}</h5>
          </div>
          <div className="ms-auto d-flex space-x-2">
            <SelectMonths
              period={period}
              setPeriod={setPeriod}
              financialYear={financialYear}
            />

            <TextButton
              title={showActuals ? "Hide Actuals" : "Show Actuals"}
              onClick={toggleActuals}
              className="text-secondary"
            >
              {showActuals ? "Hide Actuals" : "Show Actuals"}
            </TextButton>
          </div>
          <div className="d-flex space-x-3 ms-4">
            <TextButton
              className="text-dark"
              title="Go to Previous Financial Year"
              onClick={() =>
                setFinancialYear([
                  dayjs(financialYear[0])
                    .subtract(1, "year")
                    .format("YYYY-MM-DD"),
                  dayjs(financialYear[1])
                    .subtract(1, "year")
                    .format("YYYY-MM-DD"),
                ])
              }
            >
              <GrPrevious />
            </TextButton>
            <TextButton
              className="text-dark"
              title="Go to Next Financial Year"
              onClick={() =>
                setFinancialYear([
                  dayjs(financialYear[0]).add(1, "year").format("YYYY-MM-DD"),
                  dayjs(financialYear[1]).add(1, "year").format("YYYY-MM-DD"),
                ])
              }
            >
              <GrNext />
            </TextButton>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-4">
            <div className="d-flex justify-content-between mb-1">
              <label className="form-control-label tx-inverse tx-semibold">
                {filters.replace("_", " ").toUpperCase()}
              </label>
              <TextButton
                className="text-secondary border"
                onClick={() => {
                  if (filters === "branches") {
                    setSelectedFilters(
                      availableBranches?.map((b) => ({
                        label: b.label,
                        value: b.uuid,
                      })),
                    );
                  }

                  if (filters === "tracking_sites") {
                    setSelectedFilters(
                      Array.from(trackingSites).map((t) => ({
                        label: t,
                        value: t,
                      })),
                    );
                  }

                  if (filters === "divisions") {
                    setSelectedFilters(
                      Array.from(divisions).map((d) => ({
                        label: d,
                        value: d,
                      })),
                    );
                  }
                }}
              >
                Add All
              </TextButton>
            </div>
            <Select
              value={selectedFilters}
              onChange={(
                selectedOptions: MultiValue<{ label: string; value: string }>,
              ) => {
                // @ts-ignore
                setSelectedFilters(selectedOptions);
              }}
              options={
                filters === "branches"
                  ? availableBranches?.map((b) => ({
                      label: b.label,
                      value: b.uuid,
                    }))
                  : filters === "tracking_sites"
                  ? Array.from(trackingSites).map((t) => ({
                      label: t,
                      value: t,
                    }))
                  : filters === "organisations"
                  ? organisation?.children?.map((c: any) => ({
                      label: c.name,
                      value: c.id,
                    }))
                  : Array.from(divisions).map((d) => ({
                      label: d,
                      value: d,
                    }))
              }
              isMulti
            />
          </div>
          <div className="col-lg-6 d-flex align-items-end">
            <div className="d-flex space-x-3">
              <Button
                outline={filters !== "branches"}
                onClick={() => {
                  setFilters("branches");
                  setSelectedFilters([]);
                }}
                size="sm"
              >
                Branches
              </Button>
              <Button
                outline={filters !== "tracking_sites"}
                onClick={() => {
                  setFilters("tracking_sites");
                  setSelectedFilters([]);
                }}
                size="sm"
              >
                Tracking Sites
              </Button>
              <Button
                outline={filters !== "divisions"}
                onClick={() => {
                  setFilters("divisions");
                  setSelectedFilters([]);
                }}
                size="sm"
              >
                Divisions
              </Button>
              {organisation?.children?.length > 0 && (
                <Button
                  outline={filters !== "organisations"}
                  onClick={() => {
                    setFilters("organisations");
                    setSelectedFilters([]);
                  }}
                  size="sm"
                >
                  Organisations
                </Button>
              )}
            </div>
          </div>
        </div>

        <RevenueBudgetTable
          months={months}
          mainRow={mainRevenueRow}
          refreshBudgetItems={() => {}}
          adjustmentRow={adjustmentRevenueRow}
          monthlyTotals={revenueTotals}
          onAmountBlur={() => {}}
          columnStyles={columnStyles}
          subcontractorMarkupRows={subcontractorMarkupRows}
          budgetLock={undefined}
          period={period}
          showActuals={showActuals}
          isReadOnly={true}
        />

        <BudgetTable
          title="Cost of Sales"
          budgetRows={costOfSalesRows}
          months={months}
          accounts={[]}
          monthlyTotals={costOfSalesTotals}
          onAccountSelect={() => {}}
          onAmountBlur={() => {}}
          showAddButton={false}
          type="cost_of_sales"
          extraRows={<GetEstimatedCostsOfSales months={months} />}
          columnStyles={columnStyles}
          branch={undefined}
          refreshBudgetItems={() => {}}
          budgetLock={undefined}
          period={period}
          actualSpending={costOfSalesActualSpending}
          showActuals={showActuals}
          isReadOnly={true}
        />

        <BudgetTable
          title="Operating Expenses"
          budgetRows={operatingExpensesRows}
          months={months}
          accounts={[]}
          monthlyTotals={operatingExpensesTotals}
          onAccountSelect={() => {}}
          onAmountBlur={() => {}}
          showAddButton={false}
          type="operating_expenses"
          columnStyles={columnStyles}
          branch={undefined}
          refreshBudgetItems={() => {}}
          budgetLock={undefined}
          period={period}
          actualSpending={operatingExpensesActualSpending}
          showActuals={showActuals}
          isReadOnly={true}
        />

        <BudgetTable
          title="Capital Expenditure (CAPEX)"
          budgetRows={capexRows}
          months={months}
          accounts={[]}
          monthlyTotals={capexTotals}
          onAccountSelect={() => {}}
          onAmountBlur={() => {}}
          showAddButton={false}
          type="capex"
          columnStyles={columnStyles}
          branch={undefined}
          refreshBudgetItems={() => {}}
          budgetLock={undefined}
          period={period}
          actualSpending={capexActualSpending}
          showActuals={showActuals}
          isReadOnly={true}
        />

        <BudgetSummary
          months={months}
          revenueTotals={revenueTotals}
          costOfSalesTotals={costOfSalesTotals}
          operatingExpensesTotals={operatingExpensesTotals}
          capexTotals={capexTotals}
          branch={null}
          columnStyles={columnStyles}
        />
      </div>
    </>
  );
};

export default ConsolidatedBudgets;
