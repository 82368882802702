import dayjs from "dayjs";
import SpreadPrice from "../utils/SpreadPrice";
import DetailsTable from "./DetailsTable";
import { FiDownload } from "react-icons/fi";

const ExpenseClaimsTable = (props: any) => {
  const columns = [
    {
      accessorKey: "user",
      header: "User",
      cell: (info: any) => {
        const expenseClaim = info.row.original;

        return (
          <div>
            <p className="mb-0 text-dark">{info.getValue()}</p>
          </div>
        );
      },
    },
    {
      accessorKey: "description",
      header: "Description",
      cell: (info: any) => {
        return <p className="mb-0 text-dark">{info.getValue()}</p>;
      },
    },
    {
      accessorKey: "date",
      header: "Date",
      cell: (info: any) => dayjs(info.getValue()).format("DD/MM/YYYY"),
    },
    {
      accessorKey: "amount",
      header: "Amount",
      cell(info: any) {
        return <SpreadPrice price={info.getValue()} />;
      },
    },
    {
      accessorKey: "receipt",
      header: "Receipt",
      cell: (info: any) => {
        const expenseClaim = info.row.original;

        return (
          <a
            href={info.getValue().link}
            target="_blank"
            rel="noopener noreferrer"
            className="text-secondary"
          >
            <FiDownload /> Download
          </a>
        );
      },
    },
  ];

  return (
    <DetailsTable
      url="expense-claim-dashboard"
      {...props}
      title="Expense Claims"
      columns={columns}
    />
  );
};

export default ExpenseClaimsTable;
