import dayjs from "dayjs";
import { BudgetRow, MonthlyTotals } from "./types";
import { BiDownload } from "react-icons/bi";
import TextButton from "../utils/TextButton";
import { Branch } from "./branchTypes";

interface Props {
  months: string[];
  financialYearName: string;
  disabled?: boolean;
  mainRevenueRow: BudgetRow;
  adjustmentRevenueRow: BudgetRow;
  subcontractorMarkupRows: BudgetRow[];
  costOfSalesRows: BudgetRow[];
  operatingExpensesRows: BudgetRow[];
  capexRows: BudgetRow[];
  revenueTotals: MonthlyTotals;
  costOfSalesTotals: MonthlyTotals;
  operatingExpensesTotals: MonthlyTotals;
  capexTotals: MonthlyTotals;
  branch?: Branch | null;
}

const ExportBudgetCSV = ({
  months,
  financialYearName,
  disabled,
  mainRevenueRow,
  adjustmentRevenueRow,
  subcontractorMarkupRows,
  costOfSalesRows,
  operatingExpensesRows,
  capexRows,
  revenueTotals,
  costOfSalesTotals,
  operatingExpensesTotals,
  capexTotals,
  branch,
}: Props) => {
  const handleExport = () => {
    // Helper function to format amounts
    const formatAmount = (amount: string | undefined | number): number => {
      if (!amount) return 0;
      return typeof amount === "string" ? parseFloat(amount) : amount;
    };

    // Helper function to get row total
    const getRowTotal = (row: BudgetRow): number => {
      return row.amounts.reduce(
        (sum, amount) => sum + formatAmount(amount.amount),
        0,
      );
    };

    // Helper function to calculate revenue breakdown based on percentage
    const calculateRevenueBreakdown = (
      month: string,
      percentage: number | undefined,
    ): number => {
      if (!percentage) return 0;
      return ((revenueTotals[month] || 0) * percentage) / 100;
    };

    // Helper function to calculate total revenue breakdown
    const calculateTotalRevenueBreakdown = (
      percentage: number | undefined,
    ): number => {
      if (!percentage) return 0;
      const totalRevenue = Object.values(revenueTotals).reduce(
        (a, b) => a + b,
        0,
      );
      return (totalRevenue * percentage) / 100;
    };

    // Prepare the CSV data
    const csvRows = [
      // Header
      ["Budget Report - " + financialYearName],
      [""],

      // Revenue Section
      ["Revenue"],
      [
        "Account",
        ...months.map((month) => dayjs(month).format("MMM YYYY")),
        "Total",
      ],
      [
        "Main Revenue",
        ...mainRevenueRow.amounts.map((amount) => formatAmount(amount.amount)),
        getRowTotal(mainRevenueRow),
      ],
      [
        "Adjustments",
        ...adjustmentRevenueRow.amounts.map((amount) =>
          formatAmount(amount.amount),
        ),
        getRowTotal(adjustmentRevenueRow),
      ],

      // Subcontractor Markup Rows
      ...subcontractorMarkupRows.map((row) => [
        `Subcontractor Markup - ${
          row.amounts[0]?.subcontractor_account_name || ""
        }`,
        ...row.amounts.map((amount) => formatAmount(amount.amount)),
        getRowTotal(row),
      ]),

      // Revenue Totals
      [
        "Revenue Totals",
        ...months.map((month) => revenueTotals[month] || 0),
        Object.values(revenueTotals).reduce((a, b) => a + b, 0),
      ],

      // Revenue Breakdowns - only include if percentages are defined
      ...(branch?.recurring_revenue_percentage
        ? [
            [
              `Recurring Revenue (${branch.recurring_revenue_percentage}%)`,
              ...months.map((month) =>
                calculateRevenueBreakdown(
                  month,
                  branch.recurring_revenue_percentage,
                ),
              ),
              calculateTotalRevenueBreakdown(
                branch.recurring_revenue_percentage,
              ),
            ],
          ]
        : []),

      ...(branch?.key_client_percentage
        ? [
            [
              `Key Client Revenue (${branch.key_client_percentage}%)`,
              ...months.map((month) =>
                calculateRevenueBreakdown(month, branch.key_client_percentage),
              ),
              calculateTotalRevenueBreakdown(branch.key_client_percentage),
            ],
          ]
        : []),

      ...(branch?.key_project_percentage
        ? [
            [
              `Key Project Revenue (${branch.key_project_percentage}%)`,
              ...months.map((month) =>
                calculateRevenueBreakdown(month, branch.key_project_percentage),
              ),
              calculateTotalRevenueBreakdown(branch.key_project_percentage),
            ],
          ]
        : []),

      ...(branch?.one_off_client_percentage
        ? [
            [
              `One-off Client Revenue (${branch.one_off_client_percentage}%)`,
              ...months.map((month) =>
                calculateRevenueBreakdown(
                  month,
                  branch.one_off_client_percentage,
                ),
              ),
              calculateTotalRevenueBreakdown(branch.one_off_client_percentage),
            ],
          ]
        : []),

      [""],

      // Cost of Sales Section
      ["Cost of Sales"],
      [
        "Account",
        ...months.map((month) => dayjs(month).format("MMM YYYY")),
        "Total",
      ],
      ...costOfSalesRows.map((row) => [
        row.account ? `${row.account.code} - ${row.account.name}` : "",
        ...row.amounts.map((amount) => -Math.abs(formatAmount(amount.amount))),
        -Math.abs(getRowTotal(row)),
      ]),
      [
        "Cost of Sales Totals",
        ...months.map((month) => costOfSalesTotals[month] || 0),
        Object.values(costOfSalesTotals).reduce((a, b) => a + b, 0),
      ],
      [""],

      // Operating Expenses Section
      ["Operating Expenses"],
      [
        "Account",
        ...months.map((month) => dayjs(month).format("MMM YYYY")),
        "Total",
      ],
      ...operatingExpensesRows.map((row) => [
        row.account ? `${row.account.code} - ${row.account.name}` : "",
        ...row.amounts.map((amount) => -Math.abs(formatAmount(amount.amount))),
        -Math.abs(getRowTotal(row)),
      ]),
      [
        "Operating Expenses Totals",
        ...months.map((month) => operatingExpensesTotals[month] || 0),
        Object.values(operatingExpensesTotals).reduce((a, b) => a + b, 0),
      ],
      [""],

      // CAPEX Section
      ["Capital Expenditure (CAPEX)"],
      [
        "Account",
        ...months.map((month) => dayjs(month).format("MMM YYYY")),
        "Total",
      ],
      ...capexRows.map((row) => [
        row.account ? `${row.account.code} - ${row.account.name}` : "",
        ...row.amounts.map((amount) => -Math.abs(formatAmount(amount.amount))),
        -Math.abs(getRowTotal(row)),
      ]),
      [
        "CAPEX Totals",
        ...months.map((month) => capexTotals[month] || 0),
        Object.values(capexTotals).reduce((a, b) => a + b, 0),
      ],
      [""],

      // Summary Section
      ["Budget Summary"],
      [
        "Category",
        ...months.map((month) => dayjs(month).format("MMM YYYY")),
        "Total",
      ],
      [
        "Revenue",
        ...months.map((month) => revenueTotals[month] || 0),
        Object.values(revenueTotals).reduce((a, b) => a + b, 0),
      ],

      // Include revenue breakdowns in summary section too
      ...(branch?.recurring_revenue_percentage
        ? [
            [
              `  Recurring Revenue (${branch.recurring_revenue_percentage}%)`,
              ...months.map((month) =>
                calculateRevenueBreakdown(
                  month,
                  branch.recurring_revenue_percentage,
                ),
              ),
              calculateTotalRevenueBreakdown(
                branch.recurring_revenue_percentage,
              ),
            ],
          ]
        : []),

      ...(branch?.key_client_percentage
        ? [
            [
              `  Key Client Revenue (${branch.key_client_percentage}%)`,
              ...months.map((month) =>
                calculateRevenueBreakdown(month, branch.key_client_percentage),
              ),
              calculateTotalRevenueBreakdown(branch.key_client_percentage),
            ],
          ]
        : []),

      ...(branch?.key_project_percentage
        ? [
            [
              `  Key Project Revenue (${branch.key_project_percentage}%)`,
              ...months.map((month) =>
                calculateRevenueBreakdown(month, branch.key_project_percentage),
              ),
              calculateTotalRevenueBreakdown(branch.key_project_percentage),
            ],
          ]
        : []),

      ...(branch?.one_off_client_percentage
        ? [
            [
              `  One-off Client Revenue (${branch.one_off_client_percentage}%)`,
              ...months.map((month) =>
                calculateRevenueBreakdown(
                  month,
                  branch.one_off_client_percentage,
                ),
              ),
              calculateTotalRevenueBreakdown(branch.one_off_client_percentage),
            ],
          ]
        : []),

      [
        "Cost of Sales",
        ...months.map((month) => costOfSalesTotals[month] || 0),
        Object.values(costOfSalesTotals).reduce((a, b) => a + b, 0),
      ],
      [
        "Operating Expenses",
        ...months.map((month) => operatingExpensesTotals[month] || 0),
        Object.values(operatingExpensesTotals).reduce((a, b) => a + b, 0),
      ],
      [
        "CAPEX",
        ...months.map((month) => capexTotals[month] || 0),
        Object.values(capexTotals).reduce((a, b) => a + b, 0),
      ],
      [
        "Net Profit",
        ...months.map((month) => {
          return (
            (revenueTotals[month] || 0) +
            (costOfSalesTotals[month] || 0) +
            (operatingExpensesTotals[month] || 0) +
            (capexTotals[month] || 0)
          );
        }),
        Object.values(revenueTotals).reduce((a, b) => a + b, 0) +
          Object.values(costOfSalesTotals).reduce((a, b) => a + b, 0) +
          Object.values(operatingExpensesTotals).reduce((a, b) => a + b, 0) +
          Object.values(capexTotals).reduce((a, b) => a + b, 0),
      ],
    ];

    // Convert to CSV string
    const csvContent = csvRows.map((row) => row.join(",")).join("\n");

    // Create and trigger download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);

    link.setAttribute(
      "download",
      `budget_${financialYearName.replace(/\s/g, "_")}.csv`,
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <TextButton
      className="text-secondary"
      onClick={handleExport}
      disabled={disabled}
      title={`Export budget to CSV`}
    >
      <BiDownload size={20} />
    </TextButton>
  );
};

export default ExportBudgetCSV;
