import { Field, reduxForm } from "redux-form";
import SubmitButton from "../utils/SubmitButton";
import required from "../utils/required";
import SelectInput from "../form/SelectInput";
import RenderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";

const frequencies = [
  { label: "Weekly", value: "weekly" },
  { label: "Fortnightly", value: "fortnightly" },
  { label: "Monthly", value: "monthly" },
];

const PayScheduleForm = (props) => {
  const { handleSubmit, initialValues } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-4 form-group">
          <Field
            label="Name"
            name="name"
            component={RenderField}
            type="text"
            className="form-control"
            validate={required}
            required
          />
        </div>

        <div className="col-lg-4 form-group">
          <Field
            label="Frequency"
            name="frequency"
            component={SelectInput}
            className="form-control"
            validate={required}
            options={frequencies}
            required
          />
        </div>

        <div className="col-lg-4 form-group">
          <Field
            label="Paid on Days After End Date"
            name="paid_on_days_after_end_date"
            component={RenderField}
            type="number"
            validate={required}
            required
          />
        </div>

        <div className="col-lg-6 form-group">
          <Field
            label="Is Default"
            name="is_default"
            component={renderToggleInput}
            information="Used to calculate estimated wages in the future"
          />
        </div>

        <div className="col-lg-12 mg-t-25">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

export default reduxForm({})(PayScheduleForm);
