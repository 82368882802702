import { ReactComponent as Done } from "../svgs/complete.svg";
import BottomLogo from "./utils/BottomLogo";
import SVGContainer from "./utils/SVGContainer";

const Success = () => {
  return (
    <div className="signin-wrapper signin-brick">
      <div
        className="signin-box signup rounded-lg shadow position-relative"
        style={{ width: "800px" }}
      >
        <BottomLogo />
        <h2 style={{ marginBottom: "4rem" }} className="slim-logo text-center">
          <a
            href="https://app.thebossapp.com.au"
            target="_blank"
            rel="noopener noreferrer"
          >
            theBOSSapp
          </a>
        </h2>
        <SVGContainer SVG={Done} top={false}>
          <div className="text-center">
            <p className="mt-5 text-dark tx-16 mb-2">
              Form submitted successfully.
            </p>
          </div>
        </SVGContainer>
      </div>
    </div>
  );
};

export default Success;
