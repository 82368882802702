import { useEffect, useState } from "react";
import { IUseApi, IUseApiWithData } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import { OnboardingStep } from "./onboardingStepTypes";
import { BiPlus } from "react-icons/bi";
import errorSwal from "../utils/errorSwal";
import OnboardingStepForm, {
  OnboardingStepFormProps,
} from "./OnboardingStepForm";
import { FormSubmitHandler } from "redux-form";
import { CustomField } from "../customFields/customFieldTypes";
import { toast } from "react-toastify";
import formError from "../utils/formError";
import Spinner from "../utils/Spinner";
import { useParams } from "react-router-dom";
import { WizardStep } from "./OnboardingSteps";

interface OnboardingTemplate {
  uuid: string;
  name: string;
}

const OnboardingTemplateProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const {
    data: template,
    loading: templateLoading,
  }: IUseApiWithData<OnboardingTemplate> = useApi(
    `onboarding-templates/${uuid}`,
    null,
  );

  const {
    data: steps,
    refreshData,
    setData: setSteps,
    loading,
  }: IUseApiWithData<OnboardingStep[]> = useApi(
    `/onboarding-templates/${uuid}/steps`,
    [],
    true,
  );

  const { takeAction }: IUseApi = useApi();

  const [selectedStep, setSelectedStep] = useState<OnboardingStep>();

  useEffect(() => {
    if (steps.length > 0 && !selectedStep) {
      setSelectedStep(steps[0]);
    }
  }, [steps]);

  const onSubmit: FormSubmitHandler<OnboardingStep, OnboardingStepFormProps> = (
    values,
  ) => {
    return takeAction("update", `onboarding-steps/${values?.uuid}`, {
      ...values,
      custom_fields: values.custom_fields.map((field: any, index: number) => {
        return {
          ...field,
          order: index,
        };
      }),
    })
      .then(({ data }) => {
        refreshData();
        setSelectedStep(data.data);
        toast.success(`${selectedStep?.name} updated successfully`);
      })
      .catch(formError);
  };

  if (templateLoading) {
    return <Spinner loading={true} />;
  }

  return (
    <>
      <HeaderPage
        titlePage={`${template?.name} - Onboarding Steps`}
        crumbs={[
          { name: "Onboarding Templates", link: "/onboarding-templates" },
          { name: template?.name || "" },
        ]}
      />
      <Spinner loading={loading && steps.length === 0} />

      <div className="d-flex custom-scrollbar flex-nowrap overflow-auto pb-3">
        <div className="d-inline-flex space-x-3">
          {steps.map((step) => {
            return (
              <WizardStep<OnboardingStep>
                key={step.uuid}
                isActive={selectedStep?.uuid === step.uuid}
                text={step.name}
                number={step.step_number}
                onClick={setSelectedStep}
                step={step}
              />
            );
          })}
          <button
            className="border-0 d-flex align-items-center tx-16 rounded-pill text-secondary bg-gray-100 pe-4 shadow-sm no-wrap"
            type="button"
            onClick={() => {
              return takeAction("store", `onboarding-templates/${uuid}/steps`, {
                step_number: steps.length + 1,
                onboarding_template_id: uuid,
              })
                .then(({ data }) => {
                  setSteps([...steps, data.data]);
                  setSelectedStep(data.data);
                })
                .catch(errorSwal);
            }}
          >
            <span
              style={{ height: "55px", width: "55px", marginLeft: "-3px" }}
              className="tx-20 fw-bold h-3 w-3 rounded-circle me-1 d-flex align-items-center justify-content-center "
            >
              <BiPlus className="tx-30" />
            </span>
            <span className="py-3">Add Step</span>
          </button>
        </div>
      </div>
      {selectedStep && (
        <div className="mt-5">
          <OnboardingStepForm
            setSteps={setSteps}
            setSelectedStep={setSelectedStep}
            initialValues={{
              ...selectedStep,
              custom_fields:
                selectedStep?.custom_fields &&
                selectedStep?.custom_fields?.length > 0
                  ? selectedStep?.custom_fields
                  : ([{}] as CustomField[]),
            }}
            onSubmit={onSubmit}
            steps={steps}
          />
        </div>
      )}
    </>
  );
};

export default OnboardingTemplateProfile;
