import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { reduxForm } from "redux-form";
import useApi from "../api/useApi";
import ExpiredModal from "../clients/ExpiredModal";
import useUploadMultipleToS3 from "../hooks/useUploadMultipleToS3";
import errorSwal from "../utils/errorSwal";
import Spinner from "../utils/Spinner";
import ExternalForm from "./ExternalForm";
import useUploadCustomFields from "../hooks/useUploadCustomFields";

export const findInsurance = (insurance, documents) => {
  const insuranceId = `${insurance.document.name}${insurance.document.size}`;

  return documents.find((document) => {
    return `${document.name}${document.file_size}` === insuranceId;
  });
};

const ExternalPage = () => {
  const { uuid } = useParams();
  const [supplier, setSupplier] = useState();
  const [error, setError] = useState(false);
  const history = useHistory();

  const { upload, setBucket } = useUploadMultipleToS3();

  const { upload: uploadCustomFields, setBucket: setCustomFieldBucket } =
    useUploadCustomFields();

  const { takeAction, loading } = useApi();

  useEffect(() => {
    takeAction("show", `supplier-details/${uuid}`)
      .then(({ data }) => {
        setSupplier(data.data);
        setBucket(
          `organisations/${data.data.organisation_uuid}/documents/suppliers/${data.data.uuid}`,
        );
        setCustomFieldBucket(
          `organisations/${data.data.organisation_uuid}/documents/suppliers/${data.data.uuid}`,
        );
      })
      .catch((err) => {
        Sentry.captureException(err);
        setError(true);
      });
  }, []);

  const submit = (values) => {
    const files = values?.insurances_required.map(({ document }) => document);

    return upload(files)
      .then((uploaded) => {
        values = {
          ...values,
          insurances_required: values?.insurances_required?.map((insurance) => {
            return {
              ...insurance,
              document: findInsurance(insurance, uploaded),
            };
          }),
        };

        return uploadCustomFields(values);
      })
      .then((values) =>
        takeAction("update", `supplier-details/${supplier.uuid}`, values),
      )
      .then(() => {
        history.push("/success");
      })
      .catch(errorSwal);
  };

  return (
    <>
      <Spinner loading={loading} />
      <ExpiredModal modal={error} />
      <div className="signin-wrapper">
        <div
          className="signin-box signup rounded-lg shadow-sm"
          style={{ width: "800px" }}
        >
          <h2 className="slim-logo mb-0">
            <a
              href={
                supplier?.organisation_website ??
                "https://landing.thebossapp.com.au"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {supplier?.logo_url ? (
                <img src={supplier?.logo_url} alt="Logo" height="100px" />
              ) : (
                "theBOSSapp"
              )}
            </a>
            {supplier && (
              <h3 className="signin-title-primary">
                Please fill out the following details for{" "}
                {supplier?.organisation}
              </h3>
            )}
          </h2>
          <ExternalForm
            onSubmit={submit}
            initialValues={supplier}
            supplier={supplier}
          />
        </div>
      </div>
    </>
  );
};

const form = reduxForm({
  form: "ExternalSupplierForm",
  enableReinitialize: true,
});

export default form(ExternalPage);
