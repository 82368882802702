import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { QueryClient, QueryClientProvider } from "react-query";
import LogoutModal from "./components/auth/LogoutModal";
import BasicUserRoutes from "./components/BasicUserRoutes";
import Footer from "./components/footer/Footer";
import Navigation from "./components/header/Navigation";
import Main from "./components/Main";
import { AdministrationLevels } from "./components/user/userTypes";
import SetHead from "./components/utils/SetHead";
import { useAuth } from "./context/auth-context";
import { CreateZipProvider } from "./context/createZip-context";
import { ImCross } from "react-icons/im";
import "./scss/boss.scss";
import { Link, useLocation } from "react-router-dom";
import TextButton from "./components/utils/TextButton";
import { BiInfoCircle } from "react-icons/bi";

const queryClient = new QueryClient();

const AuthenticatedApp = (props) => {
  const { user, onLineStatus } = useAuth();

  const location = useLocation();

  const [showOnboardingResponses, setShowOnboardingResponses] = useState(true);

  const routesWithoutShell = ["/two-factor-reminder", "/login/consent"];

  const isBasic =
    user?.position_administration_level === AdministrationLevels.Basic &&
    !user.is_admin;

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (!import.meta.env.DEV) {
      Sentry.setContext("organisation", {
        name: user?.active_organisation?.display_name,
      });

      Sentry.setUser({
        id: user?.id?.toString(),
        username: user?.name,
      });

      ReactGA.set({ userId: user?.id });
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      {!onLineStatus ? (
        <div
          className="bg-danger text-center tx-white p-3 position-fixed w-100"
          style={{ zIndex: 10000 }}
        >
          <p className="mb-0">You are currently offline.</p>
          <p className="mb-0">Please reconnect before continuing.</p>
        </div>
      ) : null}
      {user?.onboarding_responses_required ? (
        showOnboardingResponses ? (
          <div
            className="bg-info text-center tx-white p-3  d-flex justify-content-between position-fixed w-100"
            style={{ zIndex: 10000 }}
          >
            <div />
            <div>
              <p className="mb-0">You have onboarding responses to complete.</p>
              <Link
                onClick={() => setShowOnboardingResponses(false)}
                to={`/users/${user.uuid}/onboarding-responses`}
                className="mb-0 text-white"
              >
                Please click here to complete before continuing.
              </Link>
            </div>
            <TextButton
              className="btn btn-primary"
              onClick={() => setShowOnboardingResponses(false)}
            >
              <ImCross />
            </TextButton>
          </div>
        ) : (
          <TextButton
            style={{ zIndex: 10000, right: "1rem", top: "1rem" }}
            onClick={() => setShowOnboardingResponses(true)}
            className="position-fixed right-0"
          >
            <BiInfoCircle size={24} className="text-warning" />
          </TextButton>
        )
      ) : null}

      <CreateZipProvider>
        {routesWithoutShell.includes(location.pathname) ? (
          isBasic ? (
            <BasicUserRoutes />
          ) : (
            <Main />
          )
        ) : (
          <div className="App">
            <SetHead />
            <Navigation {...props} />

            <div className="slim-mainpanel pd-b-20 mg-b-50">
              <div className="container mg-b-20" id="app-container">
                {isBasic ? <BasicUserRoutes /> : <Main />}
              </div>
            </div>
            <Footer />
          </div>
        )}
        <LogoutModal />
      </CreateZipProvider>
    </QueryClientProvider>
  );
};

export default AuthenticatedApp;
