import { connect } from "react-redux";
import useApi from "../api/useApi";
import { Branch } from "./branchTypes";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import DashboardCards from "../dashboard/DashboardCards";
import LoadingOverlay from "../utils/LoadingOverlay";
import DashboardViews from "../dashboard/DashboardViews";
import { IUseApi } from "../api/apiTypes";

interface BranchDashboardProps {
  from: string;
  to: string;
  branch: Branch;
}

const BranchDashboard = (props: BranchDashboardProps) => {
  const { from, to, branch } = props;

  const [selectedView, setSelectedView] = useState("");

  const getUrl = () => {
    return `dashboards?model=${encodeURIComponent(
      "App\\Models\\OrganisationBranch",
    )}&modelId=${branch.id}&startDate=${dayjs(from).format(
      "YYYY-MM-DD",
    )}&endDate=${dayjs(to).format("YYYY-MM-DD")}&includeProjects=true`;
  };

  const { response, setUrl, loading }: IUseApi = useApi(getUrl());

  useEffect(() => {
    if (from && to) {
      setUrl(getUrl());
    }
  }, [from, to, branch]);

  if (!response?.data) {
    return null;
  }

  return (
    <div className="position-relative">
      <LoadingOverlay loading={loading} />
      <DashboardCards
        dashboard={response?.data}
        setSelectedView={setSelectedView}
        selectedView={selectedView}
      />
      <DashboardViews
        from={from}
        to={to}
        selected={{
          model: "App\\Models\\OrganisationBranch",
          value: branch.id,
        }}
        view={selectedView}
        stringifiedFilters={""}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  from: state.projectOverviewDateRange.from,
  to: state.projectOverviewDateRange.to,
});

export default connect(mapStateToProps)(BranchDashboard);
