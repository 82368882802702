import FormModal from "../utils/FormModal";
import { Field, FieldArray, WrappedFieldArrayProps } from "redux-form";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import { Button } from "reactstrap";
import SelectInput from "../form/SelectInput";
import { money } from "../form/formatters";
import useApi from "../api/useApi";
import { IUseApi, IUseApiWithData } from "../api/apiTypes";
import FormHeader from "../utils/FormHeader";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import formError from "../utils/formError";
import { Equipment } from "./equipmentTypes";
import InformationAlert from "../utils/InformationAlert";

const AddEquipmentInvoiceModal = ({
  modal,
  toggle,
  initialValues,
  equipment,
  onSuccess,
}: {
  modal: boolean;
  toggle: () => void;
  initialValues?: any;
  equipment: Equipment;
  onSuccess?: Function;
}) => {
  const { takeAction }: IUseApi = useApi();

  const history = useHistory();

  const onSubmit = (values: any) => {
    return takeAction("store", `equipment/${equipment.uuid}/invoices`, values)
      .then(({ data }) => {
        toast.success("Invoice added successfully.");
        if (onSuccess) {
          onSuccess(data.data);
          return;
        }

        history.push(data.data.link);
      })
      .catch(formError);
  };

  return (
    <FormModal
      initialValues={initialValues}
      form="EquipmentInvoiceForm"
      modal={modal}
      title={
        <>
          <p className="mb-0">Add invoice for {initialValues?.name}</p>
          <a
            href={equipment.project.link ?? ""}
            className="tx-12 text-secondary"
            target="_blank"
          >
            {equipment.project.name} <i className="fa fa-external-link ms-2" />
          </a>
        </>
      }
      toggle={toggle}
      onSubmit={onSubmit}
    >
      {!equipment.branch_id && (
        <div className="col-12 mb-4">
          <InformationAlert
            type="warning"
            closable
            body={`Creating an invoice will automatically create a job, which must be under a branch.`}
            title={`No branch found for ${equipment.name}.`}
          />
        </div>
      )}
      <div className="col-lg-12 form-group">
        <Field
          name="name"
          component={RenderField}
          required
          label="Name"
          validate={required}
        />
      </div>
      <div className="col-lg-9 form-group">
        <Field
          name="purchase_order"
          component={RenderField}
          label="Line Item Prefix"
          placeholder="Leave blank if no prefix required."
        />
        <small className="form-text text-muted">
          Add in a note that will be prepended to all line items.
        </small>
      </div>
      {/* <div className="col-lg-3 form-group">
        <Field
          name="is_credit_note"
          component={renderToggleInput}
          label="Credit Note"
        />
      </div> */}
      <FieldArray name="prices" component={PricingFields} />
    </FormModal>
  );
};

const PricingFields = ({ fields }: WrappedFieldArrayProps & {}) => {
  const {
    data: billableItemUnits,
  }: IUseApiWithData<{ name: string; id: number }[]> = useApi(
    "billable-item-units",
    [],
  );

  const {
    data: pricingTypes,
  }: IUseApiWithData<{ label: string; value: number }[]> = useApi(
    "pricing-types",
    [],
  );

  const {
    data: pricingItems,
  }: IUseApiWithData<{ name: string; id: number; type: string }[]> = useApi(
    "pricing-items",
    [],
  );

  return (
    <>
      <FormHeader>Items</FormHeader>
      {fields.map((field, index) => {
        const price = fields.get(index);

        return (
          <>
            <div className="col-lg-12 form-group d-flex align-items-center">
              <p className="mb-0 text-dark fw-bolder">
                Item {index + 1} {price.description && `(${price.description})`}
              </p>
              <Button
                className="ms-auto"
                color="danger"
                outline
                size="sm"
                onClick={() => fields.remove(index)}
              >
                Remove Item {index + 1}
              </Button>
            </div>
            <div className="col-lg-4 form-group">
              <Field
                validate={required}
                required
                label="Units"
                name={`${field}.billable_item_units_id`}
                options={billableItemUnits.map((b) => ({
                  label: b.name,
                  value: b.id,
                }))}
                component={SelectInput}
              />
            </div>
            <div className="col-lg-4 form-group">
              <Field
                component={SelectInput}
                name={`${field}.line_item_type`}
                label="Item Type"
                options={pricingTypes}
              />
            </div>
            <div className="col-lg-4 form-group">
              <Field
                validate={required}
                required
                label="Type"
                name={`${field}.line_item_id`}
                options={pricingItems.filter(
                  (type) => type.type === price?.line_item_type,
                )}
                component={SelectInput}
              />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                validate={required}
                required
                label="Price"
                name={`${field}.price`}
                component={RenderField}
                {...money}
              />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                validate={required}
                required
                label="Quantity"
                name={`${field}.quantity`}
                component={RenderField}
                type="number"
                extraProps={{ step: "0.001" }}
              />
            </div>
            <div className="mb-3" />
          </>
        );
      })}
    </>
  );
};

export default AddEquipmentInvoiceModal;
