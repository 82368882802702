import { Field, formValueSelector } from "redux-form";
import useApi from "../api/useApi";
import SelectInput from "../form/SelectInput";
import RenderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import { connect } from "react-redux";

const LaboratoryDetails = ({
  isAnnex,
  change,
}: {
  isAnnex?: boolean;
  change: Function;
}) => {
  const { data } = useApi("organisations-standard-job-types", []);

  const organisationStandardJobTypes = data?.map(
    ({ job_type_type }: { job_type_type: string }) => job_type_type,
  );

  if (!organisationStandardJobTypes.includes("App\\Models\\TestRequest")) {
    return null;
  }

  return (
    <>
      <div className="col-lg-12 form-group">
        <Field name="site_number" component={RenderField} label="Site Number" />
      </div>
      <div className="col-lg-12 form-group">
        <Field
          component={renderToggleInput}
          name="is_annex"
          label="Is Annex Lab"
          onChange={(e: any) => {
            change("accreditation_number", "");
            change("base_lab_id", "");
          }}
        />
      </div>
      {isAnnex && (
        <div className="col-lg-12 form-group">
          <Field
            name="base_lab_id"
            component={SelectInput}
            url="organisation-branches"
            label="Base Lab"
            formatData={(data: any[]) =>
              data.map((d: any) => ({
                label: d.display_name,
                value: d.id,
              }))
            }
          />
        </div>
      )}
      {!isAnnex && (
        <div className="col-lg-12 form-group">
          <Field
            name="accreditation_number"
            component={RenderField}
            label="Accreditation Number"
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: any, { form }: { form: string }) => {
  const selector = formValueSelector(form);

  return {
    isAnnex: selector(state, "is_annex"),
  };
};

export default connect(mapStateToProps)(LaboratoryDetails);
