import { useParams } from "react-router-dom";
import PaginatedList from "../pagination/PaginatedList";
import useBranchJobs from "./hooks/useBranchJobs";
import JobTable from "../jobs/JobTable";
import { jobFilters } from "../projectManagers/ProjectManagerJobs";
import TextButton from "../utils/TextButton";
import { MdOutlineOpenInFull } from "react-icons/md";
import { useState } from "react";

const BranchJobs = () => {
  const { uuid } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <PaginatedList
      listName="branchJobsList"
      indexHook={useBranchJobs}
      indexHookArguments={[uuid]}
      originalFilters={jobFilters}
      extraButtons={({ searchQuery, sorting, setSorting }) => (
        <TextButton
          className="ms-1"
          title="Expand"
          onClick={() => setIsModalOpen(true)}
        >
          <MdOutlineOpenInFull className="tx-18 text-secondary" />
        </TextButton>
      )}
      list={({ data, setSorting, sorting, pages }) => (
        <div className="col-12">
          {/* <p>{pages?.[0]?.meta.total} jobs found</p> */}
          {data?.length > 0 && (
            <JobTable
              showProject={true}
              setSelectedJob={() => {}}
              jobs={data}
              sorting={sorting}
              setSorting={setSorting}
              disablePinning={true}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
            />
          )}
        </div>
      )}
    />
  );
};

export default BranchJobs;
