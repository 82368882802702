import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import errorSwal from "../utils/errorSwal";
import PayScheduleForm from "./Form";

const AddPaySchedule = () => {
  const { takeAction } = useApi();
  const history = useHistory();

  const onSubmit = (values) => {
    return takeAction("store", "pay-schedules", values)
      .then(({ data }) => {
        toast.success("Pay Schedule created successfully!");
        history.push(`/pay-schedules/${data.data.uuid}/edit`);
      })
      .catch(errorSwal);
  };

  return (
    <>
      <HeaderPageAdminFilter
        titlePage="New Pay Schedule"
        crumbs={[
          { link: "pay-schedules", name: "Pay Schedules" },
          { link: "pay-schedules/add", name: "New Pay Schedule", active: true },
        ]}
      />
      <PayScheduleForm onSubmit={onSubmit} form="AddPaySchedule" />
    </>
  );
};

export default AddPaySchedule;
