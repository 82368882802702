import React, { useState, useCallback } from "react";
import {
  Field,
  FieldArray,
  reduxForm,
  InjectedFormProps,
  WrappedFieldArrayProps,
  formValueSelector,
} from "redux-form";
import { BiCloudUpload } from "react-icons/bi";
import { toast } from "react-toastify";
import { useDropzone, FileRejection, DropEvent } from "react-dropzone";
import useApi from "../api/useApi";
import useUploadMultipleToS3 from "../hooks/useUploadMultipleToS3";
import renderField from "../utils/renderField";
import SubmitButton from "../utils/SubmitButton";
import errorSwal from "../utils/errorSwal";
import { AxiosResponse } from "axios";
import SelectInput from "../form/SelectInput";
import { IUseApi } from "../api/apiTypes";
import { connect } from "react-redux";
import required, { excelCell } from "../utils/required";
import { ExcelExtractionColumn } from "./excelExtractionTypes";

interface FormValues {
  name: string;
  columns: ExcelExtractionColumn[];
}

interface ExtendedFile extends File {
  path?: string;
  progress?: number;
  uuid?: string;
}

interface ExcelExtractionFormProps {
  columns: ExcelExtractionColumn[];
}

const ExcelExtractionForm: React.FC<
  InjectedFormProps<FormValues, ExcelExtractionFormProps> &
    ExcelExtractionFormProps
> = (props) => {
  const [sheets, setSheets] = useState<string[]>([]);
  const { takeAction }: IUseApi = useApi();
  const { upload, files, loading } =
    useUploadMultipleToS3("tmp/excel-extracts");

  const onDrop = useCallback(
    async (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event: DropEvent,
    ) => {
      if (fileRejections.length > 0) {
        toast.error(
          `Invalid file type(s): ${fileRejections
            .map((f) => f.file.name)
            .join(", ")}. Please upload only Excel or CSV files.`,
        );
        return;
      }

      try {
        const uploadedFiles = (await upload(acceptedFiles)) as ExtendedFile[];

        if (uploadedFiles?.[0]?.path) {
          const response = (await takeAction(
            "store",
            "/excel-sheets",
            uploadedFiles[0],
          )) as AxiosResponse<{
            data: {
              sheets: string[];
            };
            error?: string;
          }>;

          if (response.data.error) {
            throw new Error(response.data.error);
          }

          const firstFileSheets = response.data.data.sheets || [];
          setSheets(firstFileSheets);

          if (!props.columns || props.columns.length === 0) {
            props.change("columns", [
              {
                sheet: firstFileSheets[0],
              },
            ]);
          }
        }
      } catch (err) {
        errorSwal(err);
      }
    },
    [upload, takeAction, props.change],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept:
      "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,application/vnd.ms-excel.sheet.macroEnabled.12,application/vnd.ms-excel.sheet.binary.macroEnabled.12",
    multiple: false,
    disabled: loading,
  });

  return (
    <form onSubmit={props.handleSubmit}>
      <div className="row">
        <div className="mb-4 col-12">
          <Field
            name="name"
            component={renderField}
            type="text"
            label="Exctraction Name"
            validate={required}
            required
          />
        </div>

        <div className="col-12">
          <div
            {...getRootProps()}
            className="tn-300 rounded-lg bg-white d-flex align-items-center w-100 h-100 justify-content-center"
            style={{
              minHeight: "9rem",
              border: isDragActive
                ? "2px dashed #007bff"
                : "1px dashed #dee2e6",
              backgroundColor: isDragActive
                ? "rgba(0,123,255,0.05)"
                : "transparent",
              cursor: "pointer",
              transition: "all 0.2s ease",
            }}
          >
            <input {...getInputProps()} />
            {loading ? (
              <div className="text-center">
                <i className="fa fa-spinner fa-spin mb-2" />
                <div
                  className="progress"
                  style={{ height: "2px", width: "200px" }}
                ></div>
              </div>
            ) : (
              <div className="text-center">
                <div>
                  <BiCloudUpload size={42} className="text-warning" />
                </div>
                <p className="mb-1 text-dark">
                  {isDragActive
                    ? "Drop the file here..."
                    : "Drag & drop a sample Excel file or click to select"}
                </p>
                <small className="d-block">
                  Supported formats: .xlsx, .xls, .csv, .xlsm, .xlsb
                </small>
                <small className="d-block">
                  This file is only used to configure the extraction
                </small>
              </div>
            )}
          </div>
        </div>

        <>
          <div className="mt-4">
            <FieldArray
              name="columns"
              component={RenderExtractions}
              props={{
                sheets,
                loading,
                columns: props.columns,
              }}
            />
          </div>
          <div className="form-layout-footer mt-4">
            <SubmitButton {...props} />
          </div>
        </>
      </div>
    </form>
  );
};

const RenderExtractions = ({
  fields,
  sheets,
  loading,
  columns,
}: WrappedFieldArrayProps & {
  sheets: string[];
  loading: boolean;
  columns: ExcelExtractionColumn[];
}) => (
  <>
    <div className="d-flex justify-content-between align-items-center mb-3">
      <h6 className="mb-0">Column Configuration</h6>
      <button
        type="button"
        className="btn btn-primary btn-sm"
        onClick={() =>
          fields.push({
            sheet: columns?.[columns.length - 1]?.sheet || sheets[0],
          })
        }
      >
        Add Column
      </button>
    </div>

    {fields.map((column: string, index: number) => (
      <div key={index} className="card mb-3 p-3">
        <div className="row g-3">
          <div className="col-md-4">
            <Field
              name={`${column}.sheet`}
              component={SelectInput}
              className="form-select"
              label="Sheet"
              validate={required}
              required
              options={sheets.map((sheet) => ({
                label: sheet,
                value: sheet,
              }))}
            />
          </div>
          <div className="col-md-3">
            <Field
              name={`${column}.cell`}
              component={renderField}
              type="text"
              label="Cell"
              placeholder="Cell (e.g., A1)"
              validate={[excelCell, required]}
              required
            />
          </div>
          <div className="col-md-3">
            <Field
              name={`${column}.column_name`}
              component={renderField}
              type="text"
              label="Column Name"
              validate={required}
              required
            />
          </div>
          <div className="col-md-2 d-flex align-items-end">
            <button
              type="button"
              className="btn btn-outline-danger w-100"
              onClick={() => fields.remove(index)}
              disabled={loading}
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    ))}
  </>
);

const form = reduxForm<FormValues, ExcelExtractionFormProps>({
  form: "excelExtractionForm",
});

const selector = formValueSelector("excelExtractionForm");

const mapStateToProps = (state: any): ExcelExtractionFormProps => ({
  columns: selector(state, "columns"),
});

export default connect(mapStateToProps)(form(ExcelExtractionForm));
