import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "../api/api";
import JobTable from "../jobs/JobTable";
import PaginatedList from "../pagination/PaginatedList";
import { jobFilters } from "../projectManagers/ProjectManagerJobs";
import useProjectJobs from "./hooks/useProjectJobs";
import TextButton from "../utils/TextButton";
import { MdOutlineOpenInFull } from "react-icons/md";

const ProjectJobs = ({ selectedJob, setSelectedJob }) => {
  const { number } = useParams();
  const { state } = useLocation();

  const [loading, setLoading] = useState(true);
  const [jobTypes, setJobTypes] = useState([]);
  const [branches, setBranches] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    axios
      .get(`jobtypes/types`)
      .then(({ data }) => {
        setJobTypes(data);
        return axios.get(`organisationbranches/branches`);
      })
      .then((res) => {
        if (res) {
          setBranches(res.data);
        }
        setLoading(false);
      });
    return () => {};
  }, []);

  const filters = jobFilters.concat([
    {
      name: "budget_status",
      label: "Budget Status",
      dontCache: true,
      options: [
        {
          label: "Over Budget",
          value: "overbudget",
          selected: state?.filter === "overbudget",
        },
        {
          label: "On Budget",
          value: "onbudget",
        },
      ],
    },
    {
      name: "jobType",
      label: "Job Type",
      options: jobTypes.map((jobType) => ({
        label: jobType.label,
        value: [jobType.type, jobType.id],
      })),
    },
    {
      name: "branch_id",
      label: "Branch",
      multiple: true,
      options: branches.map((branch) => ({
        label: branch.label,
        value: branch.value,
      })),
    },
  ]);

  return (
    <PaginatedList
      listName="projectJobsList"
      indexHook={useProjectJobs}
      indexHookArguments={[number]}
      extraButtons={({ searchQuery, sorting, setSorting }) => (
        <TextButton
          className="ms-1"
          title="Expand"
          onClick={() => setIsModalOpen(true)}
        >
          <MdOutlineOpenInFull className="tx-18 text-secondary" />
        </TextButton>
      )}
      originalFilters={filters}
      list={({ data, pages, pageParams, setSorting, sorting }) => {
        return (
          <div className="col-12">
            {data?.length > 0 && (
              <JobTable
                selectedJob={selectedJob}
                setSelectedJob={setSelectedJob}
                jobs={data}
                sorting={sorting}
                setSorting={setSorting}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
              />
            )}
          </div>
        );
      }}
    />
  );
};

export default ProjectJobs;
