import _ from "lodash";
import { useState } from "react";
import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import formatDate from "../utils/formatDate";
import Spinner from "../utils/Spinner";
import Tab from "../utils/tabs/Tab";
import TabList from "../utils/tabs/TabList";
import DeleteButton from "./DeleteButton";
import ProfileBar from "./ProfileBar";
import ProfileButton from "./ProfileButton";
import ProfileHeader from "./ProfileHeader";
import ShowPage from "./ShowPage";
import { Field, reduxForm } from "redux-form";
import RenderField from "../utils/renderField";
import formError from "../utils/formError";
import FormErrorAlert from "../form/FormErrorAlert";
import { IntegrationPlatform } from "../integrations/integrationTypes";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import { useAuth } from "../../context/auth-context";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import { money } from "../form/formatters";
import SideList from "../comments/SideList";
import SelectInput from "../form/SelectInput";

const Profile = (props) => {
  const { uuid, page } = useParams();
  const [selectedOptions, setSelectedOptions] = useState([]);

  const { user } = useAuth();

  const [sortBy, setSortBy] = useState(
    localStorage.getItem("filter_pay_runs_by") ?? "first_name",
  );

  const { takeAction, loading: downloadingCsv } = useApi();

  const downloadEmploymentHeroCSV = () => {
    return takeAction("store", `pay-runs/${uuid}/employment-hero-csv`)
      .then(({ data }) => {
        window.location = data.data.url;
      })
      .catch(errorSwal);
  };

  const { takeAction: notifyManagers, loading: notifyingManagers } = useApi();

  const {
    loading,
    data: payRun,
    setData: setPayRun,
  } = useApi(`pay-runs/${uuid}`, null, true, "/pay-runs");

  if (loading) return <Spinner loading />;

  const change = (newSelectedOptions) => setSelectedOptions(newSelectedOptions);

  const payRunDates = `${formatDate(payRun.start_date)} - ${formatDate(
    payRun.finish_date,
  )}`;

  const filteredPays = payRun.pays.filter((pay) => {
    if (selectedOptions.length === 0) return true;
    return selectedOptions.filter(({ value }) => value === pay.branch_name)
      .length;
  });

  const sortedPays = _.orderBy(
    filteredPays,
    [
      `user.${sortBy}`,
      `user.${sortBy === "first_name" ? "last_name" : "first_name"}`,
    ],
    ["asc", "asc"],
  );

  return (
    <>
      <HeaderPage
        titlePage={payRunDates}
        crumbs={[
          { link: "organisations/settings/links", name: "Organisation" },
          {
            link: "pay-runs",
            name: "Pay Runs",
          },
          {
            link: "/",
            name: payRunDates,
            active: true,
          },
        ]}
      />

      <div className="row row-sm">
        <div className="col-lg-8">
          <ProfileHeader payRun={payRun} payRunDates={payRunDates} />
          {payRun.unapproved_deductions.length > 0 && (
            <div className="bd-l bd-3 bd-info bg-gray-200 pd-x-20 pd-y-25 shadow-sm rounded mb-3">
              <p className="mb-1">Found the following unapproved deductions</p>
              <ul className="mb-0">
                {payRun.unapproved_deductions.map((deduction) => (
                  <li key={deduction.uuid}>
                    {deduction.user.name} - {deduction.description} (
                    {money.format(deduction.amount)})
                  </li>
                ))}
              </ul>
            </div>
          )}
          {payRun.payroll_integration?.name ==
            IntegrationPlatform.EmploymentHero &&
            user.is_admin && (
              <button
                className="btn btn-block btn-outline-primary w-100"
                disabled={downloadingCsv}
                onClick={downloadEmploymentHeroCSV}
              >
                {isSubmitting(
                  downloadingCsv,
                  "Download Employment Hero CSV",
                  "Generating...",
                )}
              </button>
            )}
          {user.is_admin && <ProfileButton />}

          <ShowPage
            filteredPays={sortedPays}
            selectedOptions={selectedOptions}
            change={change}
            payRun={payRun}
            setPayRun={setPayRun}
            sortBy={sortBy}
            setSortBy={setSortBy}
            {...props}
          />
        </div>
        <div className="col col-lg-4">
          <TabList>
            <Tab
              link={`/pay-runs/${uuid}/pays`}
              icon="fa fa-file-text-o"
              active={page === "pays"}
            >
              Pays
            </Tab>
            {user.is_admin && (
              <Tab
                link={`/pay-runs/${uuid}/documents`}
                icon="fa fa-file-text-o"
                active={page === "documents"}
              >
                Documents
              </Tab>
            )}
            {user.is_admin && (
              <Tab
                link={`/pay-runs/${uuid}/branches`}
                icon="fa fa-file-text-o"
                active={page === "branches"}
              >
                Branches ({payRun.branches.length})
              </Tab>
            )}
            {user.is_admin && (
              <Tab
                link={`/pay-runs/${uuid}/timesheets`}
                icon="fa fa-file-text-o"
                active={page === "timesheets"}
              >
                Timesheets
              </Tab>
            )}
          </TabList>
          {user.is_admin && (
            <>
              <ProfileBar
                {...props}
                payRun={payRun}
                setPayRun={setPayRun}
                className="mg-y-20 d-none d-lg-block"
              />
              <DeleteButton payRun={payRun} />
              <Button
                className="mg-y-20"
                onClick={() =>
                  notifyManagers("store", `/pay-runs/${uuid}/notify-managers`)
                    .then(() => toast.success("Managers Notified"))
                    .catch(errorSwal)
                }
                color="primary"
                outline
                disabled={notifyingManagers}
                block
              >
                {isSubmitting(
                  notifyingManagers,
                  "Notify Branch Managers/Tracking Site Managers",
                  "Notifying...",
                )}
              </Button>
              <SideList
                commentableType="App\Models\PayRun"
                commentableId={payRun.id}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export const CompletedAt = (props) => {
  const { handleSubmit, setPayRun, initialValues, error } = props;

  const { data: paySchedules } = useApi(`pay-schedules`, []);
  const { takeAction } = useApi();

  const onSubmit = (values) => {
    const oldPayRun = initialValues;
    setPayRun(values);

    return takeAction("update", `pay-runs/${values.uuid}`, values)
      .then(() => {
        toast.success("Pay Run Updated");
      })
      .catch((err) => {
        setPayRun(oldPayRun);

        return formError(err);
      });
  };

  return (
    <form>
      <div className="row">
        <FormErrorAlert error={error} />
        <div className="form-group col-12">
          <Field
            component={RenderField}
            onBlur={handleSubmit(onSubmit)}
            name="paid_on"
            label="Paid On"
            type="date"
          />
        </div>
        {paySchedules.length > 0 && (
          <div className="form-group col-12">
            <Field
              component={SelectInput}
              onBlur={handleSubmit(onSubmit)}
              name="pay_schedule_id"
              label="Pay Schedule"
              options={paySchedules.map((paySchedule) => ({
                label: paySchedule.name,
                value: paySchedule.id,
              }))}
            />
          </div>
        )}
      </div>
    </form>
  );
};

const form = reduxForm({ form: "PAID_ON", enableReinitialize: true });

export const CompletedAtForm = form(CompletedAt);

export default Profile;
