import { useEffect, useState } from "react";
import { IUseApi, IUseApiWithData } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import { OnboardingStep } from "./onboardingStepTypes";
import { BiPlus } from "react-icons/bi";
import errorSwal from "../utils/errorSwal";
import OnboardingStepForm, {
  OnboardingStepFormProps,
} from "./OnboardingStepForm";
import { FormSubmitHandler } from "redux-form";
import { CustomField } from "../customFields/customFieldTypes";
import { toast } from "react-toastify";
import formError from "../utils/formError";
import Spinner from "../utils/Spinner";

const OnboardingSteps = () => {
  const {
    data: steps,
    refreshData,
    setData: setSteps,
    loading,
  }: IUseApiWithData<OnboardingStep[]> = useApi("/onboarding-steps", [], true);

  const { takeAction }: IUseApi = useApi();

  const [selectedStep, setSelectedStep] = useState<OnboardingStep>();

  useEffect(() => {
    if (steps.length > 0 && !selectedStep) {
      setSelectedStep(steps[0]);
    }
  }, [steps]);

  const onSubmit: FormSubmitHandler<OnboardingStep, OnboardingStepFormProps> = (
    values,
  ) => {
    return takeAction("update", `onboarding-steps/${values?.uuid}`, {
      ...values,
      custom_fields: values.custom_fields.map((field: any, index: number) => {
        return {
          ...field,
          order: index,
        };
      }),
    })
      .then(({ data }) => {
        refreshData();
        setSelectedStep(data.data);
        toast.success(`${selectedStep?.name} updated successfully`);
      })
      .catch(formError);
  };

  return (
    <>
      <HeaderPage
        titlePage="Default Onboarding Steps"
        crumbs={[]}
        pageDescription="These steps will be added to all new users of your organisation"
      />
      <Spinner loading={loading && steps.length === 0} />

      <div className="d-flex custom-scrollbar flex-nowrap overflow-auto pb-1 mt-3">
        <div className="d-inline-flex space-x-3">
          {steps.map((step) => {
            return (
              <WizardStep<OnboardingStep>
                isActive={selectedStep?.uuid === step.uuid}
                text={step.name}
                number={step.step_number}
                onClick={setSelectedStep}
                step={step}
              />
            );
          })}
          <button
            className="border-0 d-flex align-items-center tx-16 rounded-pill text-secondary bg-gray-100 pe-4 shadow-sm no-wrap"
            type="button"
            onClick={() => {
              return takeAction("store", "onboarding-steps", {
                step_number: steps.length + 1,
              })
                .then(({ data }) => {
                  setSteps([...steps, data.data]);
                  setSelectedStep(data.data);
                })
                .catch(errorSwal);
            }}
          >
            <span
              style={{ height: "55px", width: "55px", marginLeft: "-3px" }}
              className="tx-20 fw-bold h-3 w-3 rounded-circle me-1 d-flex align-items-center justify-content-center "
            >
              <BiPlus className="tx-30" />
            </span>
            <span className="py-3">Add Step</span>
          </button>
        </div>
      </div>
      <div className="mt-5">
        <OnboardingStepForm
          setSteps={setSteps}
          setSelectedStep={setSelectedStep}
          initialValues={{
            ...selectedStep,
            custom_fields:
              selectedStep?.custom_fields &&
              selectedStep?.custom_fields?.length > 0
                ? selectedStep?.custom_fields
                : ([{}] as CustomField[]),
          }}
          onSubmit={onSubmit}
          steps={steps}
        />
      </div>
    </>
  );
};

export const WizardStep = <T extends unknown>({
  isActive,
  text,
  number,
  onClick,
  step,
  disabled = false,
}: {
  isActive: boolean;
  text: React.ReactNode;
  number: number;
  onClick: (step: T) => void;
  step: T;
  disabled?: boolean;
}) => {
  return (
    <button
      type="button"
      className={`${
        isActive
          ? "bg-warning text-white shadow-inner money"
          : "bg-gray-300 text-secondary shadow-sm"
      }
      ${disabled ? "pointer-not-allowed" : ""}
       border-0 tx-16 tx-gray-700 rounded-pill d-flex items-center tn-300 border pe-4 d-flex align-items-center no-wrap flex-grow`}
      onClick={() => onClick(step)}
      disabled={disabled}
    >
      <span
        className={`${isActive ? "tx-warning" : ""}
        ${disabled ? "opacity-50" : ""}
         bg-white tx-20 fw-bold flex-shrink-0 h-3 w-3 tn-300 rounded-circle me-3 d-flex align-items-center justify-content-center shadow-inner`}
        style={{ height: "52px", width: "52px", marginLeft: "-3px" }}
      >
        {number}
      </span>{" "}
      <span className="py-3">{text ?? "Unnamed Step"}</span>
    </button>
  );
};

export default OnboardingSteps;
