import { Field, formValueSelector, reduxForm } from "redux-form";

import { connect } from "react-redux";
import { useAuth } from "../../context/auth-context";
import * as JobFields from "../jobs/register/jobFields";
import dateField from "../utils/dateField";
import FormHeader from "../utils/FormHeader";
import renderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import requireDashes from "../utils/requireDashes";
import { useHistory, useParams } from "react-router-dom";
import deleteSwal from "../utils/deleteSwal";
import useApi from "../api/useApi";
import { toast } from "react-toastify";
import SelectInput from "../form/SelectInput";
import FormErrorAlert from "../form/FormErrorAlert";
import _ from "lodash";
import SubmitButton from "../utils/SubmitButton";

const TestRequestForm = (props) => {
  const { initialValues, testRequest, handleSubmit, error } = props;

  const { user } = useAuth();

  const { uuid, projectUuid } = useParams();

  const history = useHistory();

  const { takeAction } = useApi();

  const { data: branches } = useApi("organisation-branches", [], true);

  const { data: locationSelectionSubMethods } = useApi(
    "location-selection-sub-methods",
    [],
    true,
  );

  const deleteTestRequest = () => {
    return deleteSwal()
      .then(() => takeAction("destroy", `/testrequests/${uuid}`))
      .then(() => {
        history.push("/samples");
        toast.success("Test Request deleted successfully");
      });
  };

  const hasChanged =
    initialValues &&
    (initialValues?.testrequest?.client_number !== testRequest?.client_number ||
      initialValues?.testrequest?.client_lot_number !==
        testRequest?.client_lot_number);

  const filteredSubMethods = locationSelectionSubMethods.filter(
    (subMethod) =>
      testRequest.client_selected ||
      subMethod.method === testRequest?.location_selection_method,
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <FormErrorAlert error={error} />
        <FormHeader>Job Details </FormHeader>
        <JobFields.Dependencies
          name="job.job_dependency"
          projectUuid={projectUuid || initialValues.project.uuid}
          className="col-lg-4"
        />
        <div className="col-lg-4 form-group">
          <Field
            component={SelectInput}
            name="job.branch_id"
            label="Branch"
            options={branches.map((branch) => ({
              label: `${branch.number} - ${branch.name}`,
              value: branch.id,
            }))}
          />
        </div>
        <JobFields.JobManager name="job.project_manager" className="col-lg-4" />
        <div className="col-lg-6 form-group">
          <Field
            name="job.scheduled_start_date"
            type="date"
            component={dateField}
            required
            label="Scheduled Start Date"
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            name="job.scheduled_start_time"
            type="time"
            component={renderField}
            label="Scheduled Start Time"
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            name="job.scheduled_finish_date"
            type="date"
            component={dateField}
            required
            label="Scheduled Finish Date"
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            name="job.scheduled_finish_time"
            type="time"
            component={renderField}
            label="Scheduled Finish Time"
          />
        </div>
        <FormHeader>Test Request Details</FormHeader>
        <div className="form-group col-lg-6">
          <Field
            name="testrequest.client_number"
            validate={requireDashes}
            required
            type="text"
            component={renderField}
            label="Client Test Request"
          />
        </div>
        <div className="form-group col-lg-6">
          <Field
            name="testrequest.client_lot_number"
            validate={requireDashes}
            required
            type="text"
            component={renderField}
            label="Client Lot Number"
          />
        </div>
        {hasChanged && (
          <div className="form-group col-lg-12">
            <Field
              name="testrequest.update_job_name"
              component={renderToggleInput}
              label="Change Job Name"
            />
          </div>
        )}
        <div className="form-group col-lg-12">
          <Field
            name="testrequest.client_selected"
            component={renderToggleInput}
            label="Client Selected Location"
            onChange={(e) => {
              props.change("testrequest.location_selection_method", null);
            }}
          />
        </div>
        {!testRequest.client_selected && (
          <div className="form-group col-lg-4">
            <Field
              component={SelectInput}
              required
              validate={required}
              name="testrequest.location_selection_method"
              // getCallback={updateSelectionMethod}
              formatData={(data) =>
                _.sortBy(
                  data.map((item) => ({
                    label: (
                      <>
                        <p className="mb-0">{item.method}</p>
                        {item.id === 347 && (
                          <small className="">
                            Please use Client Selected Location Toggle
                          </small>
                        )}
                      </>
                    ),
                    value: item.id,
                    disabled: item.id === 347,
                  })),
                  "label",
                )
              }
              url="/test-methods?filter[abbreviation_name]=LOCATION"
              label="Location Selection Method"
              changeValue={(value) => {
                props.change("testrequest.location_selection_sub_method", null);
              }}
            />
          </div>
        )}
        <div
          className={`form-group ${
            testRequest.client_selected ? "col-lg-6" : "col-lg-4"
          } `}
        >
          <Field
            component={SelectInput}
            name="testrequest.location_selection_sub_method"
            label="Location Selection Sub Method"
            placeholder="Location Selection Sub Method"
            options={filteredSubMethods.map((item) => ({
              label: item.deprecated_at ? (
                <>
                  <p className="text-dark mb-0">{item.name}</p>
                  <small className="text-danger">Deprecated</small>
                </>
              ) : (
                <p className="text-dark mb-0">{item.name}</p>
              ),
              value: item.id,
              disabled: item.deprecated_at,
            }))}
          />
        </div>
        <div
          className={`form-group ${
            testRequest.client_selected ? "col-lg-6" : "col-lg-4"
          } `}
        >
          <Field
            component={SelectInput}
            name="testrequest.type"
            label="Type"
            placeholder="Type"
            options={[
              {
                label: "Level 1",
                value: 1,
              },
              {
                label: "Level 2",
                value: 2,
              },
            ]}
          />
        </div>
        {user.is_position_admin && (
          <div className="form-group col-lg-12">
            <Field
              component={SelectInput}
              name="testrequest.branch_completed_at_id"
              label="Lab Completed At"
              options={branches.map((branch) => ({
                label: `${branch.number} - ${branch.name}`,
                value: branch.id,
              }))}
            />
            <small>
              If the test request is done from a different branch to the job,
              please select here.
            </small>
          </div>
        )}
        <div className="form-group col-lg-12">
          <Field
            name="testrequest.remarks"
            validate={requireDashes}
            required
            type="textarea"
            textarea={true}
            rows={3}
            cols={64}
            component={renderField}
            label="Remarks"
          />
        </div>

        <div className="col-lg-12 d-flex space-x-3">
          <SubmitButton {...props} />
          {initialValues !== undefined && user.is_admin && (
            <div className="">
              <div
                className="btn btn-outline-danger"
                onClick={deleteTestRequest}
              >
                <i className="fa fa-trash mg-r-5" /> Delete
              </div>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state, { form }) => {
  const selector = formValueSelector(form);

  return {
    testRequest: {
      client_number: selector(state, "testrequest.client_number"),
      client_lot_number: selector(state, "testrequest.client_lot_number"),
      client_selected: selector(state, "testrequest.client_selected"),
      location_selection_method: selector(
        state,
        "testrequest.location_selection_method",
      ),
    },
  };
};

const form = reduxForm({});

export default connect(mapStateToProps, {})(form(TestRequestForm));
