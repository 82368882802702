import useApi from "../api/useApi";
import { useParams } from "react-router-dom";
import HeaderPage from "../header/HeaderPage";
import Spinner from "../utils/Spinner";
import { IUseApi, IUseApiWithData } from "../api/apiTypes";
import { TestWorksheet } from "./testWorksheetTypes";
import TestWorksheetForm, {
  TestWorksheetFormValues,
} from "./TestWorksheetForm";
import { FormSubmitHandler } from "redux-form";
import { toast } from "react-toastify";
import formError from "../utils/formError";

const TestWorksheetProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const {
    data: testWorksheet,
    loading,
    setData: setTestWorksheet,
  }: IUseApiWithData<TestWorksheet> = useApi(
    `test-worksheets/${uuid}`,
    {},
    true,
    "/test-worksheets",
  );

  const { takeAction }: IUseApi<{}, { data: TestWorksheet }> = useApi();

  const onSubmit: FormSubmitHandler<TestWorksheetFormValues> = (
    values,
    _,
    props,
  ) => {
    return takeAction("update", `test-worksheets/${uuid}`, {
      ...values,
      test_method_ids: values.test_method_ids.map((v) => v.value),
    })
      .then(({ data }) => {
        setTestWorksheet(data.data);
        toast.success("Test worksheet updated");
        // @ts-ignore
        props?.initialize?.({
          ...data.data,
          test_method_ids: data.data.test_methods.map((testMethod) => {
            return {
              label: (
                <div>
                  <p className="text-dark mb-0">{testMethod.name}</p>
                  <p className="mb-0 text-muted">{testMethod.method}</p>
                </div>
              ),
              value: testMethod.id,
              text: `${testMethod.name} ${testMethod.method}`,
            };
          }),
          registers: data.data.registers.map((register) => {
            return {
              register_id: register.id,
            };
          }),
        });
      })
      .catch(formError);
  };

  if (loading) {
    return <Spinner loading />;
  }

  return (
    <>
      <HeaderPage
        crumbs={[
          {
            name: "Test Worksheets",
            link: "/test-worksheets",
          },
          {
            name: testWorksheet.document.name,
            link: `/test-worksheets/${uuid}`,
            active: true,
          },
        ]}
        titlePage={testWorksheet.document.name}
      />

      <TestWorksheetForm
        initialValues={{
          ...testWorksheet,
          test_method_ids: testWorksheet.test_methods.map((testMethod) => {
            return {
              label: (
                <div>
                  <p className="text-dark mb-0">{testMethod.name}</p>
                  <p className="mb-0 text-muted">{testMethod.method}</p>
                </div>
              ),
              value: testMethod.id,
              text: `${testMethod.name} ${testMethod.method}`,
            };
          }),
          registers: testWorksheet.registers.map((register) => {
            return {
              register_id: register.id,
            };
          }),
        }}
        onSubmit={onSubmit}
        form="EDIT_TEST_WORKSHEET"
      />
    </>
  );
};

export default TestWorksheetProfile;
