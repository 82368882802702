import { useParams } from "react-router-dom";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import Profile from "../profile";
import { ProfileTab } from "../tabs/tabTypes";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import CustomScaleLoader from "../utils/scaleLoader";
import {
  ExcelExtraction,
  ExcelExtractionProfilePages,
} from "./excelExtractionTypes";
import ShowExcelExtractionProfilePage from "./ShowExcelExtractionProfilePage";

const ExcelExtractionProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const {
    data,
    setData: setExcelExtraction,
    loading,
  }: IUseApi<ExcelExtraction> = useApi(`excel-extractions/${uuid}`, null, true);

  const excelExtraction = data as ExcelExtraction;

  const tabs: ProfileTab<ExcelExtractionProfilePages>[] = [
    {
      label: "Details",
      link: `/excel-extractions/${uuid}/details`,
      page: "details",
    },
    {
      label: "Extract",
      link: `/excel-extractions/${uuid}/extract`,
      page: "extract",
    },
  ];

  return (
    <>
      <HeaderPageAdminFilter
        crumbs={[
          {
            name: "Excel Extractions",
            link: "/excel-extractions",
          },
          {
            name: excelExtraction ? excelExtraction.name : "-",
            link: "/excel-extractions",
            active: true,
          },
        ]}
        titlePage={excelExtraction?.name ?? "-"}
      />
      {loading ? (
        <CustomScaleLoader>Fetching Excel Extraction...</CustomScaleLoader>
      ) : (
        <Profile<ExcelExtractionProfilePages>
          content={
            <ShowExcelExtractionProfilePage
              excelExtraction={excelExtraction}
              setExcelExtraction={setExcelExtraction}
            />
          }
          header={
            <DashboardHeader>
              <DashboardH3>{excelExtraction?.name ?? "-"}</DashboardH3>
            </DashboardHeader>
          }
          tabs={tabs}
        />
      )}
    </>
  );
};

export default ExcelExtractionProfile;
