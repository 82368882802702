export enum LineItemStyle {
  Deliverables = 1,
  Jobs = 2,
}

export interface Invoice {
  uuid: string;
  name: string;
  id: string;
  date?: string;
  prefix: string;
  number: string;
  line_item_style: LineItemStyle;
  requested_by?: {
    name: string;
    email: string;
  };
  requested_at: string;
  completed_by?: {
    name: string;
    email: string;
  };
  completed_at: string;
  link: string;
  badge?: {
    color: string;
    text: string;
  };
  project?: {
    uuid: string;
    name: string;
    id: string;
    purchase_order: string;
    link: string;
  };
  client?: {
    link: string;
    name: string;
    phone: string;
    email: string[];
    address: string;
    average_days_to_pay: string;
    is_cod: boolean;
  };
  deliverables?: {
    name: string;
    uuid: string;
    id: string;
    link: string;
    job: {
      name: string;
      link: string;
      branch: string;
    };
  }[];
  line_items?: {
    units: string;
    price: string;
    item: string;
    line_item: string;
    quantity: string;
    billable_item_units: string;
    uuid: string;
    total_quantity: string;
    suffix: string;
    description: string;
  }[];
  total_due: string;
  created_at: string;
  deliverable_uuids: string;
  integration: string;
  is_credit_note: string;
  estimated_integrations: string;
  first_completed_at: string;
  paid_at: string;
  amount_paid: string;
  is_overdue: string;
  current_total_due: string;
  expected_payment_date: string;
  sent_invoices?: {
    created_at: string;
    uuid: string;
    sent_to: string[];
    url: string;
    sent_by: {
      name: string;
      email: string;
    };
  }[];
  due_date: string;
}

export interface InvoiceState {
  invoice: Invoice;
  setInvoice: (invoice: Invoice) => void;
}

export enum InvoiceStatus {
  AwaitingInvoice,
  Invoiced,
  Paid,
  InvoiceNotRequired,
}
