import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getPurchases = (
  { pageParam = 1 },
  search?: string,
  branchId?: number,
) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(
      `purchases?page=${pageParam}&filter[search]=${search}&filter[branch_id]=${branchId}`,
    )
    .then(({ data }) => data);
};

export default function useBranchPurchases(search?: string, branchId?: number) {
  return useInfiniteQuery(
    ["purchases", { search, branchId }],
    (pageParam) => getPurchases(pageParam, search, branchId),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }
        const nextPage = meta.current_page + 1;

        return nextPage;
      },
    },
  );
}
