import React, { useState, useCallback, useEffect } from "react";
import { Field, WrappedFieldArrayProps } from "redux-form";
import { BiCloudUpload } from "react-icons/bi";
import { toast } from "react-toastify";
import { useDropzone, FileRejection, DropEvent } from "react-dropzone";
import useApi from "../api/useApi";
import useUploadMultipleToS3 from "../hooks/useUploadMultipleToS3";
import renderField from "../utils/renderField";
import errorSwal from "../utils/errorSwal";
import SelectInput from "../form/SelectInput";
import { IUseApi } from "../api/apiTypes";
import required, { excelCell } from "../utils/required";
import { BsFileExcel } from "react-icons/bs";
import { ExcelExtraction } from "../excelExtractions/excelExtractionTypes";

interface ExtractionConfig {
  sheet: string;
  range: string;
  columnName?: string;
  dataType?: "text" | "number" | "date" | "boolean";
  transformation?: string;
}

interface SheetData {
  fileName: string;
  sheet: string;
  range: string;
  columnName?: string;
  data: any[][];
}

interface UploadedFile extends File {
  path?: string;
  progress?: number;
  uuid?: string;
}

interface ApiResponse {
  data: {
    sheets?: string[];
    preview?: SheetData[];
    download_path?: string;
  };
  error?: string;
}

interface FormValues {
  extractions: ExtractionConfig[];
}

interface ExtendedFile extends File {
  path?: string;
  progress?: number;
  uuid?: string;
}

interface UploadHookResult {
  files: ExtendedFile[];
  setFiles: React.Dispatch<React.SetStateAction<ExtendedFile[]>>;
  loading: boolean;
  upload: (
    files: File[],
    onProgress?: (progress: number) => void,
  ) => Promise<ExtendedFile[]>;
  setBucket: React.Dispatch<React.SetStateAction<string>>;
}

interface ExcelExtractorProps {
  excelExtraction: ExcelExtraction;
}

const ExcelExtractor: React.FC<ExcelExtractorProps> = ({ excelExtraction }) => {
  const [sheets, setSheets] = useState<string[]>([]);
  const [extractedData, setExtractedData] = useState<SheetData[]>([]);
  const [uploadedFilePaths, setUploadedFilePaths] = useState<any[]>([]);
  const [pollingKey, setPollingKey] = useState<string | null>(null);
  const [isPolling, setIsPolling] = useState(false);

  const { upload, files, setFiles, loading } = useUploadMultipleToS3(
    "tmp/excel-extracts",
  ) as UploadHookResult;
  const { takeAction }: IUseApi = useApi();

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (pollingKey && isPolling) {
      interval = setInterval(async () => {
        try {
          const response = await takeAction(
            "show",
            `/excel-extract/${pollingKey}`,
          );

          if (response.data.data.status === "completed") {
            setIsPolling(false);
            window.location.href = response.data.data.path;
          } else if (response.data.data.status === "failed") {
            setIsPolling(false);
            toast.error(`Excel extraction failed: ${response.data.data.error}`);
          }
        } catch (err) {
          setIsPolling(false);
          errorSwal(err);
        }
      }, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [pollingKey, isPolling, takeAction]);

  const onDrop = useCallback(
    async (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event: DropEvent,
    ) => {
      if (fileRejections.length > 0) {
        toast.error(
          `Invalid file type(s): ${fileRejections
            .map((f) => f.file.name)
            .join(", ")}. Please upload only Excel or CSV files.`,
        );
        return;
      }

      try {
        const uploadedFiles = await upload(acceptedFiles);
        setFiles(uploadedFiles);
      } catch (err) {
        errorSwal(err);
      }
    },
    [upload, setFiles],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept:
      "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,application/vnd.ms-excel.sheet.macroEnabled.12,application/vnd.ms-excel.sheet.binary.macroEnabled.12",
    multiple: true,
    disabled: loading,
  });

  const onSubmit = async () => {
    if (files.length === 0) {
      toast.error("Please upload at least one Excel file");
      return;
    }

    try {
      const response = await takeAction("store", "/excel-extract", {
        paths: files.map((file) => ({
          name: file.name,
          path: file.path,
        })),
        extractions: excelExtraction.columns.map((column) => ({
          sheet: column.sheet,
          cell: column.cell,
          columnName: column.column_name,
        })),
      });

      setPollingKey(response.data.data.key);
      setIsPolling(true);
      toast.info("Processing your request. This may take a few minutes...");
    } catch (err) {
      errorSwal(err);
    }
  };

  return (
    <div className="border bg-white shadow-sm rounded-lg position-relative">
      {isPolling && (
        <div
          style={{
            bottom: "5rem",
            right: "5rem",
            zIndex: 1000,
            borderTop: "5px solid rgb(56, 156, 255)",
          }}
          className="position-fixed d-flex justify-content-center align-items-center bg-white p-3 rounded-lg shadow-sm "
        >
          <i className="fa fa-spinner fa-spin me-3 tx-18" />
          Extracting data, please don't refresh the page.
        </div>
      )}
      <div className="p-3">
        <div
          {...getRootProps()}
          className="tn-300 rounded-lg d-flex align-items-center w-100 h-100 justify-content-center"
          style={{
            minHeight: "9rem",
            border: isDragActive ? "2px dashed #007bff" : "1px dashed #dee2e6",
            backgroundColor: isDragActive
              ? "rgba(0,123,255,0.05)"
              : "transparent",
            cursor: "pointer",
            transition: "all 0.2s ease",
          }}
        >
          <input {...getInputProps()} />
          {loading ? (
            <div className="text-center">
              <i className="fa fa-spinner fa-spin mb-2" />
              <div
                className="progress"
                style={{ height: "2px", width: "200px" }}
              ></div>
            </div>
          ) : (
            <div className="text-center">
              <div>
                <BiCloudUpload size={42} className="text-warning" />
              </div>
              <p className="mb-1 text-dark">
                {isDragActive
                  ? "Drop the files here..."
                  : "Drag & drop files here or click to select"}
              </p>
              <small className="d-block">
                Supported formats: .xlsx, .xls, .csv, .xlsm, .xlsb
              </small>
              <small className="d-block">
                All files must have the same sheet structure
              </small>
            </div>
          )}
        </div>
        {files.length > 0 && (
          <div className="mt-5">
            <h6 className="mb-2 text-dark">Uploaded Files:</h6>
            <div className="row">
              {files.map((file, index) => (
                <div key={index} className="col-md-4 mb-3">
                  <div className="p-3 bg-white d-flex align-items-center rounded-lg border shadow-sm">
                    <BsFileExcel className="text-success me-3" size={24} />
                    <p className="mb-0 text-secondary">{file.name}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="mt-4">
              <button
                type="button"
                className="btn btn-primary"
                onClick={onSubmit}
                disabled={loading || isPolling}
              >
                Extract Data
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const RenderExtractions = ({
  fields,
  sheets,
  loading,
  extractions,
}: WrappedFieldArrayProps & {
  sheets: string[];
  loading: boolean;
  extractions: ExtractionConfig[];
}) => (
  <>
    <div className="d-flex justify-content-between align-items-center mb-3">
      <h6 className="mb-0">Extraction Configuration</h6>
      <button
        type="button"
        className="btn btn-primary btn-sm"
        onClick={() =>
          fields.push({
            sheet: extractions[extractions.length - 1].sheet,
          })
        }
      >
        Add Column
      </button>
    </div>

    {fields.map((extraction: string, index: number) => (
      <div key={index} className="card mb-3 p-3">
        <div className="row g-3">
          <div className="col-md-4">
            <Field
              name={`${extraction}.sheet`}
              component={SelectInput}
              className="form-select"
              label="Sheet"
              validate={required}
              required
              options={sheets.map((sheet) => ({
                label: sheet,
                value: sheet,
              }))}
            />
          </div>
          <div className="col-md-3">
            <Field
              name={`${extraction}.cell`}
              component={renderField}
              type="text"
              label="Cell"
              placeholder="Cell (e.g., A1)"
              validate={[excelCell, required]}
              required
            />
          </div>
          <div className="col-md-3">
            <Field
              name={`${extraction}.column_name`}
              component={renderField}
              type="text"
              label="Column Name"
              validate={required}
              required
            />
          </div>
          <div className="col-md-2 d-flex align-items-end">
            <button
              type="button"
              className="btn btn-outline-danger w-100"
              onClick={() => fields.remove(index)}
              disabled={loading}
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    ))}
  </>
);

export default ExcelExtractor;
