import { AxiosError } from "axios";
import { IUseApi } from "../api/apiTypes";
import formError from "../utils/formError";
import ExcelExtractionForm from "./ExcelExtractionForm";
import useApi from "../api/useApi";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import HeaderPage from "../header/HeaderPage";
const AddExcelExtraction = () => {
  const { takeAction }: IUseApi = useApi();

  const history = useHistory();

  const onSubmit = async (values: any) => {
    try {
      const response = await takeAction("store", "/excel-extractions", values);

      toast.success("Excel extraction configuration saved successfully");

      history.push(`/excel-extractions/${response.data.data.uuid}/extract`);
    } catch (err) {
      return formError(err as AxiosError<any>);
    }
  };

  return (
    <>
      <HeaderPage
        titlePage="Add Excel Extraction"
        crumbs={[
          {
            name: "Excel Extractions",
            link: "/excel-extractions",
          },
          {
            name: "Add Excel Extraction",
            active: true,
          },
        ]}
      />
      <ExcelExtractionForm onSubmit={onSubmit} />
    </>
  );
};

export default AddExcelExtraction;
