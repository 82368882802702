import { useCallback } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { BiCloudUpload } from "react-icons/bi";
import { toast } from "react-toastify";

interface FileInputDropzoneProps {
  acceptedFileTypes?: string;
  onFileSelect: (file: File | null) => void;
  uploadPercent?: number;
  selectedFile: File | null;
  multiple?: boolean;
  files?: File[];
  onRemoveFile?: (index: number) => void;
  helpText?: string;
}

const FileInputDropzone = ({
  acceptedFileTypes,
  onFileSelect,
  uploadPercent = 0,
  selectedFile,
  multiple = false,
  files = [],
  onRemoveFile,
  helpText,
}: FileInputDropzoneProps) => {
  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length > 0) {
        const errorMessage = fileRejections.map((fileRejection) => {
          return `File: ${fileRejection.file.name} unable to be added. ${fileRejection.errors[0].message}`;
        });

        toast.error(errorMessage.join(""));
        return;
      }

      if (acceptedFiles.length > 0) {
        if (multiple) {
          acceptedFiles.forEach((file) => onFileSelect(file));
        } else {
          onFileSelect(acceptedFiles[0]);
        }
      }
    },
    [onFileSelect, multiple],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    accept: acceptedFileTypes,
  });

  return (
    <div className="form-group">
      <label className="form-control-label">File</label>
      <div
        {...getRootProps()}
        style={{
          border: "2px dashed #ccc",
          borderRadius: "4px",
          padding: "20px",
          textAlign: "center",
          backgroundColor:
            isDragActive || (multiple ? files.length > 0 : selectedFile)
              ? "rgba(0,123,255,0.05)"
              : "transparent",
          cursor: "pointer",
          transition: "all 0.2s ease",
        }}
      >
        <input {...getInputProps()} />
        {multiple ? (
          files.length > 0 ? (
            <div className="text-center">
              <div>
                <BiCloudUpload size={42} className="text-success" />
              </div>
              <div className="mb-2">
                {files.map((file, index) => (
                  <div
                    key={index}
                    className="d-flex align-items-center justify-content-center mb-1"
                  >
                    <span className="text-dark">{file.name}</span>
                    {onRemoveFile && (
                      <button
                        type="button"
                        className="btn btn-link text-danger p-0 ms-2"
                        onClick={(e) => {
                          e.stopPropagation();
                          onRemoveFile(index);
                        }}
                      >
                        ×
                      </button>
                    )}
                  </div>
                ))}
              </div>
              <small className="d-block text-muted">
                Click or drag more files to add
              </small>
            </div>
          ) : (
            <div className="text-center">
              <div>
                <BiCloudUpload size={42} className="text-warning" />
              </div>
              <p className="mb-1 text-dark">
                {isDragActive
                  ? "Drop the files here..."
                  : "Drag & drop files here or click to select"}
              </p>
              <small className="d-block text-muted">
                {acceptedFileTypes
                  ? `Supported formats: ${acceptedFileTypes}`
                  : ""}
              </small>
            </div>
          )
        ) : // Single file upload UI
        selectedFile ? (
          <div className="text-center">
            <div>
              <BiCloudUpload size={42} className="text-success" />
            </div>
            <div className="mb-2">
              <div className="d-flex align-items-center justify-content-center mb-1">
                <span className="text-dark">{selectedFile.name}</span>
                <button
                  type="button"
                  className="btn btn-link text-danger p-0 ms-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    onFileSelect(null);
                  }}
                >
                  ×
                </button>
              </div>
              {uploadPercent > 0 && (
                <div className="progress mt-2">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${uploadPercent}%` }}
                    aria-valuenow={uploadPercent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  >
                    {uploadPercent}%
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="text-center">
            <div>
              <BiCloudUpload size={42} className="text-warning" />
            </div>
            <p className="mb-1 text-dark">
              {isDragActive
                ? "Drop the file here..."
                : "Drag & drop file here or click to select"}
            </p>
            <small className="d-block text-muted">
              {acceptedFileTypes
                ? `Supported formats: ${acceptedFileTypes}`
                : ""}
            </small>
          </div>
        )}
      </div>
      {helpText && <small className="d-block mt-2">{helpText}</small>}
    </div>
  );
};

export default FileInputDropzone;
