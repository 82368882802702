import React from "react";
import { MonthlyTotals } from "../types";
import { formatAmount } from "../utils/budgetFormatters";
import { Branch } from "../branchTypes";
import { UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";

interface BudgetSummaryProps {
  months: string[];
  revenueTotals: MonthlyTotals;
  costOfSalesTotals: MonthlyTotals;
  operatingExpensesTotals: MonthlyTotals;
  capexTotals: MonthlyTotals;
  branch: Branch | null;
  columnStyles: {
    account: { width: number };
    accountEditing: { minWidth: number };
    month: { width: number };
  };
}

const BudgetSummary: React.FC<BudgetSummaryProps> = ({
  months,
  revenueTotals,
  costOfSalesTotals,
  operatingExpensesTotals,
  capexTotals,
  branch,
  columnStyles,
}) => {
  // Calculate net profit
  const calculateNetProfit = (month: string): number => {
    return (
      (revenueTotals[month] || 0) +
      (costOfSalesTotals[month] || 0) +
      (operatingExpensesTotals[month] || 0)
    );
  };

  // Calculate total net profit
  const totalNetProfit =
    Object.values(revenueTotals).reduce((a, b) => a + b, 0) +
    Object.values(costOfSalesTotals).reduce((a, b) => a + b, 0) +
    Object.values(operatingExpensesTotals).reduce((a, b) => a + b, 0);

  // Calculate revenue breakdowns based on percentages
  const calculateRevenueBreakdown = (
    month: string,
    percentage: number | undefined,
  ): number => {
    if (!percentage) return 0;
    return ((revenueTotals[month] || 0) * percentage) / 100;
  };

  // Calculate total revenue breakdowns
  const calculateTotalRevenueBreakdown = (
    percentage: number | undefined,
  ): number => {
    if (!percentage) return 0;
    const totalRevenue = Object.values(revenueTotals).reduce(
      (a, b) => a + b,
      0,
    );
    return (totalRevenue * percentage) / 100;
  };

  return (
    <div className="mt-5 mb-5">
      <h5 className="mb-3 text-dark">Budget Summary</h5>
      <table className="bg-white shadow-sm rounded-lg budget-table">
        <thead>
          <tr>
            <th style={columnStyles.account}>Category</th>
            {months.map((month) => (
              <th key={month} className="text-end">
                {month}
              </th>
            ))}
            <th className="text-end" style={{ width: 150 }}>
              Total
            </th>
            <th style={{ width: 50 }}></th>
          </tr>
        </thead>
        <tbody>
          {/* Revenue Row */}
          <tr className="table-success money">
            <td className="fw-bolder">Revenue</td>
            {months.map((month) => (
              <td key={month} className="text-end">
                {formatAmount(revenueTotals[month], "revenue")}
              </td>
            ))}
            <td className="text-end">
              {formatAmount(
                Object.values(revenueTotals).reduce((a, b) => a + b, 0),
                "revenue",
              )}
            </td>
            <td></td>
          </tr>

          {/* Revenue Breakdown Rows - only show if percentages are defined */}
          {branch?.recurring_revenue_percentage ? (
            <tr className="table-success money" style={{ opacity: 0.8 }}>
              <td className="ps-4">
                <span id="recurring-revenue-info">
                  Recurring Revenue ({branch.recurring_revenue_percentage}%)
                </span>
                <UncontrolledPopover
                  placement="top"
                  target="recurring-revenue-info"
                  trigger="hover"
                >
                  <PopoverHeader>Recurring Revenue</PopoverHeader>
                  <PopoverBody>
                    Revenue from recurring clients, calculated as{" "}
                    {branch.recurring_revenue_percentage}% of total revenue.
                  </PopoverBody>
                </UncontrolledPopover>
              </td>
              {months.map((month) => (
                <td key={month} className="text-end">
                  {formatAmount(
                    calculateRevenueBreakdown(
                      month,
                      branch.recurring_revenue_percentage,
                    ),
                    "revenue",
                  )}
                </td>
              ))}
              <td className="text-end">
                {formatAmount(
                  calculateTotalRevenueBreakdown(
                    branch.recurring_revenue_percentage,
                  ),
                  "revenue",
                )}
              </td>
              <td></td>
            </tr>
          ) : null}

          {branch?.key_client_percentage ? (
            <tr className="table-success money" style={{ opacity: 0.8 }}>
              <td className="ps-4">
                <span id="key-client-info">
                  Key Client Revenue ({branch.key_client_percentage}%)
                </span>
                <UncontrolledPopover
                  placement="top"
                  target="key-client-info"
                  trigger="hover"
                >
                  <PopoverHeader>Key Client Revenue</PopoverHeader>
                  <PopoverBody>
                    Revenue from key clients, calculated as{" "}
                    {branch.key_client_percentage}% of total revenue.
                  </PopoverBody>
                </UncontrolledPopover>
              </td>
              {months.map((month) => (
                <td key={month} className="text-end">
                  {formatAmount(
                    calculateRevenueBreakdown(
                      month,
                      branch.key_client_percentage,
                    ),
                    "revenue",
                  )}
                </td>
              ))}
              <td className="text-end">
                {formatAmount(
                  calculateTotalRevenueBreakdown(branch.key_client_percentage),
                  "revenue",
                )}
              </td>
              <td></td>
            </tr>
          ) : null}

          {branch?.key_project_percentage ? (
            <tr className="table-success money" style={{ opacity: 0.8 }}>
              <td className="ps-4">
                <span id="key-project-info">
                  Key Project Revenue ({branch.key_project_percentage}%)
                </span>
                <UncontrolledPopover
                  placement="top"
                  target="key-project-info"
                  trigger="hover"
                >
                  <PopoverHeader>Key Project Revenue</PopoverHeader>
                  <PopoverBody>
                    Revenue from key projects, calculated as{" "}
                    {branch.key_project_percentage}% of total revenue.
                  </PopoverBody>
                </UncontrolledPopover>
              </td>
              {months.map((month) => (
                <td key={month} className="text-end">
                  {formatAmount(
                    calculateRevenueBreakdown(
                      month,
                      branch.key_project_percentage,
                    ),
                    "revenue",
                  )}
                </td>
              ))}
              <td className="text-end">
                {formatAmount(
                  calculateTotalRevenueBreakdown(branch.key_project_percentage),
                  "revenue",
                )}
              </td>
              <td></td>
            </tr>
          ) : null}

          {branch?.one_off_client_percentage ? (
            <tr className="table-success money" style={{ opacity: 0.8 }}>
              <td className="ps-4">
                <span id="one-off-client-info">
                  One-off Client Revenue ({branch.one_off_client_percentage}%)
                </span>
                <UncontrolledPopover
                  placement="top"
                  target="one-off-client-info"
                  trigger="hover"
                >
                  <PopoverHeader>One-off Client Revenue</PopoverHeader>
                  <PopoverBody>
                    Revenue from one-off clients, calculated as{" "}
                    {branch.one_off_client_percentage}% of total revenue.
                  </PopoverBody>
                </UncontrolledPopover>
              </td>
              {months.map((month) => (
                <td key={month} className="text-end">
                  {formatAmount(
                    calculateRevenueBreakdown(
                      month,
                      branch.one_off_client_percentage,
                    ),
                    "revenue",
                  )}
                </td>
              ))}
              <td className="text-end">
                {formatAmount(
                  calculateTotalRevenueBreakdown(
                    branch.one_off_client_percentage,
                  ),
                  "revenue",
                )}
              </td>
              <td></td>
            </tr>
          ) : null}

          {/* Cost of Sales Row */}
          <tr className="table-danger money">
            <td className="fw-bolder">Cost of Sales</td>
            {months.map((month) => (
              <td key={month} className="text-end">
                {formatAmount(costOfSalesTotals[month], "cost_of_sales")}
              </td>
            ))}
            <td className="text-end">
              {formatAmount(
                Object.values(costOfSalesTotals).reduce((a, b) => a + b, 0),
                "cost_of_sales",
              )}
            </td>
            <td></td>
          </tr>

          {/* Operating Expenses Row */}
          <tr className="table-warning money">
            <td className="fw-bolder">Operating Expenses</td>
            {months.map((month) => (
              <td key={month} className="text-end">
                {formatAmount(
                  operatingExpensesTotals[month],
                  "operating_expenses",
                )}
              </td>
            ))}
            <td className="text-end">
              {formatAmount(
                Object.values(operatingExpensesTotals).reduce(
                  (a, b) => a + b,
                  0,
                ),
                "operating_expenses",
              )}
            </td>
            <td></td>
          </tr>

          {/* CAPEX Row */}
          <tr className="table-info money">
            <td className="fw-bolder">Capital Expenditure (CAPEX)</td>
            {months.map((month) => (
              <td key={month} className="text-end">
                {formatAmount(capexTotals[month], "capex")}
              </td>
            ))}
            <td className="text-end">
              {formatAmount(
                Object.values(capexTotals).reduce((a, b) => a + b, 0),
                "capex",
              )}
            </td>
            <td></td>
          </tr>

          {/* Net Profit Row */}
          <tr
            className="bg-light font-weight-bold"
            style={{ borderTop: "2px solid #dee2e6" }}
          >
            <td className="fw-bolder">EBITDA</td>
            {months.map((month) => {
              const netProfit = calculateNetProfit(month);
              return (
                <td
                  key={month}
                  className={`text-end ${
                    netProfit >= 0 ? "text-success" : "text-danger"
                  }`}
                >
                  {formatAmount(netProfit, "revenue")}
                </td>
              );
            })}
            <td
              className={`text-end fw-bold ${
                totalNetProfit >= 0 ? "text-success" : "text-danger"
              }`}
            >
              {formatAmount(totalNetProfit, "revenue")}
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BudgetSummary;
