import { d } from "msw/lib/SetupApi-f4099ef3";
import { AuthUser } from "./../user/userTypes";

const userHasAccessTo = (user: AuthUser, model: string, method: string, modelId?: number) => {
  // Admin always has access
  if (user.is_admin) {
    return true;
  }

  // Check for matching ACL item
  const hasPermission = user.acl_items.find(item => {
    const hasBaseAccess = item.model === model && item.method === method;
    
    // If modelId is provided, check for specific access
    if (modelId) {
      return hasBaseAccess && item.model_id === modelId;
    }

    return hasBaseAccess;
  });

  return !!hasPermission;
};

export default userHasAccessTo;
