import { Field, reduxForm } from "redux-form";
import { useAuth } from "../../context/auth-context";
import CustomForm from "../customFields/CustomForm";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";

import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";
import LaboratoryDetails from "./LaboratoryDetails";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import { useHistory, useParams } from "react-router-dom";
import deleteSwal from "../utils/deleteSwal";
import isSubmitting from "../utils/submitting";
import { toast } from "react-toastify";

const BranchForm = (props) => {
  const { user } = useAuth();
  const { initialValues, handleSubmit } = props;

  const { uuid } = useParams();
  const history = useHistory();

  const { takeAction, loading } = useApi();

  const deleteBranch = () => {
    deleteSwal(initialValues?.name).then(() => {
      return takeAction("destroy", `organisation-branches/${uuid}`)
        .then(() => {
          history.push(`/branches`);
          toast.success(`${initialValues?.name} deleted successfully`);
        })
        .catch(errorSwal);
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-4 form-group">
          <Field
            label="Tracking Site"
            name="tracking_site_id"
            component={SelectInputAsync}
            url="/trackingsites/sites"
            validate={required}
            required
          />
        </div>

        <div className="col-lg-4 form-group">
          <Field
            label="Division"
            name="division_id"
            component={SelectInputAsync}
            url="/organisationdivisions/divisions"
            validate={required}
            required
          />
        </div>

        <div className="col-lg-4 form-group">
          <Field
            label="Branch Manager"
            name="branch_manager_id"
            component={SelectInputAsync}
            url="/users/staff"
            validate={required}
            required
          />
        </div>

        <div className="col-lg-12 form-group">
          <Field
            label="Pause Deliverables"
            name="pause_deliverables"
            component={renderToggleInput}
          />
        </div>

        <LaboratoryDetails form={props.form} change={props.change} />

        <CustomForm
          customForm={initialValues?.custom_form ?? props.customFields}
          {...props}
          shouldError={true}
        />

        <div className="col-lg-12 mg-t-25 d-none d-lg-block" />

        <div className="form-group col-lg-12">
          <SubmitButton {...props} />
        </div>
        {initialValues?.uuid && user.is_admin && (
          <div className="form-group col-lg-3">
            <button
              type="button"
              className="btn btn-outline-danger form-control"
              disabled={loading}
              onClick={deleteBranch}
            >
              {isSubmitting(
                loading,
                <>
                  <i className="fa fa-trash mg-r-5" /> Delete
                </>,
                "Deleting",
              )}
            </button>
          </div>
        )}
      </div>
    </form>
  );
};

const form = reduxForm({});

export default form(BranchForm);
