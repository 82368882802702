import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import formError from "../utils/formError";
import JournalForm from "./JournalForm";
import Spinner from "../utils/Spinner";
import { IUseApi } from "../api/apiTypes";
import { Journal } from "./journalTypes";
import { FiCopy } from "react-icons/fi";

const JournalProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const history = useHistory();

  const {
    takeAction,
    loading,
    data: journal,
    setData: setJournal,
    refreshData,
  }: IUseApi<Journal, { data: Journal }> = useApi(
    `journals/${uuid}`,
    null,
    true,
  );

  const onSubmit = (values: any) => {
    return takeAction("update", `journals/${uuid}`, values)
      .then(({ data }) => {
        toast.success(`${values.narration} updated successfully`);
        setJournal(data.data);
      })
      .catch(formError);
  };

  if (loading && !journal) {
    return <Spinner loading={loading} />;
  }

  return (
    <>
      <HeaderPage
        titlePage={journal?.narration ?? ""}
        crumbs={[
          {
            name: "Journals",
            link: "/journals",
            active: false,
          },
          {
            name: journal?.narration ?? "",
            link: `/journals/${uuid}`,
            active: true,
          },
        ]}
      />

      <div className="mt-4 d-flex justify-content-between">
        <label className="section-title my-0">Journal Details</label>
        <div>
          <Link
            to={{
              pathname: "/journals/add",
              state: { journal },
            }}
            title="Copy Journal"
          >
            <FiCopy className="text-secondary" size={16} />
          </Link>
        </div>
      </div>
      <hr className="w-100" />

      <JournalForm
        initialValues={journal}
        journal={journal}
        onSubmit={onSubmit}
        refreshData={refreshData}
        form="EditJournal"
      />
    </>
  );
};

export default JournalProfile;
