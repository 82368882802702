import { useMemo } from "react";
import HeaderPage from "../header/HeaderPage";
import PaginatedList from "../pagination/PaginatedList";
import useOnboardingTemplates from "./hooks/useOnboardingTemplates";
import ReactTable from "../table/ReactTable";
import useModal from "../hooks/useModal";
import { Button } from "reactstrap";
import { RiAddFill } from "react-icons/ri";
import FormModal from "../utils/FormModal";
import { Field, FormSubmitHandler } from "redux-form";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { useQueryClient } from "react-query";
import formError from "../utils/formError";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import deleteSwal from "../utils/deleteSwal";
import { Link } from "react-router-dom";
import TextButton from "../utils/TextButton";
import { FiEdit3 } from "react-icons/fi";
import { BiTrash } from "react-icons/bi";

interface OnboardingTemplate {
  uuid?: string;
  name: string;
  steps_count: number;
}

const OnboardingTemplatesList = () => {
  const { modal, toggle } = useModal();
  const { takeAction }: IUseApi = useApi();
  const queryClient = useQueryClient();

  const onSubmit: FormSubmitHandler<OnboardingTemplate> = (values) => {
    return takeAction("store", "onboarding-templates", values)
      .then(() => {
        queryClient.invalidateQueries("onboarding-templates");
        toggle();
        toast.success("Onboarding Template created");
      })
      .catch(formError);
  };

  return (
    <>
      <HeaderPage crumbs={[]} titlePage="Onboarding Templates" />
      <PaginatedList
        indexHook={useOnboardingTemplates}
        listName="onboardingTemplates"
        extraButtons={() => (
          <Button onClick={toggle} className="p-0 ms-1" color="link">
            <RiAddFill aria-label="Add New Template" className="tx-24 ms-1" />
          </Button>
        )}
        originalFilters={[]}
        list={({ data }) => {
          const templates = data as OnboardingTemplate[];

          return (
            <div className="col-12">
              <OnboardingTemplateTable
                templates={[
                  {
                    name: "Default",
                    steps_count: 0,
                  },
                  ...(templates || []),
                ]}
              />
            </div>
          );
        }}
      />
      <OnboardingTemplateModal
        form="OnboardingTemplate"
        onSubmit={onSubmit}
        toggle={toggle}
        modal={modal}
      />
    </>
  );
};

const OnboardingTemplateModal = ({
  toggle,
  modal,
  onSubmit,
  initialValues,
  form,
}: {
  toggle: Function;
  modal: boolean;
  onSubmit?: Function;
  initialValues?: OnboardingTemplate;
  form?: string;
}) => {
  return (
    <FormModal
      toggle={toggle}
      modal={modal}
      form={form}
      title={initialValues ? "Update Template" : "Create Template"}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      <div className="form-group col-12">
        <Field
          name="name"
          component={RenderField}
          required
          validate={required}
          label="Name"
        />
      </div>
    </FormModal>
  );
};

const OnboardingTemplateTable = ({
  templates,
}: {
  templates: OnboardingTemplate[];
}) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        cell: (info: any) => (
          <Link
            className="text-dark"
            to={
              info.row.original.uuid
                ? `/onboarding-templates/${info.row.original.uuid}`
                : "/onboarding-steps"
            }
          >
            <p className="text-dark mb-0">{info.row.original.name}</p>
            {info.row.original.steps_count > 0 && (
              <p className="text-muted mb-0">
                {info.row.original.steps_count} step
                {info.row.original.steps_count > 1 ? "s" : ""}
              </p>
            )}
          </Link>
        ),
      },
      {
        accessorKey: "Actions",
        header: "Actions",
        cell: (info: any) => {
          const { modal, toggle } = useModal();
          const queryClient = useQueryClient();
          const { takeAction, loading }: IUseApi = useApi();

          if (!info.row.original.uuid) {
            return "";
          }

          const onSubmit: FormSubmitHandler<OnboardingTemplate> = (values) => {
            return takeAction(
              "update",
              `onboarding-templates/${values.uuid}`,
              values,
            )
              .then(() => {
                toggle();
                toast.success("Template updated");
                queryClient.invalidateQueries("onboarding-templates");
              })
              .catch(formError);
          };

          return (
            <>
              <div className="d-flex align-items-center space-x-3">
                <TextButton
                  className="text-secondary"
                  onClick={toggle}
                  disabled={loading}
                >
                  <FiEdit3 size={18} />
                </TextButton>
                <TextButton
                  onClick={() => {
                    deleteSwal(info.row.original.name)
                      .then(() =>
                        takeAction(
                          "destroy",
                          `onboarding-templates/${info.row.original.uuid}`,
                        ),
                      )
                      .then(() => {
                        toast.success("Template Deleted");
                        queryClient.invalidateQueries("onboarding-templates");
                      })
                      .catch(errorSwal);
                  }}
                  className="text-danger"
                  disabled={loading}
                >
                  <BiTrash size={18} />
                </TextButton>
              </div>
              <OnboardingTemplateModal
                onSubmit={onSubmit}
                initialValues={info.row.original}
                toggle={toggle}
                form={`OnboardingTemplate${info.row.original.uuid}`}
                modal={modal}
              />
            </>
          );
        },
      },
    ],
    [templates],
  );

  return <ReactTable columns={columns} data={templates} disableSearch />;
};

export default OnboardingTemplatesList;
